import React, { HTMLInputTypeAttribute, useState } from "react";

import { getClasses } from "../../utils";
import classes from "./Input.module.css";
import InputLabel from "./InputLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

type Props = {
  name: string;
  placeholder?: string;
  value: string | number;
  type?: HTMLInputTypeAttribute;
  className?: string;
  inputClassName?: string;
  style?: React.CSSProperties;
  textarea?: boolean;
  min?: string;
  max?: string;
  isInvalid?: boolean;
  invalidText?: string;
  autoComplete?: string;
  disabled?: boolean;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onKeyDown?: (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => void;
};

const Input: React.FC<Props> = ({
  name,
  placeholder,
  value,
  type = "text",
  className = "",
  inputClassName = "",
  style = {},
  textarea,
  min,
  max,
  isInvalid,
  invalidText,
  autoComplete,
  disabled,
  onChange,
  onKeyDown,
}) => {
  const [showPass, setShowPass] = useState(false);

  const getDisplayText = () => {
    if (isInvalid) {
      return (!value && "This field is required") || invalidText;
    }
  };

  const getMin = () => {
    if (min) {
      return min;
    }
    if (type === "date") {
      return "2000-01-01";
    }
    if (type === "datetime-local") {
      return "2000-01-01T00:00";
    }
  };

  const getMax = () => {
    if (max) {
      return max;
    }
    if (type === "date") {
      return "9999-12-31";
    }
    if (type === "datetime-local") {
      return "9999-12-31T23:59";
    }
  };

  return (
    <div className={getClasses(classes.main, className)} style={style}>
      <InputLabel label={name} isInvalid={isInvalid} />
      <div
        className={getClasses(
          classes.inputContainer,
          type === "password" ? classes.password : "",
          isInvalid ? classes.error : ""
        )}>
        {textarea ? (
          <textarea
            value={value}
            className={getClasses(classes.textArea, inputClassName)}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        ) : (
          <input
            type={showPass ? "text" : type}
            value={value}
            placeholder={placeholder}
            className={getClasses(classes.input, inputClassName)}
            onChange={onChange}
            onKeyDown={onKeyDown}
            min={getMin()}
            max={getMax()}
            autoComplete={autoComplete}
            disabled={disabled}
          />
        )}
        {type === "password" && (
          <FontAwesomeIcon
            icon={showPass ? faEye : faEyeSlash}
            className={classes.eyeIcon}
            color='var(--app-grey)'
            onClick={() => setShowPass((prevState) => !prevState)}
          />
        )}
      </div>
      {isInvalid && <div className={classes.errorText}>{getDisplayText()}</div>}
    </div>
  );
};

export default Input;

import React from "react";

import { getClasses } from "../../utils";
import classes from "./Tab.module.css";

type Props = {
  name: string;
  active: boolean;
  onClick: () => void;
};

const Tab: React.FC<Props> = ({ name, active, onClick }) => {
  return (
    <div
      className={getClasses(classes.main, active ? classes.active : "")}
      onClick={onClick}>
      {name}
    </div>
  );
};

export default Tab;

import { PropsWithChildren, ReactElement } from "react";
import { useState } from "react";

import { Column } from "../../ts/types";
import Modal, { ModalBody, ModalFooter } from "../Modal/Modal";
import SelectItems from "./SelectItems";
import classes from "./SelectItemsModal.module.css";
import PrimaryButton from "../Buttons/PrimaryButton";
import SearchBar from "../SearchBar/SearchBar";

interface Id {
  id: string;
}

type Props<T> = {
  title: string;
  initialItemsSet: Set<string>;
  items: T[];
  noOfPages: number;
  columns: Column<T>[];
  setItemsHandler: (items: Set<string>) => void;
  onClose: () => void;
  searchItemsHandler: (offset: number, searchText: string) => void;
  isPaginated?: boolean;
};

const SelectItemsModal = <T extends Id>({
  title,
  initialItemsSet,
  items,
  noOfPages,
  columns,
  setItemsHandler,
  onClose,
  searchItemsHandler,
  isPaginated,
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  const [selectedItems, setSelectedItems] = useState<Set<string>>(
    new Set<string>(initialItemsSet)
  );
  const [searchText, setSearchText] = useState("");

  const addHandler = () => {
    setItemsHandler(selectedItems);
    onClose();
  };

  return (
    <Modal
      title={
        <div className={classes.modal}>
          <div>{title}</div>
          <div>
            <SearchBar
              debounce
              onChange={(text) => setSearchText(text)}
              greyBackground
            />
          </div>
        </div>
      }
      onClose={onClose}>
      <>
        <ModalBody>
          <div className={classes.main}>
            <SelectItems<T>
              initialItemsSet={selectedItems}
              setItemsHandler={(item) => setSelectedItems(item)}
              items={items}
              noOfPages={noOfPages}
              columns={columns}
              searchItemsHandler={searchItemsHandler}
              searchText={searchText}
              isPaginated={isPaginated}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton
              name='Add'
              onClick={addHandler}
              className={classes.addBtn}
              disabled={selectedItems.size === 0}
            />
            <PrimaryButton transparent name='Cancel' onClick={onClose} />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};
export default SelectItemsModal;

import { getClasses } from "../../utils";
import classes from "./SelectButton.module.css";

type Props = {
  name: string;
  onClick?: () => void;
  active: boolean;
  className?: string;
};

const SelectButton: React.FC<Props> = ({
  onClick,
  active,
  name,
  className,
}) => {
  return (
    <div
      onClick={onClick}
      className={getClasses(classes.main, active ? classes.active : "")}>
      <div className={className}>{name}</div>
    </div>
  );
};

export default SelectButton;

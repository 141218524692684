import { useSelector } from "react-redux";

import { RootState } from "../../store";
import classes from "./SelectPOD.module.css";
import Select from "../Inputs/Select";

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const SelectPOD: React.FC<Props> = ({ value, onChange }) => {
  const pods = useSelector((state: RootState) => state.pods.pods);

  return (
    <Select
      label='Select POD'
      htmlFor='pod'
      options={pods}
      value={value}
      nameExtractor='name'
      onChange={onChange}
      defaultOption={"Select...."}
      className={classes.main}
      selectContainerClass={classes.selectContainer}
      selectClass={classes.select}
    />
  );
};

export default SelectPOD;

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

import classes from "./Incentive.module.css";
import { AppDispatch, RootState } from "../../../store";
import SearchBar from "../../../components/SearchBar/SearchBar";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Incentive from "../../../ts/models/Incentive";
import { updateIncentiveData } from "../../../store/actions/MasterActions";

import AddIncentive from "./AddIncentive";
import PanelContainer from "../../../components/Containers/PanelContainer";
import Table from "../../../components/CustTable/Table";
import TableContainer from "../../../components/CustTable/TableContainer";
import { Column } from "../../../ts/types";
import LinkButton from "../../../components/Buttons/LinkButton";
import ActionContainer from "../../../components/CustTable/ActionContainer";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import PanelActionContainer from "../../../components/Containers/PanelActionContainer";
import addIcon from "../../../assets/icons/add-circle-white.svg";
import IncentiveSerialNumbersData from "./SerialNumbers/IncentiveSerialNumbers";

type Props = {};

const Incentives: React.FC<Props> = () => {
  const dispatch: AppDispatch = useDispatch();

  const incentives = useSelector(
    (state: RootState) => state.masters.incentives
  );

  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [incentiveToBeEdited, setIncentiveToBeEdited] = useState<Incentive>(
    new Incentive()
  );
  const [isEditIncentive, setIsEditIncentive] = useState(false);
  const addShowHandler = (isEdit: boolean) => {
    setIsEditIncentive(isEdit);
    setShowModal(true);
  };
  const [showSerialNumbersModal, setShowSerialNumbersModal] = useState({
    show: false,
    incentiveId: "",
    description: "",
  });

  const incentiveTableColumn: Column<Incentive>[] = [
    {
      header: "ID",
      accessor: (e, idx) => idx! + 1,
      width: "10%",
    },
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "15%",
    },
    {
      header: "DESCRIPTION",
      accessor: (e) => e.description,
    },
    {
      header: "AMOUNT",
      accessor: (e) => `\$${e.amount}`,
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => {
              addShowHandler(true);
              setIncentiveToBeEdited(e);
            }}
            label='Edit'
            className={classes.editAction}
          />
          <>
            {e.hasSerialNumbers && (
              <LinkButton
                style={{ marginRight: "1rem" }}
                onClick={() =>
                  setShowSerialNumbersModal({
                    show: true,
                    incentiveId: e.id,
                    description: e.description,
                  })
                }
                label={"Serials"}
              />
            )}
          </>
          <LinkButton
            onClick={() => updateStatusHandler(e.id, !e.isActive)}
            label={e.isActive ? "De-activate" : "Activate"}
          />
        </ActionContainer>
      ),
      width: "20%",
    },
  ];

  const updateStatusHandler = (id: string, isActive: boolean) => {
    dispatch(updateIncentiveData({ id, isActive }));
  };

  const filteredIncentive = incentives.filter(
    (incentive) =>
      incentive.description.toLowerCase().includes(searchText.toLowerCase()) ||
      incentive.code.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      {showSerialNumbersModal.show && (
        <IncentiveSerialNumbersData
          incentiveId={showSerialNumbersModal.incentiveId}
          description={showSerialNumbersModal.description}
          onClose={() =>
            setShowSerialNumbersModal({
              show: false,
              incentiveId: "",
              description: "",
            })
          }
        />
      )}
      {showModal && (
        <AddIncentive
          isEdit={isEditIncentive}
          incentive={incentiveToBeEdited}
          onClose={() => {
            setShowModal(false);
            setIsEditIncentive(false);
          }}
        />
      )}
      <PanelContainer name='Incentives' backBtn>
        <PanelActionContainer
          leftChildren={
            <SearchBar
              onChange={(text) => {
                setSearchText(text);
              }}
            />
          }
          rightChildren={
            <PrimaryButton
              name='Add New'
              onClick={() => {
                setIncentiveToBeEdited(new Incentive("", "", ""));
                addShowHandler(false);
              }}
              disabled={false}
              icon={<img src={addIcon} alt='' />}
            />
          }
        />
        <PanelInnerContainer>
          <TableContainer>
            <Table columns={incentiveTableColumn} data={filteredIncentive} />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default Incentives;

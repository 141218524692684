import {
  ACKNOWLEDGEMENTS_URL,
  DISCLAIMERS_URL,
  DISPOSITIONS_URL,
  ENVIRONMENTAL_ALLERGIES_URL,
  ETHNIC_GROUPS_URL,
  GENDERS_URL,
  HELP_URL,
  INCENTIVES_URL,
  INFO_QUESTIONS_URL,
  MEDICATIONS_URL,
  MEDICATION_ALLERGIES_URL,
  PROCEDURES_URL,
  PRODUCTS_URL,
  RACES_URL,
  RELATIONSHIPS_URL,
  ROUTES_URL,
  SCREENING_QUESTIONS_URL,
  SERVICES_URL,
  SITES_URL,
  SYMPTOMS_URL,
  VFC_ELIGIBILITIES_URL,
} from "../../Routes/urls";
import { Master } from "../../ts/enums";
import classes from "./Masters.module.css";
import MasterCard from "./MasterCard";
import PanelContainer from "../../components/Containers/PanelContainer";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import acknowledgementIcon from "../../assets/icons/acknowledgement.svg";
import disclaimerIcon from "../../assets/icons/disclaimer.svg";
import dispositionIcon from "../../assets/icons/disposition.svg";
import ethnicGroupIcon from "../../assets/icons/ethnic-group.svg";
import foodAllergyIcon from "../../assets/icons/environmental-allergy.svg";
import genderIcon from "../../assets/icons/gender.svg";
import incentiveIcon from "../../assets/icons/incentive.svg";
import serviceIcon from "../../assets/icons/medical-service.svg";
import medicationIcon from "../../assets/icons/medication.svg";
import procedureIcon from "../../assets/icons/procedure.svg";
import productIcon from "../../assets/icons/product.svg";
import relationshipIcon from "../../assets/icons/relationship.svg";
import raceIcon from "../../assets/icons/race.svg";
import routeIcon from "../../assets/icons/route.svg";
import medicalAllergyIcon from "../../assets/icons/medical-allergy.svg";
import screeningQuestionIcon from "../../assets/icons/screening-question.svg";
import infoQuestionIcon from "../../assets/icons/info-question.svg";
import siteIcon from "../../assets/icons/site.svg";
import symptomIcon from "../../assets/icons/symptom.svg";
import vfcEligibilityIcon from "../../assets/icons/vfc-eligibility.svg";
import helpIcon from "../../assets/icons/help.svg";

const allMasters = [
  {
    name: Master.Acknowledgements,
    path: ACKNOWLEDGEMENTS_URL,
    icon: acknowledgementIcon,
  },
  {
    name: Master.Disclaimers,
    path: DISCLAIMERS_URL,
    icon: disclaimerIcon,
  },
  {
    name: Master.Dispositions,
    path: DISPOSITIONS_URL,
    icon: dispositionIcon,
  },
  {
    name: Master.EthnicGroups,
    path: ETHNIC_GROUPS_URL,
    icon: ethnicGroupIcon,
  },
  {
    name: Master.EnvironmentalAllergies,
    path: ENVIRONMENTAL_ALLERGIES_URL,
    icon: foodAllergyIcon,
  },
  {
    name: Master.VfcEligibilities,
    path: VFC_ELIGIBILITIES_URL,
    icon: vfcEligibilityIcon,
  },
  {
    name: Master.Genders,
    path: GENDERS_URL,
    icon: genderIcon,
  },
  {
    name: Master.Incentives,
    path: INCENTIVES_URL,
    icon: incentiveIcon,
  },
  {
    name: Master.InfoQuestions,
    path: INFO_QUESTIONS_URL,
    icon: infoQuestionIcon,
  },
  {
    name: Master.MedicationAllergies,
    path: MEDICATION_ALLERGIES_URL,
    icon: medicalAllergyIcon,
  },
  {
    name: Master.Services,
    path: SERVICES_URL,
    icon: serviceIcon,
  },
  {
    name: Master.Medications,
    path: MEDICATIONS_URL,
    icon: medicationIcon,
  },
  {
    name: Master.Procedures,
    path: PROCEDURES_URL,
    icon: procedureIcon,
  },
  {
    name: Master.Products,
    path: PRODUCTS_URL,
    icon: productIcon,
  },
  {
    name: Master.Races,
    path: RACES_URL,
    icon: raceIcon,
  },
  {
    name: Master.Relationships,
    path: RELATIONSHIPS_URL,
    icon: relationshipIcon,
  },
  {
    name: Master.Routes,
    path: ROUTES_URL,
    icon: routeIcon,
  },
  {
    name: Master.ScreeningQuestions,
    path: SCREENING_QUESTIONS_URL,
    icon: screeningQuestionIcon,
  },
  {
    name: Master.Sites,
    path: SITES_URL,
    icon: siteIcon,
  },
  {
    name: Master.Symptoms,
    path: SYMPTOMS_URL,
    icon: symptomIcon,
  },
  {
    name: Master.Help,
    path: HELP_URL,
    icon: helpIcon,
  },
];

const Masters = () => {
  return (
    <PanelContainer name='Masters'>
      <PanelInnerContainer>
        <div className={classes.main}>
          {allMasters.map((master, idx) => (
            <MasterCard {...master} key={idx} />
          ))}
        </div>
      </PanelInnerContainer>
    </PanelContainer>
  );
};

export default Masters;

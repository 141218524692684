import { useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { MedicationAdministered, PatientReportData } from "../../../ts/types";
import { fetchMedications, fetchSymptoms } from "../../../services/masters";
import { RootState } from "../../../store";
import {
  getAge,
  getFormattedDateTime,
  getPatientFullName,
  getBloodGlucoseUnit,
  getTemperatureUnit,
  getExtensionFromUri,
} from "../../../utils";
import CommonMaster from "../../../ts/models/CommonMaster";
import ClosableBox from "../../../components/Box/ClosableBox";
import DataField from "../../../components/Box/DataField";
import classes from "./PatientReportDetails.module.css";
import Event from "../../../ts/models/Event";
import Card from "../../../components/Card/Card";
import Medication from "../../../ts/models/Medication";
import closeIcon from "../../../assets/icons/close-x.svg";
import imageIcon from "../../../assets/icons/image.png";
import pdfIcon from "../../../assets/icons/pdf.png";

const BASE = "/api";

const getImageUrl = (
  imageUrl: string,
  updateTime?: number,
  tenantId?: string
) => {
  if (!imageUrl || !tenantId) {
    return "";
  }
  return `${BASE}${imageUrl}?${updateTime}&tenantId=${tenantId}`;
};

type Props = {
  patientReportData: PatientReportData;
  event?: Event;
};

const PatientReportDetails: React.FC<Props> = ({
  patientReportData,
  event,
}) => {
  const { genders, dispositions, services, incentives } = useSelector(
    (state: RootState) => state.masters
  );
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );
  const [symptoms, setSymptoms] = useState<CommonMaster[]>([]);
  const [medicationData, setMedicationData] =
    useState<Map<string, Medication>>();

  const { patient, encounter } = patientReportData;
  const { dob, gender } = patient;
  const checkIn = encounter?.checkIn;
  const encounterDetails = encounter?.encounterDetails;
  const discharge = encounter?.discharge;
  const dischargeIncentives: { description: string; serialNumber: string }[] =
    [];
  const [viewImage, setViewImage] = useState("");
  const [showImageViewer, setShowImageViewer] = useState(false);

  discharge?.incentives?.forEach((incentiveData) => {
    const masterIncentive = incentives.find(
      (incentive) => incentiveData.incentive === incentive.id
    );
    if (masterIncentive) {
      dischargeIncentives.push({
        description: masterIncentive.description,
        serialNumber: incentiveData.serialNumber,
      });
    }
  });

  useEffect(() => {
    const symptoms =
      encounterDetails
        ?.map((encounterDetail) => encounterDetail.observations?.symptoms)
        .flat()
        .filter((ele) => !!ele) || [];

    const getSymptoms = async () => {
      const symptomOIDs = symptoms.map((symptom) => ({
        $oid: symptom,
      }));

      if (symptomOIDs.length) {
        const filter = {
          offset: 0,
          where: {
            _id: {
              $in: symptomOIDs,
            },
          },
        };
        const { status, data } = await fetchSymptoms(filter);
        if (status === 200) {
          setSymptoms(data);
        }
      }
    };

    if (symptoms.length) {
      getSymptoms();
    }
  }, [encounterDetails]);

  const getGender = (): string => {
    const genderMaster = genders.find((gen) => gen.id === gender);

    if (genderMaster) {
      return genderMaster.description;
    }
    return "";
  };

  const getDisposition = (): string => {
    const disposition = dispositions.find(
      (disposition) => disposition.id === encounter?.discharge?.disposition
    );

    if (disposition) {
      return disposition.description;
    }

    return "";
  };

  const getService = (serviceId: string) => {
    return services.find((service) => service.id === serviceId)?.name || "";
  };

  const getSymptoms = (symptomIds?: string[]) => {
    return symptoms
      .filter((symptom) => symptomIds?.includes(symptom.id))
      .map((symptom) => symptom.description)
      .join(", ");
  };

  useEffect(() => {
    const medications =
      encounterDetails
        ?.map(
          (encounterDetail) =>
            encounterDetail.administration?.medicationAdministered
        )
        .flat()
        .filter((ele) => !!ele) || [];

    const getMedicationData = async () => {
      const medicationIds = medications.map((medication) => ({
        $oid: medication?.medication,
      }));

      if (medicationIds.length) {
        const filter = {
          where: {
            _id: {
              $in: medicationIds,
            },
          },
        };
        const { status, data } = await fetchMedications(filter);
        if (status === 200) {
          let medicationData = new Map<string, Medication>();
          data.forEach((medication: Medication) => {
            medicationData.set(medication.id, medication);
          });

          setMedicationData(medicationData);
        }
      }
    };

    if (medications.length) {
      getMedicationData();
    }
  }, [encounterDetails]);

  const getMedicationName = (medicationId: string) => {
    if (medicationData?.get(medicationId)) {
      return medicationData.get(medicationId)?.name;
    }
    return "";
  };

  const getMedication = (
    medicationAdministeredData?: MedicationAdministered[]
  ) => {
    return (
      <DataField
        name='Medication:'
        data={
          <>
            {medicationAdministeredData?.length ? (
              medicationAdministeredData.map((medicationAdministered) => (
                <div key={medicationAdministered.medication}>
                  <div>{`${getMedicationName(
                    medicationAdministered.medication || ""
                  )} ${medicationAdministered.dosage}`}</div>
                  <div className={classes.administeredTime}>
                    {getFormattedDateTime(
                      medicationAdministered.administeredTime || 0
                    )}
                  </div>
                </div>
              ))
            ) : (
              <>None</>
            )}
          </>
        }
      />
    );
  };

  const handleAttachment = (attachment: string) => {
    if (getExtensionFromUri(attachment) === "pdf") {
      window.open(
        getImageUrl(attachment, encounter.checkIn?.updateTime, resourceName),
        "_blank"
      );
    } else {
      setShowImageViewer(true);
      setViewImage(
        getImageUrl(attachment, encounter.checkIn?.updateTime, resourceName)
      );
    }
  };

  return (
    <>
      {showImageViewer && (
        <div className={classes.imageViewer}>
          <div className={classes.imageContainer}>
            <img className={classes.viewImage} src={viewImage} alt='' />
            <img
              src={closeIcon}
              alt=''
              onClick={() => setShowImageViewer(false)}
              className={classes.closeIcon}
            />
          </div>
        </div>
      )}
      <div className={classes.main}>
        <div className={classes.container}>
          <Card className={classes.card}>
            <div className={classes.patientName}>
              {getPatientFullName(patient)}
            </div>
            <div className={classes.age}>
              {`${
                typeof dob === "number" && getAge(dob)
              } years / ${getGender()}`}
            </div>
            <DataField name='Event' data={event?.name} />
            <DataField
              name='Check-in Date'
              data={
                checkIn?.updateTime
                  ? getFormattedDateTime(checkIn?.updateTime)
                  : "Not checked-in"
              }
            />
          </Card>
          {encounterDetails ? (
            encounterDetails.map((encounterDetail, idx) => (
              <ClosableBox
                name={getService(encounterDetail.service)}
                defaultState
                key={idx}>
                <div className={classes.service}>
                  <div className={classes.medications}>
                    Medication Administered:
                  </div>
                  <div className={classes.medicationsAdministered}>
                    {getMedication(
                      encounterDetail.administration?.medicationAdministered
                    )}
                  </div>
                  <div className={classes.observations}>Observations:</div>
                  {!!encounterDetail.observations?.bloodGlucose?.length && (
                    <DataField
                      name='Blood Glucose:'
                      data={`${
                        encounterDetail.observations.bloodGlucose[
                          encounterDetail.observations.bloodGlucose.length - 1
                        ].value
                      } ${getBloodGlucoseUnit(
                        encounterDetail.observations.bloodGlucose[
                          encounterDetail.observations.bloodGlucose.length - 1
                        ].unit
                      )}`}
                    />
                  )}
                  {!!encounterDetail.observations?.bloodPressure?.length && (
                    <DataField
                      name='Blood Pressure:'
                      data={
                        <div className={classes.bloodPressure}>
                          <div>
                            {` ${
                              encounterDetail.observations.bloodPressure[
                                encounterDetail.observations.bloodPressure
                                  .length - 1
                              ].systolic
                            }/${
                              encounterDetail.observations.bloodPressure[
                                encounterDetail.observations.bloodPressure
                                  .length - 1
                              ].diastolic
                            } mmHg`}
                          </div>
                        </div>
                      }
                    />
                  )}
                  {!!encounterDetail.observations?.pulse?.length && (
                    <DataField
                      name='Pulse:'
                      data={`${
                        encounterDetail.observations.pulse[
                          encounterDetail.observations.pulse.length - 1
                        ].value
                      } beats/min`}
                    />
                  )}
                  {!!encounterDetail.observations?.respiratoryRate?.length && (
                    <DataField
                      name='Respiratory Rate:'
                      data={`${
                        encounterDetail.observations.respiratoryRate[
                          encounterDetail.observations.respiratoryRate.length -
                            1
                        ].value
                      } breaths/min`}
                    />
                  )}
                  {!!encounterDetail.observations?.spO2?.length && (
                    <DataField
                      name='SpO2:'
                      data={`${
                        encounterDetail.observations.spO2[
                          encounterDetail.observations.spO2.length - 1
                        ].value
                      }%`}
                    />
                  )}
                  {!!encounterDetail.observations?.temperature?.length && (
                    <DataField
                      name='Temperature:'
                      data={`${
                        encounterDetail.observations.temperature[
                          encounterDetail.observations.temperature.length - 1
                        ].value
                      } ${getTemperatureUnit(
                        encounterDetail.observations.temperature[
                          encounterDetail.observations.temperature.length - 1
                        ].unit
                      )}`}
                    />
                  )}
                  <div className={classes.symptoms}>Symptoms:</div>
                  <div className={classes.symptomsDescription}>
                    {getSymptoms(encounterDetail.observations?.symptoms)}
                  </div>
                </div>
              </ClosableBox>
            ))
          ) : (
            <></>
          )}
          {encounterDetails && (
            <ClosableBox name='Discharge' defaultState>
              <DataField name='Disposition:' data={getDisposition()} />
              <DataField
                name='Incentives:'
                data={
                  <>
                    {dischargeIncentives.length ? (
                      dischargeIncentives.map((incentive, idx) => (
                        <div key={idx}>
                          {incentive.description}
                          {incentive.serialNumber
                            ? ` (${incentive.serialNumber})`
                            : ""}
                        </div>
                      ))
                    ) : (
                      <>None</>
                    )}
                  </>
                }
              />
            </ClosableBox>
          )}
          {patient.insuranceCardImages &&
            (patient.insuranceCardImages.frontImage ||
              patient.insuranceCardImages.backImage) && (
              <ClosableBox name='Insurance Card Images' defaultState>
                <>
                  {patient.insuranceCardImages.frontImage && (
                    <>
                      <DataField name='Front Image:' data={<></>} />
                      <img
                        src={getImageUrl(
                          patient.insuranceCardImages.frontImage,
                          encounter.checkIn?.updateTime,
                          resourceName
                        )}
                        onClick={() => {
                          setShowImageViewer(true);
                          setViewImage(
                            getImageUrl(
                              patient.insuranceCardImages.frontImage,
                              encounter.checkIn?.updateTime,
                              resourceName
                            )
                          );
                        }}
                        className={classes.cardImage}
                        alt=''
                      />
                    </>
                  )}
                  {patient.insuranceCardImages.backImage && (
                    <>
                      <DataField name='Back Image:' data={<></>} />
                      <img
                        src={getImageUrl(
                          patient.insuranceCardImages.backImage,
                          encounter.checkIn?.updateTime,
                          resourceName
                        )}
                        onClick={() => {
                          setShowImageViewer(true);
                          setViewImage(
                            getImageUrl(
                              patient.insuranceCardImages.backImage,
                              encounter.checkIn?.updateTime,
                              resourceName
                            )
                          );
                        }}
                        className={classes.cardImage}
                        alt=''
                      />
                    </>
                  )}
                </>
              </ClosableBox>
            )}
          <>
            {encounter?.checkIn?.attachments?.length ? (
              <ClosableBox name='Attachments' defaultState>
                {encounter?.checkIn?.attachments.map((attachment, idx) => (
                  <div
                    className={classes.attachments}
                    onClick={() => handleAttachment(attachment)}>
                    <img
                      src={
                        getExtensionFromUri(attachment) === "pdf"
                          ? pdfIcon
                          : imageIcon
                      }
                      alt=''
                    />{" "}
                    {`Attachment ${idx + 1}`}
                  </div>
                ))}
              </ClosableBox>
            ) : (
              ""
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default PatientReportDetails;

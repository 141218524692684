import React from "react";
import classes from "./TableContainer.module.css";

type Props = {
  children: JSX.Element | JSX.Element[];
};

const TableContainer: React.FC<Props> = ({ children }) => {
  return <div className={classes.main}>{children}</div>;
};

export default TableContainer;

export enum Master {
  Acknowledgements = "Acknowledgements",
  Disclaimers = "Disclaimers",
  Dispositions = "Dispositions",
  EnvironmentalAllergies = "Food/Environmental Allergies",
  EthnicGroups = "Ethnic groups",
  Genders = "Genders",
  Incentives = "Incentives",
  InfoQuestions = "Info Questions",
  MedicationAllergies = "Medication Allergies",
  Medications = "Medications",
  Procedures = "Procedures",
  Products = "Products",
  Races = "Races",
  Relationships = "Relationships",
  Routes = "Routes",
  ScreeningQuestions = "Screening Questions",
  Services = "Medical Services",
  Sites = "Sites",
  Symptoms = "Symptoms",
  VfcEligibilities = "Funding Sources",
  Help = "Help",
}

export enum Status {
  NotSet = "NOT_SET",
  CheckedIn = "CHECKED_IN",
  ServiceAdministered = "SERVICE_ADMINISTERED",
  UnderObservation = "UNDER_OBSERVATION",
  Discharged = "DISCHARGED",
}

export enum UserRole {
  SuperAdmin = "SUPER_ADMIN",
  Admin = "ADMIN", // Access to web and app
  Clinical = "CLINICAL", // Access to all app functions
  NonClinical = "NON_CLINICAL", // Access to all app functions less Meds
  NonClinicalPlus = "NON_CLINICAL_PLUS", // Access tyo all app functions less Meds, and web events
}

export enum Permission {
  ManagePods = "MANAGE_PODS",
  ManageEvents = "MANAGE_EVENTS",
  ManageMasters = "MANAGE_MASTERS",
  ManageUsers = "MANAGE_USERS",
  CreateReports = "CREATE_REPORTS",
  ListActivityLogs = "LIST_ACTIVITY_LOGS",
}

export enum TemperatureUnit {
  Celsius = "CELSIUS",
  Fahrenheit = "FAHRENHEIT",
}

export enum GlucoseUnit {
  MmolPerLitre = "MMOL_PER_LITRE",
  MgPerDeciLitre = "MG_PER_DECI_LITRE",
}

export enum InsuranceStatus {
  Yes = "YES",
  No = "NO",
}

export enum InfoQuestionType {
  OptionList = "OPTION_LIST",
  FreeText = "FREE_TEXT",
  Number = "NUMBER",
  Date = "DATE",
}

export enum ActivityType {
  Login = "LOGIN",
  Logout = "LOGOUT",
  AddMaster = "ADD_MASTER",
  UploadMasters = "UPLOAD_MASTERS",
  UpdateMaster = "UPDATE_MASTER",
  AddUser = "ADD_USER",
  UploadUsers = "UPLOAD_USERS",
  UpdateUser = "UPDATE_USER",
  AddPod = "ADD_POD",
  UpdatePod = "UPDATE_POD",
  AddEvent = "ADD_EVENT",
  UpdateEvent = "UPDATE_EVENT",
  CheckInPatient = "CHECKIN_PATIENT",
  AdministerPatient = "ADMINISTER_PATIENT",
  ObservePatient = "OBSERVE_PATIENT",
  DischargePatient = "DISCHARGE_PATIENT",
  FetchCheckInList = "FETCH_CHECKIN_LIST",
  UpdatePatient = "UPDATE_PATIENT",
  ViewEventReport = "VIEW_EVENT_REPORT",
  DownloadEventReport = "DOWNLOAD_EVENT_REPORT",
  ViewPatientReport = "VIEW_PATIENT_REPORT",
  DownloadPatientReport = "DOWNLOAD_PATIENT_REPORT",
  DownloadBillingReport = "DOWNLOAD_BILLING_REPORT",
  ViewAgencyReport = "VIEW_AGENCY_REPORT",
  DownloadAgencyReport = "DOWNLOAD_AGENCY_REPORT",
}

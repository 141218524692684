import axios from "axios";
import { store } from "./store";
import { loaderActions } from "./store/slices/loaderSlice";
import { authActions } from "./store/slices/AuthSlice";
import { warningActions } from "./store/slices/WarningSlice";
import { BASE_URL, SIGN_IN_URL } from "./Routes/urls";

axios.interceptors.request.use(function (request) {
  store.dispatch(loaderActions.fetching());
  const resourceName = store.getState().auth.resourceName;
  if (resourceName) {
    request.headers["X-Tenant"] = resourceName;
  }
  return request;
});

axios.interceptors.response.use(
  function (response) {
    store.dispatch(loaderActions.fetched());
    return response;
  },
  function (error) {
    store.dispatch(loaderActions.fetched());
    store.dispatch(warningActions.showWarning({}));

    if (error.response.status === 401) {
      const resourceName = store.getState().auth.resourceName;
      window.location.href = `${BASE_URL}/${resourceName}/${SIGN_IN_URL}`;
      store.dispatch(authActions.logOut());
    }
    return Promise.reject(error);
  }
);

export default axios;

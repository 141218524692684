import { useState } from "react";
import { addDays } from "date-fns";
import { useSelector } from "react-redux";

import {
  downloadFile,
  getClasses,
  getEndOfDayTimestamp,
  getLocalDateString,
  getStartOfDayTimestamp,
} from "../../utils";
import { downloadBillingCSV, fetchPodBillingData } from "../../services/orders";
import { RootState } from "../../store";
import MultiSelect, {
  MultiSelectOption,
} from "../../components/Inputs/MultiSelect";
import { BillingData } from "../../ts/types";

import classes from "./BillingFile.module.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import PanelContainer from "../../components/Containers/PanelContainer";
import PanelActionContainer from "../../components/Containers/PanelActionContainer";
import Input from "../../components/Inputs/Input";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import Card from "../../components/Card/Card";
import BillingFileCard from "./BillingFileCard";
import Checkbox from "../../components/Checkbox/Checkbox";
import LinkButton from "../../components/Buttons/LinkButton";
import helpIcon from "../../assets/icons/help-info.svg";
import HelpModal from "../../components/Help/HelpModal";

const BillingFile = () => {
  const pods = useSelector((state: RootState) => state.pods.pods);

  const [podIds, setPodIds] = useState<string[]>([]);
  const [selectedPods, setSelectedPods] = useState<MultiSelectOption[]>([]);
  const [billingData, setBillingData] = useState<BillingData[]>([]);
  const [startDate, setStartDate] = useState(
    getStartOfDayTimestamp(getLocalDateString(Date.now()))
  );
  const [endDate, setEndDate] = useState(
    getEndOfDayTimestamp(getLocalDateString(addDays(new Date(), 6).getTime()))
  );
  const [sendAllForBilling, setSendAllForBilling] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [allSelectedEventsSet, setAllSelectedEventsSet] = useState<Set<string>>(
    new Set<string>()
  );

  const podOptions = pods.map((pod) => ({ value: pod.id, label: pod.name }));

  const fetchBillingData = async () => {
    const podIdsCopy = [...podIds];
    if (podIdsCopy[0] === "*") {
      podIdsCopy.splice(0, 1);
    }
    try {
      const res = await fetchPodBillingData(podIdsCopy, startDate, endDate);
      if (res.status === 200) {
        setBillingData(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const changeBillingStatus = (podId: string, billedEventIds: string[]) => {
    setBillingData((prevState) => {
      const newState = [...prevState];
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].podId === podId) {
          newState[i].events.forEach((e) => {
            if (billedEventIds.includes(e.id)) {
              e.isBilled = true;
            }
          });
          break;
        }
      }
      return newState;
    });
  };

  const getBillingFileName = () => {
    let fileName = `Billing_${getLocalDateString(
      startDate
    )}_${getLocalDateString(endDate)}.csv`;
    return fileName.replaceAll("-", "_");
  };

  const downloadCsvHandler = async (
    podId: string,
    eventIds: string[],
    sendForBilling: boolean
  ) => {
    try {
      const res = await downloadBillingCSV(eventIds, sendForBilling);
      downloadFile(res, getBillingFileName());
      if (sendForBilling && res.status === 200) {
        changeBillingStatus(podId, eventIds);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadAllCsvHandler = async () => {
    const allEventIds = Array.from(allSelectedEventsSet).map(
      (eventId) => eventId
    );

    try {
      const res = await downloadBillingCSV(allEventIds, sendAllForBilling);
      downloadFile(res, getBillingFileName());
      if (sendAllForBilling && res.status === 200) {
        setBillingData((prevState) => {
          const newState = [...prevState];
          for (let i = 0; i < newState.length; i++) {
            newState[i].events.forEach((e) => {
              if (allEventIds.includes(e.id)) {
                e.isBilled = true;
              }
            });
          }
          return newState;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onPodChange = (selectedOptions: any[]) => {
    const podIds = selectedOptions.map(
      (selectedOption) => selectedOption.value
    );
    setSelectedPods(selectedOptions);
    setPodIds(podIds);
  };

  const anyPodHasEvents = billingData.some((item) => item.events.length > 0);

  const allSelectedEventIdsHandler = (isChecked: boolean, eventId: string) => {
    setAllSelectedEventsSet((prevState) => {
      if (isChecked) {
        const newState: Set<string> = new Set<string>(prevState.add(eventId));
        return newState;
      } else {
        if (prevState.has(eventId)) {
          const newState: Set<string> = new Set<string>(prevState);
          newState.delete(eventId);
          return newState;
        } else {
          return prevState;
        }
      }
    });
  };

  return (
    <>
      {showHelpModal && (
        <HelpModal
          title='Create Billing Report'
          content={`/api/storage/cimpar/resources/help/create-billing-report-help.html`}
          onClose={() => setShowHelpModal(false)}
        />
      )}
      <PanelContainer name='Billing Report'>
        <PanelActionContainer
          leftChildren={
            <div style={{ width: "20rem" }}>
              <MultiSelect
                label='Select POD'
                onChange={onPodChange}
                value={selectedPods}
                options={podOptions}
                isAll
              />
            </div>
          }
          rightChildren={
            <div className={classes.helpContainer}>
              <LinkButton
                label={"Know About How To Create Billing Report"}
                icon={<img src={helpIcon} alt='' />}
                onClick={() => setShowHelpModal(true)}
                className={classes.helpBtn}
              />
            </div>
          }
        />
        <Card className={classes.filterCard}>
          <div className={classes.filterContainer}>
            <div className={classes.filters}>
              <Input
                type='date'
                name='Start Date'
                className={classes.filterDates}
                value={getLocalDateString(startDate)}
                onChange={(e) => {
                  const startDate = getStartOfDayTimestamp(e.target.value);
                  setStartDate(startDate);
                  if (startDate > endDate)
                    setEndDate(getEndOfDayTimestamp(e.target.value));
                }}
              />
              <Input
                type='date'
                name='End Date'
                className={classes.filterDates}
                value={getLocalDateString(endDate)}
                onChange={(e) =>
                  setEndDate(getEndOfDayTimestamp(e.target.value))
                }
                min={getLocalDateString(startDate)}
              />
            </div>
            <PrimaryButton
              name='Filter'
              onClick={fetchBillingData}
              className={classes.filterBtn}
              disabled={!podIds.length}
            />
          </div>
        </Card>
        <PanelInnerContainer className={classes.innerContainer}>
          <div className={classes.main}>
            {billingData.map((data, idx) => (
              <div key={idx}>
                <Card>
                  <BillingFileCard
                    podData={data}
                    onDownloadCSV={downloadCsvHandler}
                    setEventIdHandler={allSelectedEventIdsHandler}
                  />
                </Card>
              </div>
            ))}
            <div className={classes.actionsContainer}>
              {billingData.length > 1 && (
                <div
                  className={getClasses(
                    classes.actions,
                    !anyPodHasEvents || allSelectedEventsSet.size === 0
                      ? classes.actionsDisabled
                      : ""
                  )}>
                  <PrimaryButton
                    className={classes.action}
                    name='Download All CSV'
                    onClick={downloadAllCsvHandler}
                  />
                  <Checkbox
                    checked={sendAllForBilling}
                    className={classes.sendForBillingCheckbox}
                    label='Mark these records sent to billing'
                    id={`check-box-all`}
                    onChange={(checked) => setSendAllForBilling(checked)}
                  />
                </div>
              )}
            </div>
          </div>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default BillingFile;

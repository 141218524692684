import classes from "./PanelActionContainer.module.css";

type Props = {
  leftChildren: JSX.Element | JSX.Element[];
  rightChildren?: JSX.Element | JSX.Element[];
};

const PanelActionContainer: React.FC<Props> = ({
  leftChildren,
  rightChildren,
}) => {
  return (
    <div className={classes.main}>
      <div className={classes.left}>{leftChildren}</div>
      <div className={classes.right}>{rightChildren}</div>
    </div>
  );
};

export default PanelActionContainer;

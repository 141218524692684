import { useEffect, useState } from "react";

import {
  fetchMedications,
  fetchMedicationsCount,
  updateMedication,
  uploadMedicationsData,
} from "../../../services/masters";
import { Column } from "../../../ts/types";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import PanelActionContainer from "../../../components/Containers/PanelActionContainer";
import PanelContainer from "../../../components/Containers/PanelContainer";
import SearchBar from "../../../components/SearchBar/SearchBar";
import addIcon from "../../../assets/icons/add-circle-white.svg";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import TableContainer from "../../../components/CustTable/TableContainer";
import Table from "../../../components/CustTable/Table";
import Medication from "../../../ts/models/Medication";
import AddMedication from "./AddMedication";
import UploadCSV from "../../../components/UploadCSV/UploadCSV";
import ActionContainer from "../../../components/CustTable/ActionContainer";
import LinkButton from "../../../components/Buttons/LinkButton";

const pageSize = 20;

type Props = {};

const Medications: React.FC<Props> = () => {
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState({
    show: false,
    data: new Medication(),
  });

  const [showUploadCSV, setShowUploadCSV] = useState(false);
  const [medicationsData, setMedicationsData] = useState<Medication[]>([]);
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [skipCount, setSkipCount] = useState(true);

  useEffect(() => {
    fetchMedicationsData();
  }, [currPageNo]);

  useEffect(() => {
    if (currPageNo === 1) {
      // Preventing first time auto api call because it is already called from currPageNo useEffect
      if (skipCount) {
        setSkipCount(false);
      } else {
        fetchMedicationsData();
      }
    } else {
      // We need to go to page no. 1 if search text change, as well as it will fetch data
      setCurrPageNo(1);
    }
  }, [searchText]);

  const fetchMedicationsData = async () => {
    const filter = {
      limit: pageSize,
      offset: (currPageNo - 1) * pageSize,
      where: {
        $or: [
          {
            code: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
          {
            name: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
          {
            manufacturer: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
        ],
      },
    };
    try {
      const [medicationsRes, countRes] = await Promise.all([
        fetchMedications(filter),
        fetchMedicationsCount(filter.where),
      ]);
      if (medicationsRes?.status === 200) {
        setMedicationsData(medicationsRes.data);
      }
      if (countRes?.status === 200) {
        const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
        setTotalPages(noOfPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const medicationTableColumn: Column<Medication>[] = [
    {
      header: "ID",
      accessor: (e, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "10%",
    },
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "15%",
    },
    {
      header: "NAME",
      accessor: (e) => e.name,
      width: "25%",
    },
    {
      header: "MANUFACTURER",
      accessor: (e) => e.manufacturer,
    },
    {
      header: "DOSAGE",
      accessor: (e) => e.dosage,
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => setShowModal({ show: true, data: e })}
            label='Edit'
          />
          <LinkButton
            onClick={() => updateMedicationStatus(e.id, !e.isActive)}
            label={e.isActive ? "De-activate" : "Activate"}
            style={{ marginLeft: "1rem" }}
          />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];

  const updateMedicationStatus = (id: string, isActive: boolean) => {
    updateMedication({ id, isActive }).then((res) => {
      if (res.status === 200) {
        fetchMedicationsData();
      }
    });
  };

  const uploadMedicationDataHandler = (file: any) => {
    uploadMedicationsData(file).then((res) => {
      if (res.status === 200) {
        fetchMedicationsData();
        setShowUploadCSV(false);
      }
    });
  };

  const addMedicationCloseHandler = (isUpdated: boolean) => {
    setShowModal({ show: false, data: new Medication() });
    if (isUpdated) {
      fetchMedicationsData();
    }
  };

  return (
    <>
      {showModal.show && (
        <AddMedication
          medication={showModal.data}
          onClose={addMedicationCloseHandler}
        />
      )}
      {showUploadCSV && (
        <UploadCSV
          uploadHandler={uploadMedicationDataHandler}
          onClose={() => {
            setShowUploadCSV(false);
          }}
        />
      )}
      <PanelContainer name='Medications' backBtn>
        <PanelActionContainer
          leftChildren={
            <SearchBar
              debounce
              onChange={(text) => {
                setSearchText(text);
              }}
            />
          }
          rightChildren={
            <>
              <PrimaryButton
                name='Bulk Upload'
                onClick={() => setShowUploadCSV(true)}
                style={{ margin: "0 1rem" }}
              />
              <PrimaryButton
                name='Add New'
                onClick={() => {
                  setShowModal({ show: true, data: new Medication() });
                }}
                disabled={false}
                icon={<img src={addIcon} alt='' />}
              />
            </>
          }
        />
        <PanelInnerContainer>
          <TableContainer>
            <Table
              columns={medicationTableColumn}
              data={medicationsData}
              isPaginated
              pageNo={currPageNo}
              totalPages={totalPages}
              onPrevClick={() => setCurrPageNo((state) => state - 1)}
              onNextClick={() => setCurrPageNo((state) => state + 1)}
            />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default Medications;

import { AppDispatch } from "..";
import { fetchEventsCount } from "../../services/events";
import { fetchProductsCount } from "../../services/masters";
import { fetchMedicalProfsCount } from "../../services/users";
import { eventsActions } from "../slices/EventSlice";
import { mastersActions } from "../slices/MasterSlice";
import { usersActions } from "../slices/UserSlice";

export const getDashboardStats = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const [
        totalEventCountRes,
        liveRegCountRes,
        liveEventsCountRes,
        totalProductsCountRes,
        medProfsCountRes,
      ] = await Promise.all([
        fetchEventsCount(),
        fetchEventsCount({
          startDate: Date.now(),
        }),
        fetchEventsCount({
          startDate: Date.now(),
          endDate: Date.now(),
        }),
        fetchProductsCount(),
        fetchMedicalProfsCount(),
      ]);

      const eventStats = {
        totalEventCount: 0,
        liveRegCount: 0,
        liveEventsCount: 0,
      };

      if (totalEventCountRes.status === 200) {
        eventStats.totalEventCount = totalEventCountRes.data.count;
      }

      if (liveRegCountRes.status === 200) {
        eventStats.liveRegCount = liveRegCountRes.data.count;
      }

      if (liveEventsCountRes.status === 200) {
        eventStats.liveEventsCount = liveEventsCountRes.data.count;
      }

      dispatch(eventsActions.getEventStats(eventStats));

      if (totalProductsCountRes.status === 200) {
        dispatch(
          mastersActions.getTotalProductsCount(totalProductsCountRes.data.count)
        );
      }

      if (medProfsCountRes.status === 200) {
        dispatch(
          usersActions.setTotalMedProfsCount(medProfsCountRes.data.count)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

import { useState } from "react";
import { useDispatch } from "react-redux";

import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Modal, { ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import classes from "./AddIncentive.module.css";
import Incentive from "../../../ts/models/Incentive";
import {
  addNewIncentive,
  updateIncentiveData,
} from "../../../store/actions/MasterActions";
import { AppDispatch } from "../../../store";
import Input from "../../../components/Inputs/Input";
import Checkbox from "../../../components/Checkbox/Checkbox";

type props = {
  isEdit: Boolean;
  incentive: Incentive;
  onClose: () => void;
};

const AddIncentive: React.FC<props> = ({ onClose, isEdit, incentive }) => {
  const dispatch: AppDispatch = useDispatch();
  const [data, setData] = useState({
    code: incentive.code,
    description: incentive.description,
    amount: incentive.amount,
    hasSerialNumbers: incentive.hasSerialNumbers,
  });

  const addIncentiveHandler = () => {
    dispatch(
      addNewIncentive(
        new Incentive(
          data.code,
          data.description,
          data.amount,
          data.hasSerialNumbers
        )
      )
    );
    onClose();
  };

  const editIncentiveHandler = () => {
    const incentiveToBeEdited = new Incentive(
      data.code,
      data.description,
      data.amount,
      data.hasSerialNumbers
    );
    incentiveToBeEdited.id = incentive.id;
    dispatch(updateIncentiveData(incentiveToBeEdited));
    onClose();
  };

  const isRequiredDataMissing = () => {
    if (
      !data.code?.trim() ||
      !data.description?.trim() ||
      !data.amount?.trim()
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        className={classes.heading}
        title={isEdit ? <>Update Incentive</> : <>Add Incentive</>}
        onClose={onClose}
        style={{ minHeight: "25rem" }}>
        <>
          <ModalBody>
            <div className={classes.main}>
              <Input
                name='Code'
                value={data.code}
                onChange={(e) =>
                  setData((state) => ({ ...state, code: e.target.value }))
                }
                className={classes.input}
              />
              <Input
                name='Incentive Description'
                value={data.description}
                onChange={(e) =>
                  setData((state) => ({
                    ...state,
                    description: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Input
                name='Incentive Amount (USD)'
                type='number'
                value={data.amount}
                onChange={(e) =>
                  setData((state) => ({
                    ...state,
                    amount: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Checkbox
                className={classes.hasSerialNumbersCheckbox}
                checked={data.hasSerialNumbers ?? false}
                id={"hasSerialNumbers"}
                label={"Has Serial Numbers"}
                onChange={(checked) =>
                  setData((state) => ({
                    ...state,
                    hasSerialNumbers: checked,
                  }))
                }
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className={classes.actions}>
              <PrimaryButton
                className={classes.actionBtn}
                name={isEdit ? "Update" : "Add"}
                onClick={
                  isEdit === true ? editIncentiveHandler : addIncentiveHandler
                }
                style={{ marginRight: "1rem" }}
                disabled={isRequiredDataMissing()}
              />
              <PrimaryButton
                className={classes.actionBtn}
                name='Cancel'
                onClick={onClose}
                transparent
              />
            </div>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};
export default AddIncentive;

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { BASE_URL, EVENTS_URL, EVENT_DETAILS_URL } from "../../Routes/urls";
import {
  getEventFullAddress,
  getFormattedDate,
  getFormattedTime,
} from "../../utils";
import { eventsActions } from "../../store/slices/EventSlice";
import { RootState } from "../../store";
import classes from "./EventCard.module.css";
import Event from "../../ts/models/Event";
import MultiStatusView from "../../components/Status/MultiStatusView";
import calendarIcon from "../../assets/icons/calendar.svg";
import clockIcon from "../../assets/icons/clock.svg";
import addressIcon from "../../assets/icons/address.svg";

type Props = {
  event: Event;
};
const DashboardEventCard: React.FC<Props> = ({ event }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pods = useSelector((state: RootState) => state.pods.pods);
  const { services } = useSelector((state: RootState) => state.masters);
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  const serviceNames = event.services.map((serviceWithId) => {
    const service = services.find(
      (service) => service.id === serviceWithId.serviceId
    );

    if (service) {
      return service.name;
    } else {
      return "";
    }
  });

  const eventDetailsHandler = (): void => {
    dispatch(eventsActions.setCurrEventDetails(event));
    navigate(
      BASE_URL + `/${resourceName}` + `/${EVENTS_URL}/` + EVENT_DETAILS_URL
    );
  };

  const podName = pods.find((pod) => pod.id === event.podId)?.name;
  const { name, startTime, endTime } = event;

  return (
    <div className={classes.main}>
      <div className={classes.podName}>
        <span>{podName}</span>
      </div>
      <div className={classes.eventName}>{name}</div>
      <div className={classes.fieldsContainer}>
        <IconField
          icon={calendarIcon}
          data={`${getFormattedDate(startTime)} - ${getFormattedDate(endTime)}`}
        />
        <IconField
          icon={clockIcon}
          data={`${getFormattedTime(startTime)} - ${getFormattedTime(endTime)}`}
        />
        <IconField icon={addressIcon} data={getEventFullAddress(event)} />
      </div>
      <div className={classes.medicalServices}>Medical Services</div>
      <div className={classes.medicalServicesContainer}>
        <MultiStatusView names={serviceNames} maxCount={2} />
      </div>
      <div className={classes.viewEvent} onClick={eventDetailsHandler}>
        View Event Details
      </div>
    </div>
  );
};

export default DashboardEventCard;

type IconFieldProps = {
  icon: string;
  data: string;
};

const IconField: React.FC<IconFieldProps> = ({ icon, data }) => {
  return (
    <>
      <div className={classes.iconContainer}>
        <img src={icon} alt='' />
        <div className={classes.iconFieldData}>{data}</div>
      </div>
    </>
  );
};

import axios from "../axios";
import Event, { EventNotes, EventStatus } from "../ts/models/Event";
import { encodeQueryFilter } from "./utils";

const EVENTS_URL = "/api/events";

export function fetchEvents(filter?: any) {
  let url = EVENTS_URL;
  if (filter) {
    url += `?filter=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function fetchEventsCount(filter?: any) {
  let url = `${EVENTS_URL}/count`;
  if (filter) {
    url += `?where=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function fetchAnEvent(id: string) {
  return axios({
    url: `${EVENTS_URL}/${id}`,
    method: "get",
  });
}

export function addNewEvent(data: Event) {
  return axios({
    url: EVENTS_URL,
    method: "post",
    data,
  });
}

export function editAnEvent(data: Event | EventNotes) {
  return axios({
    url: `${EVENTS_URL}/${data.id}`,
    method: "put",
    data,
  });
}

export function updateEventStatus(data: EventStatus) {
  return axios({
    url: `${EVENTS_URL}/${data.id}`,
    method: "put",
    data,
  });
}

export function deleteAnEvent(eventId: string) {
  return axios({
    url: `${EVENTS_URL}/${eventId}`,
    method: "delete",
  });
}

export function fetchPodwiseEventCount() {
  let url = `${EVENTS_URL}/podwisecount`;
  return axios({
    url,
    method: "get",
  });
}

export function fetchEventReport(filter: any) {
  let url = `${EVENTS_URL}/event-report?filter=${encodeQueryFilter(filter)}`;
  return axios({
    url,
    method: "get",
  });
}

export function fetchEventReportCount(filter: any) {
  let url = `${EVENTS_URL}/event-report-count?filter=${encodeQueryFilter(
    filter
  )}`;
  return axios({
    url,
    method: "get",
  });
}

export function downloadEventReport(filter: any) {
  let url = `${EVENTS_URL}/download-event-report?filter=${encodeQueryFilter(
    filter
  )}`;
  return axios({
    url,
    method: "get",
    responseType: "blob",
  });
}

export function fetchEventRegistrationCount(eventIds: string[]) {
  let url = `/api/registrationCount?eventIds=${eventIds.join(",")}`;
  return axios({
    url,
    method: "get",
  });
}

import axios from "../axios";
import Patient from "../ts/models/Patient";
import { encodeQueryFilter } from "./utils";

const PATIENTS_URL = "/api/patients";

export function fetchPatients(filter?: any) {
  let url = PATIENTS_URL;
  if (filter) {
    url += `?filter=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function fetchPatient(id: string) {
  return axios({
    url: `${PATIENTS_URL}/${id}`,
    method: "get",
  });
}

export function editPatient(data: Patient) {
  const formData = new FormData();
  formData.append("patient", JSON.stringify(data));
  return axios({
    url: PATIENTS_URL + `/${data.id}`,
    method: "put",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

import { createSlice } from "@reduxjs/toolkit";
import { addDays } from "date-fns";

import {
  getEndOfDayTimestamp,
  getLocalDateString,
  getStartOfDayTimestamp,
} from "../../utils";
import { EventFilter } from "../../ts/types";
import Survey from "../../ts/models/Survey";

type InitialState = {
  filter: EventFilter;
  surveys: Survey[];
  surveyToBeEdited: Survey;
  currPageNo: number;
  count: number;
};

const emptySurvey = { ...new Survey() };

const emptyFilter: EventFilter = {
  podId: "",
  searchText: "",
  startDate: getStartOfDayTimestamp(getLocalDateString(Date.now())),
  endDate: getEndOfDayTimestamp(
    getLocalDateString(addDays(new Date(), 6).getTime())
  ),
};

// Storing surveys and filter to redux to prevent filter's and survey's data loss while returning from create new survey page
const initialState: InitialState = {
  filter: emptyFilter,
  surveys: [],
  surveyToBeEdited: emptySurvey,
  currPageNo: 0,
  count: 0,
};

const surveys = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    getSurveys: (state, action) => {
      const { surveys, count, currPageNo } = action.payload;
      state.surveys = surveys;
      state.count = count;
      state.currPageNo = currPageNo;
    },
    addSurvey: (state, action) => {
      const survey: Survey = action.payload;
      if (survey.podId === state.filter.podId) state.surveys.push(survey);
    },
    editSurvey: (state, action) => {
      let index: number = -1;
      index = state.surveys.findIndex((survey: Survey) => {
        return survey.id === action.payload.id;
      });
      if (index !== -1) {
        state.surveys[index] = { ...action.payload };
      }
    },
    setSurveytToBeEdited: (state, action) => {
      state.surveyToBeEdited = action.payload;
    },
    newSurveyToBeEdited: (state) => {
      const now = new Date().getTime();
      state.surveyToBeEdited = {
        ...emptySurvey,
        podId: state.filter.podId || "",
        startTime: now,
        endTime: now,
      };
    },
    setFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    deleteSurvey: (state, action) => {
      let index: number = -1;
      index = state.surveys.findIndex((survey: Survey) => {
        return survey.id === action.payload;
      });
      if (index !== -1) {
        state.surveys.splice(index, 1);
      }
    },
  },
});

export const surveysActions = surveys.actions;

export default surveys.reducer;

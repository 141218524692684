import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { getClasses, navigationBack } from "../../utils";
import { AppDispatch, RootState } from "../../store";
import { logOutAndRemoveData } from "../../store/actions/UserActions";
import classes from "./TitleContainer.module.css";
import leftArrowIcon from "../../assets/icons/left-arrow-back.svg";
import KebabMenu from "../KebabMenu/KebabMenu";
import dropDownIcon from "../../assets/icons/drop-down.svg";
import ChangePassword from "../../modules/ChangePassword/ChangePassword";

type Props = {
  name: string;
  backBtn?: boolean;
};

const TitleContainer: React.FC<Props> = ({ name, backBtn }) => {
  const { currUserData, tenants } = useSelector(
    (state: RootState) => state.users
  );
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );
  const dispatch: AppDispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [changePassword, setChangePassword] = useState(false);

  const logoutHandler = () => {
    dispatch(logOutAndRemoveData());
  };

  const backHandler = () => {
    navigationBack(location, navigate);
  };

  const getTenantName = () => {
    const tenant = tenants?.find((tenant) => tenant.id === resourceName);
    if (tenant) {
      return tenant.name;
    } else {
      return "";
    }
  };

  return (
    <>
      {changePassword && (
        <ChangePassword onClose={() => setChangePassword(false)} />
      )}
      <div className={classes.container}>
        <div
          className={getClasses(classes.title, backBtn ? classes.cursor : "")}
          onClick={backBtn ? backHandler : () => {}}>
          {backBtn && (
            <img src={leftArrowIcon} alt='Back' className={classes.icon} />
          )}
          {name}
        </div>
        <div className={classes.right}>
          <div>{getTenantName()}</div>
          <div className={classes.email}>
            {currUserData.email}
            <KebabMenu
              icon={
                <img
                  className={classes.dropDownIcon}
                  src={dropDownIcon}
                  alt=''
                />
              }
              options={[
                {
                  name: "Change Password",
                  onClick: () => setChangePassword(true),
                },
                { name: "Log out", onClick: logoutHandler },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TitleContainer;

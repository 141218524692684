import { AppDispatch } from "..";
import {
  fetchPODS,
  addNewPOD,
  editAPOD,
  deleteAPOD,
} from "../../services/pods";
import { podsActions } from "../slices/PODSlice";
import { warningActions } from "../slices/WarningSlice";

export const getPODs = () => {
  return async (dispatch: AppDispatch) => {
    fetchPODS()
      .then((res) => {
        if (res.status === 200) {
          dispatch(podsActions.getPODs(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const addPOD = (data: FormData, handleBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    return addNewPOD(data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errorCode === "pod_code_exists") {
            dispatch(
              warningActions.showWarning({
                message: "Code is already used for another POD.",
              })
            );
          } else {
            dispatch(podsActions.addPOD(res.data));
            handleBack();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const editPOD = (data: FormData, id: string, handleBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    return editAPOD(data, id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errorCode === "pod_code_exists") {
            dispatch(
              warningActions.showWarning({
                message: "Code is already used for another POD.",
              })
            );
          } else {
            dispatch(podsActions.editPOD(res.data));
            handleBack();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updatePODStatusData = (data: FormData, id: string) => {
  return async (dispatch: AppDispatch) => {
    editAPOD(data, id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(podsActions.editPOD(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deletePOD = (podId: string) => {
  return async (dispatch: AppDispatch) => {
    deleteAPOD(podId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errorCode === "bad_request") {
            dispatch(
              warningActions.showWarning({
                message:
                  "Failed to delete POD. The POD has events with associated registrations/orders.",
              })
            );
          } else {
            dispatch(podsActions.deletePOD(podId));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

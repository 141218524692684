import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
  BASE_URL,
  BILLING_REPORT_URL,
  CHECKIN_LIST_URL,
  DASHBOARD_URL,
  EVENTS_URL,
  EVENT_CALENDAR_URL,
  MASTER_MANAGEMENT_URL,
  POD_URL,
  REPORT_URL,
  USER_MANAGEMENT_URL,
  AGENCY_REPORT_URL,
  LOGS_URL,
  SURVEYS_URL,
} from "../../Routes/urls";
import { logOutAndRemoveData } from "../../store/actions/UserActions";
import classes from "./Sidebar.module.css";
import SidebarButton from "../../components/Buttons/SidebarButton";
import logoImage from "../../assets/icons/cimpar-logo.svg";
import activeDashboardIcon from "../../assets/icons/sidebar-dashboard-active.svg";
import dashboardIcon from "../../assets/icons/sidebar-dashboard.svg";
import activeLocationIcon from "../../assets/icons/sidebar-location-active.svg";
import locationIcon from "../../assets/icons/sidebar-location.svg";
import activeEventsIcon from "../../assets/icons/sidebar-event-active.svg";
import eventsIcon from "../../assets/icons/sidebar-event.svg";
import activeUsersIcon from "../../assets/icons/sidebar-user-active.svg";
import usersIcon from "../../assets/icons/sidebar-user.svg";
import activeBillingIcon from "../../assets/icons/sidebar-billing-active.svg";
import billingIcon from "../../assets/icons/sidebar-billing.svg";
import activeMastersIcon from "../../assets/icons/sidebar-master-active.svg";
import mastersIcon from "../../assets/icons/sidebar-master.svg";
import activeReportsIcon from "../../assets/icons/sidebar-report-active.svg";
import reportsIcon from "../../assets/icons/sidebar-report.svg";
import logoutIcon from "../../assets/sidebar-logout.svg";
import activeCalenderIcon from "../../assets/icons/event-calendar-active.svg";
import calenderIcon from "../../assets/icons/event-calendar.svg";
import activeCheckInIcon from "../../assets/icons/check-in-list-active.svg";
import checkInListIcon from "../../assets/icons/check-in-list.svg";
import activeLogsIcon from "../../assets/icons/sidebar-logs-active.svg";
import logsIcon from "../../assets/icons/sidebar-logs.svg";
import surveyIcon from "../../assets/icons/survey.svg";
import activeSurveyIcon from "../../assets/icons/survey-active.svg";
import { Permission } from "../../ts/enums";
import { hasPermission } from "../../utils";

const Sidebar = () => {
  const userData = useSelector((state: RootState) => state.users.currUserData);
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let currSidebarUrl = location.pathname.replace(
    `${BASE_URL}/${resourceName}/`,
    ""
  );

  if (currSidebarUrl.indexOf("/") !== -1) {
    currSidebarUrl = currSidebarUrl.substring(
      0,
      currSidebarUrl.lastIndexOf("/")
    );
  }

  const Dashboard = "Dashboard";
  const POD = "POD";
  const Event = "Events";
  const EventCalendar = "Event Calendar";
  const Survey = "Surveys";
  const CheckInList = "Check-In List";
  const MasterManagement = "Masters";
  const UserManagement = "User Management";
  const BillingReport = "Billing Report";
  const Report = "Report";
  const HL7Report = "Agency Report";
  const Logs = "Logs";
  const LogOut = "Log out";

  const sidebarMenus = [
    {
      name: Dashboard,
      url: DASHBOARD_URL,
      activeIcon: activeDashboardIcon,
      icon: dashboardIcon,
    },
    {
      name: POD,
      url: POD_URL,
      activeIcon: activeLocationIcon,
      icon: locationIcon,
      requires: Permission.ManagePods,
    },
    {
      name: Event,
      url: EVENTS_URL,
      activeIcon: activeEventsIcon,
      icon: eventsIcon,
      requires: Permission.ManageEvents,
    },
    {
      name: EventCalendar,
      url: EVENT_CALENDAR_URL,
      activeIcon: activeCalenderIcon,
      icon: calenderIcon,
      requires: Permission.ManageEvents,
    },
    {
      name: Survey,
      url: SURVEYS_URL,
      activeIcon: activeSurveyIcon,
      icon: surveyIcon,
      requires: Permission.ManageEvents,
    },
    {
      name: CheckInList,
      url: CHECKIN_LIST_URL,
      activeIcon: activeCheckInIcon,
      icon: checkInListIcon,
      requires: Permission.CreateReports,
    },
    {
      name: MasterManagement,
      url: MASTER_MANAGEMENT_URL,
      activeIcon: activeMastersIcon,
      icon: mastersIcon,
      requires: Permission.ManageMasters,
    },
    {
      name: UserManagement,
      url: USER_MANAGEMENT_URL,
      activeIcon: activeUsersIcon,
      icon: usersIcon,
      requires: Permission.ManageUsers,
    },
    {
      name: BillingReport,
      url: BILLING_REPORT_URL,
      activeIcon: activeBillingIcon,
      icon: billingIcon,
      requires: Permission.CreateReports,
    },
    {
      name: Report,
      url: REPORT_URL,
      activeIcon: activeReportsIcon,
      icon: reportsIcon,
      requires: Permission.CreateReports,
    },
    {
      name: HL7Report,
      url: AGENCY_REPORT_URL,
      activeIcon: activeReportsIcon,
      icon: reportsIcon,
      requires: Permission.CreateReports,
    },
    {
      name: Logs,
      url: LOGS_URL,
      activeIcon: activeLogsIcon,
      icon: logsIcon,
      requires: Permission.ListActivityLogs,
    },
  ];

  const logoutHandler = () => {
    dispatch(logOutAndRemoveData());
  };

  const navigationHandler = (url: string) => {
    navigate(BASE_URL + `/${resourceName}` + `/${url}`);
  };

  return (
    <div className={classes.sidebarContainer}>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logoImage} alt='CIMPAR Logo' />
      </div>
      <div className={classes.sidebars}>
        {sidebarMenus
          .filter((sidebarMenu) => {
            if (!sidebarMenu.requires) {
              return true;
            }
            return hasPermission(userData.permissions, sidebarMenu.requires);
          })
          .map((sidebarMenu, idx) => (
            <SidebarButton
              {...sidebarMenu}
              onClick={() => navigationHandler(sidebarMenu.url)}
              isActive={
                currSidebarUrl === sidebarMenu.url ||
                (!currSidebarUrl && idx === 0) // If no currSidebarUrl present then by default first element is active
              }
              key={idx}
            />
          ))}
      </div>
      <div className={classes.logoutContainer}>
        <SidebarButton
          name={LogOut}
          activeIcon={logoutIcon}
          icon={logoutIcon}
          onClick={logoutHandler}
        />
      </div>
    </div>
  );
};

export default Sidebar;

import { FileUploader } from "react-drag-drop-files";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

import classes from "./DragDropField.module.css";
import { getClasses } from "../../utils";

type Props = {
  children: JSX.Element | JSX.Element[];
  name: string;
  onChange: any;
  selected: boolean;
  fileTypes: string[];
  className?: string;
  iconClassName?: string;
};

const DragDropField: React.FC<Props> = ({
  children,
  name,
  selected,
  fileTypes,
  onChange,
  className = "",
  iconClassName = "",
}) => {
  return (
    <div className={classes.main}>
      <div className={classes.name}>{name}</div>
      <FileUploader handleChange={onChange} name='file' types={fileTypes}>
        <div className={getClasses(classes.container, className)}>
          {children}
          <FontAwesomeIcon
            icon={faCloudArrowUp}
            className={getClasses(classes.icon, iconClassName)}
          />
          <div className={classes.clickHere}>
            Click here to upload or drag and drop here
          </div>
        </div>
      </FileUploader>
    </div>
  );
};

export default DragDropField;

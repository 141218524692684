import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { BASE_URL, RESET_PASSWORD_URL, SIGN_IN_URL } from "../../Routes/urls";
import { ResetPasswordData } from "../../ts/types";
import { resetPassword } from "../../services/login";
import { RootState } from "../../store";
import classes from "./ForgotPassword.module.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Card from "../../components/Card/Card";
import Input from "../../components/Inputs/Input";
import logoImage from "../../assets/cimpar-logo.svg";

type Props = {};

const getInitialFieldErrors = () => {
  return {
    password: false,
    confirmPassword: false,
  };
};

const ResetPassword: React.FC<Props> = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const token = query.get("token");
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  const [userDetails, setUserDetails] = useState<ResetPasswordData>({
    token: token ? token : "",
    password: "",
  });
  const [fieldErrors, setFieldErrors] = useState(getInitialFieldErrors());
  const [updatePassword, setUpdatePassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [reset, setReset] = useState(false);
  const isFieldsValid = (): boolean => {
    const fieldErrors = getInitialFieldErrors();

    if (!userDetails.password || userDetails.password !== confirmPassword) {
      fieldErrors.confirmPassword = true;
    }
    if (!userDetails.password) {
      fieldErrors.password = true;
    }

    setFieldErrors(fieldErrors);

    return Object.values(fieldErrors).every((value) => value === false);
  };

  const clearFieldErrors = (key: keyof typeof fieldErrors) => {
    if (fieldErrors[key])
      setFieldErrors((prevState) => {
        const newState = { ...prevState };
        newState[key] = false;
        return newState;
      });
  };
  const forgotPasswordHandler = async () => {
    if (!isFieldsValid()) {
      return;
    }

    try {
      const res = await resetPassword(userDetails);
      if (res.status === 200) {
        setUpdatePassword(true);
        if (res.data.success) {
          setReset(true);
        }
      }
    } catch (err) {}
  };

  const HandleBack = () => {
    navigate(`${BASE_URL}/${resourceName}/${SIGN_IN_URL}`);
  };

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.logoContainer}>
          <img src={logoImage} alt='CIMPAR Logo' className={classes.logo} />
        </div>
        {updatePassword ? (
          <div>
            {reset ? (
              <>
                <div className={classes.heading}>Password Changed</div>
                <div className={classes.sentText}>
                  Your Password has been successfully changed.
                </div>
                <div className={classes.actions}>
                  <PrimaryButton
                    name='Login'
                    className={classes.resetBtn}
                    onClick={HandleBack}
                  />
                </div>
              </>
            ) : (
              <div className={classes.expiredText}>
                The reset password link has either expired or has already been
                used.
                <a href='/forgot-password'> Please try again.</a>
              </div>
            )}
          </div>
        ) : (
          <>
            <div className={classes.heading}>Reset Password</div>
            <div className={classes.container}>
              <Input
                name='New password'
                type='password'
                value={userDetails.password || ""}
                onChange={(e) => {
                  setUserDetails((prevState) => {
                    return {
                      ...prevState,
                      password: e.target.value,
                    };
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    forgotPasswordHandler();
                  }
                  clearFieldErrors("password");
                }}
                isInvalid={fieldErrors.password}
                className={classes.inputContainer}
                inputClassName={classes.input}
              />
              <Input
                name='Confirm Password'
                type='password'
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  clearFieldErrors("confirmPassword");
                }}
                isInvalid={fieldErrors.confirmPassword}
                invalidText={"Password does not match"}
                className={classes.inputContainer}
                inputClassName={classes.input}
              />
              <div className={classes.actions}>
                <PrimaryButton
                  name='Reset'
                  className={classes.resetBtn}
                  onClick={forgotPasswordHandler}
                />
              </div>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default ResetPassword;

import { Column, ServiceAndProducts } from "../../../ts/types";
import LinkButton from "../../../components/Buttons/LinkButton";
import classes from "./SelectedServiceAndProduct.module.css";
import Table from "../../../components/CustTable/Table";
import Product from "../../../ts/models/Products";

type Props = {
  data: ServiceAndProducts;
  removeProduct: (id: string) => void;
  removeService: () => void;
  editService: (id: string) => void;
};

const SelectedServiceAndProduct: React.FC<Props> = ({
  data,
  removeProduct,
  removeService,
  editService,
}) => {
  const { service, products } = data;

  const tableColumns: Column<Product>[] = [
    {
      header: "S.NO.",
      accessor: (_, idx) => idx + 1,
    },
    {
      header: "CODE",
      accessor: (product) => product.code,
    },
    {
      header: "PRODUCT NAME",
      accessor: (product) => product.name,
    },
    {
      header: "DESCRIPTION",
      accessor: (product) => product.description,
    },
    {
      header: "ACTION",
      accessor: (product) => (
        <LinkButton onClick={() => removeProduct(product.id)} label='Remove' />
      ),
    },
  ];

  return (
    <div className={classes.main}>
      <div className={classes.serviceContainer}>
        <div>{service.name}</div>
        <div className={classes.actions}>
          <LinkButton
            onClick={() => editService(service.id)}
            label='Edit'
            className={classes.editAction}
          />
          <LinkButton onClick={removeService} label='Remove' />
        </div>
      </div>
      <div className={classes.productContainer}>
        <Table columns={tableColumns} data={products} />
      </div>
    </div>
  );
};

export default SelectedServiceAndProduct;

import { createPortal } from "react-dom";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import classes from "./Loader.module.css";

const container = document.querySelector("#root")!;

const Loader = () => {
  const loading = useSelector((state: RootState) => state.loader.loading);
  if (loading < 0) {
    console.log("Something went wrong as loading state going below ", loading);
  }

  return createPortal(
    !!loading && (
      <>
        <div className={classes.container}>
          <div className={classes.loader} />
        </div>
      </>
    ),
    container
  );
};

export default Loader;

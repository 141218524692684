export default class ScreeningQuestion {
  id!: string;
  code: string;
  question: string;
  options: Option[];
  isActive: boolean;

  constructor(
    code: string,
    question: string,
    options: Option[],
    isActive: boolean = true
  ) {
    this.code = code;
    this.question = question;
    this.options = options;
    this.isActive = isActive;
  }
}

export type Option = {
  text: string;
  showReason: boolean;
};

export type ScreeningQuestionStatus = {
  id: string;
  isActive: boolean;
};

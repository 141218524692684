import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { fetchProducts } from "../../services/masters";
import { SelectedService } from "../../ts/models/Event";
import Modal, { ModalBody, ModalFooter } from "../../components/Modal/Modal";
import classes from "./SelectService.module.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SelectItems from "../../components/SelectItems/SelectItems";
import Select from "../../components/Inputs/Select";
import Product from "../../ts/models/Products";

type Props = {
  alreadySelectedServices: SelectedService[];
  selectedServiceId?: string;
  onClose: () => void;
  onAccept: (service: SelectedService) => void;
};

const columns = [
  {
    header: "CODE",
    accessor: (e: Product) => <div>{e.code}</div>,
  },
  {
    header: "NAME",
    accessor: (e: Product) => <div>{e.name}</div>,
  },
  {
    header: "DESCRIPTION",
    accessor: (e: Product) => <div>{e.description}</div>,
  },
];

const SelectService: React.FC<Props> = ({
  alreadySelectedServices,
  selectedServiceId,
  onClose,
  onAccept,
}) => {
  const alreadySelectedProducts =
    alreadySelectedServices.find(
      (service) => service.serviceId === selectedServiceId
    )?.productIds || [];

  const services = useSelector((state: RootState) => state.masters.services);
  const [serviceId, setServiceId] = useState(selectedServiceId || "");
  const [selectedProducts, setSelectedProducts] = useState<Set<string>>(
    new Set<string>(alreadySelectedProducts)
  );
  const [filteredProd, setFilteredProd] = useState<Product[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const service = services.find((service) => service.id === serviceId);

    const getProducts = async () => {
      const productIds = service?.products?.map((product) => ({
        $oid: product,
      }));

      if (productIds?.length) {
        const productFilter = {
          offset: 0,
          where: {
            _id: {
              $in: productIds,
            },
          },
        };
        try {
          const res = await fetchProducts(productFilter);
          setAllProducts(res.data);
          setFilteredProd(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    };
    getProducts();
  }, [serviceId]);

  const searchItemsHandler = (offset: number, searchText: string) => {
    const filteredProducts = allProducts.filter((pro) => {
      if (
        pro.description.toLowerCase().includes(searchText.toLowerCase()) ||
        pro.code.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return true;
      }
      return false;
    });
    setFilteredProd(filteredProducts);
    setOffset(offset);
  };

  const addServiceHandler = () => {
    const productIds = Array.from(selectedProducts);
    const serviceAndProductId = new SelectedService(serviceId, productIds);

    const service = services.find((service) => service.id === serviceId);
    if (service) {
      onAccept(serviceAndProductId);
      onClose();
    }
  };

  const getFilteredServices = () => {
    const filteredServices = selectedServiceId
      ? [services.find((service) => service.id === selectedServiceId)]
      : services.filter(
          (service) =>
            !alreadySelectedServices.some((s) => s.serviceId === service.id)
        );

    return filteredServices;
  };

  const filteredServices = useMemo(() => getFilteredServices(), [services]);

  const activeServices = filteredServices.filter((service) => {
    return service?.isActive;
  });

  return (
    <>
      <Modal
        title={
          selectedServiceId ? (
            <>Update Medical Service</>
          ) : (
            <>Add Medical Service</>
          )
        }
        onClose={onClose}>
        <>
          <ModalBody>
            <div className={classes.main}>
              <div className={classes.SelectContainer}>
                <Select
                  label={"Select Service"}
                  htmlFor='selectService'
                  options={activeServices}
                  defaultOption={"Select Service"}
                  nameExtractor='name'
                  value={serviceId}
                  onChange={(e) => setServiceId(e.target.value)}
                />
              </div>
              <div className={classes.tableContainer}>
                <SelectItems<Product>
                  initialItemsSet={selectedProducts}
                  setItemsHandler={(items) => setSelectedProducts(items)}
                  items={filteredProd.slice(offset, offset + 10)}
                  noOfPages={Math.ceil(filteredProd.length / 10)}
                  columns={columns}
                  searchItemsHandler={searchItemsHandler}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className={classes.footer}>
              <PrimaryButton
                name={selectedServiceId ? "Update" : "Add"}
                onClick={addServiceHandler}
                disabled={selectedProducts.size === 0}
              />
              <PrimaryButton
                style={{ marginLeft: "1rem" }}
                name='Cancel'
                onClick={() => onClose()}
                transparent
              />
            </div>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

export default SelectService;

import { useEffect, useState } from "react";

import {
  fetchIncentiveSerialNumbers,
  fetchIncentiveSerialNumbersCount,
  updateIncentiveSerialNumber,
  uploadIncentiveSerialNumbersData,
} from "../../../../services/masters";
import { Column, IncentiveSerialNumber } from "../../../../ts/types";
import Modal, { ModalBody } from "../../../../components/Modal/Modal";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import PanelActionContainer from "../../../../components/Containers/PanelActionContainer";
import Table from "../../../../components/CustTable/Table";
import TableContainer from "../../../../components/CustTable/TableContainer";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import UploadCSV from "../../../../components/UploadCSV/UploadCSV";
import classes from "./IncentiveSerialNumbers.module.css";
import LinkButton from "../../../../components/Buttons/LinkButton";

const pageSize = 10;

type Props = {
  incentiveId: string;
  description: string;
  onClose: () => void;
};

const IncentiveSerialNumbersData: React.FC<Props> = ({
  incentiveId,
  description,
  onClose,
}) => {
  const [showUploadCSV, setShowUploadCSV] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [incentiveSerialNumberData, setIncentiveSerialNumberData] = useState<
    IncentiveSerialNumber[]
  >([]);
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [skipCount, setSkipCount] = useState(true);

  useEffect(() => {
    fetchIncentiveSerialNumberData();
  }, [currPageNo]);

  useEffect(() => {
    if (currPageNo === 1) {
      // Preventing first time auto api call because it is already called from currPageNo useEffect
      if (skipCount) {
        setSkipCount(false);
      } else {
        fetchIncentiveSerialNumberData();
      }
    } else {
      // We need to go to page no. 1 if search text change, as well as it will fetch data
      setCurrPageNo(1);
    }
  }, [searchText]);

  const fetchIncentiveSerialNumberData = async () => {
    const filter: any = {
      limit: pageSize,
      offset: (currPageNo - 1) * pageSize,
      where: {
        incentiveId: incentiveId,
      },
    };

    if (searchText) {
      filter.where.serialNumber = {
        $regex: `.*${searchText}.*`,
        $options: "i",
      };
    }

    try {
      const [serialNumbersRes, countRes] = await Promise.all([
        fetchIncentiveSerialNumbers(filter),
        fetchIncentiveSerialNumbersCount(filter.where),
      ]);
      if (serialNumbersRes?.status === 200) {
        setIncentiveSerialNumberData(serialNumbersRes.data);
      }
      if (countRes?.status === 200) {
        const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
        setTotalPages(noOfPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const serialNumbersTableColumn: Column<IncentiveSerialNumber>[] = [
    {
      header: "ID",
      accessor: (e, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "12%",
    },
    {
      header: "SERIAL NUMBER",
      accessor: (e) => e.serialNumber,
    },
    {
      header: "USED",
      accessor: (e) => (e.encounterId ? "YES" : "NO"),
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <LinkButton
          onClick={() => updateStatusHandler(e.id, !e.isActive)}
          label={e.isActive ? (e.encounterId ? "" : "De-activate") : "Activate"}
        />
      ),
    },
  ];

  const uploadSerialNumberDataHandler = (file: any) => {
    uploadIncentiveSerialNumbersData(file, incentiveId).then((res) => {
      if (res.status === 200) {
        fetchIncentiveSerialNumberData();
        setShowUploadCSV(false);
      }
    });
  };

  const updateStatusHandler = (id: string, isActive: boolean) => {
    updateIncentiveSerialNumber({ id, isActive }).then((res) => {
      if (res.status === 200) {
        fetchIncentiveSerialNumberData();
      }
    });
  };
  return (
    <>
      {showUploadCSV && (
        <UploadCSV
          uploadHandler={uploadSerialNumberDataHandler}
          onClose={() => {
            setShowUploadCSV(false);
          }}
        />
      )}
      <Modal
        className={classes.heading}
        title={<>{description} Serial Numbers</>}
        onClose={onClose}>
        <>
          <ModalBody>
            <div className={classes.main}>
              <PanelActionContainer
                leftChildren={
                  <SearchBar
                    onChange={(text) => {
                      setSearchText(text);
                    }}
                    greyBackground
                  />
                }
                rightChildren={
                  <PrimaryButton
                    name='Bulk Upload'
                    onClick={() => setShowUploadCSV(true)}
                  />
                }
              />
              <TableContainer>
                <Table
                  columns={serialNumbersTableColumn}
                  data={incentiveSerialNumberData}
                  isPaginated
                  pageNo={currPageNo}
                  totalPages={totalPages}
                  onPrevClick={() => setCurrPageNo((state) => state - 1)}
                  onNextClick={() => setCurrPageNo((state) => state + 1)}
                />
              </TableContainer>
            </div>
          </ModalBody>
        </>
      </Modal>
    </>
  );
};

export default IncentiveSerialNumbersData;

export default class Survey {
  id!: string;
  podId: string;
  code: string;
  name: string;
  visibleToCustomer: boolean;
  startTime: number;
  endTime: number;
  timezoneOffset: number;
  surveyLink: string;
  isActive: boolean;

  constructor(
    podId = "",
    code = "",
    name = "",
    visibleToCustomer = false,
    startTime = new Date().getTime(),
    endTime = new Date().getTime(),
    timezoneOffset = new Date().getTimezoneOffset() * -1,
    surveyLink = "",
    isActive: boolean = true
  ) {
    this.podId = podId;
    this.code = code;
    this.name = name;
    this.visibleToCustomer = visibleToCustomer;
    this.startTime = startTime;
    this.endTime = endTime;
    this.timezoneOffset = timezoneOffset;
    this.surveyLink = surveyLink;
    this.isActive = isActive;
  }
}

export type SurveyStatus = {
  id: string;
  isActive: boolean;
};

import { useEffect, useState } from "react";

import {
  fetchProducts,
  fetchProductsCount,
  updateProduct,
} from "../../../services/masters";
import PanelContainer from "../../../components/Containers/PanelContainer";
import PanelActionContainer from "../../../components/Containers/PanelActionContainer";
import SearchBar from "../../../components/SearchBar/SearchBar";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import TableContainer from "../../../components/CustTable/TableContainer";
import Table from "../../../components/CustTable/Table";
import addIcon from "../../../assets/icons/add-circle-white.svg";
import { Column } from "../../../ts/types";
import Product from "../../../ts/models/Products";
import AddProduct from "./AddProduct";
import ActionContainer from "../../../components/CustTable/ActionContainer";
import LinkButton from "../../../components/Buttons/LinkButton";

const pageSize = 20;

type Props = {};

const Products: React.FC<Props> = () => {
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState({
    show: false,
    data: new Product(),
  });
  const [productsData, setProductsData] = useState<Product[]>([]);
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [skipCount, setSkipCount] = useState(true);

  useEffect(() => {
    fetchProductsData();
  }, [currPageNo]);

  useEffect(() => {
    if (currPageNo === 1) {
      // Preventing first time auto api call because it is already called from currPageNo useEffect
      if (skipCount) {
        setSkipCount(false);
      } else {
        fetchProductsData();
      }
    } else {
      // We need to go to page no. 1 if search text change, as well as it will fetch data
      setCurrPageNo(1);
    }
  }, [searchText]);

  const fetchProductsData = async () => {
    const filter = {
      limit: pageSize,
      offset: (currPageNo - 1) * pageSize,
      where: {
        $or: [
          {
            code: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
          {
            name: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
          {
            description: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
        ],
      },
    };
    try {
      const [productsRes, countRes] = await Promise.all([
        fetchProducts(filter),
        fetchProductsCount(filter.where),
      ]);
      if (productsRes?.status === 200) {
        setProductsData(productsRes.data);
      }
      if (countRes?.status === 200) {
        const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
        setTotalPages(noOfPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const productTableColumn: Column<Product>[] = [
    {
      header: "ID",
      accessor: (e, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "10%",
    },
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "15%",
    },
    {
      header: "NAME",
      accessor: (e) => e.name,
      width: "25%",
    },
    {
      header: "DESCRIPTION",
      accessor: (e) => e.description,
      width: "25%",
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => setShowModal({ show: true, data: e })}
            label='Edit'
            style={{ marginRight: "1rem" }}
          />
          <LinkButton
            onClick={() => updateStatusHandler(e.id, !e.isActive)}
            label={e.isActive ? "De-activate" : "Activate"}
          />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];

  const updateStatusHandler = (id: string, isActive: boolean) => {
    updateProduct({ id, isActive }).then((res) => {
      if (res.status === 200) {
        fetchProductsData();
      }
    });
  };

  const addProductCloseHandler = (isUpdated: boolean) => {
    setShowModal({ show: false, data: new Product() });
    if (isUpdated) {
      fetchProductsData();
    }
  };

  return (
    <>
      {showModal.show && (
        <AddProduct product={showModal.data} onClose={addProductCloseHandler} />
      )}
      <PanelContainer name='Products' backBtn>
        <PanelActionContainer
          leftChildren={
            <SearchBar
              debounce
              onChange={(text) => {
                setSearchText(text);
              }}
            />
          }
          rightChildren={
            <>
              <PrimaryButton
                name='Add New'
                onClick={() => {
                  setShowModal({ show: true, data: new Product() });
                }}
                disabled={false}
                icon={<img src={addIcon} alt='' />}
              />
            </>
          }
        />
        <PanelInnerContainer>
          <TableContainer>
            <Table
              columns={productTableColumn}
              data={productsData}
              isPaginated
              pageNo={currPageNo}
              totalPages={totalPages}
              onPrevClick={() => setCurrPageNo((state) => state - 1)}
              onNextClick={() => setCurrPageNo((state) => state + 1)}
            />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default Products;

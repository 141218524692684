export default class Product {
  id!: string;
  code: string;
  cptCode: string;
  name: string;
  description: string;
  isActive: boolean;

  constructor(
    code = "",
    cptCode = "",
    name = "",
    description = "",
    isActive: boolean = true
  ) {
    this.code = code;
    this.cptCode = cptCode;
    this.name = name;
    this.description = description;
    this.isActive = isActive;
  }
}

export type ProductStatus = {
  id: string;
  isActive: boolean;
};

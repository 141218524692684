import React, { useEffect, useRef, useState, MouseEvent } from "react";

import { KebabMenuOption } from "../../ts/types";
import verticalDotsIcon from "../../assets/icons/vertical-dots.svg";
import horizontalDotsIcon from "../../assets/icons/horizontal-dots.svg";
import classes from "./KebabMenu.module.css";

type Props = {
  options: KebabMenuOption[];
  isHorizontal?: boolean;
  icon?: JSX.Element | JSX.Element;
};

const KebabMenu: React.FC<Props> = ({ options, isHorizontal, icon }) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState<{
    top?: number;
    right?: number;
  }>({});

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showMenu && mainRef.current && !mainRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showMenu]);

  const menuHandler = (event: MouseEvent<HTMLElement>) => {
    let rect = event.currentTarget.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    // Each option have 36px height and 12px is padding of container
    const menuContainerHeight = options.length * 36 + 12;

    // We are positioning menu container at bottom of icon
    // but when menu height increases window height we are positioning menu container to top of icon
    const right = windowWidth - rect.right + rect.width;
    if (rect.y + rect.height + menuContainerHeight > windowHeight) {
      const top = rect.y - menuContainerHeight;
      setMenuPosition({ top, right });
    } else {
      const top = rect.y + rect.height;
      setMenuPosition({ top, right });
    }
    setShowMenu((state) => !state);
  };

  const getIcon = () => {
    if (icon) {
      return icon;
    } else if (isHorizontal) {
      return <img src={horizontalDotsIcon} alt='' />;
    } else {
      return <img src={verticalDotsIcon} alt='' />;
    }
  };
  return (
    <div className={classes.main} ref={mainRef}>
      {showMenu && (
        <div
          className={classes.menuContainer}
          style={menuPosition}
          ref={menuContainerRef}>
          {options.map(({ name, onClick }, idx) => (
            <div className={classes.menu} onClick={onClick} key={idx}>
              {name}
            </div>
          ))}
        </div>
      )}
      <div className={classes.iconContainer} onClick={menuHandler}>
        {getIcon()}
      </div>
    </div>
  );
};

export default KebabMenu;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../store";
import { Master } from "../../../ts/enums";
import {
  updateDisclaimerData,
  updateDispositionData,
  updateEthnicGroupData,
  updateGenderData,
  updateProcedureData,
  updateRaceData,
  updateRelationshipData,
  updateRouteData,
  updateSiteData,
  updateVfcEligibilityData,
} from "../../../store/actions/MasterActions";
import {
  fetchEnvironmentalAllergies,
  fetchEnvironmentalAllergiesCount,
  fetchMedicationAllergies,
  fetchMedicationAllergiesCount,
  fetchSymptoms,
  fetchSymptomsCount,
  updateEnvironmentalAllergy,
  updateMedicationAllergies,
  updateSymptom,
  uploadEnvironmentalAllergiesData,
  uploadMedicationAllergiesData,
} from "../../../services/masters";
import { Column } from "../../../ts/types";
import classes from "./CommonMaster.module.css";
import CommonMaster from "../../../ts/models/CommonMaster";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SearchBar from "../../../components/SearchBar/SearchBar";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import AddCommonMasters from "./AddCommonMaster";
import PanelContainer from "../../../components/Containers/PanelContainer";
import TableContainer from "../../../components/CustTable/TableContainer";
import Table from "../../../components/CustTable/Table";
import LinkButton from "../../../components/Buttons/LinkButton";
import ActionContainer from "../../../components/CustTable/ActionContainer";
import PanelActionContainer from "../../../components/Containers/PanelActionContainer";
import addIcon from "../../../assets/icons/add-circle-white.svg";
import UploadCSV from "../../../components/UploadCSV/UploadCSV";

const pageSize = 20;

type Props = {
  masterName: string;
  isPaginated?: boolean;
};

const CommonMasters: React.FC<Props> = ({
  masterName,
  isPaginated = false,
}) => {
  const {
    disclaimers,
    dispositions,
    ethnicGroups,
    genders,
    procedures,
    races,
    relationships,
    routes,
    sites,
    vfcEligibilities,
  } = useSelector((state: RootState) => state.masters);
  const dispatch: AppDispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [toBeEdited, setToBeEdited] = useState<CommonMaster>(
    new CommonMaster()
  );
  const [isEditCommonMaster, setIsEditCommonMaster] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [mastersData, setMastersData] = useState<CommonMaster[]>([]);
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [skipCount, setSkipCount] = useState(true);
  const [showUploadCSV, setShowUploadCSV] = useState(false);

  useEffect(() => {
    fetchMasterData();
  }, [currPageNo]);

  useEffect(() => {
    if (currPageNo === 1) {
      // Preventing first time auto api call because it is already called from currPageNo useEffect
      if (skipCount) {
        setSkipCount(false);
      } else {
        fetchMasterData();
      }
    } else {
      // We need to go to page no. 1 if search text change, as well as it will fetch data
      setCurrPageNo(1);
    }
  }, [searchText]);

  const fetchMasterData = async () => {
    const filter = {
      limit: pageSize,
      offset: (currPageNo - 1) * pageSize,
      where: {
        $or: [
          {
            code: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
          {
            description: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
        ],
      },
    };
    try {
      let masterDataRes;
      let countRes;
      switch (masterName) {
        case Master.EnvironmentalAllergies:
          [masterDataRes, countRes] = await Promise.all([
            fetchEnvironmentalAllergies(filter),
            fetchEnvironmentalAllergiesCount(filter.where),
          ]);
          break;
        case Master.MedicationAllergies:
          [masterDataRes, countRes] = await Promise.all([
            fetchMedicationAllergies(filter),
            fetchMedicationAllergiesCount(filter.where),
          ]);
          break;
        case Master.Symptoms:
          [masterDataRes, countRes] = await Promise.all([
            fetchSymptoms(filter),
            fetchSymptomsCount(filter.where),
          ]);
          break;
      }
      if (masterDataRes?.status === 200) {
        setMastersData(masterDataRes.data);
      }
      if (countRes?.status === 200) {
        const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
        setTotalPages(noOfPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addMasterHandler = () => {
    setToBeEdited(new CommonMaster());
    setShowModal(true);
  };

  const closeModalHandler = (isUpdate: boolean) => {
    setShowModal(false);
    setIsEditCommonMaster(false);
    if (isUpdate) {
      fetchMasterData();
    }
  };

  const showAddEditHandler = (isEdit: boolean) => {
    setIsEditCommonMaster(isEdit);
    setShowModal(true);
  };

  const getFilteredData = (data: CommonMaster[]) => {
    return data.filter(
      (master) =>
        master.description.toLowerCase().includes(searchText.toLowerCase()) ||
        master.code.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const getCurrentMaster = (): CommonMaster[] => {
    switch (masterName) {
      case Master.Disclaimers:
        return getFilteredData(disclaimers);
      case Master.Dispositions:
        return getFilteredData(dispositions);
      case Master.EnvironmentalAllergies:
        return mastersData;
      case Master.EthnicGroups:
        return getFilteredData(ethnicGroups);
      case Master.Genders:
        return getFilteredData(genders);
      case Master.MedicationAllergies:
        return mastersData;
      case Master.Procedures:
        return getFilteredData(procedures);
      case Master.Races:
        return getFilteredData(races);
      case Master.Relationships:
        return getFilteredData(relationships);
      case Master.Routes:
        return getFilteredData(routes);
      case Master.Sites:
        return getFilteredData(sites);
      case Master.Symptoms:
        return mastersData;
      case Master.VfcEligibilities:
        return getFilteredData(vfcEligibilities);
      default:
        return [];
    }
  };

  const getEntityName = (): string => {
    switch (masterName) {
      case Master.Disclaimers:
        return "Disclaimer";
      case Master.Dispositions:
        return "Disposition";
      case Master.EnvironmentalAllergies:
        return "Food/Environmental Allergy";
      case Master.EthnicGroups:
        return "Ethnic group";
      case Master.Genders:
        return "Gender";
      case Master.MedicationAllergies:
        return "Medication Allergy";
      case Master.Procedures:
        return "Procedure";
      case Master.Races:
        return "Race";
      case Master.Relationships:
        return "Relationship";
      case Master.Routes:
        return "Route";
      case Master.Sites:
        return "Site";
      case Master.Symptoms:
        return "Symptom";
      case Master.VfcEligibilities:
        return "Funding Source";
      default:
        return "";
    }
  };

  const commonMasterTableColumn: Column<CommonMaster>[] = [
    {
      header: "ID",
      accessor: (e, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "10%",
    },
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "15%",
    },
    {
      header: "DESCRIPTION",
      accessor: (e) => e.description,
      width: "50%",
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => {
              showAddEditHandler(true);
              setToBeEdited(e);
            }}
            label='Edit'
            className={classes.editAction}
          />
          <LinkButton
            onClick={() => updateStatusHandler(e.id, !e.isActive)}
            label={e.isActive ? "De-activate" : "Activate"}
          />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];

  const updateStatusHandler = async (id: string, isActive: boolean) => {
    let updateMaster;

    switch (masterName) {
      case Master.Disclaimers:
        dispatch(updateDisclaimerData({ id, isActive }));
        break;
      case Master.Dispositions:
        dispatch(updateDispositionData({ id, isActive }));
        break;
      case Master.EnvironmentalAllergies:
        updateMaster = updateEnvironmentalAllergy;
        break;
      case Master.EthnicGroups:
        dispatch(updateEthnicGroupData({ id, isActive }));
        break;
      case Master.Genders:
        dispatch(updateGenderData({ id, isActive }));
        break;
      case Master.MedicationAllergies:
        updateMaster = updateMedicationAllergies;
        break;
      case Master.Procedures:
        dispatch(updateProcedureData({ id, isActive }));
        break;
      case Master.Races:
        dispatch(updateRaceData({ id, isActive }));
        break;
      case Master.Relationships:
        dispatch(updateRelationshipData({ id, isActive }));
        break;
      case Master.Routes:
        dispatch(updateRouteData({ id, isActive }));
        break;
      case Master.Sites:
        dispatch(updateSiteData({ id, isActive }));
        break;
      case Master.Symptoms:
        updateMaster = updateSymptom;
        break;
      case Master.VfcEligibilities:
        dispatch(updateVfcEligibilityData({ id, isActive }));
        break;
      default:
    }
    if (updateMaster) {
      try {
        const res = await updateMaster({ id, isActive });
        if (res.status === 200) {
          fetchMasterData();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const uploadEnvironmentalAllergiesDataHandler = (file: any) => {
    uploadEnvironmentalAllergiesData(file).then((res) => {
      if (res.status === 200) {
        fetchMasterData();
        setShowUploadCSV(false);
      }
    });
  };

  const uploadMedicationAllergiesDataHandler = (file: any) => {
    uploadMedicationAllergiesData(file).then((res) => {
      if (res.status === 200) {
        fetchMasterData();
        setShowUploadCSV(false);
      }
    });
  };

  return (
    <>
      {showModal && (
        <AddCommonMasters
          currentMaster={masterName}
          entityName={getEntityName()}
          onClose={closeModalHandler}
          prevData={toBeEdited}
          isEdit={isEditCommonMaster}
          isTextarea={masterName === Master.Disclaimers}
        />
      )}
      {showUploadCSV && (
        <UploadCSV
          uploadHandler={
            masterName === Master.EnvironmentalAllergies
              ? uploadEnvironmentalAllergiesDataHandler
              : uploadMedicationAllergiesDataHandler
          }
          onClose={() => setShowUploadCSV(false)}
        />
      )}
      <PanelContainer name={masterName} backBtn>
        <PanelActionContainer
          leftChildren={
            <SearchBar debounce onChange={(text) => setSearchText(text)} />
          }
          rightChildren={
            <>
              {masterName === Master.EnvironmentalAllergies ||
              masterName === Master.MedicationAllergies ? (
                <PrimaryButton
                  name='Bulk Upload'
                  onClick={() => setShowUploadCSV(true)}
                  style={{ margin: "0 1rem" }}
                />
              ) : (
                <></>
              )}
              <PrimaryButton
                name='Add New'
                onClick={addMasterHandler}
                disabled={false}
                icon={<img src={addIcon} alt='+' />}
              />
            </>
          }
        />
        <PanelInnerContainer>
          <TableContainer>
            <Table
              columns={commonMasterTableColumn}
              data={getCurrentMaster()}
              isPaginated={isPaginated}
              pageNo={currPageNo}
              totalPages={totalPages}
              onPrevClick={() => setCurrPageNo((state) => state - 1)}
              onNextClick={() => setCurrPageNo((state) => state + 1)}
            />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default CommonMasters;

import { useState } from "react";
import { useDispatch } from "react-redux";

import classes from "./EditAcknowledgement.module.css";
import Modal, { ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { AppDispatch } from "../../../store";
import { updateAcknowledgementData } from "../../../store/actions/MasterActions";
import Acknowledgement from "../../../ts/models/Acknowledgement";
import Input from "../../../components/Inputs/Input";

type props = {
  onClose: () => void;
  prevData: Acknowledgement;
};

const EditAcknowledgement: React.FC<props> = ({ onClose, prevData }) => {
  const dispatch: AppDispatch = useDispatch();
  const [data, setData] = useState<Acknowledgement>(prevData);

  const editAcknowledgementHandler = () => {
    const newMaster = new Acknowledgement(data.type, data.description);

    if (prevData.id) {
      newMaster.id = prevData.id;
    }

    dispatch(updateAcknowledgementData(newMaster));

    onClose();
  };

  return (
    <Modal title={<>Update Acknowledgement</>} onClose={onClose}>
      <>
        <ModalBody>
          <div className={classes.main}>
            <Input
              name='Type'
              value={data.type}
              onChange={(e) => {}}
            />
            <Input
              name='Description'
              value={data.description}
              onChange={(e) =>
                setData((state) => ({
                  ...state,
                  description: e.target.value,
                }))
              }
              textarea
              inputClassName={classes.textAreaHeight}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton
              className={classes.actionBtn}
              name='Update'
              onClick={editAcknowledgementHandler}
              style={{ marginRight: "1rem" }}
            />
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={onClose}
              transparent
            />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default EditAcknowledgement;

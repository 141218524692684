import { getClasses } from "../../utils";
import InputLabel from "./InputLabel";
import classes from "./Select.module.css";

type Props = {
  label: string;
  htmlFor: string;
  options: any[];
  value: string | number | undefined;
  defaultOption?: string;
  nameExtractor: string;
  valueExtractor?: string;
  className?: string;
  labelClass?: string;
  selectContainerClass?: string;
  selectClass?: string;
  isInvalid?: boolean;
  invalidText?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const Select: React.FC<Props> = ({
  label,
  htmlFor,
  options,
  value,
  defaultOption,
  nameExtractor,
  valueExtractor,
  className = "",
  labelClass = "",
  selectContainerClass = "",
  selectClass = "",
  isInvalid,
  invalidText,
  onChange,
}) => {
  const getDisplayText = () => {
    if (isInvalid) {
      return (!value && "This field is required") || invalidText;
    }
  };
  return (
    <div className={getClasses(classes.main, className)}>
      <InputLabel label={label} isInvalid={isInvalid} className={labelClass} />
      <div
        className={getClasses(
          classes.inputContainer,
          selectContainerClass,
          isInvalid ? classes.error : ""
        )}>
        <select
          name={htmlFor}
          value={value}
          className={getClasses(classes.select, selectClass)}
          onChange={onChange}>
          {defaultOption && (
            <option value='' disabled hidden>
              {defaultOption}
            </option>
          )}
          {options.map((option, idx) => (
            <option
              value={valueExtractor ? option[valueExtractor] : option.id}
              key={idx}>
              {option.icon && <div>{option.icon}&nbsp;&nbsp;</div>}
              {option[nameExtractor]}
            </option>
          ))}
        </select>
      </div>
      {isInvalid && <div className={classes.errorText}>{getDisplayText()}</div>}
    </div>
  );
};

export default Select;

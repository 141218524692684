import classes from "./Drawer.module.css";
import closeIcon from "../../assets/icons/close.svg";

type Props = {
  name: string;
  children?: JSX.Element | JSX.Element[];
  onClose: () => void;
};

const Drawer: React.FC<Props> = ({ name, children, onClose }) => {
  return (
    <div className={classes.backdrop} onClick={onClose}>
      <div className={classes.main} onClick={(e) => e.stopPropagation()}>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <div className={classes.title}>{name}</div>
            <img
              src={closeIcon}
              alt='Close'
              className={classes.close}
              onClick={onClose}
            />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Drawer;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useTimezoneSelect } from "react-timezone-select";

import { AppDispatch, RootState } from "../../../store";
import { addSurvey, editSurvey } from "../../../store/actions/SurveyActions";
import {
  getLocalDateTimeString,
  getTimestampFromDateValue,
  navigationBack,
} from "../../../utils";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import PanelContainer from "../../../components/Containers/PanelContainer";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Input from "../../../components/Inputs/Input";
import Select from "../../../components/Inputs/Select";
import classes from "./CreateNewSurvey.module.css";
import FormBox from "../../../components/Box/FormBox";
import Survey from "../../../ts/models/Survey";

const getInitialFieldErrors = () => {
  return {
    podId: false,
    name: false,
    surveyLink: false,
  };
};

const getLocalTime = (timestamp: number, offset: number) => {
  const localOffset = new Date().getTimezoneOffset() * 60000;
  return timestamp + localOffset - offset * 60000;
};

const getZoneTime = (timestamp: number, offset: number) => {
  const localOffset = new Date().getTimezoneOffset() * 60000;
  return timestamp - localOffset + offset * 60000;
};

const CreateNewSurvey = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({});

  const surveyToBeEdited = useSelector(
    (state: RootState) => state.surveys.surveyToBeEdited
  );
  const pods = useSelector((state: RootState) => state.pods.pods);

  const [fieldErrors, setFieldErrors] = useState(getInitialFieldErrors());
  const [surveyData, setSurveyData] = useState<Survey>(surveyToBeEdited);

  const [startTime, setStartTime] = useState(
    getLocalTime(surveyData.startTime, -surveyData.timezoneOffset)
  );
  const [endTime, setEndTime] = useState(
    getLocalTime(surveyData.endTime, -surveyData.timezoneOffset)
  );
  const [timezone, setTimezone] = useState<any>(getDefaultTimezone());

  function getDefaultTimezone() {
    let defaultTimeZone = parseTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    if (surveyToBeEdited.timezoneOffset) {
      const offset = surveyToBeEdited.timezoneOffset / 60;
      const timezone = timezoneOptions.find(
        (timezone) => timezone.offset === offset
      );
      if (timezone) defaultTimeZone = timezone;
    }
    return defaultTimeZone;
  }

  const isFieldsValid = (): boolean => {
    const fieldErrors = getInitialFieldErrors();

    if (!surveyData.podId) {
      fieldErrors.podId = true;
    }
    if (!surveyData.name) {
      fieldErrors.name = true;
    }
    if (!surveyData.surveyLink) {
      fieldErrors.surveyLink = true;
    }

    setFieldErrors(fieldErrors);

    return Object.values(fieldErrors).every((value) => value === false);
  };

  const clearFieldErrors = (key: keyof typeof fieldErrors) => {
    if (fieldErrors[key])
      setFieldErrors((prevState) => {
        const newState = { ...prevState };
        newState[key] = false;
        return newState;
      });
  };

  const addSurveyHandler = async (isEdit = false) => {
    if (!isFieldsValid()) {
      return;
    }
    const timezoneOffset = timezone.offset * 60;
    const survey = {
      ...surveyData,
      startTime: getZoneTime(startTime, -timezoneOffset),
      endTime: getZoneTime(endTime, -timezoneOffset),
      timezoneOffset,
    };
    survey.name = survey.name.trim();

    if (isEdit) {
      dispatch(editSurvey(survey, () => handleBack(false)));
    } else {
      dispatch(addSurvey(survey, () => handleBack(false)));
    }
  };

  const handleBack = (addOrUpdateCanceled: boolean) => {
    navigationBack(location, navigate, { addOrUpdateCanceled });
  };

  return (
    <PanelContainer
      name={`${surveyToBeEdited.id ? "Update" : "Create New"} Survey`}
      backBtn>
      <PanelInnerContainer>
        <FormBox name='Basic Details'>
          <div className={classes.basicDetails}>
            <div>
              <Select
                label='Select POD'
                htmlFor='pod'
                options={pods}
                defaultOption={"Select POD"}
                nameExtractor='name'
                value={surveyData.podId}
                onChange={(e) => {
                  setSurveyData((state) => ({
                    ...state,
                    podId: e.target.value,
                  }));
                  clearFieldErrors("podId");
                }}
                isInvalid={fieldErrors.podId}
                className={classes.input}
              />
            </div>
            <div>
              <Input
                name='Name'
                value={surveyData.name}
                onChange={(e) => {
                  setSurveyData((state) => ({
                    ...state,
                    name: e.target.value,
                  }));
                  clearFieldErrors("name");
                }}
                isInvalid={fieldErrors.name}
                className={classes.input}
              />
            </div>
          </div>
          <div className={classes.otherContainer}>
            <div>
              <Input
                name='Start Date'
                value={getLocalDateTimeString(startTime)}
                type='datetime-local'
                onChange={(e) => {
                  const timestamp = getTimestampFromDateValue(e.target.value);
                  setStartTime(timestamp);
                  if (timestamp > endTime) {
                    setEndTime(timestamp);
                  }
                }}
                className={classes.input}
              />
            </div>
            <div>
              <Input
                name='End Date'
                value={getLocalDateTimeString(endTime)}
                type='datetime-local'
                onChange={(e) => {
                  setEndTime(getTimestampFromDateValue(e.target.value));
                }}
                className={classes.input}
                min={getLocalDateTimeString(startTime)}
              />
            </div>
            <div>
              <Select
                label='Select Timezone'
                htmlFor='timezone'
                options={timezoneOptions}
                nameExtractor='label'
                valueExtractor='value'
                value={timezone.value}
                onChange={(e) =>
                  setTimezone(parseTimezone(e.currentTarget.value))
                }
                className={classes.input}
              />
            </div>
          </div>
        </FormBox>
        <FormBox name='Survey Link'>
          <Input
            name='Survey URL'
            value={surveyData.surveyLink}
            onChange={(e) => {
              setSurveyData((state) => ({
                ...state,
                surveyLink: e.target.value,
              }));
              clearFieldErrors("surveyLink");
            }}
            isInvalid={fieldErrors.surveyLink}
            className={classes.input}
          />
        </FormBox>
        <div className={classes.actions}>
          <PrimaryButton
            className={classes.actionBtn}
            name={`${surveyToBeEdited.id ? "Update" : "Create New"} Survey`}
            onClick={() => addSurveyHandler(!!surveyToBeEdited.id)}
            style={{ marginRight: "1rem" }}
          />
          <PrimaryButton
            className={classes.actionBtn}
            name='Cancel'
            onClick={() => handleBack(true)}
            transparent
          />
        </div>
      </PanelInnerContainer>
    </PanelContainer>
  );
};

export default CreateNewSurvey;

import { useState } from "react";

import classes from "./ClosableBox.module.css";
import Card from "../Card/Card";
import upIcon from "../../assets/icons/arrow-up-circle.svg";
import downIcon from "../../assets/icons/arrow-down-circle.svg";

type Props = {
  name: string;
  children: JSX.Element | JSX.Element[];
  defaultState?: boolean;
};

const ClosableBox: React.FC<Props> = ({
  name,
  children,
  defaultState = false,
}) => {
  const [boxOpen, setBoxOpen] = useState(defaultState);

  const dropDownHandler = () => {
    setBoxOpen((state) => !state);
  };

  return (
    <Card className={classes.main}>
      <>
        <div className={classes.nameContainer} onClick={dropDownHandler}>
          <div className={classes.name}>{name}</div>
          {boxOpen ? (
            <img src={upIcon} alt='Close' />
          ) : (
            <img src={downIcon} alt='Open' />
          )}
        </div>
        {boxOpen && <div className={classes.contentBox}>{children}</div>}
      </>
    </Card>
  );
};

export default ClosableBox;

import { getClasses } from "../../utils";
import classes from "./ServiceStatus.module.css";

type Props = {
  serviceName: string;
  count: number;
};
const ServiceStatus: React.FC<Props> = ({ serviceName, count = 0 }) => {
  return (
    <div className={classes.container}>
      <div>{serviceName}</div>
      <div
        className={getClasses(
          classes.slotText,
          count === 0 ? classes.noSlotText : ""
        )}>
        {count} slots left
      </div>
    </div>
  );
};
export default ServiceStatus;

import { useState } from "react";
import { getClasses } from "../../utils";
import classes from "./Checkbox.module.css";

type Props = {
  id: string;
  checked: boolean;
  label?: string;
  className?: string;
  style?: React.CSSProperties;
  onChange: (checked: boolean) => void;
};

const Checkbox: React.FC<Props> = ({
  id,
  checked,
  label,
  className = "",
  style,
  onChange,
}) => {
  return (
    <div className={getClasses(classes.checkbox, className)} style={style}>
      <input
        type='checkbox'
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
};

export default Checkbox;

import { useState } from "react";

import Tab from "./Tab";
import classes from "./TabBar.module.css";

type Props = {
  tabs: any[];
  nameExtractor?: string;
  idExtractor?: string;
  defaultState?: string;
  onTabChange: (id: string) => void;
};

const TabBar: React.FC<Props> = ({
  tabs,
  nameExtractor = "name",
  idExtractor = "id",
  defaultState,
  onTabChange,
}) => {
  const [currTab, setCurrTab] = useState(defaultState || tabs[0]?.[idExtractor]);

  const tabChangeHandler = (id: string) => {
    setCurrTab(id);
    onTabChange(id);
  };

  return (
    <div className={classes.main}>
      {tabs.map((tab, idx) => (
        <Tab
          name={tab[nameExtractor]}
          active={tab[idExtractor] === currTab}
          key={idx}
          onClick={() => tabChangeHandler(tab[idExtractor])}
        />
      ))}
    </div>
  );
};

export default TabBar;

import Loader from "./components/Loader/Loader";
import Router from "./Routes/Router";
import "./App.css";
import WarningDialog from "./components/WarningDialog/WarningDialog";

function App() {
  return (
    <div className='App'>
      <WarningDialog />
      <Loader />
      <Router />
    </div>
  );
}

export default App;

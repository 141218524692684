import React from "react";

import { getClasses } from "../../utils";
import classes from "./Status.module.css";

type Props = {
  name: string;
  status?: "active" | "upcoming" | "closed";
  className?: string;
};

const Status: React.FC<Props> = ({ name, status, className = "" }) => {
  const getClassName = (): string => {
    switch (status) {
      case "active":
        return classes.active;
      case "upcoming":
        return classes.upcoming;
      case "closed":
        return classes.closed;
      default:
        return "";
    }
  };

  return (
    <div className={getClasses(classes.main, getClassName(), className)}>
      {name}
    </div>
  );
};

export default Status;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getUsers,
  updateUserStatusData,
} from "../../store/actions/UserActions";
import { AppDispatch, RootState } from "../../store";
import { downloadUserData, uploadUserData } from "../../services/users";
import { downloadFile, getUserFullName } from "../../utils";
import { Column } from "../../ts/types";
import {
  BASE_URL,
  CREATE_USER_URL,
  UPDATE_USER_URL,
  USER_MANAGEMENT_URL,
} from "../../Routes/urls";
import { usersActions } from "../../store/slices/UserSlice";

import { UserRole } from "../../ts/enums";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import PanelContainer from "../../components/Containers/PanelContainer";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import UploadCSV from "../../components/UploadCSV/UploadCSV";
import PanelActionContainer from "../../components/Containers/PanelActionContainer";
import SearchBar from "../../components/SearchBar/SearchBar";
import TableContainer from "../../components/CustTable/TableContainer";
import Table from "../../components/CustTable/Table";
import User from "../../ts/models/User";
import Status from "../../components/Status/Status";
import KebabMenu from "../../components/KebabMenu/KebabMenu";

const downloadUserDataHandler = async () => {
  try {
    const res = await downloadUserData();
    downloadFile(res, "users.csv");
  } catch (error) {
    console.log(error);
  }
};

const pageSize = 20;

const UserManagement = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { users, currPageNo, count } = useSelector(
    (state: RootState) => state.users
  );
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );
  const [showUploadCSV, setShowUploadCSV] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [state, setState] = useState(location.state || {});

  const totalPages = Math.ceil(Number(count) / pageSize);

  const getUsersHandler = async (updatedPageNo: number) => {
    const filter: any = {
      limit: pageSize,
      offset: (updatedPageNo - 1) * pageSize,
      where: {
        role: {
          $ne: UserRole.SuperAdmin,
        },
      },
    };

    if (searchText.trim()) {
      filter.where = {
        ...filter.where,
        $or: [
          {
            fullName: {
              $regex: `.*${searchText.trim()}.*`,
              $options: "i",
            },
          },
          {
            role: {
              $regex: `.*${searchText.trim()}.*`,
              $options: "i",
            },
          },
          {
            email: {
              $regex: `.*${searchText.trim()}.*`,
              $options: "i",
            },
          },
        ],
      };
    }

    dispatch(getUsers(filter, updatedPageNo));
  };

  useEffect(() => {
    if (searchText) {
      getUsersHandler(1);
    } else {
      if (state.addOrUpdateCanceled) {
        setState({});
      } else {
        getUsersHandler(1);
      }
    }
  }, [searchText]);

  const userTableColumn: Column<User>[] = [
    {
      header: "ID",
      accessor: (e, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "10%",
    },
    {
      header: "NAME",
      accessor: (e) => getUserFullName(e),
      width: "20%",
    },
    {
      header: "EMAIL",
      accessor: (e) => e.email,
    },
    {
      header: "ROLE",
      accessor: (e) => <Status name={e.role} />,
    },
    {
      header: "LOCATION",
      accessor: (e) => e.defaultLocation,
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
    },
    {
      header: "",
      accessor: (e) => (
        <KebabMenu
          options={[
            { name: "Edit", onClick: () => editUserHandler(e) },
            {
              name: e.isActive ? "De-activate" : "Activate",
              onClick: () => markUserHandler(e.id, !e.isActive),
            },
          ]}
        />
      ),
    },
  ];

  const editUserHandler = (user: User) => {
    dispatch(usersActions.setUserToBeEdited(user));
    navigate(
      BASE_URL +
        `/${resourceName}` +
        `/${USER_MANAGEMENT_URL}/` +
        UPDATE_USER_URL
    );
  };

  const markUserHandler = (id: string, isActive: boolean) => {
    dispatch(updateUserStatusData({ id, isActive }));
  };

  const uploadUserDataHandler = async (file: any) => {
    const { status } = await uploadUserData(file);

    if (status === 200) {
      setShowUploadCSV(false);
      getUsersHandler(1);
    }
  };

  const addUserHandler = () => {
    dispatch(usersActions.emptyUserToBeEdited());
    navigate(
      BASE_URL +
        `/${resourceName}` +
        `/${USER_MANAGEMENT_URL}/` +
        CREATE_USER_URL
    );
  };

  return (
    <>
      {showUploadCSV && (
        <UploadCSV
          uploadHandler={uploadUserDataHandler}
          onClose={() => {
            setShowUploadCSV(false);
          }}
        />
      )}
      <PanelContainer name='User Management'>
        <PanelActionContainer
          leftChildren={
            <SearchBar
              debounce
              onChange={(text) => {
                setSearchText(text);
              }}
            />
          }
          rightChildren={
            <>
              <PrimaryButton
                name='Export CSV'
                onClick={downloadUserDataHandler}
              />
              <PrimaryButton
                name='Bulk Upload'
                onClick={() => setShowUploadCSV(true)}
                style={{ margin: "0 1rem" }}
              />
              <PrimaryButton name='Add User' onClick={addUserHandler} />
            </>
          }
        />
        <PanelInnerContainer>
          <TableContainer>
            <Table
              columns={userTableColumn}
              data={users}
              isPaginated
              pageNo={currPageNo}
              totalPages={totalPages}
              onPrevClick={() => getUsersHandler(currPageNo - 1)}
              onNextClick={() => getUsersHandler(currPageNo + 1)}
            />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default UserManagement;

import PrimaryButton from "../Buttons/PrimaryButton";
import Modal, { ModalBody, ModalFooter } from "../Modal/Modal";
import classes from "./Confirm.module.css";

export const Confirm: React.FC<{
  message: string;
  onClose: () => void;
  onConfirmation: () => void;
}> = ({ message, onClose, onConfirmation }) => {
  return (
    <Modal title={<>Confirmation</>} onClose={onClose}>
      <>
        <ModalBody>
          <div>{message}</div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton
              onClick={onConfirmation}
              name='OK'
              style={{ marginRight: "1rem" }}
            />
            <PrimaryButton onClick={onClose} name='Cancel' transparent />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default Confirm;

export default class InfoQuestion {
  id!: string;
  code: string;
  question: string;
  questionType: string;
  options: string[];
  isActive: boolean;

  constructor(
    code: string = "",
    question: string = "",
    questionType: string = "",
    options: string[] = [],
    isActive: boolean = true
  ) {
    this.code = code;
    this.question = question;
    this.questionType = questionType;
    this.options = options;
    this.isActive = isActive;
  }
}

export type InfoQuestionStatus = {
  id: string;
  isActive: boolean;
};

import axios from "../axios";
import Survey, { SurveyStatus } from "../ts/models/Survey";
import { encodeQueryFilter } from "./utils";

const SURVEYS_URL = "/api/surveys";

export function fetchSurveys(filter?: any) {
  let url = SURVEYS_URL;
  if (filter) {
    url += `?filter=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function fetchSurveysCount(filter?: any) {
  let url = `${SURVEYS_URL}/count`;
  if (filter) {
    url += `?where=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function addNewSurvey(data: Survey) {
  return axios({
    url: SURVEYS_URL,
    method: "post",
    data,
  });
}

export function editAnSurvey(data: Survey) {
  return axios({
    url: `${SURVEYS_URL}/${data.id}`,
    method: "put",
    data,
  });
}

export function updateSurveyStatus(data: SurveyStatus) {
  return axios({
    url: `${SURVEYS_URL}/${data.id}`,
    method: "put",
    data,
  });
}

export function deleteAnSurvey(surveyId: string) {
  return axios({
    url: `${SURVEYS_URL}/${surveyId}`,
    method: "delete",
  });
}

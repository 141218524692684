import React, { useState } from "react";

import Modal, { ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import ScreeningQuestion from "../../../ts/models/ScreeningQuestion";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import classes from "./AddScreeningQuestion.module.css";
import Input from "../../../components/Inputs/Input";
import LinkButton from "../../../components/Buttons/LinkButton";
import InputLabel from "../../../components/Inputs/InputLabel";
import trashIcon from "../../../assets/icons/trash.svg";
import Checkbox from "../../../components/Checkbox/Checkbox";
import {
  addScreeningQuestion,
  updateScreeningQuestion,
} from "../../../services/masters";

type Props = {
  onClose: (isUpdated: boolean) => void;
  prevData?: ScreeningQuestion;
  isEdit: Boolean;
};

const AddScreeningQuestion: React.FC<Props> = ({
  onClose,
  prevData,
  isEdit,
}) => {
  const [data, setData] = useState<ScreeningQuestion>(
    prevData ? prevData : new ScreeningQuestion("", "", [])
  );
  const [option, setOption] = useState({
    text: "",
    showReason: false,
  });

  const addMasterHandler = async () => {
    const newMaster = new ScreeningQuestion(
      data.code,
      data.question,
      data.options
    );
    let res;
    try {
      if (isEdit) {
        newMaster.id = prevData!.id;
        res = await updateScreeningQuestion(newMaster);
      } else {
        res = await addScreeningQuestion(newMaster);
      }
      if (res.status === 200) {
        onClose(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addOptionHandler = () => {
    if (option.text) {
      setData((state) => ({
        ...state,
        options: [...state.options, option],
      }));
      setOption({ text: "", showReason: false });
    }
  };

  const isRequiredDataMissing = () => {
    if (
      !data.code?.trim() ||
      !data.question?.trim() ||
      data.options.length === 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={
        <>{isEdit ? "Edit Screening Question" : "Add Screening Question"}</>
      }
      onClose={() => onClose(false)}
      style={{ width: "30rem" }}>
      <>
        <ModalBody>
          <div className={classes.main}>
            <Input
              name='Code'
              value={data.code}
              onChange={(e) =>
                setData((state) => ({ ...state, code: e.target.value }))
              }
              className={classes.input}
            />
            <Input
              name='Question'
              value={data.question}
              onChange={(e) =>
                setData((state) => ({
                  ...state,
                  question: e.target.value,
                }))
              }
              className={classes.input}
            />
            <div className={classes.addOptionContainer}>
              <div>
                <Input
                  name='Add Option'
                  value={option.text}
                  onChange={(e) => {
                    setOption((state) => ({
                      ...state,
                      text: e.target.value,
                    }));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addOptionHandler();
                    }
                  }}
                  className={classes.optionInput}
                />
                <Checkbox
                  className={classes.reasonCheckBox}
                  checked={option.showReason}
                  id={"reason-box"}
                  label={"Show Reason"}
                  onChange={(checked) => {
                    setOption((prevState) => ({
                      ...prevState,
                      showReason: checked,
                    }));
                  }}
                />
              </div>
              <div>
                <LinkButton
                  onClick={addOptionHandler}
                  label='Add'
                  className={classes.addOptionBtn}
                />
              </div>
            </div>
            <div className={classes.optionsContainer}>
              <InputLabel label='Options' />
              <div className={classes.options}>
                {data.options.map((option, index) => (
                  <div key={index} className={classes.option}>
                    <span>
                      {`${index + 1}. `}
                      {`${option.text} ${
                        option.showReason ? "(with reason)" : ""
                      }`}
                    </span>
                    <img
                      src={trashIcon}
                      alt='delete'
                      onClick={() => {
                        setData((state) => ({
                          ...state,
                          options: state.options.filter(
                            (_, idx) => idx !== index
                          ),
                        }));
                      }}
                      className={classes.trashIcon}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton
              className={classes.actionBtn}
              name={isEdit ? "Update" : "Add"}
              onClick={addMasterHandler}
              style={{ marginRight: "1rem" }}
              disabled={isRequiredDataMissing()}
            />
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={() => onClose(false)}
              transparent
            />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default AddScreeningQuestion;

import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getFormattedDate, getFormattedTime } from "../../utils";
import KebabMenu from "../../components/KebabMenu/KebabMenu";
import Status from "../../components/Status/Status";
import Survey from "../../ts/models/Survey";
import classes from "./SurveyCard.module.css";
import { UserRole } from "../../ts/enums";

type Props = {
  survey: Survey;
  onEdit: (data: any) => void;
  onStatusUpdate: (id: string, isActive: boolean) => void;
  onDelete: (data: Survey) => void;
};

const SurveyCard: React.FC<Props> = ({
  survey,
  onEdit,
  onStatusUpdate,
  onDelete,
}) => {
  const userData = useSelector((state: RootState) => state.users.currUserData);
  const { id, name, startTime, endTime, isActive } = survey;
  const isUpcoming = startTime > Date.now();
  const isLive = startTime < Date.now() && endTime > Date.now();
  const getOptions = () => {
    const options = [
      { name: "Edit", onClick: () => onEdit(survey) },
      {
        name: isActive ? "De-activate" : "Activate",
        onClick: () => onStatusUpdate(id, !isActive),
      },
    ];

    if (
      userData.role === UserRole.Admin ||
      userData.role === UserRole.SuperAdmin
    ) {
      options.push({
        name: "Delete",
        onClick: () => onDelete(survey),
      });
    }
    return options;
  };
  return (
    <>
      <div className={classes.main}>
        <div className={classes.topContainer}>
          <div>
            <Status
              name={isUpcoming ? "Upcoming" : isLive ? "Live" : "Closed"}
              status={isUpcoming ? "upcoming" : isLive ? "active" : "closed"}
            />
          </div>
          <KebabMenu options={getOptions()} isHorizontal />
        </div>
        <div>
          <div>
            <div className={classes.name}>{name}</div>
            <div className={classes.date}>
              <div>
                <span style={{ display: "block" }}>{`${getFormattedDate(
                  startTime
                )} - ${getFormattedDate(endTime)}`}</span>
                <span style={{ display: "block" }}>{`${getFormattedTime(
                  startTime
                )} - ${getFormattedTime(endTime)}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyCard;

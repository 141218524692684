import { useState } from "react";
import { useSelector } from "react-redux";
import Acknowledgement from "../../../ts/models/Acknowledgement";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import { RootState } from "../../../store";
import PanelContainer from "../../../components/Containers/PanelContainer";
import TableContainer from "../../../components/CustTable/TableContainer";
import Table from "../../../components/CustTable/Table";
import { Column } from "../../../ts/types";
import LinkButton from "../../../components/Buttons/LinkButton";
import ActionContainer from "../../../components/CustTable/ActionContainer";
import EditAcknowledgement from "./EditAcknowledgement";

const Acknowledgements = () => {
  const { acknowledgements } = useSelector((state: RootState) => state.masters);

  const [showModal, setShowModal] = useState(false);
  const [toBeEdited, setToBeEdited] = useState<Acknowledgement>(
    new Acknowledgement("", "")
  );

  const closeModalHandler = () => {
    setShowModal(false);
  };

  const acknowledgementTableColumn: Column<Acknowledgement>[] = [
    {
      header: "ID",
      accessor: (e, idx) => idx! + 1,
      width: "10%",
    },
    {
      header: "TYPE",
      accessor: (e) => e.type,
      width: "20%",
    },
    {
      header: "DESCRIPTION",
      accessor: (e) => e.description,
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => {
              setToBeEdited(e);
              setShowModal(true);
            }}
            label='Edit'
          />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];
  return (
    <>
      {showModal && (
        <EditAcknowledgement
          onClose={closeModalHandler}
          prevData={toBeEdited}
        />
      )}
      <PanelContainer name='Acknowledgements' backBtn>
        <PanelInnerContainer>
          <TableContainer>
            <Table
              columns={acknowledgementTableColumn}
              data={acknowledgements}
            />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default Acknowledgements;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import Event, { SelectedService } from "../../../ts/models/Event";
import { AppDispatch, RootState } from "../../../store";
import {
  getLocalDateTimeString,
  getTimestampFromDateValue,
  navigationBack,
  getUserFullName,
} from "../../../utils";
import { Column, ServiceAndProducts } from "../../../ts/types";
import { addEvent, editEvent } from "../../../store/actions/EventActions";
import { fetchProducts } from "../../../services/masters";
import { fetchUsers, fetchUsersCount } from "../../../services/users";
import SelectedServiceAndProduct from "./SelectedServiceAndProduct";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import SelectItemsModal from "../../../components/SelectItems/SelectItemsModal";
import PanelContainer from "../../../components/Containers/PanelContainer";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SelectService from "../SelectService";
import LinkButton from "../../../components/Buttons/LinkButton";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Input from "../../../components/Inputs/Input";
import Select from "../../../components/Inputs/Select";
import Table from "../../../components/CustTable/Table";
import User from "../../../ts/models/User";
import Status from "../../../components/Status/Status";
import addIcon from "../../../assets/icons/add-circle.svg";
import Product from "../../../ts/models/Products";
import classes from "./CreateNewEvent.module.css";
import { useTimezoneSelect } from "react-timezone-select";
import FormBox from "../../../components/Box/FormBox";
import helpIcon from "../../../assets/icons/help-info.svg";
import HelpModal from "../../../components/Help/HelpModal";

const getInitialFieldErrors = () => {
  return {
    podId: false,
    name: false,
    capacity: false,
    address: false,
    city: false,
    state: false,
    zipCode: false,
  };
};

const getLocalTime = (timestamp: number, offset: number) => {
  const localOffset = new Date().getTimezoneOffset() * 60000;
  return timestamp + localOffset - offset * 60000;
};

const getZoneTime = (timestamp: number, offset: number) => {
  const localOffset = new Date().getTimezoneOffset() * 60000;
  return timestamp - localOffset + offset * 60000;
};

const CreateNewEvent = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({});

  const eventToBeEdited = useSelector(
    (state: RootState) => state.events.eventToBeEdited
  );

  const pods = useSelector((state: RootState) => state.pods.pods);
  const servicesMaster = useSelector(
    (state: RootState) => state.masters.services
  );

  const [fieldErrors, setFieldErrors] = useState(getInitialFieldErrors());
  const [totalPages, setTotalPages] = useState(1);
  const [eventData, setEventData] = useState<Event>(eventToBeEdited);
  const [showModalMedicalProfSelection, setShowModalForMedicalProfSelection] =
    useState(false);
  const [showSelectService, setShowSelectService] = useState<{
    show: boolean;
    serviceId?: string;
  }>({ show: false });

  // Selected Med Prof ids to update data
  const [selectedMedProfIds, setSelectedMedProfIds] = useState<Set<string>>(
    new Set<string>(new Set(eventToBeEdited.medProfs))
  );

  // Selected Med Prof to display list of Services and Products
  const [selectedMedProfs, setSelectedMedProfs] = useState<User[]>([]);
  const [medProfs, setMedProfs] = useState<User[]>([]);

  // Selected service and product ids to update data
  const [selectedServices, setSelectedServices] = useState<SelectedService[]>(
    eventToBeEdited.services
  );

  // Selected services and products to display list of Services and Products
  const [servicesAndProductList, setServicesAndProductList] = useState<
    ServiceAndProducts[]
  >([]);

  const [startTime, setStartTime] = useState(
    getLocalTime(eventData.startTime, -eventData.timezoneOffset)
  );
  const [endTime, setEndTime] = useState(
    getLocalTime(eventData.endTime, -eventData.timezoneOffset)
  );
  const [timezone, setTimezone] = useState<any>(getDefaultTimezone());
  const [showHelpModal, setShowHelpModal] = useState(false);

  function getDefaultTimezone() {
    let defaultTimeZone = parseTimezone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    if (eventToBeEdited.timezoneOffset) {
      const offset = eventToBeEdited.timezoneOffset / 60;
      const timezone = timezoneOptions.find(
        (timezone) => timezone.offset === offset
      );
      if (timezone) defaultTimeZone = timezone;
    }
    return defaultTimeZone;
  }

  const searchMedicalProfsHandler = async (
    offset: number = 0,
    searchText: string = ""
  ) => {
    const filter = {
      limit: 10,
      offset,
      where: {
        isActive: true,
        $or: [
          {
            firstName: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
          {
            middleName: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
          {
            lastName: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
        ],
      },
    };

    try {
      const [medicalPropsRes, countRes] = await Promise.all([
        fetchUsers(filter),
        fetchUsersCount(filter.where),
      ]);

      const count = Math.ceil(Number(countRes.data.count) / 10);
      setMedProfs(medicalPropsRes.data);
      setTotalPages(count);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const fetchMedProfsFromIds = async () => {
      const medProfIds = Array.from(selectedMedProfIds).map((MedProfId) => ({
        $oid: MedProfId,
      }));

      if (medProfIds?.length) {
        const filter = {
          offset: 0,
          where: {
            _id: {
              $in: medProfIds,
            },
          },
        };

        try {
          const [res, medicalProfsCount] = await Promise.all([
            fetchUsers(filter),
            fetchUsersCount(filter.where),
          ]);

          if (res.status) {
            setSelectedMedProfs(res.data);
          }

          if (medicalProfsCount?.status === 200) {
            const noOfPages = Math.ceil(
              Number(medicalProfsCount.data.count) / 10
            );
            setTotalPages(noOfPages);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setSelectedMedProfs([]);
      }
    };

    fetchMedProfsFromIds();
  }, [selectedMedProfIds]);

  useEffect(() => {
    const addServiceAndProducts = async () => {
      const selectedServiceList: ServiceAndProducts[] = [];

      // collecting product ids to fetch products data
      const allProductsIds = new Set();
      let allProducts: Product[] = [];

      selectedServices.forEach((selectedService: SelectedService) => {
        selectedService.productIds.forEach((productId) =>
          allProductsIds.add(productId)
        );
      });

      const productIds = Array.from(allProductsIds).map((product) => ({
        $oid: product,
      }));

      if (productIds?.length) {
        const productFilter = {
          offset: 0,
          where: {
            _id: {
              $in: productIds,
            },
          },
        };

        try {
          const res = await fetchProducts(productFilter);
          if (res.status) {
            allProducts = res.data;
          }
        } catch (err) {
          console.log(err);
        }
      }

      for (let i = 0; i < selectedServices.length; i++) {
        const selectedService = servicesMaster.find(
          (service) => service.id === selectedServices[i].serviceId
        );

        let productList: Product[] = [];

        selectedServices[i].productIds.forEach((productId) => {
          const product = allProducts.find(
            (product) => product.id === productId
          );

          if (product) {
            productList.push(product);
          }
        });

        if (selectedService) {
          const selectedServiceAndProducts: ServiceAndProducts = {
            service: selectedService,
            products: productList,
          };

          selectedServiceList.push(selectedServiceAndProducts);
        }
      }

      setServicesAndProductList(selectedServiceList);
    };

    addServiceAndProducts();
  }, [selectedServices]);

  const isFieldsValid = (): boolean => {
    const fieldErrors = getInitialFieldErrors();

    if (!eventData.podId) {
      fieldErrors.podId = true;
    }
    if (!eventData.name) {
      fieldErrors.name = true;
    }
    if (!eventData.address) {
      fieldErrors.address = true;
    }
    if (!eventData.city) {
      fieldErrors.city = true;
    }
    if (!eventData.state) {
      fieldErrors.state = true;
    }
    if (!eventData.zipCode) {
      fieldErrors.zipCode = true;
    }
    if (!eventData.capacity) {
      fieldErrors.capacity = true;
    }

    setFieldErrors(fieldErrors);

    return Object.values(fieldErrors).every((value) => value === false);
  };

  const clearFieldErrors = (key: keyof typeof fieldErrors) => {
    if (fieldErrors[key])
      setFieldErrors((prevState) => {
        const newState = { ...prevState };
        newState[key] = false;
        return newState;
      });
  };

  const addEventHandler = async (isEdit = false) => {
    if (!isFieldsValid()) {
      return;
    }
    const timezoneOffset = timezone.offset * 60;
    const event = {
      ...eventData,
      startTime: getZoneTime(startTime, -timezoneOffset),
      endTime: getZoneTime(endTime, -timezoneOffset),
      timezoneOffset,
      services: selectedServices,
      medProfs: Array.from(selectedMedProfIds),
    };
    event.name = event.name.trim();

    if (isEdit) {
      dispatch(editEvent(event, () => handleBack(false)));
    } else {
      dispatch(addEvent(event, () => handleBack(false)));
    }
  };

  const selectServiceHandler = (service: SelectedService) => {
    setSelectedServices((prevState) => {
      let isPresent = false;
      const newState = prevState.map((state) => {
        let tempState: SelectedService;
        if (state.serviceId === service.serviceId) {
          tempState = { ...state, productIds: service.productIds };
          isPresent = true;
          return tempState;
        }
        return state;
      });
      if (isPresent) {
        return newState;
      } else {
        return [...prevState, service];
      }
    });
  };

  const productRemoveHandler = (productId: string, serviceListIdx: number) => {
    setSelectedServices((state) => {
      const newProductList = state[serviceListIdx].productIds.filter(
        (product) => product !== productId
      );

      let services = [...state];

      let serviceToBeEdited = { ...services[serviceListIdx] };
      serviceToBeEdited.productIds = newProductList;

      services[serviceListIdx] = serviceToBeEdited;

      return services;
    });
  };

  const removeServiceHandler = (idx: number) => {
    setSelectedServices((state) => {
      const services = [...state];
      services.splice(idx, 1);
      return services;
    });
  };

  const selectMedProfColumns = [
    {
      header: "NAME",
      accessor: (e: User) => getUserFullName(e),
    },
    {
      header: "LOCATION",
      accessor: (e: User) => e.defaultLocation,
    },
    {
      header: "SERVICE TYPES",
      accessor: (e: User) => {
        const serviceNames = e.serviceTypes.map((serviceId) => {
          const service = servicesMaster.find(
            (service) => service.id === serviceId
          );

          if (service) {
            return service.name;
          } else {
            return "";
          }
        });
        return (
          <div className={classes.eventServiceContainer}>
            {serviceNames.map((serviceName, idx) => (
              <Status key={idx} name={serviceName} />
            ))}
          </div>
        );
      },
    },
  ];

  const removeMedProf = (id: string) => {
    setSelectedMedProfIds((state) => {
      const newMedProfSet = new Set(state);
      newMedProfSet.delete(id);
      return newMedProfSet;
    });
  };

  const selectedMedProfsColumn: Column<User>[] = [
    {
      header: "S.NO.",
      accessor: (_, idx) => idx + 1,
    },
    {
      header: "NAME",
      accessor: (medProf) => getUserFullName(medProf),
    },
    {
      header: "EMAIL",
      accessor: (medProf) => medProf.email,
    },
    {
      header: "ACTION",
      accessor: (medProf) => (
        <LinkButton onClick={() => removeMedProf(medProf.id)} label='Remove' />
      ),
    },
  ];

  const handleBack = (addOrUpdateCanceled: boolean) => {
    navigationBack(location, navigate, { addOrUpdateCanceled });
  };

  return (
    <>
      {showSelectService.show && (
        <SelectService
          alreadySelectedServices={selectedServices}
          onClose={() => setShowSelectService({ show: false })}
          onAccept={selectServiceHandler}
          selectedServiceId={showSelectService.serviceId}
        />
      )}
      {showModalMedicalProfSelection && (
        <SelectItemsModal<User>
          title={"Add Medical Professional"}
          initialItemsSet={selectedMedProfIds}
          setItemsHandler={(items) => setSelectedMedProfIds(items)}
          onClose={() => setShowModalForMedicalProfSelection(false)}
          items={medProfs}
          noOfPages={totalPages}
          columns={selectMedProfColumns}
          searchItemsHandler={searchMedicalProfsHandler}
          isPaginated
        />
      )}
      {showHelpModal && (
        <HelpModal
          title='Create Event'
          content={`/api/storage/cimpar/resources/help/create-event-help.html`}
          onClose={() => setShowHelpModal(false)}
        />
      )}
      <PanelContainer
        name={`${eventToBeEdited.id ? "Update" : "Create New"} Event`}
        backBtn>
        <div className={classes.helpContainer}>
          <LinkButton
            label={"Know About How To Create Event"}
            icon={<img src={helpIcon} alt='' />}
            onClick={() => setShowHelpModal(true)}
            className={classes.helpBtn}
          />
        </div>
        <PanelInnerContainer>
          <FormBox name='Basic Details'>
            <div className={classes.basicDetails}>
              <div>
                <Select
                  label='Select POD'
                  htmlFor='pod'
                  options={pods}
                  defaultOption={"Select POD"}
                  nameExtractor='name'
                  value={eventData.podId}
                  onChange={(e) => {
                    setEventData((state) => ({
                      ...state,
                      podId: e.target.value,
                    }));
                    clearFieldErrors("podId");
                  }}
                  isInvalid={fieldErrors.podId}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='Name'
                  value={eventData.name}
                  onChange={(e) => {
                    setEventData((state) => ({
                      ...state,
                      name: e.target.value,
                    }));
                    clearFieldErrors("name");
                  }}
                  isInvalid={fieldErrors.name}
                  className={classes.input}
                />
              </div>
            </div>
          </FormBox>
          <FormBox name='Other Details'>
            <div className={classes.otherContainer}>
              <div>
                <Input
                  name='Start Date'
                  value={getLocalDateTimeString(startTime)}
                  type='datetime-local'
                  onChange={(e) => {
                    const timestamp = getTimestampFromDateValue(e.target.value);
                    setStartTime(timestamp);
                    if (timestamp > endTime) {
                      setEndTime(timestamp);
                    }
                  }}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='End Date'
                  value={getLocalDateTimeString(endTime)}
                  type='datetime-local'
                  onChange={(e) => {
                    setEndTime(getTimestampFromDateValue(e.target.value));
                  }}
                  className={classes.input}
                  min={getLocalDateTimeString(startTime)}
                />
              </div>
              <div>
                <Select
                  label='Select Timezone'
                  htmlFor='timezone'
                  options={timezoneOptions}
                  nameExtractor='label'
                  valueExtractor='value'
                  value={timezone.value}
                  onChange={(e) =>
                    setTimezone(parseTimezone(e.currentTarget.value))
                  }
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='Capacity (per service per day)'
                  value={eventData.capacity || ""}
                  type='number'
                  onChange={(e) => {
                    setEventData((state) => ({
                      ...state,
                      capacity: Number(e.target.value),
                    }));
                    clearFieldErrors("capacity");
                  }}
                  isInvalid={fieldErrors.capacity}
                  className={classes.input}
                />
              </div>
              <Checkbox
                className={classes.homeEventCheckbox}
                checked={eventData.isHome}
                id={"home-event"}
                label={"Mark this as a Home event"}
                onChange={(checked) =>
                  setEventData((state) => ({
                    ...state,
                    isHome: checked,
                  }))
                }
              />
            </div>
          </FormBox>
          <FormBox name='Location'>
            <div className={classes.addressDetails}>
              <div>
                <Input
                  name='Address'
                  value={eventData.address}
                  onChange={(e) => {
                    setEventData((state) => ({
                      ...state,
                      address: e.target.value,
                    }));
                    clearFieldErrors("address");
                  }}
                  isInvalid={fieldErrors.address}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='City'
                  value={eventData.city}
                  onChange={(e) => {
                    setEventData((state) => ({
                      ...state,
                      city: e.target.value,
                    }));
                    clearFieldErrors("city");
                  }}
                  isInvalid={fieldErrors.city}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='State'
                  value={eventData.state}
                  onChange={(e) => {
                    setEventData((state) => ({
                      ...state,
                      state: e.target.value,
                    }));
                    clearFieldErrors("state");
                  }}
                  isInvalid={fieldErrors.state}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='Zip Code'
                  type='number'
                  value={eventData.zipCode}
                  onChange={(e) => {
                    setEventData((state) => ({
                      ...state,
                      zipCode: e.target.value,
                    }));
                    clearFieldErrors("zipCode");
                  }}
                  isInvalid={fieldErrors.zipCode}
                  className={classes.input}
                />
              </div>
            </div>
          </FormBox>
          <FormBox name='Medical Professionals'>
            <>
              {selectedMedProfs.length ? (
                <div className={classes.medProfListContainer}>
                  <Table
                    columns={selectedMedProfsColumn}
                    data={selectedMedProfs}
                  />
                </div>
              ) : (
                <div className={classes.noSelected}>
                  No medical professional selected
                </div>
              )}
              <div className={classes.addBtn}>
                <LinkButton
                  label='Add Medical Professionals'
                  icon={<img src={addIcon} alt='' />}
                  onClick={() => setShowModalForMedicalProfSelection(true)}
                />
              </div>
            </>
          </FormBox>
          <FormBox name='Medical Services'>
            <>
              {servicesAndProductList.length ? (
                servicesAndProductList.map((servicesAndProduct, idx) => (
                  <SelectedServiceAndProduct
                    data={servicesAndProduct}
                    editService={() =>
                      setShowSelectService({
                        show: true,
                        serviceId: servicesAndProduct.service.id,
                      })
                    }
                    removeService={() => removeServiceHandler(idx)}
                    removeProduct={(id) => productRemoveHandler(id, idx)}
                    key={idx}
                  />
                ))
              ) : (
                <div className={classes.noSelected}>
                  No medical service selected
                </div>
              )}
              <div className={classes.addBtn}>
                <LinkButton
                  label='Add Medical Service'
                  icon={<img src={addIcon} alt='' />}
                  onClick={() => {
                    setShowSelectService({ show: true });
                  }}
                />
              </div>
            </>
          </FormBox>
          <div className={classes.actions}>
            <PrimaryButton
              className={classes.actionBtn}
              name={`${eventToBeEdited.id ? "Update" : "Create New"} Event`}
              onClick={() => addEventHandler(!!eventToBeEdited.id)}
              style={{ marginRight: "1rem" }}
            />
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={() => handleBack(true)}
              transparent
            />
          </div>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default CreateNewEvent;

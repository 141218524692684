import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { BASE_URL, SIGN_IN_URL } from "../../Routes/urls";
import { validateEmail } from "../../utils";
import { ResetPasswordData } from "../../ts/types";
import { forgotPassword } from "../../services/login";
import { RootState } from "../../store";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Card from "../../components/Card/Card";
import Input from "../../components/Inputs/Input";
import classes from "./ForgotPassword.module.css";
import logoImage from "../../assets/cimpar-logo.svg";
import LinkButton from "../../components/Buttons/LinkButton";

type Props = {};

const getInitialFieldErrors = () => {
  return {
    email: false,
  };
};

const ForgotPassword: React.FC<Props> = () => {
  const navigate = useNavigate();
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  const [userDetails, setUserDetails] = useState<ResetPasswordData>({
    email: "",
  });
  const [fieldErrors, setFieldErrors] = useState(getInitialFieldErrors());
  const [sentLink, setSentLink] = useState(false);

  const isFieldsValid = (): boolean => {
    const fieldErrors = getInitialFieldErrors();

    if (!userDetails.email || !validateEmail(userDetails.email)) {
      fieldErrors.email = true;
    }
    setFieldErrors(fieldErrors);

    return Object.values(fieldErrors).every((value) => value === false);
  };

  const clearFieldErrors = (key: keyof typeof fieldErrors) => {
    if (fieldErrors[key])
      setFieldErrors((prevState) => {
        const newState = { ...prevState };
        newState[key] = false;
        return newState;
      });
  };

  const forgotPasswordHandler = async () => {
    if (!isFieldsValid()) {
      return;
    }

    try {
      const res = await forgotPassword(userDetails);
      if (res.status === 200) {
        setSentLink(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const HandleBack = () => {
    navigate(`${BASE_URL}/${resourceName}/${SIGN_IN_URL}`);
  };

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.logoContainer}>
          <img src={logoImage} alt='CIMPAR Logo' className={classes.logo} />
        </div>
        {sentLink ? (
          <div>
            <div className={classes.heading}>Email sent</div>
            <div className={classes.sentText}>
              We have sent an email to {userDetails.email} with a link to reset
              your password.
            </div>
            <div className={classes.resendLink}>
              Didn't receive link?
              <LinkButton
                label={"Resend link"}
                onClick={forgotPasswordHandler}
              />
            </div>
            <div className={classes.backToLogin}>
              <LinkButton label='Back to login' onClick={HandleBack} />
            </div>
          </div>
        ) : (
          <>
            <div className={classes.heading}>Forgot Password</div>
            <div>
              Enter your email to receive a link to reset your password.
            </div>
            <div className={classes.container}>
              <Input
                name='Email'
                value={userDetails.email || ""}
                onChange={(e) => {
                  setUserDetails((prevState) => {
                    return {
                      ...prevState,
                      email: e.target.value,
                    };
                  });
                  clearFieldErrors("email");
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    forgotPasswordHandler();
                  }
                }}
                isInvalid={fieldErrors.email}
                invalidText='Invalid email'
                className={classes.inputContainer}
                inputClassName={classes.input}
              />
              <div className={classes.actions}>
                <PrimaryButton
                  name='Request a reset link'
                  className={classes.loginBtn}
                  onClick={forgotPasswordHandler}
                />
                <PrimaryButton
                  name='Cancel'
                  className={classes.loginBtn}
                  onClick={HandleBack}
                  transparent
                  style={{ marginLeft: "1rem" }}
                />
              </div>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default ForgotPassword;

import { useEffect, useState } from "react";
import classes from "./InfoQuestion.module.css";
import InfoQuestion from "../../../ts/models/InfoQuestion";
import AddInfoQuestion from "./AddInfoQuestion";
import PanelActionContainer from "../../../components/Containers/PanelActionContainer";
import PanelContainer from "../../../components/Containers/PanelContainer";
import SearchBar from "../../../components/SearchBar/SearchBar";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import TableContainer from "../../../components/CustTable/TableContainer";
import Table from "../../../components/CustTable/Table";
import ActionContainer from "../../../components/CustTable/ActionContainer";
import LinkButton from "../../../components/Buttons/LinkButton";
import { Column } from "../../../ts/types";
import {
  fetchInfoQuestions,
  fetchInfoQuestionsCount,
  updateInfoQuestion,
} from "../../../services/masters";
import addIcon from "../../../assets/icons/add-circle-white.svg";
import { InfoQuestionType } from "../../../ts/enums";

const pageSize = 20;

const infoQuestionTypes = [
  {
    name: "Option List",
    type: InfoQuestionType.OptionList,
  },
  {
    name: "Free Text",
    type: InfoQuestionType.FreeText,
  },
  {
    name: "Number",
    type: InfoQuestionType.Number,
  },
  {
    name: "Date",
    type: InfoQuestionType.Date,
  },
];

const InfoQuestions = () => {
  const [show, setShow] = useState(false);
  const [isEditQues, setIsEditQues] = useState(false);
  const [toBeEdited, setToBeEdited] = useState<InfoQuestion>(
    new InfoQuestion()
  );
  const [searchText, setSearchText] = useState("");
  const [infoQuestionData, setInfoQuestionData] = useState<InfoQuestion[]>([]);
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [skipCount, setSkipCount] = useState(true);

  useEffect(() => {
    fetchInfoQuestionData();
  }, [currPageNo]);

  useEffect(() => {
    if (currPageNo === 1) {
      // Preventing first time auto api call because it is already called from currPageNo useEffect
      if (skipCount) {
        setSkipCount(false);
      } else {
        fetchInfoQuestionData();
      }
    } else {
      // We need to go to page no. 1 if search text change, as well as it will fetch data
      setCurrPageNo(1);
    }
  }, [searchText]);

  const fetchInfoQuestionData = async () => {
    const filter = {
      limit: pageSize,
      offset: (currPageNo - 1) * pageSize,
      where: {
        $or: [
          {
            code: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },

          {
            question: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
        ],
      },
    };
    try {
      const [infoQuestionRes, countRes] = await Promise.all([
        fetchInfoQuestions(filter),
        fetchInfoQuestionsCount(filter.where),
      ]);
      if (infoQuestionRes?.status === 200) {
        setInfoQuestionData(infoQuestionRes.data);
      }
      if (countRes?.status === 200) {
        const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
        setTotalPages(noOfPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const InfoQuestionTableColumn: Column<InfoQuestion>[] = [
    {
      header: "ID",
      accessor: (e, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "10%",
    },
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "15%",
    },
    {
      header: "QUESTION",
      accessor: (e) => e.question,
    },
    {
      header: "TYPE",
      accessor: (e) =>
        infoQuestionTypes.find(
          (questionType) => questionType.type === e.questionType
        )?.name || "",
    },
    {
      header: "OPTIONS",
      accessor: (e) => (
        <div>
          {e.questionType !== InfoQuestionType.OptionList && (
            <div className={classes.questionType}>Not Applicable</div>
          )}
          {e.options.map((item, idx) => (
            <div key={idx}>
              {`${idx + 1}. `}
              {item}
            </div>
          ))}
        </div>
      ),
      width: "20%",
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => {
              editHandler(e);
            }}
            label='Edit'
            className={classes.editAction}
          />
          <LinkButton
            onClick={() => updateStatusHandler(e.id, !e.isActive)}
            label={e.isActive ? "De-activate" : "Activate"}
          />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];

  const updateStatusHandler = (id: string, isActive: boolean) => {
    updateInfoQuestion({ id, isActive }).then((res) => {
      if (res.status === 200) {
        fetchInfoQuestionData();
      }
    });
  };

  const addShowHandle = () => {
    setShow(true);
    setToBeEdited(new InfoQuestion());
  };

  const editHandler = (data: InfoQuestion) => {
    setShow(true);
    setIsEditQues(true);
    setToBeEdited(data);
  };

  const closeHandler = (isUpdate: boolean) => {
    setShow(false);
    setIsEditQues(false);
    if (isUpdate) fetchInfoQuestionData();
  };

  return (
    <>
      {show && (
        <AddInfoQuestion
          onClose={closeHandler}
          isEdit={isEditQues}
          prevData={toBeEdited}
        />
      )}
      <PanelContainer name='Info Questions' backBtn>
        <PanelActionContainer
          leftChildren={
            <SearchBar
              debounce
              onChange={(text) => {
                setSearchText(text);
              }}
            />
          }
          rightChildren={
            <PrimaryButton
              name='Add New'
              onClick={addShowHandle}
              disabled={false}
              icon={<img src={addIcon} alt='' />}
            />
          }
        />
        <PanelInnerContainer>
          <TableContainer>
            <Table
              columns={InfoQuestionTableColumn}
              data={infoQuestionData}
              isPaginated
              pageNo={currPageNo}
              totalPages={totalPages}
              onPrevClick={() => setCurrPageNo((state) => state - 1)}
              onNextClick={() => setCurrPageNo((state) => state + 1)}
            />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default InfoQuestions;

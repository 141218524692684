export default class Medication {
  id!: string;
  code: string;
  cvxCode: string;
  ndcCode: string;
  name: string;
  manufacturer: string;
  mvxCode: string;
  dosage: string;
  guideline: string;
  isActive: boolean;

  constructor(
    code = "",
    cvxCode = "",
    ndcCode = "",
    name = "",
    manufacturer = "",
    mvxCode = "",
    dosage = "",
    guideline = "",
    isActive = true
  ) {
    this.code = code;
    this.cvxCode = cvxCode;
    this.ndcCode = cvxCode;
    this.name = name;
    this.manufacturer = manufacturer;
    this.mvxCode = mvxCode;
    this.dosage = dosage;
    this.guideline = guideline;
    this.isActive = isActive;
  }
}

export type MedicationStatus = {
  id: string;
  isActive: boolean;
};

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { addDays } from "date-fns";

import {
  getStartOfDayTimestamp,
  getEndOfDayTimestamp,
  getPatientFullName,
  getFormattedDateTime,
  downloadFile,
  getLocalDateString,
} from "../../../utils";
import {
  fetchAgencyReport,
  fetchAgencyReportCount,
  downloadAgencyReport,
} from "../../../services/orders";
import { AgencyReportData, PatientReportData } from "../../../ts/types";
import { AppDispatch, RootState } from "../../../store";
import { warningActions } from "../../../store/slices/WarningSlice";
import { Column } from "../../../ts/types";
import PatientReportDetails from "../ReportDetails/PatientReportDetails";
import Select from "../../../components/Inputs/Select";
import PanelContainer from "../../../components/Containers/PanelContainer";
import TableContainer from "../../../components/CustTable/TableContainer";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SearchBar from "../../../components/SearchBar/SearchBar";
import classes from "./AgencyReport.module.css";
import Table from "../../../components/CustTable/Table";
import Card from "../../../components/Card/Card";
import Input from "../../../components/Inputs/Input";
import Drawer from "../../../components/Drawer/Drawer";
import LinkButton from "../../../components/Buttons/LinkButton";
import Event from "../../../ts/models/Event";
import Checkbox from "../../../components/Checkbox/Checkbox";

const pageSize = 20;

const statuses = [
  { name: "All", id: "all" },
  { name: "Sent", id: "sent" },
  { name: "Not Sent", id: "not sent" },
];

const AgencyReport = () => {
  const dispatch: AppDispatch = useDispatch();
  const pods = useSelector((state: RootState) => state.pods.pods);

  const [searchText, setSearchText] = useState("");
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [skipCall, setSkipCall] = useState(true);
  const [status, setStatus] = useState(statuses[0].id);
  const [startDate, setStartDate] = useState(
    getStartOfDayTimestamp(getLocalDateString(Date.now()))
  );
  const [endDate, setEndDate] = useState(
    getEndOfDayTimestamp(getLocalDateString(addDays(new Date(), 6).getTime()))
  );
  const [data, setData] = useState<AgencyReportData[]>([]);
  const [showReport, setShowReport] = useState<
    | { show: false }
    | { show: true; patientData: PatientReportData; event: Event }
  >({
    show: false,
  });
  const [isSendToAgency, setIsSendToAgency] = useState(false);

  useEffect(() => {
    if (skipCall) {
      setSkipCall(false);
    } else {
      fetchReport();
    }
  }, [currPageNo]);

  const getReportFilter = () => {
    if (searchText && searchText.length < 3) {
      dispatch(
        warningActions.showWarning({
          message:
            "For search by patient name, please specify at least 3 letters of the name.",
        })
      );
      return;
    }

    const filter: any = {
      startDate,
      endDate,
      status,
    };

    if (searchText) {
      filter.patientName = searchText;
    } else {
      filter.offset = (currPageNo - 1) * pageSize;
      filter.count = pageSize;
    }

    return filter;
  };

  const fetchReport = async () => {
    const filter = getReportFilter();

    if (filter) {
      const apis = [fetchAgencyReport(filter)];

      if (!searchText) {
        apis.push(fetchAgencyReportCount(filter));
      }

      try {
        const [reportRes, countRes] = await Promise.all(apis);

        if (reportRes?.status === 200) {
          setData(reportRes.data);
        }

        // Fetching all data if patient name present, for that setting total page count to zero.
        if (countRes?.status === 200) {
          const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
          setTotalPages(noOfPages);
        } else {
          setTotalPages(0);
          setCurrPageNo(1);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getAgencyReportFileName = () => {
    let fileName = `HL7_${getLocalDateString(startDate)}_${getLocalDateString(
      endDate
    )}.txt`;
    return fileName.replaceAll("-", "_");
  };

  const downloadReportHandler = async () => {
    const filter = getReportFilter();
    delete filter.offset;
    delete filter.count;

    if (filter) {
      try {
        const res = await downloadAgencyReport(filter, isSendToAgency);
        downloadFile(res, getAgencyReportFileName());
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getPodName = (podId: string) => {
    return pods.find((pod) => pod.id === podId)?.name || "";
  };

  const viewDetailsHandler = (report: AgencyReportData) => {
    const event = report.event;
    const patient = report.patient;
    const order = report.order;
    const encounter: any = { ...report };

    // We are getting single encounterDetails object in report
    encounter.encounterDetails = [report.encounterDetails];
    delete encounter.event;
    delete encounter.patient;
    delete encounter.order;

    setShowReport({
      show: true,
      patientData: { patient, order, encounter },
      event,
    });
  };

  const reportTableColumn: Column<AgencyReportData>[] = [
    {
      header: "ID",
      accessor: (_, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "8%",
    },
    {
      header: "PATIENT NAME",
      accessor: ({ patient }) => getPatientFullName(patient),
    },
    {
      header: "POD NAME",
      accessor: ({ order }) => getPodName(order.podId),
    },
    {
      header: "ADMINISTRATION DATE",
      accessor: ({ encounterDetails }: any) => {
        const updateTime =
          encounterDetails?.administration?.medicationAdministered?.length > 0
            ? encounterDetails?.administration.medicationAdministered[0]
                .administeredTime
            : encounterDetails?.administration.updateTime;
        return updateTime ? getFormattedDateTime(updateTime) : "";
      },
    },
    {
      header: "EVENT LOCATION",
      accessor: ({ event }) => `${event.address}, ${event.city}`,
      style: { textAlign: "center" },
    },
    {
      header: "STATUS",
      accessor: ({ isReported }) => (isReported ? "Sent" : "Not Sent"),
      style: { textAlign: "center" },
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <div>
          <LinkButton
            onClick={() => viewDetailsHandler(e)}
            label='View Details'
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {showReport.show && (
        <Drawer
          name='Patient Report'
          onClose={() => setShowReport({ show: false })}>
          <PatientReportDetails
            event={showReport.event}
            patientReportData={showReport.patientData}
          />
        </Drawer>
      )}
      <PanelContainer name='HL7 Report'>
        <Card>
          <div className={classes.filterContainer}>
            <div className={classes.filters}>
              <Input
                type='date'
                name='Start Date'
                className={classes.filterDates}
                value={getLocalDateString(startDate || Date.now())}
                onChange={(e) => {
                  const startDate = getStartOfDayTimestamp(e.target.value);
                  setStartDate(startDate);
                  if (startDate > endDate)
                    setEndDate(getEndOfDayTimestamp(e.target.value));
                }}
              />
              <Input
                type='date'
                name='End Date'
                className={classes.filterDates}
                value={getLocalDateString(endDate || Date.now())}
                onChange={(e) =>
                  setEndDate(getEndOfDayTimestamp(e.target.value))
                }
                min={getLocalDateString(startDate)}
              />
              <Select
                label='Select Status'
                htmlFor='status'
                options={statuses}
                nameExtractor='name'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className={classes.select}
              />
              <div className={classes.searchBar}>
                <SearchBar
                  placeholder='Search by patient name'
                  onChange={(text) => {
                    setSearchText(text);
                  }}
                  greyBackground
                />
              </div>
            </div>
            <div className={classes.actionBtns}>
              <PrimaryButton name='Filter' onClick={fetchReport} />
              <div className={classes.actionsContainer}>
                <PrimaryButton
                  name='Download'
                  onClick={downloadReportHandler}
                />
                <Checkbox
                  checked={isSendToAgency}
                  label='Mark as sent'
                  id={`check-box-all`}
                  onChange={(checked) => setIsSendToAgency(checked)}
                  className={classes.sendForAgencyReportCheckbox}
                />
              </div>
            </div>
          </div>
        </Card>
        <div className={classes.main}>
          <TableContainer>
            <Table
              columns={reportTableColumn}
              data={data}
              isPaginated
              pageNo={currPageNo}
              totalPages={totalPages}
              onPrevClick={() => setCurrPageNo((state) => state - 1)}
              onNextClick={() => setCurrPageNo((state) => state + 1)}
            />
          </TableContainer>
        </div>
      </PanelContainer>
    </>
  );
};

export default AgencyReport;

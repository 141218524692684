import axios from "../axios";
import Acknowledgement from "../ts/models/Acknowledgement";
import CommonMaster, { CommonMasterStatus } from "../ts/models/CommonMaster";
import Incentive, { IncentiveStatus } from "../ts/models/Incentive";
import InfoQuestion, { InfoQuestionStatus } from "../ts/models/InfoQuestion";
import Medication, { MedicationStatus } from "../ts/models/Medication";
import { ProductStatus } from "../ts/models/Products";
import ScreeningQuestion, {
  ScreeningQuestionStatus,
} from "../ts/models/ScreeningQuestion";
import Service, { ServiceStatus } from "../ts/models/Service";
import { IncentiveSerialNumberStatus } from "../ts/types";
import { encodeQueryFilter } from "./utils";

const BASE = "/api/masters";
const ACKNOWLEDGEMENTS_MASTER_URL = "/acknowledgements";
const DISCLAIMERS_MASTER_URL = "/disclaimers";
const DISPOSITIONS_MASTER_URL = "/dispositions";
const ENVIRONMENTAL_ALLERGIES_MASTER_URL = "/environmental-allergies";
const ETHNIC_GROUPS_MASTER_URL = "/ethnic-groups";
const GENDERS_MASTER_URL = "/genders";
const INCENTIVES_MASTER_URL = "/incentives";
const INCENTIVE_SERIAL_NUMBER_URL = "/incentiveSerialNumbers";
const UPLOAD_INCENTIVE_SERIAL_NUMBER_URL =
  INCENTIVE_SERIAL_NUMBER_URL + "/uploadcsv";
const MEDICATION_ALLERGIES_MASTER_URL = "/medical-allergies";
const MEDICATIONS_MASTER_URL = "/medications";
const PROCEDURES_MASTER_URL = "/procedures";
const PRODUCTS_MASTER_URL = "/products";
const RACES_MASTER_URL = "/races";
const RELATIONSHIPS_MASTER_URL = "/relationships";
const ROUTES_MASTER_URL = "/routes";
const SCREENING_QUESTIONS_MASTER_URL = "/screening-questions";
const SERVICES_MASTER_URL = "/services";
const SITES_MASTER_URL = "/sites";
const SYMPTOMS_MASTER_URL = "/symptoms";
const VFC_ELIGIBILITIES_MASTER_URL = "/vfc-eligibilities";
const UPLOAD_MEDICATIONS_URL = MEDICATIONS_MASTER_URL + "/uploadcsv";
const UPLOAD_ENVIRONMENTAL_ALLERGIES_URL =
  ENVIRONMENTAL_ALLERGIES_MASTER_URL + "/uploadcsv";
const UPLOAD_MEDICATIONS_ALLERGIES_URL =
  MEDICATION_ALLERGIES_MASTER_URL + "/uploadcsv";
const INFO_QUESTIONS_MASTER_URL = "/info-questions";
const UPLOAD_HELP_DOCUMENTS = "/api/help/upload";

export function fetchAllMaster() {
  return axios({
    url: BASE,
    method: "get",
  });
}

export function fetchMasters(url: string, filter?: any) {
  let completeUrl = `${BASE}${url}`;
  if (filter) {
    completeUrl += `?filter=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url: completeUrl,
    method: "get",
  });
}

export function fetchMastersCount(url: string, filter?: string) {
  let completeUrl = `${BASE}${url}/count`;
  if (filter) {
    completeUrl += `?where=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url: completeUrl,
    method: "get",
  });
}

function addMaster(
  url: string,
  data:
    | CommonMaster
    | ScreeningQuestion
    | Service
    | Incentive
    | Medication
    | InfoQuestion
) {
  return axios({
    url: BASE + url,
    method: "post",
    data,
  });
}

function updateMaster(
  url: string,
  data:
    | CommonMaster
    | ScreeningQuestion
    | Acknowledgement
    | Medication
    | MedicationStatus
    | CommonMasterStatus
    | ServiceStatus
    | ProductStatus
    | InfoQuestion
    | InfoQuestionStatus
    | IncentiveSerialNumberStatus
) {
  return axios({
    url: BASE + url + `/${data.id}`,
    method: "put",
    data,
  });
}

export function uploadMedicationsData(file: any) {
  const formData = new FormData();
  formData.append("file", file);
  return axios({
    url: BASE + UPLOAD_MEDICATIONS_URL,
    method: "post",
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function uploadIncentiveSerialNumbersData(
  file: any,
  incentiveId: string
) {
  const formData = new FormData();
  formData.append("file", file);
  return axios({
    url: `${BASE}${UPLOAD_INCENTIVE_SERIAL_NUMBER_URL}?incentiveId=${incentiveId}`,
    method: "post",
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function uploadEnvironmentalAllergiesData(file: any) {
  const formData = new FormData();
  formData.append("file", file);
  return axios({
    url: BASE + UPLOAD_ENVIRONMENTAL_ALLERGIES_URL,
    method: "post",
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function uploadMedicationAllergiesData(file: any) {
  const formData = new FormData();
  formData.append("file", file);
  return axios({
    url: BASE + UPLOAD_MEDICATIONS_ALLERGIES_URL,
    method: "post",
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function uploadHelpDocuments(data: FormData) {
  return axios({
    url: UPLOAD_HELP_DOCUMENTS,
    method: "post",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

// Acknowledgements master
export function updateAcknowledgement(data: Acknowledgement) {
  return updateMaster(ACKNOWLEDGEMENTS_MASTER_URL, data);
}

// Disclaimers master
export function addDisclaimer(data: CommonMaster) {
  return addMaster(DISCLAIMERS_MASTER_URL, data);
}

export function updateDisclaimer(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(DISCLAIMERS_MASTER_URL, data);
}

// Dispositions master
export function addDisposition(data: CommonMaster) {
  return addMaster(DISPOSITIONS_MASTER_URL, data);
}

export function updateDisposition(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(DISPOSITIONS_MASTER_URL, data);
}

// Environmental Allergies master
export function fetchEnvironmentalAllergies(filter: any) {
  return fetchMasters(ENVIRONMENTAL_ALLERGIES_MASTER_URL, filter);
}

export function fetchEnvironmentalAllergiesCount(filter?: any) {
  return fetchMastersCount(ENVIRONMENTAL_ALLERGIES_MASTER_URL, filter);
}

export function addEnvironmentalAllergy(data: CommonMaster) {
  return addMaster(ENVIRONMENTAL_ALLERGIES_MASTER_URL, data);
}

export function updateEnvironmentalAllergy(
  data: CommonMaster | CommonMasterStatus
) {
  return updateMaster(ENVIRONMENTAL_ALLERGIES_MASTER_URL, data);
}

// Ethnic Groups master
export function addEthnicGroup(data: CommonMaster) {
  return addMaster(ETHNIC_GROUPS_MASTER_URL, data);
}

export function updateEthnicGroup(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(ETHNIC_GROUPS_MASTER_URL, data);
}

// Genders master
export function addGender(data: CommonMaster) {
  return addMaster(GENDERS_MASTER_URL, data);
}

export function updateGender(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(GENDERS_MASTER_URL, data);
}

// Incentives master
export function addIncentive(data: Incentive) {
  return addMaster(INCENTIVES_MASTER_URL, data);
}

export function updateIncentive(data: Incentive | IncentiveStatus) {
  return updateMaster(INCENTIVES_MASTER_URL, data);
}

export function fetchIncentiveSerialNumbers(filter: any) {
  return fetchMasters(INCENTIVE_SERIAL_NUMBER_URL, filter);
}

export function fetchIncentiveSerialNumbersCount(filter?: any) {
  return fetchMastersCount(INCENTIVE_SERIAL_NUMBER_URL, filter);
}

export function updateIncentiveSerialNumber(data: IncentiveSerialNumberStatus) {
  return updateMaster(INCENTIVE_SERIAL_NUMBER_URL, data);
}

// Info Questions master
export function fetchInfoQuestions(filter?: any) {
  return fetchMasters(INFO_QUESTIONS_MASTER_URL, filter);
}

export function fetchInfoQuestionsCount(filter?: any) {
  return fetchMastersCount(INFO_QUESTIONS_MASTER_URL, filter);
}

export function addInfoQuestion(data: InfoQuestion) {
  return addMaster(INFO_QUESTIONS_MASTER_URL, data);
}

export function updateInfoQuestion(data: InfoQuestion | InfoQuestionStatus) {
  return updateMaster(INFO_QUESTIONS_MASTER_URL, data);
}

// Medical Allergies master
export function fetchMedicationAllergies(filter: any) {
  return fetchMasters(MEDICATION_ALLERGIES_MASTER_URL, filter);
}

export function fetchMedicationAllergiesCount(filter?: any) {
  return fetchMastersCount(MEDICATION_ALLERGIES_MASTER_URL, filter);
}

export function addMedicationAllergies(data: CommonMaster) {
  return addMaster(MEDICATION_ALLERGIES_MASTER_URL, data);
}

export function updateMedicationAllergies(
  data: CommonMaster | CommonMasterStatus
) {
  return updateMaster(MEDICATION_ALLERGIES_MASTER_URL, data);
}

// Medications master
export function fetchMedications(filter: any) {
  return fetchMasters(MEDICATIONS_MASTER_URL, filter);
}

export function fetchMedicationsCount(filter?: any) {
  return fetchMastersCount(MEDICATIONS_MASTER_URL, filter);
}

export function addMedication(data: Medication) {
  return addMaster(MEDICATIONS_MASTER_URL, data);
}

export function updateMedication(data: Medication | MedicationStatus) {
  return updateMaster(MEDICATIONS_MASTER_URL, data);
}

// Procedures master
export function addProcedure(data: CommonMaster) {
  return addMaster(PROCEDURES_MASTER_URL, data);
}

export function updateProcedure(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(PROCEDURES_MASTER_URL, data);
}

// Products master
export function fetchProducts(filter: any) {
  return fetchMasters(PRODUCTS_MASTER_URL, filter);
}

export function fetchProductsCount(filter?: any) {
  return fetchMastersCount(PRODUCTS_MASTER_URL, filter);
}

export function addProduct(data: CommonMaster) {
  return addMaster(PRODUCTS_MASTER_URL, data);
}

export function updateProduct(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(PRODUCTS_MASTER_URL, data);
}

// Races master
export function addRace(data: CommonMaster) {
  return addMaster(RACES_MASTER_URL, data);
}

export function updateRace(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(RACES_MASTER_URL, data);
}

// Relationships master
export function addRelationship(data: CommonMaster) {
  return addMaster(RELATIONSHIPS_MASTER_URL, data);
}

export function updateRelationship(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(RELATIONSHIPS_MASTER_URL, data);
}

// Routes master
export function addRoute(data: CommonMaster) {
  return addMaster(ROUTES_MASTER_URL, data);
}

export function updateRoute(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(ROUTES_MASTER_URL, data);
}

// Screening Questions master
export function fetchScreeningQuestions(filter?: any) {
  return fetchMasters(SCREENING_QUESTIONS_MASTER_URL, filter);
}

export function fetchScreeningQuestionsCount(filter?: any) {
  return fetchMastersCount(SCREENING_QUESTIONS_MASTER_URL, filter);
}

export function addScreeningQuestion(data: ScreeningQuestion) {
  return addMaster(SCREENING_QUESTIONS_MASTER_URL, data);
}

export function updateScreeningQuestion(
  data: ScreeningQuestion | ScreeningQuestionStatus
) {
  return updateMaster(SCREENING_QUESTIONS_MASTER_URL, data);
}

// Services master
export function fetchServices(filter?: any) {
  return fetchMasters(SERVICES_MASTER_URL, filter);
}

export function fetchServicesCount(filter?: any) {
  return fetchMastersCount(SERVICES_MASTER_URL, filter);
}

export function addService(data: Service) {
  return addMaster(SERVICES_MASTER_URL, data);
}

export function updateService(data: Service | ServiceStatus) {
  return updateMaster(SERVICES_MASTER_URL, data);
}

// Sites master
export function addSite(data: CommonMaster) {
  return addMaster(SITES_MASTER_URL, data);
}

export function updateSite(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(SITES_MASTER_URL, data);
}

// Symptoms master
export function fetchSymptoms(filter: any) {
  return fetchMasters(SYMPTOMS_MASTER_URL, filter);
}

export function fetchSymptomsCount(filter?: any) {
  return fetchMastersCount(SYMPTOMS_MASTER_URL, filter);
}

export function addSymptom(data: CommonMaster) {
  return addMaster(SYMPTOMS_MASTER_URL, data);
}

export function updateSymptom(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(SYMPTOMS_MASTER_URL, data);
}

// Vfc Eligibilities master
export function addVfcEligibility(data: CommonMaster) {
  return addMaster(VFC_ELIGIBILITIES_MASTER_URL, data);
}

export function updateVfcEligibility(data: CommonMaster | CommonMasterStatus) {
  return updateMaster(VFC_ELIGIBILITIES_MASTER_URL, data);
}
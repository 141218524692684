import { Outlet, useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";

import {
  BASE_URL,
  EVENT_REPORT_URL,
  PATIENT_REPORT_URL,
  REPORT_URL,
} from "../../Routes/urls";
import { RootState } from "../../store";
import PanelContainer from "../../components/Containers/PanelContainer";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import TabBar from "../../components/TabBar/TabBar";
import classes from "./Report.module.css";

type Props = {};

const Report: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  let pathname = location.pathname;

  const tabChangeHandler = (url: string) => {
    navigate(url);
  };

  return (
    <>
      <div className={classes.leftContainer}>
        <PanelContainer name='Report'>
          <TabBar
            tabs={[
              {
                name: "Event Report",
                url: `${BASE_URL}/${resourceName}/${REPORT_URL}/${EVENT_REPORT_URL}`,
              },
              {
                name: "Patient Report",
                url: `${BASE_URL}/${resourceName}/${REPORT_URL}/${PATIENT_REPORT_URL}`,
              },
            ]}
            idExtractor='url'
            onTabChange={tabChangeHandler}
            // Setting default state as pathname but if the url is upto REPORT_URL the we are setting default state upto EVENT_REPORTS url
            defaultState={
              pathname === `${BASE_URL}/${resourceName}/${REPORT_URL}`
                ? `${BASE_URL}/${resourceName}/${REPORT_URL}/${EVENT_REPORT_URL}`
                : pathname
            }
          />
          <PanelInnerContainer className={classes.innerContainer}>
            <Outlet />
          </PanelInnerContainer>
        </PanelContainer>
      </div>
    </>
  );
};

export default Report;

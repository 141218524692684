import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../../store";
import { getClasses } from "../../../utils";
import { Master } from "../../../ts/enums";
import {
  addEnvironmentalAllergy,
  addMedicationAllergies,
  addSymptom,
  updateEnvironmentalAllergy,
  updateMedicationAllergies,
  updateSymptom,
} from "../../../services/masters";
import {
  addNewDisclaimer,
  addNewDisposition,
  addNewEthnicGroup,
  addNewGender,
  addNewProcedure,
  addNewRace,
  addNewRelationship,
  addNewRoute,
  addNewSite,
  addNewVfcEligibility,
  updateDisclaimerData,
  updateDispositionData,
  updateEthnicGroupData,
  updateGenderData,
  updateProcedureData,
  updateRaceData,
  updateRelationshipData,
  updateRouteData,
  updateSiteData,
  updateVfcEligibilityData,
} from "../../../store/actions/MasterActions";
import Modal, { ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import classes from "./AddCommonMaster.module.css";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

import CommonMaster from "../../../ts/models/CommonMaster";
import Input from "../../../components/Inputs/Input";

type props = {
  currentMaster: string;
  entityName: string;
  onClose: (isUpdate: boolean) => void;
  prevData?: CommonMaster;
  isEdit: Boolean;
  isTextarea?: boolean;
};

const AddCommonMasters: React.FC<props> = ({
  currentMaster,
  entityName,
  onClose,
  prevData,
  isEdit,
  isTextarea,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const [data, setData] = useState<CommonMaster>(
    prevData ? prevData : new CommonMaster()
  );

  const addMasterHandler = async () => {
    const newMaster = new CommonMaster(data.code, data.description);

    if (prevData?.id) {
      newMaster.id = prevData?.id;
    }

    let addUpdateMaster;
    switch (currentMaster) {
      case Master.Disclaimers:
        if (isEdit) {
          dispatch(updateDisclaimerData(newMaster));
        } else {
          dispatch(addNewDisclaimer(newMaster));
        }
        break;
      case Master.Dispositions:
        if (isEdit) {
          dispatch(updateDispositionData(newMaster));
        } else {
          dispatch(addNewDisposition(newMaster));
        }
        break;
      case Master.EnvironmentalAllergies:
        if (isEdit) {
          addUpdateMaster = updateEnvironmentalAllergy;
        } else {
          addUpdateMaster = addEnvironmentalAllergy;
        }
        break;
      case Master.EthnicGroups:
        if (isEdit) {
          dispatch(updateEthnicGroupData(newMaster));
        } else {
          dispatch(addNewEthnicGroup(newMaster));
        }
        break;
      case Master.Genders:
        if (isEdit) {
          dispatch(updateGenderData(newMaster));
        } else {
          dispatch(addNewGender(newMaster));
        }
        break;
      case Master.MedicationAllergies:
        if (isEdit) {
          addUpdateMaster = updateMedicationAllergies;
        } else {
          addUpdateMaster = addMedicationAllergies;
        }
        break;
      case Master.Procedures:
        if (isEdit) {
          dispatch(updateProcedureData(newMaster));
        } else {
          dispatch(addNewProcedure(newMaster));
        }
        break;
      case Master.Races:
        if (isEdit) {
          dispatch(updateRaceData(newMaster));
        } else {
          dispatch(addNewRace(newMaster));
        }
        break;
      case Master.Relationships:
        if (isEdit) {
          dispatch(updateRelationshipData(newMaster));
        } else {
          dispatch(addNewRelationship(newMaster));
        }
        break;
      case Master.Routes:
        if (isEdit) {
          dispatch(updateRouteData(newMaster));
        } else {
          dispatch(addNewRoute(newMaster));
        }
        break;
      case Master.Sites:
        if (isEdit) {
          dispatch(updateSiteData(newMaster));
        } else {
          dispatch(addNewSite(newMaster));
        }
        break;
      case Master.Symptoms:
        if (isEdit) {
          addUpdateMaster = updateSymptom;
        } else {
          addUpdateMaster = addSymptom;
        }
        break;
      case Master.VfcEligibilities:
        if (isEdit) {
          dispatch(updateVfcEligibilityData(newMaster));
        } else {
          dispatch(addNewVfcEligibility(newMaster));
        }
        break;
      default:
    }
    if (addUpdateMaster) {
      try {
        const res = await addUpdateMaster(newMaster);
        if (res.status === 200) {
          onClose(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      onClose(true);
    }
  };

  const isRequiredDataMissing = () => {
    if (!data.code?.trim() || !data.description?.trim()) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={<>{isEdit ? `Update ${entityName}` : `Add ${entityName}`}</>}
      onClose={() => onClose(false)}>
      <>
        <ModalBody>
          <div className={classes.main}>
            <Input
              name='Code'
              value={data.code}
              onChange={(e) =>
                setData((state) => ({ ...state, code: e.target.value }))
              }
            />
            <Input
              name='Description'
              value={data.description}
              onChange={(e) =>
                setData((state) => ({
                  ...state,
                  description: e.target.value,
                }))
              }
              textarea={isTextarea}
              inputClassName={getClasses(isTextarea ? classes.textarea : "")}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton
              className={classes.actionBtn}
              name={isEdit ? "Update" : "Add"}
              onClick={addMasterHandler}
              style={{ marginRight: "1rem" }}
              disabled={isRequiredDataMissing()}
            />
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={() => onClose(false)}
              transparent
            />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default AddCommonMasters;

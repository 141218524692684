import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../store";
import {
  addEvent,
  deleteEvent,
  getEvents,
  updateEventStatusData,
} from "../../store/actions/EventActions";
import {
  getEndOfDayTimestamp,
  getFormattedDate,
  getFormattedTime,
  getLocalDateString,
  getStartOfDayTimestamp,
} from "../../utils";
import {
  BASE_URL,
  CREATE_EVENT_URL,
  EVENTS_URL,
  EVENT_DETAILS_URL,
  UPDATE_EVENT_URL,
} from "../../Routes/urls";
import { fetchEventWiseOrderCount } from "../../services/orders";
import { EventOrderCount } from "../../ts/models/Order";
import { Column, RegistrationCount } from "../../ts/types";
import { eventsActions } from "../../store/slices/EventSlice";
import { eventTypes } from "../../ts/consts";
import { fetchEventRegistrationCount } from "../../services/events";
import PanelActionContainer from "../../components/Containers/PanelActionContainer";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import classes from "./Events.module.css";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import EventCard from "./EventCard";
import PanelContainer from "../../components/Containers/PanelContainer";
import SearchBar from "../../components/SearchBar/SearchBar";
import Input from "../../components/Inputs/Input";
import Select from "../../components/Inputs/Select";
import SelectPOD from "../../components/SelectPOD/SelectPOD";
import TableContainer from "../../components/CustTable/TableContainer";
import Table from "../../components/CustTable/Table";
import Event from "../../ts/models/Event";
import Status from "../../components/Status/Status";
import Card from "../../components/Card/Card";
import KebabMenu from "../../components/KebabMenu/KebabMenu";
import addIcon from "../../assets/icons/add-circle-white.svg";
import gridIcon from "../../assets/icons/grid.svg";
import listIcon from "../../assets/icons/list-dots.svg";
import activeGridIcon from "../../assets/icons/grid-active.svg";
import activeListIcon from "../../assets/icons/list-dots-active.svg";
import MultiStatusView from "../../components/Status/MultiStatusView";
import DeleteConfirm from "../../components/Confirm/DeleteConfirm";
import { UserRole } from "../../ts/enums";

const pageSize = 20;

export const Events = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  // Storing events and filter to redux to prevent filter's and event's data loss while returning from create new event page
  const {
    events,
    filter: { podId, searchText, startDate, endDate, isHome },
    currPageNo,
    count,
  } = useSelector((state: RootState) => state.events);
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );
  const serviceMaster = useSelector(
    (state: RootState) => state.masters.services
  );
  const userData = useSelector((state: RootState) => state.users.currUserData);
  const [orderCounts, setOrderCounts] = useState(new Map<string, number>());
  const [gridView, setGridView] = useState(
    localStorage.getItem("isGridView") === "true"
  );
  const [registrationCountData, setRegistrationCountData] = useState<
    RegistrationCount[]
  >([]);
  const [state, setState] = useState(location.state || {});
  const [showConfirm, setShowConfirm] = useState({
    show: false,
    name: "",
    eventId: "",
    orderCount: 0,
  });

  const totalPages = Math.ceil(Number(count) / pageSize);

  const getEventsHandler = async (updatedPageNo: number) => {
    if (podId) {
      const filter: any = {
        limit: pageSize,
        offset: (updatedPageNo - 1) * pageSize,
        where: {
          podId,
          name: searchText,
          startDate: startDate,
          endDate: endDate,
        },
      };
      if (isHome) {
        filter.where.isHome = true;
      }
      dispatch(getEvents(filter, updatedPageNo));
    }
  };

  const createEventHandler = () => {
    dispatch(eventsActions.newEventToBeEdited());
    navigate(
      BASE_URL + `/${resourceName}` + `/${EVENTS_URL}/` + CREATE_EVENT_URL
    );
  };

  useEffect(() => {
    if (state.addOrUpdateCanceled) {
      setState({});
    } else {
      getEventsHandler(1);
    }
  }, [podId]);

  useEffect(() => {
    if (events.length !== 0) {
      const eventIds = events.map((event) => event.id);
      fetchEventWiseOrderCount(eventIds)
        .then((res) => {
          if (res.status === 200) {
            var counts = new Map<string, number>();
            res.data.forEach((event: EventOrderCount) => {
              counts.set(event.eventId, event.orderCount);
            });
            setOrderCounts(counts);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOrderCounts(new Map<string, number>());
    }
  }, [events]);

  const filterChangeHandler = (filter: any) => {
    dispatch(eventsActions.setFilter(filter));
  };

  const getOptions = (event: Event, orderCount: number) => {
    const options = [
      { name: "View Details", onClick: () => eventDetailsHandler(event) },
      { name: "Edit", onClick: () => editEventHandler(event) },
      { name: "Copy", onClick: () => copyEventHandler(event) },
      {
        name: event.isActive ? "De-activate" : "Activate",
        onClick: () => updateStatusHandler(event.id, !event.isActive),
      },
    ];

    if (
      userData.role === UserRole.Admin ||
      userData.role === UserRole.SuperAdmin
    ) {
      options.push({
        name: "Delete",
        onClick: () => deleteEventHandler(event, orderCount),
      });
    }
    return options;
  };

  const eventsTableColumn: Column<Event>[] = [
    {
      header: "ID",
      accessor: (e, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "10%",
    },
    {
      header: "NAME",
      accessor: (e) => e.name,
    },
    {
      header: "STATUS",
      accessor: (e) => {
        const isUpcoming = e.startTime > Date.now();
        const isLive = e.startTime < Date.now() && e.endTime > Date.now();
        return (
          <Status
            status={isUpcoming ? "upcoming" : isLive ? "active" : "closed"}
            name={isUpcoming ? "Upcoming" : isLive ? "Live" : "Closed"}
          />
        );
      },
    },
    {
      header: "START DATE - END DATE",
      accessor: (e) => {
        return (
          <div>
            <span style={{ display: "block" }}>{`${getFormattedDate(
              e.startTime
            )} - ${getFormattedDate(e.endTime)}`}</span>
            <span>{`${getFormattedTime(e.startTime)} - ${getFormattedTime(
              e.endTime
            )}`}</span>
          </div>
        );
      },
    },
    {
      header: "ORDERS",
      accessor: (e) => orderCounts.get(e.id) ?? 0,
    },
    {
      header: "MEDICAL SERVICES",
      accessor: (e) => {
        const serviceNames = e.services.map((serviceWithId) => {
          const service = serviceMaster.find(
            (service) => service.id === serviceWithId.serviceId
          );

          if (service) {
            return service.name;
          } else {
            return "";
          }
        });
        return <MultiStatusView names={serviceNames} maxCount={2} />;
      },
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
    },
    {
      header: "",
      accessor: (e) => (
        <KebabMenu options={getOptions(e, orderCounts.get(e.id) ?? 0)} />
      ),
    },
  ];

  const updateStatusHandler = (id: string, isActive: boolean) => {
    dispatch(updateEventStatusData({ id, isActive }));
  };

  const copyEventHandler = (event: Event) => {
    const newEvent: any = { ...event, name: `Copy of ${event.name}` };
    delete newEvent.id;
    dispatch(addEvent(newEvent));
  };

  const viewChangeHandler = (isGridView: boolean) => {
    localStorage.setItem("isGridView", `${isGridView}`);
    setGridView(isGridView);
  };

  const editEventHandler = (event: Event) => {
    dispatch(eventsActions.setEventToBeEdited(event));
    navigate(
      BASE_URL + `/${resourceName}` + `/${EVENTS_URL}/` + UPDATE_EVENT_URL
    );
  };

  const eventDetailsHandler = (event: Event) => {
    dispatch(eventsActions.setCurrEventDetails(event));
    navigate(
      BASE_URL + `/${resourceName}` + `/${EVENTS_URL}/` + EVENT_DETAILS_URL
    );
  };

  const confirmHandler = () => {
    dispatch(deleteEvent(showConfirm.eventId));
    setShowConfirm({ show: false, name: "", eventId: "", orderCount: 0 });
  };

  const deleteEventHandler = (event: Event, orderCount: number) => {
    setShowConfirm({
      show: true,
      name: event.name,
      eventId: event.id,
      orderCount: orderCount,
    });
  };

  const fetchEventRegistrationCountData = async (eventIds: string[]) => {
    try {
      const registrationCountRes = await fetchEventRegistrationCount(eventIds);
      if (registrationCountRes.status) {
        setRegistrationCountData(registrationCountRes.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (events.length) {
      const eventIds = events.map((event) => event.id);
      fetchEventRegistrationCountData(eventIds);
    }
  }, [events]);

  return (
    <>
      {showConfirm.show && (
        <DeleteConfirm
          message={
            <>
              <div>{"Are you sure you want to delete this event?"}</div>
              <div className={classes.ordersWarning}>
                {showConfirm.orderCount > 0
                  ? "All associated orders for this event will be deleted."
                  : ""}
              </div>
            </>
          }
          name={showConfirm.name}
          collectionName='Event'
          onClose={() =>
            setShowConfirm({
              show: false,
              name: "",
              eventId: "",
              orderCount: 0,
            })
          }
          onConfirmation={confirmHandler}
        />
      )}
      <PanelContainer name='Events'>
        <PanelActionContainer
          leftChildren={
            <SelectPOD
              value={podId || ""}
              onChange={(e) => filterChangeHandler({ podId: e.target.value })}
            />
          }
          rightChildren={
            <PrimaryButton
              name='Create New Event'
              onClick={createEventHandler}
              className={classes.actionBtn}
              icon={<img src={addIcon} alt='' />}
            />
          }
        />
        <PanelInnerContainer>
          <Card>
            <div className={classes.filterContainer}>
              <div className={classes.filters}>
                <Input
                  type='date'
                  name='Start Date'
                  className={classes.filterDates}
                  value={getLocalDateString(startDate)}
                  onChange={(e) => {
                    const filter: any = {
                      startDate: getStartOfDayTimestamp(e.target.value),
                    };
                    if (filter.startDate > endDate) {
                      filter.endDate = getEndOfDayTimestamp(e.target.value);
                    }
                    filterChangeHandler(filter);
                  }}
                />
                <Input
                  type='date'
                  name='End Date'
                  className={classes.filterDates}
                  value={getLocalDateString(endDate)}
                  onChange={(e) =>
                    filterChangeHandler({
                      endDate: getEndOfDayTimestamp(e.target.value),
                    })
                  }
                  min={getLocalDateString(startDate)}
                />
                <div className={classes.eventTypeDropDown}>
                  <Select
                    label='Type of event'
                    htmlFor='Type of event'
                    options={eventTypes}
                    value={`${isHome}`}
                    nameExtractor='name'
                    onChange={(e) =>
                      filterChangeHandler({
                        isHome: e.target.value === "true" ? true : false,
                      })
                    }
                    defaultOption='Type of event'
                  />
                </div>
                <div className={classes.searchContainer}>
                  <SearchBar
                    placeholder='Search by event name'
                    onChange={(searchText) =>
                      filterChangeHandler({ searchText })
                    }
                    greyBackground
                  />
                </div>
                <PrimaryButton
                  name='Filter'
                  onClick={() => getEventsHandler(1)}
                  className={classes.filterBtn}
                />
              </div>
              <div className={classes.switchBtnContainer}>
                <img
                  src={gridView ? activeGridIcon : gridIcon}
                  onClick={() => viewChangeHandler(true)}
                  alt='Show in grid view'
                  className={classes.switchBtn}
                />
                <img
                  src={gridView ? listIcon : activeListIcon}
                  onClick={() => viewChangeHandler(false)}
                  alt='Show in list view'
                  className={classes.switchBtn}
                />
              </div>
            </div>
          </Card>
          {gridView ? (
            <div className={classes.cardContainer}>
              {events.map((event, idx) => (
                <EventCard
                  event={event}
                  registrationCountData={registrationCountData}
                  orderCount={orderCounts.get(event.id) ?? 0}
                  key={event.id}
                  onEdit={editEventHandler}
                  onStatusUpdate={updateStatusHandler}
                  onView={eventDetailsHandler}
                  onCopy={copyEventHandler}
                  onDelete={deleteEventHandler}
                />
              ))}
            </div>
          ) : (
            <TableContainer>
              <Table
                columns={eventsTableColumn}
                data={events}
                isPaginated
                pageNo={currPageNo}
                totalPages={totalPages}
                onPrevClick={() => getEventsHandler(currPageNo - 1)}
                onNextClick={() => getEventsHandler(currPageNo + 1)}
              />
            </TableContainer>
          )}
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};
export default Events;

import {
  addDisclaimer,
  addDisposition,
  addEthnicGroup,
  addGender,
  addIncentive,
  addProcedure,
  addRace,
  addRelationship,
  addRoute,
  addService,
  addSite,
  addVfcEligibility,
  fetchAllMaster,
  updateAcknowledgement,
  updateDisclaimer,
  updateDisposition,
  updateEthnicGroup,
  updateGender,
  updateIncentive,
  updateProcedure,
  updateRace,
  updateRelationship,
  updateRoute,
  updateService,
  updateSite,
  updateVfcEligibility,
} from "../../services/masters";
import { mastersActions } from "../slices/MasterSlice";
import { AppDispatch } from "..";
import CommonMaster, { CommonMasterStatus } from "../../ts/models/CommonMaster";
import Service, { ServiceStatus } from "../../ts/models/Service";
import Incentive, { IncentiveStatus } from "../../ts/models/Incentive";
import Acknowledgement from "../../ts/models/Acknowledgement";

export const getAllMasters = () => {
  return async (dispatch: AppDispatch) => {
    fetchAllMaster()
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.fetchAllMaster(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Acknowledgements master
export const updateAcknowledgementData = (data: Acknowledgement) => {
  return async (dispatch: AppDispatch) => {
    updateAcknowledgement(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateAcknowledgement(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Disclaimers master

export const addNewDisclaimer = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addDisclaimer(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addDisclaimer(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateDisclaimerData = (
  data: CommonMaster | CommonMasterStatus
) => {
  return async (dispatch: AppDispatch) => {
    updateDisclaimer(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateDisclaimer(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Dispositions master
export const addNewDisposition = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addDisposition(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addDisposition(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateDispositionData = (
  data: CommonMaster | CommonMasterStatus
) => {
  return async (dispatch: AppDispatch) => {
    updateDisposition(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateDisposition(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Ethnic Groups master
export const addNewEthnicGroup = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addEthnicGroup(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addEthnicGroup(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateEthnicGroupData = (
  data: CommonMaster | CommonMasterStatus
) => {
  return async (dispatch: AppDispatch) => {
    updateEthnicGroup(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateEthnicGroup(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Genders master
export const addNewGender = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addGender(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addGender(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateGenderData = (data: CommonMaster | CommonMasterStatus) => {
  return async (dispatch: AppDispatch) => {
    updateGender(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateGender(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Incentives master
export const addNewIncentive = (data: Incentive) => {
  return async (dispatch: AppDispatch) => {
    addIncentive(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addIncentive(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateIncentiveData = (data: Incentive | IncentiveStatus) => {
  return async (dispatch: AppDispatch) => {
    updateIncentive(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateIncentive(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Procedures master
export const addNewProcedure = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addProcedure(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addProcedure(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateProcedureData = (
  data: CommonMaster | CommonMasterStatus
) => {
  return async (dispatch: AppDispatch) => {
    updateProcedure(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateProcedure(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Races master
export const addNewRace = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addRace(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addRace(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateRaceData = (data: CommonMaster | CommonMasterStatus) => {
  return async (dispatch: AppDispatch) => {
    updateRace(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateRace(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Relationships master
export const addNewRelationship = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addRelationship(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addRelationship(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateRelationshipData = (
  data: CommonMaster | CommonMasterStatus
) => {
  return async (dispatch: AppDispatch) => {
    updateRelationship(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateRelationship(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Routes master
export const addNewRoute = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addRoute(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addRoute(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateRouteData = (data: CommonMaster | CommonMasterStatus) => {
  return async (dispatch: AppDispatch) => {
    updateRoute(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateRoute(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Services master
export const addNewService = (data: Service) => {
  return async (dispatch: AppDispatch) => {
    addService(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addService(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateServiceData = (data: Service | ServiceStatus) => {
  return async (dispatch: AppDispatch) => {
    updateService(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateService(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Sites master
export const addNewSite = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addSite(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addSite(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateSiteData = (data: CommonMaster | CommonMasterStatus) => {
  return async (dispatch: AppDispatch) => {
    updateSite(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateSite(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Vfc Eligibilities master
export const addNewVfcEligibility = (data: CommonMaster) => {
  return async (dispatch: AppDispatch) => {
    addVfcEligibility(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.addVfcEligibility(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateVfcEligibilityData = (data: CommonMaster | CommonMasterStatus) => {
  return async (dispatch: AppDispatch) => {
    updateVfcEligibility(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(mastersActions.updateVfcEligibility(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

import { useSelector } from "react-redux";
import { Navigate } from "react-router";

import { BASE_URL, DASHBOARD_URL, SIGN_IN_URL } from "./urls";
import { hasPermission } from "../utils";
import { RootState } from "../store";

type Props = {
  permission?: string;
  children: JSX.Element | JSX.Element[];
};

const Protected: React.FC<Props> = ({ permission, children }) => {
  const { isAuthenticated, resourceName } = useSelector(
    (state: RootState) => state.auth
  );

  const userData = useSelector((state: RootState) => state.users.currUserData);

  if (!isAuthenticated) {
    return (
      <Navigate to={`${BASE_URL}/${resourceName}/${SIGN_IN_URL}`} replace />
    );
  }
  if (permission && !hasPermission(userData.permissions, permission)) {
    return (
      <Navigate to={`${BASE_URL}/${resourceName}/${DASHBOARD_URL}`} replace />
    );
  }
  return <>{children}</>;
};

export default Protected;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router";

import { AppDispatch } from "../../store";
import { getAllMasters } from "../../store/actions/MasterActions";
import {
  fetchCurrentUser,
  fetchTenantsData,
} from "../../store/actions/UserActions";
import { getPODs } from "../../store/actions/PODActions";
import { getDashboardStats } from "../../store/actions/DashboardActions";
import classes from "./Home.module.css";
import Sidebar from "../Sidebar/Sidebar";

const Home: React.FC<{}> = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllMasters());
    dispatch(getPODs());
    dispatch(fetchCurrentUser());
    dispatch(getDashboardStats());
    dispatch(fetchTenantsData());
  }, [dispatch]);

  return (
    <div className={classes.main}>
      <div className={classes.left}>
        <Sidebar />
      </div>
      <div className={classes.right}>
        <Outlet />
      </div>
    </div>
  );
};

export default Home;

import { createSlice } from "@reduxjs/toolkit";
import POD from "../../ts/models/POD";

type InitialState = {
  pods: POD[];
  podToBeEdited: POD;
};

const emptyPod = { ...new POD() };

const initialState: InitialState = {
  pods: [],
  podToBeEdited: emptyPod,
};

const pods = createSlice({
  name: "pods",
  initialState,
  reducers: {
    getPODs: (state, action) => {
      state.pods = action.payload;
    },
    addPOD: (state, action) => {
      state.pods.push(action.payload);
    },
    editPOD: (state, action) => {
      let index: number = -1;
      index = state.pods.findIndex((pod: POD) => {
        return pod.id === action.payload.id;
      });
      if (index !== -1) {
        state.pods[index] = { ...action.payload };
      }
    },
    setPodToBeEdited: (state, action) => {
      state.podToBeEdited = action.payload;
    },
    emptyPodToBeEdited: (state) => {
      state.podToBeEdited = emptyPod;
    },
    deletePOD: (state, action) => {
      let index: number = -1;
      index = state.pods.findIndex((pod: POD) => {
        return pod.id === action.payload;
      });
      if (index !== -1) {
        state.pods.splice(index, 1);
      }
    },
  },
});

export const podsActions = pods.actions;

export default pods.reducer;

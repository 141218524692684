import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  BASE_URL,
  DASHBOARD_URL,
  FORGOT_PASSWORD_URL,
} from "../../Routes/urls";
import { warningActions } from "../../store/slices/WarningSlice";
import { validateEmail } from "../../utils";
import { authActions } from "../../store/slices/AuthSlice";
import { LoginData, Tenant } from "../../ts/types";
import { login } from "../../services/login";
import { AppDispatch, RootState } from "../../store";
import { fetchTenantsData } from "../../store/actions/UserActions";
import classes from "./SignIn.module.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Card from "../../components/Card/Card";
import logoImage from "../../assets/cimpar-logo.svg";
import Input from "../../components/Inputs/Input";
import LinkButton from "../../components/Buttons/LinkButton";

type Props = {};

const getInitialFieldErrors = () => {
  return {
    email: false,
    password: false,
  };
};

const SignIn: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const resourceName =
    useSelector((state: RootState) => state.auth.resourceName) || "";
  const tenants = useSelector((state: RootState) => state.users.tenants);

  const [userDetails, setUserDetails] = useState<LoginData>({
    email: "",
    password: "",
  });
  const [fieldErrors, setFieldErrors] = useState(getInitialFieldErrors());
  const environments = tenants.map((tenant: Tenant) => tenant.id);

  const isFieldsValid = (): boolean => {
    const fieldErrors = getInitialFieldErrors();

    if (!userDetails.email || !validateEmail(userDetails.email)) {
      fieldErrors.email = true;
    }

    if (!userDetails.password) {
      fieldErrors.password = true;
    }

    setFieldErrors(fieldErrors);

    return Object.values(fieldErrors).every((value) => value === false);
  };

  const clearFieldErrors = (key: keyof typeof fieldErrors) => {
    if (fieldErrors[key])
      setFieldErrors((prevState) => {
        const newState = { ...prevState };
        newState[key] = false;
        return newState;
      });
  };

  const signInHandler = async () => {
    if (!isFieldsValid()) {
      return;
    }

    if (!environments.includes(resourceName)) {
      dispatch(
        warningActions.showWarning({
          message: `Invalid tenant name "${resourceName}". Please check the url.`,
        })
      );
      return;
    }

    try {
      const res = await login(userDetails);
      if (res.status === 200) {
        if (res.data.success) {
          localStorage.setItem("isLoggedIn", "true");
          dispatch(authActions.setAuthenticated());
          navigate(`${BASE_URL}/${resourceName}/${DASHBOARD_URL}`);
        } else {
          if (res.data.errorCode === "access_denied") {
            dispatch(
              warningActions.showWarning({
                message:
                  "Sorry, you do not have the permission to access the Reya portal.",
              })
            );
          } else {
            dispatch(
              warningActions.showWarning({
                message: "Invalid username or password",
              })
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const forgotPasswordHandler = () => {
    navigate(`${BASE_URL}/${resourceName}/${FORGOT_PASSWORD_URL}`);
  };

  useEffect(() => {
    if (tenants.length === 0) {
      dispatch(fetchTenantsData());
    }
  }, []);

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.logoContainer}>
          <img src={logoImage} alt='CIMPAR Logo' className={classes.logo} />
        </div>
        <div className={classes.heading}>Login</div>
        <div className={classes.container}>
          <Input
            name='Email'
            value={userDetails.email}
            onChange={(e) => {
              setUserDetails((prevState) => {
                return {
                  ...prevState,
                  email: e.target.value,
                };
              });
              clearFieldErrors("email");
            }}
            isInvalid={fieldErrors.email}
            invalidText='Invalid email'
            className={classes.inputContainer}
            inputClassName={classes.input}
          />
          <Input
            name='Password'
            type='password'
            value={userDetails.password}
            onChange={(e) => {
              setUserDetails((prevState) => {
                return {
                  ...prevState,
                  password: e.target.value,
                };
              });
              clearFieldErrors("password");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                signInHandler();
              }
            }}
            isInvalid={fieldErrors.password}
            className={classes.inputContainer}
            inputClassName={classes.input}
          />
          <LinkButton
            className={classes.forgotPassword}
            label={"Forgot Password?"}
            onClick={forgotPasswordHandler}
          />
          <PrimaryButton
            name='LOGIN'
            className={classes.loginBtn}
            onClick={signInHandler}
          />
        </div>
      </Card>
    </div>
  );
};

export default SignIn;

import classes from "./DashboardCard.module.css";

type Props = {
  name: string;
  value: number | string;
  color: string;
};

const DashboardCard: React.FC<Props> = ({ name, value, color }) => {
  return (
    <div className={classes.main} style={{ backgroundColor: color }}>
       <div className={classes.value}>{value}</div>
      <div className={classes.name}>{name}</div>
    </div>
  );
};

export default DashboardCard;

export const BASE_URL = "";

// Main screens
export const SIGN_IN_URL = "sign-in";
export const DASHBOARD_URL = "dashboard";
export const POD_URL = "pod";
export const EVENTS_URL = "events";
export const EVENT_CALENDAR_URL = "event-calendar";
export const SURVEYS_URL = "surveys";
export const CHECKIN_LIST_URL = "check-in-list";
export const MASTER_MANAGEMENT_URL = "master-management";
export const USER_MANAGEMENT_URL = "user-management";
export const BILLING_REPORT_URL = "billing-report";
export const REPORT_URL = "report";
export const AGENCY_REPORT_URL = "agency-report";
export const LOGS_URL = "logs";
export const FORGOT_PASSWORD_URL = "forgot-password";
export const RESET_PASSWORD_URL = "reset-password";

// Master management child screens
export const ACKNOWLEDGEMENTS_URL = "acknowledgements";
export const DISCLAIMERS_URL = "disclaimers";
export const DISPOSITIONS_URL = "dispositions";
export const ENVIRONMENTAL_ALLERGIES_URL = "environmental-allergies";
export const ETHNIC_GROUPS_URL = "ethnic-groups";
export const GENDERS_URL = "genders";
export const INCENTIVES_URL = "incentives";
export const INFO_QUESTIONS_URL = "info-questions";
export const MEDICATION_ALLERGIES_URL = "medication-allergies";
export const MEDICATIONS_URL = "medications";
export const PROCEDURES_URL = "procedures";
export const PRODUCTS_URL = "products";
export const RACES_URL = "races";
export const RELATIONSHIPS_URL = "relationships";
export const ROUTES_URL = "routes";
export const SCREENING_QUESTIONS_URL = "screening-questions";
export const SERVICES_URL = "services";
export const SITES_URL = "sites";
export const SYMPTOMS_URL = "symptoms";
export const VFC_ELIGIBILITIES_URL = "vfc-eligibilities";
export const HELP_URL = "help";

export const CREATE_POD_URL = "create-pod";
export const UPDATE_POD_URL = "update-pod";
export const CREATE_EVENT_URL = "create-event";
export const UPDATE_EVENT_URL = "update-event";
export const CREATE_SURVEY_URL = "create-survey";
export const UPDATE_SURVEY_URL = "update-survey";
export const CREATE_USER_URL = "create-user";
export const UPDATE_USER_URL = "update-user";
export const EVENT_DETAILS_URL = "event-details";

export const PATIENT_REPORT_URL = "patient-report";
export const EVENT_REPORT_URL = "event-report";

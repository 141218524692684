import { AppDispatch } from "..";
import { eventsActions } from "../slices/EventSlice";
import {
  addNewEvent,
  deleteAnEvent,
  editAnEvent,
  fetchEvents,
  fetchEventsCount,
  updateEventStatus,
} from "../../services/events";
import Event, { EventNotes, EventStatus } from "../../ts/models/Event";
import { warningActions } from "../slices/WarningSlice";

// Takes page no to be updated at currPageNo state
export const getEvents = (filter?: any, updatedPageNo?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const [eventRes, eventCountRes] = await Promise.all([
        fetchEvents(filter),
        fetchEventsCount(filter.where),
      ]);
      if (eventRes.status === 200 && eventCountRes.status === 200) {
        dispatch(
          eventsActions.getEvents({
            events: eventRes.data,
            count: eventCountRes.data?.count,
            currPageNo: updatedPageNo,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addEvent = (data: Event, handleBack?: () => void) => {
  return async (dispatch: AppDispatch) => {
    return addNewEvent(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(eventsActions.addEvent(res.data));
          handleBack?.();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const editEvent = (
  data: Event | EventNotes,
  handleBack?: () => void
) => {
  return async (dispatch: AppDispatch) => {
    return editAnEvent(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(eventsActions.editEvent(res.data));
          handleBack?.();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateEventStatusData = (data: EventStatus) => {
  return async (dispatch: AppDispatch) => {
    updateEventStatus(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(eventsActions.editEvent(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteEvent = (eventId: string) => {
  return async (dispatch: AppDispatch) => {
    return deleteAnEvent(eventId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errorCode === "bad_request") {
            dispatch(
              warningActions.showWarning({
                message:
                  "Failed to delete event. The event has associated registrations/orders.",
              })
            );
          } else {
            dispatch(eventsActions.deleteEvent(eventId));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export default class Service {
  id!: string;
  code: string;
  name: string;
  description: string;
  isReportableToAgency: boolean;
  products: string[];
  screeningQuestions: string[];
  isActive: boolean;

  constructor(
    code = "",
    name = "",
    description = "",
    isReportableToAgency = false,
    products: string[] = [],
    screeningQuestions: string[] = [],
    isActive: boolean = true
  ) {
    this.code = code;
    this.name = name;
    this.description = description;
    this.isReportableToAgency = isReportableToAgency;
    this.products = products;
    this.screeningQuestions = screeningQuestions;
    this.isActive = isActive;
  }
}

export type ServiceStatus = {
  id: string;
  isActive: boolean;
};

import { useState } from "react";

import Modal, { ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import { addMedication, updateMedication } from "../../../services/masters";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Input from "../../../components/Inputs/Input";
import Medication from "../../../ts/models/Medication";
import classes from "./AddMedication.module.css";

type props = {
  medication: Medication;
  onClose: (isUpdated: boolean) => void;
};

const AddMedication: React.FC<props> = ({ onClose, medication }) => {
  const [data, setData] = useState(medication);
  const isEdit = data.id;

  const addMedicationHandler = async () => {
    let res;
    try {
      if (isEdit) {
        res = await updateMedication(data);
      } else {
        res = await addMedication(data);
      }
      if (res.status === 200) {
        onClose(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isRequiredDataMissing = () => {
    if (
      !data.code?.trim() ||
      !data.name?.trim() ||
      !data.manufacturer?.trim() ||
      !data.dosage?.trim()
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        className={classes.heading}
        title={isEdit ? <>Update Medication</> : <>Add Medication</>}
        onClose={() => onClose(false)}>
        <>
          <ModalBody>
            <div className={classes.main}>
              <Input
                name='Code'
                value={data.code}
                onChange={(e) =>
                  setData((state) => ({ ...state, code: e.target.value }))
                }
              />
              <div className={classes.codeContainer}>
                <Input
                  name='CVX Code'
                  value={data.cvxCode}
                  onChange={(e) =>
                    setData((state) => ({ ...state, cvxCode: e.target.value }))
                  }
                />
                <Input
                  name='NDC Code'
                  value={data.ndcCode}
                  onChange={(e) =>
                    setData((state) => ({ ...state, ndcCode: e.target.value }))
                  }
                />
              </div>
              <Input
                name='Name'
                value={data.name}
                onChange={(e) =>
                  setData((state) => ({
                    ...state,
                    name: e.target.value,
                  }))
                }
              />
              <div className={classes.codeContainer}>
                <Input
                  name='Manufacturer'
                  value={data.manufacturer}
                  onChange={(e) =>
                    setData((state) => ({
                      ...state,
                      manufacturer: e.target.value,
                    }))
                  }
                />
                <Input
                  name='MVX Code'
                  value={data.mvxCode}
                  onChange={(e) =>
                    setData((state) => ({ ...state, mvxCode: e.target.value }))
                  }
                />
              </div>
              <Input
                name='Dosage'
                value={data.dosage}
                onChange={(e) =>
                  setData((state) => ({
                    ...state,
                    dosage: e.target.value,
                  }))
                }
              />
              <Input
                name='Guideline'
                value={data.guideline}
                onChange={(e) =>
                  setData((state) => ({
                    ...state,
                    guideline: e.target.value,
                  }))
                }
                textarea
                inputClassName={classes.textAreaHeight}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className={classes.actions}>
              <PrimaryButton
                className={classes.actionBtn}
                name={isEdit ? "Update" : "Add"}
                onClick={addMedicationHandler}
                style={{ marginRight: "1rem" }}
                disabled={isRequiredDataMissing()}
              />
              <PrimaryButton
                className={classes.actionBtn}
                name='Cancel'
                onClick={() => onClose(false)}
                transparent
              />
            </div>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};
export default AddMedication;

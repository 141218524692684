import { useState, useEffect, PropsWithChildren, ReactElement } from "react";

import { Column } from "../../ts/types";
import classes from "./SelectItems.module.css";
import Table from "../CustTable/Table";
import Checkbox from "../Checkbox/Checkbox";

const pageSize = 10;

interface Id {
  id: string;
}

type Props<T> = {
  initialItemsSet: Set<string>;
  items: T[];
  noOfPages: number;
  columns: Column<T>[];
  isPaginated?: boolean;
  setItemsHandler: (items: Set<string>) => void;
  searchItemsHandler: (offset: number, searchText: string) => void;
  searchText?: string;
};

const SelectItems = <T extends Id>({
  initialItemsSet,
  items,
  noOfPages,
  columns,
  isPaginated,
  setItemsHandler,
  searchItemsHandler,
  searchText = "",
}: PropsWithChildren<Props<T>>): ReactElement | null => {
  // return a React element or null

  const [selectedItemsSet, setSelectedItemsSet] = useState<Set<string>>(
    new Set<string>(initialItemsSet)
  );
  const [currPageNo, setCurrPageNo] = useState(1);
  const [skipCount, setSkipCount] = useState(true);

  useEffect(() => {
    searchItemsHandler((currPageNo - 1) * pageSize, searchText);
  }, [currPageNo]);

  useEffect(() => {
    if (currPageNo === 1) {
      // Preventing first time auto api call because it is already called from currPageNo useEffect
      if (skipCount) {
        setSkipCount(false);
      } else {
        searchItemsHandler(0, searchText);
      }
    } else {
      // We need to go to page no. 1 if search text change, as well as it will fetch data
      setCurrPageNo(1);
    }
  }, [searchText]);

  const tableColumns: Column<T>[] = [
    {
      header: "SELECT",
      accessor: (product, idx) => (
        <div className={classes.checkboxContainer}>
          <Checkbox
            id={`checkbox-${product.id}`}
            checked={selectedItemsSet.has(product.id)}
            onChange={(e) => {
              const value: boolean = !selectedItemsSet.has(product.id);
              setSelectedItemsSet((prevState) => {
                if (value) {
                  const newState: Set<string> = new Set<string>(
                    prevState.add(product.id)
                  );
                  setItemsHandler(newState);
                  return newState;
                } else {
                  if (prevState.has(product.id)) {
                    const newState: Set<string> = new Set<string>(prevState);
                    newState.delete(product.id);
                    setItemsHandler(newState);
                    return newState;
                  } else {
                    return prevState;
                  }
                }
              });
            }}
          />
        </div>
      ),
      width: "10%",
    },
    {
      header: "ID",
      accessor: (e, idx: number) => (
        <div>{(currPageNo - 1) * pageSize + (idx + 1)}</div>
      ),
    },
    ...columns,
  ];

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        {isPaginated ? (
          <Table
            columns={tableColumns}
            data={items}
            isPaginated
            pageNo={currPageNo}
            totalPages={noOfPages}
            onPrevClick={() => setCurrPageNo((state) => state - 1)}
            onNextClick={() => setCurrPageNo((state) => state + 1)}
          />
        ) : (
          <Table columns={tableColumns} data={items} />
        )}
      </div>
    </div>
  );
};
export default SelectItems;

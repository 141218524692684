export default class Incentive {
  id!: string;
  code: string;
  description: string;
  amount: string;
  hasSerialNumbers: boolean;
  isActive: boolean;

  constructor(
    code = "",
    description = "",
    amount = "",
    hasSerialNumbers = false,
    isActive = true
  ) {
    this.code = code;
    this.description = description;
    this.amount = amount;
    this.hasSerialNumbers = hasSerialNumbers;
    this.isActive = isActive;
  }
}

export type IncentiveStatus = {
  id: string;
  isActive: boolean;
};

export default class CommonMaster {
  id!: string;
  code: string;
  description: string;
  isActive: boolean;

  constructor(code = "", description = "", isActive: boolean = true) {
    this.code = code;
    this.description = description;
    this.isActive = isActive;
  }
}

export type CommonMasterStatus = {
  id: string;
  isActive: boolean;
};

import { createSlice } from "@reduxjs/toolkit";
import CommonMaster from "../../ts/models/CommonMaster";
import Acknowledgement from "../../ts/models/Acknowledgement";
import Service from "../../ts/models/Service";
import Incentive from "../../ts/models/Incentive";

// Non-paginated masters.
type InitialState = {
  acknowledgements: Acknowledgement[];
  disclaimers: CommonMaster[];
  dispositions: CommonMaster[];
  ethnicGroups: CommonMaster[];
  genders: CommonMaster[];
  incentives: Incentive[];
  procedures: CommonMaster[];
  races: CommonMaster[];
  relationships: CommonMaster[];
  routes: CommonMaster[];
  services: Service[];
  sites: CommonMaster[];
  vfcEligibilities: CommonMaster[];

  totalProductsCount: number;
};

const initialState: InitialState = {
  acknowledgements: [],
  disclaimers: [],
  dispositions: [],
  ethnicGroups: [],
  genders: [],
  incentives: [],
  procedures: [],
  races: [],
  relationships: [],
  routes: [],
  services: [],
  sites: [],
  vfcEligibilities: [],

  totalProductsCount: 0,
};

const masters = createSlice({
  name: "masters",
  initialState,
  reducers: {
    fetchAllMaster: (state, action) => {
      const {
        acknowledgements,
        disclaimers,
        dispositions,
        ethnicGroups,
        genders,
        incentives,
        procedures,
        races,
        relationships,
        routes,
        services,
        sites,
        vfcEligibilities,
      } = action.payload;

      state.acknowledgements = acknowledgements || [];
      state.disclaimers = disclaimers || [];
      state.dispositions = dispositions || [];
      state.ethnicGroups = ethnicGroups || [];
      state.genders = genders || [];
      state.incentives = incentives || [];
      state.procedures = procedures || [];
      state.races = races || [];
      state.relationships = relationships || [];
      state.routes = routes || [];
      state.services = services || [];
      state.sites = sites || [];
      state.vfcEligibilities = vfcEligibilities || [];
    },
    getTotalProductsCount: (state, action) => {
      state.totalProductsCount = action.payload;
    },
    addDisclaimer: (state, action) => {
      state.disclaimers.push(action.payload);
    },
    addDisposition: (state, action) => {
      state.dispositions.push(action.payload);
    },
    addEthnicGroup: (state, action) => {
      state.ethnicGroups.push(action.payload);
    },
    addGender: (state, action) => {
      state.genders.push(action.payload);
    },
    addIncentive: (state, action) => {
      state.incentives.push(action.payload);
    },
    addProcedure: (state, action) => {
      state.procedures.push(action.payload);
    },
    addRace: (state, action) => {
      state.races.push(action.payload);
    },
    addRelationship: (state, action) => {
      state.relationships.push(action.payload);
    },
    addRoute: (state, action) => {
      state.routes.push(action.payload);
    },
    addService: (state, action) => {
      state.services.push(action.payload);
    },
    addSite: (state, action) => {
      state.sites.push(action.payload);
    },
    addVfcEligibility: (state, action) => {
      state.vfcEligibilities.push(action.payload);
    },    
    updateAcknowledgement: (state, action) => {
      const index = state.acknowledgements.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.acknowledgements[index] = action.payload;
    },
    updateDisclaimer: (state, action) => {
      const index = state.disclaimers.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.disclaimers[index] = action.payload;
    },
    updateDisposition: (state, action) => {
      const index = state.dispositions.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.dispositions[index] = action.payload;
    },
    updateEthnicGroup: (state, action) => {
      const index = state.ethnicGroups.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.ethnicGroups[index] = action.payload;
    },
    updateGender: (state, action) => {
      const index = state.genders.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.genders[index] = action.payload;
    },
    updateIncentive: (state, action) => {
      const index = state.incentives.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.incentives[index] = action.payload;
    },
    updateProcedure: (state, action) => {
      const index = state.procedures.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.procedures[index] = action.payload;
    },
    updateRace: (state, action) => {
      const index = state.races.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.races[index] = action.payload;
    },
    updateRelationship: (state, action) => {
      const index = state.relationships.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.relationships[index] = action.payload;
    },
    updateRoute: (state, action) => {
      const index = state.routes.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.routes[index] = action.payload;
    },
    updateService: (state, action) => {
      const index = state.services.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.services[index] = action.payload;
    },
    updateSite: (state, action) => {
      const index = state.sites.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.sites[index] = action.payload;
    },
    updateVfcEligibility: (state, action) => {
      const index = state.vfcEligibilities.findIndex(
        (ele) => ele.id === action.payload.id
      );
      state.vfcEligibilities[index] = action.payload;
    },
  },
});

export const mastersActions = masters.actions;

export default masters.reducer;

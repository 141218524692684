import { createPortal } from "react-dom";

import { getClasses } from "../../utils";
import classes from "./Modal.module.css";
import closeIcon from "../../assets/icons/close-x.svg";

export const ModalTitle: React.FC<{
  children: JSX.Element;
  onClose?: () => void;
}> = ({ children, onClose }) => {
  return (
    <div className={classes.header}>
      {children}
      {onClose && (
        <div style={{ marginLeft: "1rem", cursor: "pointer" }}>
          <img src={closeIcon} alt='Close' onClick={onClose} />
        </div>
      )}
    </div>
  );
};

export const ModalBody: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <div className={classes.body}>{children}</div>;
};

export const ModalFooter: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  return <div className={classes.footer}>{children}</div>;
};

const container = document.querySelector("#root")!;

const Modal: React.FC<{
  children: JSX.Element;
  title?: JSX.Element;
  onClose?: () => void;
  className?: string;
  style?: React.CSSProperties;
}> = ({ title, children, onClose, className, style }) => {
  return createPortal(
    <>
      <div className={classes.backdrop}>
        <div
          className={getClasses(classes.modal, className ? className : "")}
          style={style}>
          {Boolean(title || onClose) && (
            <ModalTitle onClose={onClose}>{title!}</ModalTitle>
          )}
          {children}
        </div>
      </div>
    </>,
    container
  );
};

export default Modal;

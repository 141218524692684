import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { addDays, addWeeks } from "date-fns";

import {
  getEndOfDayTimestamp,
  getLocalDateString,
  getStartOfDayTimestamp,
} from "../../utils";
import { fetchEvents } from "../../services/events";
import { RootState } from "../../store";
import PanelContainer from "../../components/Containers/PanelContainer";
import TabBar from "../../components/TabBar/TabBar";
import Card from "../../components/Card/Card";
import classes from "./Dashboard.module.css";
import DashboardCard from "./DashboardCard";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import EventCard from "./EventCard";
import Event from "../../ts/models/Event";
import noEventsCalendarImage from "../../assets/icons/no-events-calendar.svg";

const dashboardStats = "Analytics";
const todaysEvents = "Today's Events";
const eventThisWeek = "Events This Week";

const Dashboard = () => {
  const { pods } = useSelector((state: RootState) => state.pods);
  const { totalEventCount, liveRegCount, liveEventsCount } = useSelector(
    (state: RootState) => state.events
  );
  const { services, totalProductsCount } = useSelector(
    (state: RootState) => state.masters
  );
  const { totalMedProfsCount } = useSelector((state: RootState) => state.users);
  const [activeTab, setActiveTab] = useState(dashboardStats);

  const [eventsData, setEventsData] = useState<Event[]>([]);

  const fetchEventsData = async () => {
    const filter: any = {
      offset: 0,
      where: {
        startDate: getStartOfDayTimestamp(getLocalDateString(Date.now())),
        isActive: true,
      },
    };

    if (activeTab === todaysEvents) {
      filter.where.endDate = getEndOfDayTimestamp(
        getLocalDateString(Date.now())
      );
    } else {
      filter.where.endDate = getEndOfDayTimestamp(
        getLocalDateString(addDays(new Date(), 6).getTime())
      );
    }

    try {
      const { status, data } = await fetchEvents(filter);
      if (status) {
        setEventsData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (activeTab !== dashboardStats) fetchEventsData();
  }, [activeTab]);

  const cardData = [
    {
      name: "Pod",
      value: pods.length,
      color: "#ffefe3",
    },
    {
      name: "Total Events",
      value: totalEventCount,
      color: "#e4f5f9",
    },
    {
      name: "Live Registrations",
      value: liveRegCount,
      color: "#eefcef",
    },
    {
      name: "Ongoing Events",
      value: liveEventsCount,
      color: "#cfe5d1",
    },
    {
      name: "Medical Services",
      value: services.length,
      color: "#f4f6fa",
    },
    {
      name: "Products",
      value: totalProductsCount,
      color: "#ecf8d7",
    },
    {
      name: "Medical Professionals",
      value: totalMedProfsCount,
      color: "#eaecfc",
    },
  ];

  const tabChangeHandler = (tabName: string) => {
    setActiveTab(tabName);
  };

  const getTabContent = () => {
    if (activeTab === dashboardStats) {
      return cardData.map((card, idx) => <DashboardCard {...card} key={idx} />);
    }

    if (eventsData.length > 0) {
      return eventsData.map((event) => (
        <EventCard key={event.id} event={event} />
      ));
    }

    const noEventsTitle =
      activeTab === todaysEvents
        ? "No Events, Today !"
        : "No Events, This Week !";
    return <NoEvents title={noEventsTitle} />;
  };

  return (
    <PanelContainer name='Dashboard'>
      <PanelInnerContainer>
        <Card className={classes.card}>
          <TabBar
            tabs={[
              {
                name: dashboardStats,
              },
              {
                name: todaysEvents,
              },
              {
                name: eventThisWeek,
              },
            ]}
            idExtractor='name'
            onTabChange={tabChangeHandler}
          />
          <div className={classes.main}>{getTabContent()}</div>
        </Card>
      </PanelInnerContainer>
    </PanelContainer>
  );
};

type NoEventsProps = {
  title: string;
};

const NoEvents: React.FC<NoEventsProps> = ({ title }) => {
  return (
    <div className={classes.noEventsMain}>
      <img
        className={classes.noEventsImage}
        src={noEventsCalendarImage}
        alt=''
      />
      <div className={classes.noEventsTitle}>{title}</div>
    </div>
  );
};

export default Dashboard;

import classes from "./DataField.module.css";

type Props = {
  name: string;
  data?: string | number | JSX.Element;
};

const DataField: React.FC<Props> = ({ name, data }) => {
  return (
    <div className={classes.main}>
      <div className={classes.name}>{name}</div>
      {data && <div className={classes.data}>{data}</div>}
    </div>
  );
};

export default DataField;

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";

import { BASE_URL, CREATE_POD_URL, POD_URL } from "../../Routes/urls";
import { AppDispatch, RootState } from "../../store";
import { deletePOD, updatePODStatusData } from "../../store/actions/PODActions";
import { fetchPodwiseEventCount } from "../../services/events";
import { podsActions } from "../../store/slices/PODSlice";
import classes from "./POD.module.css";
import PanelContainer from "../../components/Containers/PanelContainer";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import SearchBar from "../../components/SearchBar/SearchBar";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import PODCard from "./PODCard";
import PanelActionContainer from "../../components/Containers/PanelActionContainer";
import addIcon from "../../assets/icons/add-circle-white.svg";
import { PODEventCount } from "../../ts/models/Event";
import { getJsonBlob } from "../../utils";
import POD from "../../ts/models/POD";
import DeleteConfirm from "../../components/Confirm/DeleteConfirm";

const PODs = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const pods = useSelector((state: RootState) => state.pods.pods);
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );
  const [eventCounts, setEventCounts] = useState(new Map<string, number>());

  const [searchText, setSearchText] = useState("");
  const [showConfirmation, setShowConfirmation] = useState({
    show: false,
    name: "",
    podId: "",
    eventCount: 0,
  });

  useEffect(() => {
    fetchPodwiseEventCount()
      .then((res) => {
        if (res.status === 200) {
          var counts = new Map<string, number>();
          res.data.forEach((pod: PODEventCount) => {
            counts.set(pod.podId, pod.eventCount);
          });
          setEventCounts(counts);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pods]);

  const filteredPods = pods.filter(
    (pod) =>
      pod.name.toLowerCase().includes(searchText.toLowerCase()) ||
      pod.code.toLowerCase().includes(searchText.toLowerCase())
  );

  const updateStatusHandler = (id: string, isActive: boolean) => {
    const formData = new FormData();
    formData.append("pod", getJsonBlob({ id, isActive }));

    dispatch(updatePODStatusData(formData, id));
  };

  const createPODHandler = () => {
    dispatch(podsActions.emptyPodToBeEdited());
    navigate(BASE_URL + `/${resourceName}` + `/${POD_URL}/` + CREATE_POD_URL);
  };

  const deletePODHandler = (pod: POD, eventCount: number) => {
    setShowConfirmation({
      show: true,
      name: pod.name,
      podId: pod.id,
      eventCount: eventCount,
    });
  };
  const confirmHandler = () => {
    dispatch(deletePOD(showConfirmation.podId));
    setShowConfirmation({ show: false, name: "", podId: "", eventCount: 0 });
  };

  return (
    <>
      {showConfirmation.show && (
        <DeleteConfirm
          message={
            <>
              <div>{"Are you sure you want to delete this POD?"}</div>
              <div className={classes.eventsWarning}>
                {showConfirmation.eventCount > 0
                  ? "All associated events for this POD will be deleted."
                  : ""}
              </div>
            </>
          }
          name={showConfirmation.name}
          collectionName='POD'
          onClose={() =>
            setShowConfirmation({
              show: false,
              name: "",
              podId: "",
              eventCount: 0,
            })
          }
          onConfirmation={confirmHandler}
        />
      )}
      <PanelContainer name='POD'>
        <PanelActionContainer
          leftChildren={
            <SearchBar
              placeholder='Search by POD name'
              onChange={(text) => setSearchText(text)}
            />
          }
          rightChildren={
            <PrimaryButton
              name='Create New POD'
              onClick={createPODHandler}
              icon={<img src={addIcon} alt='' />}
            />
          }
        />
        <PanelInnerContainer>
          <div className={classes.main}>
            {filteredPods.map((pod, idx) => (
              <PODCard
                data={pod}
                eventCount={eventCounts.get(pod.id) ?? 0}
                key={idx}
                onStatusUpdate={updateStatusHandler}
                onDelete={deletePODHandler}
              />
            ))}
          </div>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default PODs;

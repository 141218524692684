import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import {
  BASE_URL,
  EVENTS_URL,
  POD_URL,
  UPDATE_POD_URL,
} from "../../Routes/urls";
import { AppDispatch, RootState } from "../../store";
import { podsActions } from "../../store/slices/PODSlice";
import { eventsActions } from "../../store/slices/EventSlice";
import classes from "./PODCard.module.css";
import POD from "../../ts/models/POD";
import InputLabel from "../../components/Inputs/InputLabel";
import KebabMenu from "../../components/KebabMenu/KebabMenu";
import CopyButton from "../../components/Buttons/CopyButton";
import { UserRole } from "../../ts/enums";

type Props = {
  data: POD;
  eventCount: number;
  onStatusUpdate: (id: string, isActive: boolean) => void;
  onDelete: (data: POD, eventCount: number) => void;
};

const PODCard: React.FC<Props> = ({
  data,
  eventCount,
  onStatusUpdate,
  onDelete,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.users.currUserData);
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  const url = window.location.href;
  const domainName = url.substring(0, url.lastIndexOf(`/${resourceName}`));

  const { id, name, code, isActive, landingPage } = data;
  const podLink = `${domainName}/registration/${resourceName}/pod/${id}`;
  const surveyLink = `${domainName}/survey/${resourceName}/pod/${id}`;
  const landingPageLink = `${domainName}/landing/${resourceName}/pod/${id}`;

  const editPODHandler = () => {
    dispatch(podsActions.setPodToBeEdited(data));
    navigate(BASE_URL + `/${resourceName}` + `/${POD_URL}/` + UPDATE_POD_URL);
  };

  const viewEventsHandler = () => {
    dispatch(eventsActions.setFilter({ podId: id }));
    navigate(BASE_URL + `/${resourceName}` + `/${EVENTS_URL}/`);
  };

  const getOptions = () => {
    const options = [
      { name: "Edit", onClick: editPODHandler },
      {
        name: isActive ? "De-activate" : "Activate",
        onClick: () => onStatusUpdate(id, !isActive),
      },
    ];

    if (
      userData.role === UserRole.Admin ||
      userData.role === UserRole.SuperAdmin
    ) {
      options.push({
        name: "Delete",
        onClick: () => onDelete(data, eventCount),
      });
    }

    return options;
  };

  return (
    <div className={classes.main}>
      <div className={classes.nameContainer}>
        <div className={classes.name}>{name}</div>
        <KebabMenu options={getOptions()} isHorizontal />
      </div>
      <div className={classes.codeContainer}>
        <CardField name='Code' value={code} />
        <CardField name='Events' value={`${eventCount}`} />
      </div>
      <div className={classes.descriptionContainer}>
        <div className={classes.linkContainer}>
          <InputLabel className={classes.label} label={"Pod Link"} />
          <CopyButton
            copyText={podLink}
            tipText='Copy link'
            style={{ marginRight: "0.25rem" }}
          />
        </div>
        <div className={classes.podLink}>
          <a href={podLink} target='_blank' rel='noreferrer'>
            {podLink}
          </a>
        </div>
      </div>
      <div className={classes.descriptionContainer}>
        <div className={classes.linkContainer}>
          <InputLabel className={classes.label} label={"Survey Link"} />
          <CopyButton
            copyText={surveyLink}
            tipText='Copy link'
            style={{ marginRight: "0.25rem" }}
          />
        </div>
        <div className={classes.podLink}>
          <a href={surveyLink} target='_blank' rel='noreferrer'>
            {surveyLink}
          </a>
        </div>
      </div>
      <div className={classes.descriptionContainer}>
        {landingPage?.pageLink && (
          <>
            <div className={classes.linkContainer}>
              <InputLabel
                className={classes.label}
                label={"Landing Page Link"}
              />
              <CopyButton
                copyText={landingPageLink}
                tipText='Copy link'
                style={{ marginRight: "0.25rem" }}
              />
            </div>
            <div className={classes.podLink}>
              <a href={landingPageLink} target='_blank' rel='noreferrer'>
                {landingPageLink}
              </a>
            </div>
          </>
        )}
      </div>
      <div className={classes.editContainer}>
        <div className={classes.viewEvents} onClick={viewEventsHandler}>
          View Events
        </div>
      </div>
    </div>
  );
};
export default PODCard;

type CardFieldType = {
  name: string;
  value: string;
};

const CardField: React.FC<CardFieldType> = ({ name, value }) => {
  return (
    <div className={classes.cardFieldMain}>
      <div className={classes.cardFieldName}>{name}</div>
      <div className={classes.cardFieldValue}>{value}</div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Column, PatientReportData } from "../../../ts/types";
import { downloadFile, getAge, getPatientFullName } from "../../../utils";
import { RootState } from "../../../store";
import {
  fetchEncounters,
  fetchPatientReport,
  fetchPatientReportCount,
  downloadPatientReport,
} from "../../../services/orders";
import { fetchEvents } from "../../../services/events";
import MultiSelect, {
  MultiSelectOption,
} from "../../../components/Inputs/MultiSelect";
import PatientReportDetails from "../ReportDetails/PatientReportDetails";
import MultiStatusView from "../../../components/Status/MultiStatusView";
import TableContainer from "../../../components/CustTable/TableContainer";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import LinkButton from "../../../components/Buttons/LinkButton";
import SearchBar from "../../../components/SearchBar/SearchBar";
import Table from "../../../components/CustTable/Table";
import classes from "./PatientReport.module.css";
import Drawer from "../../../components/Drawer/Drawer";
import Select from "../../../components/Inputs/Select";
import Card from "../../../components/Card/Card";
import Event from "../../../ts/models/Event";

const pageSize = 20;

const PatientReport = () => {
  const pods = useSelector((state: RootState) => state.pods.pods);
  const { genders, services } = useSelector(
    (state: RootState) => state.masters
  );

  const [orders, setOrders] = useState<PatientReportData[]>([]);
  const [showReport, setShowReport] = useState<
    { show: false } | { show: true; data: PatientReportData }
  >({
    show: false,
  });
  const [events, setEvents] = useState<Event[]>([]);
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [podId, setPodId] = useState("");
  const [eventIds, setEventIds] = useState<string[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<MultiSelectOption[]>([]);

  const getGender = (id: string): string => {
    const gender = genders.find((gender) => gender.id === id);
    if (gender) {
      return gender.description;
    }
    return "";
  };

  const eventOptions = events.map((event) => ({
    value: event.id,
    label: event.name,
  }));

  const getServicesAvailed = (serviceIds: string[]) => {
    const serviceName = serviceIds.map((serviceId: string) => {
      const service = services.find((service) => service.id === serviceId);
      if (service) {
        return service.name;
      }
      return "";
    });
    return <MultiStatusView names={serviceName} maxCount={2} />;
  };

  useEffect(() => {
    if (podId) {
      const filter = {
        where: {
          podId,
        },
      };
      const getEvents = async () => {
        try {
          const res = await fetchEvents(filter);
          if (res.status === 200) {
            setEvents(res.data);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getEvents();
      setOrders([]);
    }
  }, [podId]);

  useEffect(() => {
    if (eventIds.length) filterHandler();
  }, [currPageNo]);

  const filterHandler = () => {
    setTotalPages(0);

    const getPatientReport = async () => {
      const eventIdsCopy = [...eventIds];
      if (eventIdsCopy[0] === "*") {
        eventIdsCopy.splice(0, 1);
      }
      const filter: any = {
        eventIds: eventIdsCopy,
      };
      if (searchText.trim()) {
        filter.patientName = searchText.trim();
      }
      try {
        // Fetch the patient report.
        const [patientsReportRes, patientsReportCountRes] = await Promise.all([
          fetchPatientReport({
            ...filter,
            offset: (currPageNo - 1) * pageSize,
            count: pageSize,
          }),
          fetchPatientReportCount(filter),
        ]);
        if (patientsReportCountRes.status === 200) {
          const noOfPages = Math.ceil(
            Number(patientsReportCountRes.data.count) / pageSize
          );
          setTotalPages(noOfPages);
        }
        if (patientsReportRes.status === 200) {
          setOrders(patientsReportRes.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPatientReport();
  };

  const viewDetailsHandler = async (patient: PatientReportData) => {
    const patientData = patient;

    try {
      const encounterRes = await fetchEncounters({
        orderId: patientData.order.id,
      });

      if (encounterRes.status === 200) {
        patientData.encounter = encounterRes.data[0];
      }
    } catch (error) {
      console.log(error);
    }
    setShowReport({ show: true, data: patientData });
  };

  const patientData = orders.filter((e) => {
    return e.patient !== undefined;
  });

  const getEvent = (eventId: string) => {
    return events.find((event) => event.id === eventId);
  };

  const getEventName = (eventId?: string) => {
    return events.find((event) => event.id === eventId)?.name || "";
  };

  const patientReportTableColumn: Column<PatientReportData>[] = [
    {
      header: "ID",
      accessor: (_, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "10%",
    },
    {
      header: "PATIENT NAME",
      accessor: (e) => getPatientFullName(e.patient),
      width: "20%",
    },
    {
      header: "AGE",
      accessor: (e) =>
        typeof e.patient.dob === "number" ? getAge(e.patient.dob) : "",
      width: "10%",
    },
    {
      header: "GENDER",
      accessor: (e) => getGender(e.patient.gender),
    },
    {
      header: "EVENT",
      accessor: (e) => getEventName(e.order.eventId),
    },
    {
      header: "SERVICES AVAILED",
      accessor: (e) =>
        getServicesAvailed(
          e.order.services?.map((service) => service.serviceId) || []
        ),
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <div>
          <LinkButton
            onClick={() => viewDetailsHandler(e)}
            label='View Details'
          />
        </div>
      ),
    },
  ];

  const downloadReportHandler = async () => {
    const filter: any = {
      eventIds,
    };
    if (searchText.trim()) {
      filter.patientName = searchText.trim();
    }

    if (filter) {
      try {
        const res = await downloadPatientReport(filter);
        downloadFile(res, "PatientReport.csv");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onPodChange = (selectedOptions: MultiSelectOption[]) => {
    const eventIds = selectedOptions.map(
      (selectedOption) => selectedOption.value
    );

    setSelectedEvents(selectedOptions);
    setEventIds(eventIds);
  };

  return (
    <>
      {showReport.show && (
        <Drawer
          name='Patient Report'
          onClose={() => setShowReport({ show: false })}>
          <PatientReportDetails
            event={getEvent(showReport.data.order.eventId || "")}
            patientReportData={showReport.data}
          />
        </Drawer>
      )}
      <Card>
        <div className={classes.filterContainer}>
          <div className={classes.filters}>
            <Select
              label='Select POD'
              htmlFor='pod'
              options={pods}
              defaultOption={"Select POD"}
              nameExtractor='name'
              value={podId}
              onChange={(e) => {
                setPodId(e.target.value);
                setEventIds([]);
              }}
              className={classes.podSelectContainer}
              selectClass={classes.podSelect}
            />
            <div className={classes.eventDropDown}>
              <MultiSelect
                label='Select Event'
                onChange={onPodChange}
                value={selectedEvents}
                options={eventOptions}
                isDisabled={!podId}
                isAll
              />
            </div>
            <div className={classes.searchBar}>
              <SearchBar
                placeholder='Search by patient name'
                onChange={(text) => {
                  setSearchText(text);
                }}
                greyBackground
              />
            </div>
          </div>
          <div className={classes.filterBtnContainer}>
            <PrimaryButton
              className={classes.filterBtn}
              name='Filter'
              onClick={() => filterHandler()}
              disabled={!eventIds.length}
            />
            <PrimaryButton
              name='Download'
              onClick={downloadReportHandler}
              disabled={!eventIds.length}
            />
          </div>
        </div>
      </Card>
      <div className={classes.main}>
        <TableContainer>
          <Table
            columns={patientReportTableColumn}
            data={patientData}
            isPaginated
            pageNo={currPageNo}
            totalPages={totalPages}
            onPrevClick={() => setCurrPageNo((state) => state - 1)}
            onNextClick={() => setCurrPageNo((state) => state + 1)}
          />
        </TableContainer>
      </div>
    </>
  );
};

export default PatientReport;

export default class User {
  id!: string;
  email: string;
  role: string;
  permissions: string[];
  firstName: string;
  middleName: string;
  lastName: string;
  serviceTypes: string[];
  defaultLocation: string;
  mobileNo: string;
  licenseId: string;
  licenseLevel: string;
  lastLoginTime?: number;
  isActive: boolean;

  constructor(
    email: string = "",
    role: string = "",
    permissions: string[] = [],
    firstName: string = "",
    middleName: string = "",
    lastName: string = "",
    serviceTypes: string[] = [],
    defaultLocation: string = "",
    mobileNo: string = "",
    licenseId: string = "",
    licenseLevel: string = "",
    isActive: boolean = true
  ) {
    this.email = email;
    this.role = role;
    this.permissions = permissions;
    this.firstName = firstName;
    this.middleName = middleName;
    this.lastName = lastName;
    this.serviceTypes = serviceTypes;
    this.defaultLocation = defaultLocation;
    this.mobileNo = mobileNo;
    this.licenseId = licenseId;
    this.licenseLevel = licenseLevel;
    this.isActive = isActive;
  }
}

export type UserStatus = {
  id: string;
  isActive: boolean;
};

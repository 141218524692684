import { configureStore } from "@reduxjs/toolkit";
import mastersReducer from "./slices/MasterSlice";
import podsReducer from "./slices/PODSlice";
import loaderReducer from "./slices/loaderSlice";
import eventsReducer from "./slices/EventSlice";
import usersReducer from "./slices/UserSlice";
import authReducer from "./slices/AuthSlice";
import warningReducer from "./slices/WarningSlice";
import surveysReducer from "./slices/SurveySlice";

// Configure our store combining all the state reducers.
export const store = configureStore({
  reducer: {
    auth: authReducer,
    masters: mastersReducer,
    pods: podsReducer,
    events: eventsReducer,
    users: usersReducer,
    loader: loaderReducer,
    warning: warningReducer,
    surveys: surveysReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import React, { useState, useEffect } from "react";

import { Column } from "../../../ts/types";
import classes from "./ScreeningQuestions.module.css";
import ScreeningQuestion from "../../../ts/models/ScreeningQuestion";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import AddScreeningQuestion from "./AddScreeningQuestion";
import Table from "../../../components/CustTable/Table";
import TableContainer from "../../../components/CustTable/TableContainer";
import PanelContainer from "../../../components/Containers/PanelContainer";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import SearchBar from "../../../components/SearchBar/SearchBar";
import ActionContainer from "../../../components/CustTable/ActionContainer";
import LinkButton from "../../../components/Buttons/LinkButton";
import PanelActionContainer from "../../../components/Containers/PanelActionContainer";
import addIcon from "../../../assets/icons/add-circle-white.svg";
import {
  fetchScreeningQuestions,
  fetchScreeningQuestionsCount,
  updateScreeningQuestion,
} from "../../../services/masters";

const pageSize = 20;

const ScreeningQuestions = () => {
  const [show, setShow] = useState(false);
  const [isEditQues, setIsEditQues] = useState(false);
  const [toBeEdited, setToBeEdited] = useState<ScreeningQuestion>(
    new ScreeningQuestion("", "", [])
  );
  const [searchText, setSearchText] = useState("");
  const [screeningQuestionData, setScreeningQuestionData] = useState<
    ScreeningQuestion[]
  >([]);
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [skipCount, setSkipCount] = useState(true);

  useEffect(() => {
    fetchScreeningQuestionData();
  }, [currPageNo]);

  useEffect(() => {
    if (currPageNo === 1) {
      // Preventing first time auto api call because it is already called from currPageNo useEffect
      if (skipCount) {
        setSkipCount(false);
      } else {
        fetchScreeningQuestionData();
      }
    } else {
      // We need to go to page no. 1 if search text change, as well as it will fetch data
      setCurrPageNo(1);
    }
  }, [searchText]);

  const fetchScreeningQuestionData = async () => {
    const filter = {
      limit: pageSize,
      offset: (currPageNo - 1) * pageSize,
      where: {
        $or: [
          {
            code: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },

          {
            question: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
        ],
      },
    };
    try {
      const [screeningQuestionRes, countRes] = await Promise.all([
        fetchScreeningQuestions(filter),
        fetchScreeningQuestionsCount(filter.where),
      ]);
      if (screeningQuestionRes?.status === 200) {
        setScreeningQuestionData(screeningQuestionRes.data);
      }
      if (countRes?.status === 200) {
        const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
        setTotalPages(noOfPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ScreeningQuestionTableColumn: Column<ScreeningQuestion>[] = [
    {
      header: "ID",
      accessor: (e, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "10%",
    },
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "15%",
    },
    {
      header: "QUESTION",
      accessor: (e) => e.question,
    },
    {
      header: "OPTIONS",
      accessor: (e) => (
        <div>
          {e.options.map((item, idx) => (
            <div key={idx}>
              {`${idx + 1}. `}
              {`${item.text} ${item.showReason ? "(with reason)" : ""}`}
            </div>
          ))}
        </div>
      ),
      width: "20%",
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => {
              editHandler(e);
            }}
            label='Edit'
            className={classes.editAction}
          />
          <LinkButton
            onClick={() => updateStatusHandler(e.id, !e.isActive)}
            label={e.isActive ? "De-activate" : "Activate"}
          />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];

  const updateStatusHandler = (id: string, isActive: boolean) => {
    updateScreeningQuestion({ id, isActive }).then((res) => {
      if (res.status === 200) {
        fetchScreeningQuestionData();
      }
    });
  };

  const addShowHandle = () => {
    setShow(true);
    setToBeEdited(new ScreeningQuestion("", "", []));
  };

  const editHandler = (data: ScreeningQuestion) => {
    setShow(true);
    setIsEditQues(true);
    setToBeEdited(data);
  };

  const closeHandler = (isUpdate: boolean) => {
    setShow(false);
    setIsEditQues(false);
    if (isUpdate) fetchScreeningQuestionData();
  };

  return (
    <>
      {show && (
        <AddScreeningQuestion
          onClose={closeHandler}
          isEdit={isEditQues}
          prevData={toBeEdited}
        />
      )}
      <PanelContainer name='Screening Questions' backBtn>
        <PanelActionContainer
          leftChildren={
            <SearchBar
              debounce
              onChange={(text) => {
                setSearchText(text);
              }}
            />
          }
          rightChildren={
            <PrimaryButton
              name='Add New'
              onClick={addShowHandle}
              disabled={false}
              icon={<img src={addIcon} alt='+' />}
            />
          }
        />
        <PanelInnerContainer>
          <TableContainer>
            <Table
              columns={ScreeningQuestionTableColumn}
              data={screeningQuestionData}
              isPaginated
              pageNo={currPageNo}
              totalPages={totalPages}
              onPrevClick={() => setCurrPageNo((state) => state - 1)}
              onNextClick={() => setCurrPageNo((state) => state + 1)}
            />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default ScreeningQuestions;

import { encodeQueryFilter } from "./utils";
import axios from "../axios";
import Order from "../ts/models/Order";

const ORDERS_URL = "/api/orders";
const ENCOUNTERS_URL = "/api/encounters";
const DOWNLOAD_BILLING_CSV_URL = ENCOUNTERS_URL + "/billing-report";
const POD_BILLING_STATUS = ENCOUNTERS_URL + "/pod-billing-status";
const STATS_URL = "/stats";

export function fetchOrders(filter?: any) {
  let url = ORDERS_URL;
  if (filter) {
    url += `?filter=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function fetchOrdersCount(filter?: any) {
  let url = `${ORDERS_URL}/count`;
  if (filter) {
    url += `?where=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function fetchEncounters(filter?: any) {
  let url = ENCOUNTERS_URL;
  if (filter) {
    url += `?filter=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function fetchEncountersCount(filter?: any) {
  let url = `${ENCOUNTERS_URL}/count`;
  if (filter) {
    url += `?where=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function fetchEventWiseOrderCount(eventIds: string[]) {
  let url = `${ORDERS_URL}/eventwisecount?eventIds=${eventIds.join(",")}`;
  return axios({
    url,
    method: "get",
  });
}

export function fetchPodBillingData(
  podIds: string[],
  startDate: number,
  endDate: number
) {
  let url = `${POD_BILLING_STATUS}?podIds=${podIds.join(
    ","
  )}&startDate=${startDate}&endDate=${endDate}`;
  return axios({
    url,
    method: "get",
  });
}

export function downloadBillingCSV(
  eventIds: string[],
  sendForBilling: boolean
) {
  return axios({
    url: `${DOWNLOAD_BILLING_CSV_URL}?eventIds=${eventIds.join(
      ","
    )}&sendForBilling=${sendForBilling}`,
    method: "get",
    responseType: "blob",
  });
}

export function fetchAgencyReport(filter: any) {
  let url = `${ENCOUNTERS_URL}/agency-report?filter=${encodeQueryFilter(
    filter
  )}`;
  return axios({
    url,
    method: "get",
  });
}

export function fetchAgencyReportCount(filter: any) {
  let url = `${ENCOUNTERS_URL}/agency-report-count?filter=${encodeQueryFilter(
    filter
  )}`;
  return axios({
    url,
    method: "get",
  });
}

export function downloadAgencyReport(filter: any, sendToAgency: boolean) {
  let url = `${ENCOUNTERS_URL}/download-agency-report?filter=${encodeQueryFilter(
    filter
  )}&sendToAgency=${sendToAgency}`;
  return axios({
    url,
    method: "get",
    responseType: "blob",
  });
}

export function editOrder(data: Order) {
  return axios({
    url: `${ORDERS_URL}/${data.id}`,
    method: "put",
    data,
  });
}

export function fetchCheckins(filter: any) {
  let url = `${ORDERS_URL}/checkin-report?filter=${encodeQueryFilter(filter)}`;
  return axios({
    url,
    method: "get",
  });
}

export function fetchCheckinsCount(filter: any) {
  let url = `${ORDERS_URL}/checkin-report-count?filter=${encodeQueryFilter(
    filter
  )}`;
  return axios({
    url,
    method: "get",
  });
}

export function fetchPatientReport(filter: any) {
  let url = `${ORDERS_URL}/patient-report?filter=${encodeQueryFilter(filter)}`;
  return axios({
    url,
    method: "get",
  });
}

export function fetchPatientReportCount(filter: any) {
  let url = `${ORDERS_URL}/patient-report-count?filter=${encodeQueryFilter(
    filter
  )}`;
  return axios({
    url,
    method: "get",
  });
}

export function downloadPatientReport(filter: any) {
  let url = `${ORDERS_URL}/download-patient-report?filter=${encodeQueryFilter(
    filter
  )}`;
  return axios({
    url,
    method: "get",
    responseType: "blob",
  });
}

export function fetchEncounterStats(id: string) {
  let url = ENCOUNTERS_URL + STATS_URL + `?eventId=${id}`;
  return axios({
    url,
    method: "get",
  });
}

export class SelectedService {
  serviceId: string;
  productIds: string[];
  constructor(serviceId: string, productIds: string[]) {
    this.serviceId = serviceId;
    this.productIds = productIds;
  }
}

export default class Event {
  id!: string;
  name: string;
  podId: string;
  code: string;
  visibleToCustomer: boolean;
  startTime: number;
  endTime: number;
  timezoneOffset: number;
  capacity: number;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  isHome: boolean;
  services: SelectedService[];
  medProfs: string[];
  notes: Note[];
  isActive: boolean;
  isBilled?: boolean;

  constructor(
    name = "",
    podId = "",
    code = "",
    visibleToCustomer = false,
    startTime = new Date().getTime(),
    endTime = new Date().getTime(),
    timezoneOffset = new Date().getTimezoneOffset() * -1,
    capacity = 10,
    address = "",
    city = "",
    state = "",
    zipCode = "",
    isHome = false,
    services: SelectedService[] = [],
    medProfs: string[] = [],
    notes = [],
    isActive: boolean = true,
    isBilled = false
  ) {
    this.name = name;
    this.podId = podId;
    this.code = code;
    this.visibleToCustomer = visibleToCustomer;
    this.startTime = startTime;
    this.endTime = endTime;
    this.timezoneOffset = timezoneOffset;
    this.capacity = capacity;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.isHome = isHome;
    this.services = services;
    this.medProfs = medProfs;
    this.notes = notes;
    this.isActive = isActive;
    this.isBilled = isBilled;
  }
}

export type Note = {
  userId: string;
  noteTime: number;
  noteText: string;
};

export type EventNotes = {
  id: string;
  notes: Note[];
};

export type PODEventCount = {
  podId: string;
  eventCount: number;
};

export type EventStatus = {
  id: string;
  isActive: boolean;
};

import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteSurvey,
  getSurveys,
  updateSurveyStatusData,
} from "../../store/actions/SurveyActions";
import {
  getEndOfDayTimestamp,
  getFormattedDate,
  getFormattedTime,
  getLocalDateString,
  getStartOfDayTimestamp,
} from "../../utils";
import {
  BASE_URL,
  CREATE_SURVEY_URL,
  SURVEYS_URL,
  UPDATE_SURVEY_URL,
} from "../../Routes/urls";
import { AppDispatch, RootState } from "../../store";
import { Column } from "../../ts/types";
import PanelActionContainer from "../../components/Containers/PanelActionContainer";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import classes from "./Surveys.module.css";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import PanelContainer from "../../components/Containers/PanelContainer";
import SearchBar from "../../components/SearchBar/SearchBar";
import Input from "../../components/Inputs/Input";
import SelectPOD from "../../components/SelectPOD/SelectPOD";
import TableContainer from "../../components/CustTable/TableContainer";
import Table from "../../components/CustTable/Table";
import Status from "../../components/Status/Status";
import Card from "../../components/Card/Card";
import KebabMenu from "../../components/KebabMenu/KebabMenu";
import addIcon from "../../assets/icons/add-circle-white.svg";
import gridIcon from "../../assets/icons/grid.svg";
import listIcon from "../../assets/icons/list-dots.svg";
import activeGridIcon from "../../assets/icons/grid-active.svg";
import activeListIcon from "../../assets/icons/list-dots-active.svg";
import SurveyCard from "./SurveyCard";
import Survey from "../../ts/models/Survey";
import { surveysActions } from "../../store/slices/SurveySlice";
import { UserRole } from "../../ts/enums";
import Confirm from "../../components/Confirm/Confirm";

const pageSize = 20;

export const Surveys = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();

  // Storing Surveys and filter to redux to prevent filter's and survey's data loss while returning from create new survey page
  const {
    surveys,
    filter: { podId, searchText, startDate, endDate },
    currPageNo,
    count,
  } = useSelector((state: RootState) => state.surveys);
  const userData = useSelector((state: RootState) => state.users.currUserData);
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  const [gridView, setGridView] = useState(
    localStorage.getItem("isGridView") === "true"
  );
  const [state, setState] = useState(location.state || {});
  const [showConfirm, setShowConfirm] = useState({
    show: false,
    surveyId: "",
  });

  const totalPages = Math.ceil(Number(count) / pageSize);

  const getSurveysHandler = async (updatedPageNo: number) => {
    if (podId) {
      const filter: any = {
        limit: pageSize,
        offset: (updatedPageNo - 1) * pageSize,
        where: {
          podId,
          name: searchText,
          startDate: startDate,
          endDate: endDate,
        },
      };
      dispatch(getSurveys(filter, updatedPageNo));
    }
  };

  const createSurveyHandler = () => {
    dispatch(surveysActions.newSurveyToBeEdited());
    navigate(
      BASE_URL + `/${resourceName}` + `/${SURVEYS_URL}/` + CREATE_SURVEY_URL
    );
  };

  useEffect(() => {
    if (state.addOrUpdateCanceled) {
      setState({});
    } else {
      getSurveysHandler(1);
    }
  }, [podId]);

  const filterChangeHandler = (filter: any) => {
    dispatch(surveysActions.setFilter(filter));
  };

  const getOptions = (survey: Survey) => {
    const options = [
      { name: "Edit", onClick: () => editSurveyHandler(survey) },
      {
        name: survey.isActive ? "De-activate" : "Activate",
        onClick: () => updateStatusHandler(survey.id, !survey.isActive),
      },
    ];

    if (
      userData.role === UserRole.Admin ||
      userData.role === UserRole.SuperAdmin
    ) {
      options.push({
        name: "Delete",
        onClick: () => deleteSurveyHandler(survey),
      });
    }
    return options;
  };

  const surveysTableColumn: Column<Survey>[] = [
    {
      header: "ID",
      accessor: (e, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "10%",
    },
    {
      header: "NAME",
      accessor: (e) => e.name,
    },
    {
      header: "STATUS",
      accessor: (e) => {
        const isUpcoming = e.startTime > Date.now();
        const isLive = e.startTime < Date.now() && e.endTime > Date.now();
        return (
          <Status
            status={isUpcoming ? "upcoming" : isLive ? "active" : "closed"}
            name={isUpcoming ? "Upcoming" : isLive ? "Live" : "Closed"}
          />
        );
      },
    },
    {
      header: "START DATE - END DATE",
      accessor: (e) => {
        return (
          <div>
            <span style={{ display: "block" }}>{`${getFormattedDate(
              e.startTime
            )} - ${getFormattedDate(e.endTime)}`}</span>
            <span>{`${getFormattedTime(e.startTime)} - ${getFormattedTime(
              e.endTime
            )}`}</span>
          </div>
        );
      },
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
    },
    {
      header: "",
      accessor: (e) => <KebabMenu options={getOptions(e)} />,
    },
  ];

  const updateStatusHandler = (id: string, isActive: boolean) => {
    dispatch(updateSurveyStatusData({ id, isActive }));
  };

  const viewChangeHandler = (isGridView: boolean) => {
    localStorage.setItem("isGridView", `${isGridView}`);
    setGridView(isGridView);
  };

  const editSurveyHandler = (survey: Survey) => {
    dispatch(surveysActions.setSurveytToBeEdited(survey));
    navigate(
      BASE_URL + `/${resourceName}` + `/${SURVEYS_URL}/` + UPDATE_SURVEY_URL
    );
  };

  const confirmHandler = () => {
    dispatch(deleteSurvey(showConfirm.surveyId));
    setShowConfirm({ show: false, surveyId: "" });
  };

  const deleteSurveyHandler = (survey: Survey) => {
    setShowConfirm({
      show: true,
      surveyId: survey.id,
    });
  };

  return (
    <>
      {showConfirm.show && (
        <Confirm
          message='Are you sure you want to delete this survey?'
          onClose={() => setShowConfirm({ show: false, surveyId: "" })}
          onConfirmation={confirmHandler}
        />
      )}
      <PanelContainer name='Surveys'>
        <PanelActionContainer
          leftChildren={
            <SelectPOD
              value={podId || ""}
              onChange={(e) => filterChangeHandler({ podId: e.target.value })}
            />
          }
          rightChildren={
            <PrimaryButton
              name='Create New Survey'
              onClick={createSurveyHandler}
              className={classes.actionBtn}
              icon={<img src={addIcon} alt='' />}
            />
          }
        />
        <PanelInnerContainer>
          <Card>
            <div className={classes.filterContainer}>
              <div className={classes.filters}>
                <Input
                  type='date'
                  name='Start Date'
                  className={classes.filterDates}
                  value={getLocalDateString(startDate)}
                  onChange={(e) => {
                    const filter: any = {
                      startDate: getStartOfDayTimestamp(e.target.value),
                    };
                    if (filter.startDate > endDate) {
                      filter.endDate = getEndOfDayTimestamp(e.target.value);
                    }
                    filterChangeHandler(filter);
                  }}
                />
                <Input
                  type='date'
                  name='End Date'
                  className={classes.filterDates}
                  value={getLocalDateString(endDate)}
                  onChange={(e) =>
                    filterChangeHandler({
                      endDate: getEndOfDayTimestamp(e.target.value),
                    })
                  }
                  min={getLocalDateString(startDate)}
                />
                <div className={classes.searchContainer}>
                  <SearchBar
                    placeholder='Search by survey name'
                    onChange={(searchText) =>
                      filterChangeHandler({ searchText })
                    }
                    greyBackground
                  />
                </div>
                <PrimaryButton
                  name='Filter'
                  onClick={() => getSurveysHandler(1)}
                  className={classes.filterBtn}
                />
              </div>
              <div className={classes.switchBtnContainer}>
                <img
                  src={gridView ? activeGridIcon : gridIcon}
                  onClick={() => viewChangeHandler(true)}
                  alt='Show in grid view'
                  className={classes.switchBtn}
                />
                <img
                  src={gridView ? listIcon : activeListIcon}
                  onClick={() => viewChangeHandler(false)}
                  alt='Show in list view'
                  className={classes.switchBtn}
                />
              </div>
            </div>
          </Card>
          {gridView ? (
            <div className={classes.cardContainer}>
              {surveys.map((survey, idx) => (
                <SurveyCard
                  survey={survey}
                  key={survey.id}
                  onEdit={editSurveyHandler}
                  onStatusUpdate={updateStatusHandler}
                  onDelete={deleteSurveyHandler}
                />
              ))}
            </div>
          ) : (
            <TableContainer>
              <Table
                columns={surveysTableColumn}
                data={surveys}
                isPaginated
                pageNo={currPageNo}
                totalPages={totalPages}
                onPrevClick={() => getSurveysHandler(currPageNo - 1)}
                onNextClick={() => getSurveysHandler(currPageNo + 1)}
              />
            </TableContainer>
          )}
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};
export default Surveys;

import { Location, NavigateFunction } from "react-router";
import { format } from "date-fns";
import POD from "./ts/models/POD";
import User from "./ts/models/User";
import Event from "./ts/models/Event";
import Patient from "./ts/models/Patient";
import { ActivityType } from "./ts/enums";

export const getClasses = (...classes: string[]): string => {
  return classes.join(" ");
};

export const addLocalOffsetToTimestamp = (timestamp: number) => {
  const offset = new Date(timestamp).getTimezoneOffset() * 60000;
  return timestamp + offset;
};

export const getUTCDateString = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getUTCFullYear();
  let month = (date.getUTCMonth() + 1).toString();
  let day = date.getUTCDate().toString();

  if (month.length < 2) {
    month = "0" + month;
  }

  if (day.length < 2) {
    day = "0" + day;
  }

  return `${year}-${month}-${day}`;
};

export const getTimestampFromDateValue = (value: string) => {
  // Takes a date or date time value in string format, and creates a date object from it.
  // YYYY-MM-DD
  // YYYY-MM-DDTHH:mm
  return new Date(value).getTime();
};

export const getStartOfDay = (value: string) => {
  return value + "T00:00:00";
};

export const getEndOfDay = (value: string) => {
  return value + "T23:59:59";
};

export const getStartOfDayTimestamp = (value: string) => {
  return getTimestampFromDateValue(getStartOfDay(value));
};

export const getEndOfDayTimestamp = (value: string) => {
  return getTimestampFromDateValue(getEndOfDay(value));
};

export const getLocalDateTimeString = (timestamp: number) => {
  if (isNaN(timestamp)) {
    return "";
  }
  return format(new Date(timestamp), "yyyy-MM-dd'T'HH:mm");
};

export const getLocalDateString = (timestamp: number): string => {
  if (isNaN(timestamp)) {
    return "";
  }
  return format(new Date(timestamp), "yyyy-MM-dd");
};

export const getLocalTimeString = (timestamp: number) => {
  if (isNaN(timestamp)) {
    return "";
  }
  return format(new Date(timestamp), "HH:mm");
};

export const getFormattedDate = (timestamp: number) => {
  if (isNaN(timestamp)) {
    return "";
  }
  // Jun 2nd, 2023
  return format(new Date(timestamp), "MMM do, yyyy");
};

export const getFormattedDob = (timestamp: number) => {
  return format(new Date(timestamp), "MMM/dd/yyyy");
};

export const getFormattedTime = (timestamp: number) => {
  if (isNaN(timestamp)) {
    return "";
  }
  // 10:20am
  return format(new Date(timestamp), "h:mm aaa");
};

export const getFormattedDateTime = (timestamp: number) => {
  if (isNaN(timestamp)) {
    return "";
  }
  // Jun 2nd, 2023 10:20am
  return format(new Date(timestamp), "MMM do, yyyy hh:mm aaa");
};

export const getLogDateTime = (timestamp: number) => {
  if (isNaN(timestamp)) {
    return "";
  }
  // Jun 2nd, 2023 10:20am
  return format(new Date(timestamp), "MMM dd, yyyy HH:mm");
};

export const getAge = (timestamp: number | undefined) => {
  if (timestamp) {
    // Calculate age in msecs.
    let msecs = Date.now() - timestamp;

    // Convert the difference to a date.
    let date = new Date(msecs);

    // The year of this converted date from epoch 1970 would be the age.
    let age = Math.abs(date.getUTCFullYear() - 1970);

    return age;
  }
  return "";
};

export const validateEmail = (email: string) => {
  let validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
  if (!email.match(validEmail)) {
    return false;
  }
  return true;
};

export const navigationBack = (
  location: Location,
  navigate: NavigateFunction,
  data?: any
) => {
  const path = location.pathname;
  const newPath = path.substring(0, path.lastIndexOf("/"));
  if (data) {
    navigate(newPath, { state: data });
  } else {
    navigate(newPath);
  }
};

export const getUserFullName = (user: User) => {
  let fullName = user.firstName;
  if (user.middleName) {
    fullName += " " + user.middleName;
  }
  if (user.lastName) {
    fullName += " " + user.lastName;
  }
  return fullName;
};

export const getPatientFullName = (patient: Patient) => {
  let fullName = patient.firstName;
  if (patient.middleName) {
    fullName += " " + patient.middleName;
  }
  if (patient.lastName) {
    fullName += " " + patient.lastName;
  }
  return fullName;
};

export const getEventFullAddress = (event: Event) => {
  const address = [event.address, event.city, event.state];

  let fullAddress = address.filter((element) => Boolean(element)).join(", ");
  if (event.zipCode) {
    fullAddress = `${fullAddress} ${event.zipCode}`;
  }
  return fullAddress;
};

export const getPODFullAddress = (pod: POD) => {
  const address = [pod.address, pod.city, pod.state];

  let fullAddress = address.filter((element) => Boolean(element)).join(", ");
  if (pod.zipCode) {
    fullAddress = `${fullAddress} ${pod.zipCode}`;
  }
  return fullAddress;
};

export const copyTextToClipboard = (textToCopy: string) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(textToCopy);
  } else {
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((resolve, reject) => {
      document.execCommand("copy") ? resolve() : reject();
      textArea.remove();
    });
  }
};

export const hasPermission = (permissions: string[], checkFor: string) => {
  return permissions.includes(checkFor);
};

export const downloadFile = (response: any, fileName: string) => {
  let a = document.createElement("a");
  let url = window.URL.createObjectURL(response.data);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  return response;
};

const getDate = (date: Date) => {
  return new Date(date.toDateString());
};

export const getDaysCount = (date1: number, date2: number) => {
  // The number of milliseconds in one day.
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in days.
  const differenceMs = Math.abs(
    getDate(new Date(date1)).getTime() - getDate(new Date(date2)).getTime()
  );

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY) + 1;
};

export const getJsonBlob = (obj: any) => {
  return new Blob([JSON.stringify(obj)], {
    type: "application/json",
  });
};

export const getStates = [
  { stateName: "Alabama", alphaCode: "AL" },
  { stateName: "Alaska", alphaCode: "AK" },
  { stateName: "Arizona", alphaCode: "AZ" },
  { stateName: "Arkansas", alphaCode: "AR" },
  { stateName: "California", alphaCode: "CA" },
  { stateName: "Colorado", alphaCode: "CO" },
  { stateName: "Connecticut", alphaCode: "CT" },
  { stateName: "Delaware", alphaCode: "DE" },
  { stateName: "Florida", alphaCode: "FL" },
  { stateName: "Georgia", alphaCode: "GA" },
  { stateName: "Hawaii", alphaCode: "HI" },
  { stateName: "Idaho", alphaCode: "ID" },
  { stateName: "Illinois", alphaCode: "IL" },
  { stateName: "Indiana", alphaCode: "IN" },
  { stateName: "Iowa", alphaCode: "IA" },
  { stateName: "Kansas", alphaCode: "KS" },
  { stateName: "Kentucky", alphaCode: "KY" },
  { stateName: "Louisiana", alphaCode: "LA" },
  { stateName: "Maine", alphaCode: "ME" },
  { stateName: "Maryland", alphaCode: "MD" },
  { stateName: "Massachusetts", alphaCode: "MA" },
  { stateName: "Michigan", alphaCode: "MI" },
  { stateName: "Minnesota", alphaCode: "MN" },
  { stateName: "Mississippi", alphaCode: "MS" },
  { stateName: "Missouri", alphaCode: "MO" },
  { stateName: "Montana", alphaCode: "MT" },
  { stateName: "Nebraska", alphaCode: "NE" },
  { stateName: "Nevada", alphaCode: "NV" },
  { stateName: "New Hampshire", alphaCode: "NH" },
  { stateName: "New Jersey", alphaCode: "NJ" },
  { stateName: "New Mexico", alphaCode: "NM" },
  { stateName: "New York", alphaCode: "NY" },
  { stateName: "North Carolina", alphaCode: "NC" },
  { stateName: "North Dakota", alphaCode: "ND" },
  { stateName: "Ohio", alphaCode: "OH" },
  { stateName: "Oklahoma", alphaCode: "OK" },
  { stateName: "Oregon", alphaCode: "OR" },
  { stateName: "Pennsylvania", alphaCode: "PA" },
  { stateName: "Rhode Island", alphaCode: "RI" },
  { stateName: "South Carolina", alphaCode: "SC" },
  { stateName: "South Dakota", alphaCode: "SD" },
  { stateName: "Tennessee", alphaCode: "TN" },
  { stateName: "Texas", alphaCode: "TX" },
  { stateName: "Utah", alphaCode: "UT" },
  { stateName: "Vermont", alphaCode: "VT" },
  { stateName: "Virginia", alphaCode: "VA" },
  { stateName: "Washington", alphaCode: "WA" },
  { stateName: "West Virginia", alphaCode: "WV" },
  { stateName: "Wisconsin", alphaCode: "WI" },
  { stateName: "Wyoming", alphaCode: "WY" },
];

export const getActivityType = [
  { label: "Login", value: ActivityType.Login },
  { label: "Logout", value: ActivityType.Logout },
  { label: "Add Master", value: ActivityType.AddMaster },
  { label: "Upload Masters", value: ActivityType.UploadMasters },
  { label: "Update Master", value: ActivityType.UpdateMaster },
  { label: "Add User", value: ActivityType.AddUser },
  { label: "Upload Users", value: ActivityType.UploadUsers },
  { label: "Update User", value: ActivityType.UpdateUser },
  { label: "Add Pod", value: ActivityType.AddPod },
  { label: "Update Pod", value: ActivityType.UpdatePod },
  { label: "Add Event", value: ActivityType.AddEvent },
  { label: "Update Event", value: ActivityType.UpdateEvent },
  { label: "CheckIn Patient", value: ActivityType.CheckInPatient },
  { label: "Administer Patient", value: ActivityType.AdministerPatient },
  { label: "Observe Patient", value: ActivityType.ObservePatient },
  { label: "Discharge Patient", value: ActivityType.DischargePatient },
  { label: "Fetch CheckIn List", value: ActivityType.FetchCheckInList },
  { label: "Update Patient", value: ActivityType.UpdatePatient },
  { label: "View Event Report", value: ActivityType.ViewEventReport },
  { label: "Download Event Report", value: ActivityType.DownloadEventReport },
  { label: "View Patient Report", value: ActivityType.ViewPatientReport },
  {
    label: "Download Patient Report",
    value: ActivityType.DownloadPatientReport,
  },
  {
    label: "Download Billing Report",
    value: ActivityType.DownloadBillingReport,
  },
  { label: "View Agency Report", value: ActivityType.ViewAgencyReport },
  { label: "Download Agency Report", value: ActivityType.DownloadAgencyReport },
];

export const getBloodGlucoseUnit = (unit: string) => {
  if (unit === "MMOL_PER_LITRE") {
    return "mmol/L";
  }

  if (unit === "MG_PER_DECI_LITRE") {
    return "mg/dL";
  }

  return "";
};

export const getTemperatureUnit = (unit: string) => {
  if (unit === "CELSIUS") {
    return "°C";
  }

  if (unit === "FAHRENHEIT") {
    return "°F";
  }

  return "";
};

export const getExtensionFromUri = (fileUri: string) => {
  return fileUri.split(".").pop();
};

import { AppDispatch } from "..";
import {
  addNewSurvey,
  deleteAnSurvey,
  editAnSurvey,
  fetchSurveys,
  fetchSurveysCount,
  updateSurveyStatus,
} from "../../services/surveys";
import Survey, { SurveyStatus } from "../../ts/models/Survey";
import { surveysActions } from "../slices/SurveySlice";
import { warningActions } from "../slices/WarningSlice";

// Takes page no to be updated at currPageNo state
export const getSurveys = (filter?: any, updatedPageNo?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const [surveyRes, surveyCountRes] = await Promise.all([
        fetchSurveys(filter),
        fetchSurveysCount(filter.where),
      ]);
      if (surveyRes.status === 200 && surveyCountRes.status === 200) {
        dispatch(
          surveysActions.getSurveys({
            surveys: surveyRes.data,
            count: surveyCountRes.data?.count,
            currPageNo: updatedPageNo,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addSurvey = (data: Survey, handleBack?: () => void) => {
  return async (dispatch: AppDispatch) => {
    return addNewSurvey(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(surveysActions.addSurvey(res.data));
          handleBack?.();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const editSurvey = (data: Survey, handleBack?: () => void) => {
  return async (dispatch: AppDispatch) => {
    return editAnSurvey(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(surveysActions.editSurvey(res.data));
          handleBack?.();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateSurveyStatusData = (data: SurveyStatus) => {
  return async (dispatch: AppDispatch) => {
    updateSurveyStatus(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(surveysActions.editSurvey(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteSurvey = (surveyId: string) => {
  return async (dispatch: AppDispatch) => {
    return deleteAnSurvey(surveyId)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errorCode === "bad_request") {
            dispatch(
              warningActions.showWarning({
                message: "Failed to delete survey.",
              })
            );
          } else {
            dispatch(surveysActions.deleteSurvey(surveyId));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

import { getClasses } from "../../utils";
import classes from "./SidebarButton.module.css";

type SidebarButtonProps = {
  name: string;
  onClick: (name: string) => void;
  isActive?: boolean;
  activeIcon: string;
  icon: string;
};

const SidebarButton: React.FC<SidebarButtonProps> = ({
  name,
  onClick,
  isActive,
  activeIcon,
  icon,
}) => {
  return (
    <div
      className={getClasses(classes.main, isActive ? classes.active : "")}
      onClick={() => onClick(name)}>
      <div className={classes.iconContainer}>
        <img
          className={classes.icon}
          src={isActive ? activeIcon : icon}
          alt=''
        />
      </div>
      {name}
    </div>
  );
};

export default SidebarButton;

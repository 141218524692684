import classes from "./ActionContainer.module.css";

type Props = {
  children: JSX.Element | JSX.Element[];
};

const ActionContainer: React.FC<Props> = ({ children }) => {
  return <div className={classes.main}>{children}</div>;
};

export default ActionContainer;

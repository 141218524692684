import { useState } from "react";
import InfoQuestion from "../../../ts/models/InfoQuestion";
import classes from "./AddInfoQuestion.module.css";
import { addInfoQuestion, updateInfoQuestion } from "../../../services/masters";
import Modal, { ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import Input from "../../../components/Inputs/Input";
import LinkButton from "../../../components/Buttons/LinkButton";
import InputLabel from "../../../components/Inputs/InputLabel";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import trashIcon from "../../../assets/icons/trash.svg";
import Select from "../../../components/Inputs/Select";
import { InfoQuestionType } from "../../../ts/enums";

const options = [
  {
    name: "Option List",
    type: InfoQuestionType.OptionList,
    icon: String.fromCodePoint(0xf0ca), // fa-list-ul
  },
  {
    name: "Free Text",
    type: InfoQuestionType.FreeText,
    icon: String.fromCodePoint(0xf031), // fa-font
  },
  {
    name: "Number",
    type: InfoQuestionType.Number,
    icon: String.fromCodePoint(0xf292), // fa-hashtag
  },
  {
    name: "Date",
    type: InfoQuestionType.Date,
    icon: String.fromCodePoint(0xf073), // fa-calendar
  },
];

type Props = {
  onClose: (isUpdated: boolean) => void;
  prevData?: InfoQuestion;
  isEdit: Boolean;
};

const AddInfoQuestion: React.FC<Props> = ({ onClose, prevData, isEdit }) => {
  const [data, setData] = useState<InfoQuestion>(
    prevData ? prevData : new InfoQuestion()
  );
  const [option, setOption] = useState("");

  const addMasterHandler = async () => {
    const newMaster = new InfoQuestion(
      data.code,
      data.question,
      data.questionType,
      data.options
    );
    let res;
    try {
      if (isEdit) {
        newMaster.id = prevData!.id;
        res = await updateInfoQuestion(newMaster);
      } else {
        res = await addInfoQuestion(newMaster);
      }
      if (res.status === 200) {
        onClose(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addOptionHandler = () => {
    if (option) {
      setData((state) => ({
        ...state,
        options: [...state.options, option],
      }));
      setOption("");
    }
  };

  const isRequiredDataMissing = () => {
    if (
      !data.code?.trim() ||
      !data.question?.trim() ||
      (data.options.length === 0 &&
        data.questionType === InfoQuestionType.OptionList) ||
      !data.questionType?.trim()
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={<>{isEdit ? "Edit Info Question" : "Add Info Question"}</>}
      onClose={() => onClose(false)}
      style={{ width: "30rem" }}>
      <>
        <ModalBody>
          <div className={classes.main}>
            <Input
              name='Code'
              value={data.code}
              onChange={(e) =>
                setData((state) => ({ ...state, code: e.target.value }))
              }
              className={classes.input}
            />
            <Input
              name='Question'
              value={data.question}
              onChange={(e) =>
                setData((state) => ({
                  ...state,
                  question: e.target.value,
                }))
              }
              className={classes.input}
            />
            <Select
              label={"Question Type"}
              htmlFor='type'
              options={options}
              value={data.questionType}
              defaultOption='Select Question Type'
              nameExtractor={"name"}
              valueExtractor={"type"}
              onChange={(e) => {
                setData((prevState) => {
                  let newState = { ...prevState, questionType: e.target.value };
                  if (e.target.value !== InfoQuestionType.OptionList) {
                    newState.options = [];
                  }
                  return newState;
                });
              }}
            />
            <div className={classes.addOptionContainer}>
              <div className={classes.optionInputContainer}>
                <Input
                  name='Add Option'
                  value={option}
                  onChange={(e) => {
                    setOption(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addOptionHandler();
                    }
                  }}
                  disabled={data.questionType !== InfoQuestionType.OptionList}
                />
              </div>
              <div>
                <LinkButton
                  onClick={addOptionHandler}
                  label='Add'
                  className={classes.addOptionBtn}
                  disabled={data.questionType !== InfoQuestionType.OptionList}
                />
              </div>
            </div>
            <div className={classes.optionsContainer}>
              <InputLabel label='Options' />
              <div className={classes.options}>
                {data.options.map((option, index) => (
                  <div key={index} className={classes.option}>
                    <span>
                      {`${index + 1}. `}
                      {option}
                    </span>
                    <img
                      src={trashIcon}
                      alt='delete'
                      onClick={() => {
                        setData((state) => ({
                          ...state,
                          options: state.options.filter(
                            (_, idx) => idx !== index
                          ),
                        }));
                      }}
                      className={classes.trashIcon}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton
              className={classes.actionBtn}
              name={isEdit ? "Update" : "Add"}
              onClick={addMasterHandler}
              style={{ marginRight: "1rem" }}
              disabled={isRequiredDataMissing()}
            />
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={() => onClose(false)}
              transparent
            />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default AddInfoQuestion;

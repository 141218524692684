import { getClasses } from "../../utils";
import classes from "./PanelInnerContainer.module.css";

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

const PanelInnerContainer: React.FC<Props> = ({ children, className = "" }) => {
  return <div className={getClasses(classes.main, className)}>{children}</div>;
};

export default PanelInnerContainer;

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { addNewUser, updateUserData } from "../../store/actions/UserActions";
import { AppDispatch, RootState } from "../../store";
import { UserRole } from "../../ts/enums";
import { navigationBack, validateEmail } from "../../utils";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import classes from "./CreateUser.module.css";
import User from "../../ts/models/User";
import Input from "../../components/Inputs/Input";
import PanelContainer from "../../components/Containers/PanelContainer";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import InputLabel from "../../components/Inputs/InputLabel";
import SelectButton from "../../components/Buttons/SelectButton";
import FormBox from "../../components/Box/FormBox";
import helpIcon from "../../assets/icons/help-info.svg";
import LinkButton from "../../components/Buttons/LinkButton";
import HelpModal from "../../components/Help/HelpModal";

const roles = [
  { id: UserRole.Admin },
  { id: UserRole.Clinical },
  { id: UserRole.NonClinical },
  { id: UserRole.NonClinicalPlus },
];

type props = {};

const getInitialFieldErrors = () => {
  return {
    firstName: false,
    lastName: false,
    email: false,
    mobileNo: false,
    role: false,
  };
};

const CreateUser: React.FC<props> = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userToBeEdited = useSelector(
    (state: RootState) => state.users.userToBeEdited
  );
  const services = useSelector((state: RootState) => state.masters.services);

  const [data, setData] = useState<User>(userToBeEdited);
  const [fieldErrors, setFieldErrors] = useState(getInitialFieldErrors());
  const [showHelpModal, setShowHelpModal] = useState(false);

  const activeServices = services.filter((service) => {
    return service.isActive;
  });

  const handleBack = (addOrUpdateCanceled: boolean) => {
    navigationBack(location, navigate, { addOrUpdateCanceled });
  };

  const isFieldsValid = (): boolean => {
    const fieldErrors = getInitialFieldErrors();

    if (!data.firstName) {
      fieldErrors.firstName = true;
    }
    if (!data.lastName) {
      fieldErrors.lastName = true;
    }
    if (!data.email || !validateEmail(data.email)) {
      fieldErrors.email = true;
    }
    if (!data.mobileNo || data.mobileNo.length !== 10) {
      fieldErrors.mobileNo = true;
    }
    if (!data.role) {
      fieldErrors.role = true;
    }

    setFieldErrors(fieldErrors);

    return Object.values(fieldErrors).every((value) => value === false);
  };

  const clearFieldErrors = (key: keyof typeof fieldErrors) => {
    if (fieldErrors[key])
      setFieldErrors((prevState) => {
        const newState = { ...prevState };
        newState[key] = false;
        return newState;
      });
  };

  const addOrEditUserHandler = async (isEdit = false) => {
    if (!isFieldsValid()) {
      return;
    }

    if (isEdit) {
      dispatch(updateUserData(data, () => handleBack(false)));
    } else {
      dispatch(addNewUser(data, () => handleBack(false)));
    }
  };

  return (
    <>
      {showHelpModal && (
        <HelpModal
          title='Create User'
          content={`/api/storage/cimpar/resources/help/create-user-help.html`}
          onClose={() => setShowHelpModal(false)}
        />
      )}
      <PanelContainer
        name={`${userToBeEdited.id ? "Update" : "Create New"} User`}
        backBtn>
        <div className={classes.helpContainer}>
          <LinkButton
            label={"Know About How To Create User"}
            icon={<img src={helpIcon} alt='' />}
            onClick={() => setShowHelpModal(true)}
            className={classes.helpBtn}
          />
        </div>
        <PanelInnerContainer>
          <FormBox name='Basic Details'>
            <div className={classes.boxContainer}>
              <div>
                <Input
                  name='First Name'
                  value={data.firstName}
                  onChange={(e) => {
                    setData((prevState) => {
                      const newState = { ...prevState };
                      newState.firstName = e.target.value;
                      return newState;
                    });
                    clearFieldErrors("firstName");
                  }}
                  isInvalid={fieldErrors.firstName}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='Middle Name'
                  value={data.middleName}
                  onChange={(e) => {
                    setData((prevState) => {
                      const newState = { ...prevState };
                      newState.middleName = e.target.value;
                      return newState;
                    });
                  }}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='Last Name'
                  value={data.lastName}
                  onChange={(e) => {
                    setData((prevState) => {
                      const newState = { ...prevState };
                      newState.lastName = e.target.value;
                      return newState;
                    });
                    clearFieldErrors("lastName");
                  }}
                  isInvalid={fieldErrors.lastName}
                  className={classes.input}
                />
              </div>
              <div>
                <Input
                  name='Email'
                  value={data.email}
                  onChange={(e) => {
                    setData((prevState) => {
                      const newState = { ...prevState };
                      newState.email = e.target.value;
                      return newState;
                    });
                    clearFieldErrors("email");
                  }}
                  isInvalid={fieldErrors.email}
                  invalidText='Invalid email'
                  className={classes.input}
                  disabled={!!data.id}
                />
              </div>
              <Input
                name='Default Location'
                value={data.defaultLocation}
                onChange={(e) => {
                  setData((prevState) => {
                    const newState = { ...prevState };
                    newState.defaultLocation = e.target.value;
                    return newState;
                  });
                }}
                className={classes.input}
              />
              <Input
                name='State License ID'
                value={data.licenseId}
                onChange={(e) => {
                  setData((prevState) => {
                    const newState = { ...prevState };
                    newState.licenseId = e.target.value;
                    return newState;
                  });
                }}
                className={classes.input}
              />
              <Input
                name='State Certificate License Level'
                value={data.licenseLevel}
                onChange={(e) => {
                  setData((prevState) => {
                    const newState = { ...prevState };
                    newState.licenseLevel = e.target.value;
                    return newState;
                  });
                }}
                className={classes.input}
              />
            </div>
            <InputLabel
              className={classes.label}
              label='Select Medical Service'
            />
            <div className={classes.servicesContainer}>
              {activeServices.map((service, idx) => (
                <SelectButton
                  key={idx}
                  name={service.name}
                  onClick={() => {
                    setData((state) => {
                      const prevServiceType = [...state.serviceTypes];
                      let newServiceType: string[] = [...prevServiceType];
                      if (prevServiceType.includes(service.id)) {
                        newServiceType = prevServiceType.filter(
                          (serviceId) => serviceId !== service.id
                        );
                      } else {
                        newServiceType.push(service.id);
                      }
                      return {
                        ...state,
                        serviceTypes: newServiceType,
                      };
                    });
                  }}
                  active={data.serviceTypes.includes(service.id)}
                />
              ))}
            </div>
            <InputLabel
              className={classes.label}
              label='Role of the user'
              isInvalid={fieldErrors.role}
              invalidText='This field is required'
            />
            <div className={classes.rolesContainer}>
              {roles.map((item, idx) => (
                <SelectButton
                  key={idx}
                  name={item.id}
                  onClick={() => {
                    setData((prevState) => {
                      const newState = { ...prevState };
                      newState.role = item.id;
                      return newState;
                    });
                    clearFieldErrors("role");
                  }}
                  active={item.id === data.role}
                />
              ))}
            </div>
          </FormBox>
          <FormBox name='Contact Information'>
            <div className={classes.boxContainer}>
              <Input
                name='Mobile Phone'
                type='number'
                value={data.mobileNo}
                onChange={(e) => {
                  setData((prevState) => {
                    const newState = { ...prevState };
                    newState.mobileNo = e.target.value;
                    return newState;
                  });
                  clearFieldErrors("mobileNo");
                }}
                isInvalid={fieldErrors.mobileNo}
                invalidText='Must be 10 digits'
                className={classes.input}
              />
            </div>
          </FormBox>
          <div className={classes.actions}>
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={() => handleBack(true)}
              transparent
              style={{ marginRight: "1rem" }}
            />
            <PrimaryButton
              className={classes.actionBtn}
              name={userToBeEdited.id ? "Update User" : "Create User"}
              onClick={() => addOrEditUserHandler(!!userToBeEdited.id)}
              style={{ marginRight: "1rem" }}
            />
          </div>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default CreateUser;

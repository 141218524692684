import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Column, CheckinReportData, PatientReportData } from "../../ts/types";
import {
  fetchCheckins,
  fetchCheckinsCount,
  fetchOrders,
} from "../../services/orders";
import { fetchPatient } from "../../services/patients";
import { fetchEvents } from "../../services/events";
import { RootState } from "../../store";
import MultiSelect, {
  MultiSelectOption,
} from "../../components/Inputs/MultiSelect";
import { getPatientFullName } from "../../utils";
import PanelInnerContainer from "../../components/Containers/PanelInnerContainer";
import MultiStatusView from "../../components/Status/MultiStatusView";
import PanelContainer from "../../components/Containers/PanelContainer";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SearchBar from "../../components/SearchBar/SearchBar";
import classes from "./CheckInList.module.css";
import Table from "../../components/CustTable/Table";
import Order from "../../ts/models/Order";
import Patient from "../../ts/models/Patient";
import Card from "../../components/Card/Card";
import Event from "../../ts/models/Event";
import ActionContainer from "../../components/CustTable/ActionContainer";
import LinkButton from "../../components/Buttons/LinkButton";
import EditPatient from "./EditPatient";
import TableContainer from "../../components/CustTable/TableContainer";
import Select from "../../components/Inputs/Select";

const pageSize = 20;

const CheckInList = () => {
  const { services } = useSelector((state: RootState) => state.masters);
  const pods = useSelector((state: RootState) => state.pods.pods);

  const [podId, setPodId] = useState("");
  const [eventIds, setEventIds] = useState<string[]>([]);
  const [selectedEvents, setSelectedEvents] = useState<MultiSelectOption[]>([]);
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [checkins, setCheckins] = useState<CheckinReportData[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editPatientData, setEditPatientData] = useState<PatientReportData>({
    patient: new Patient(),
    order: new Order(),
    encounter: {},
  });

  const eventOptions = events.map((event) => ({
    value: event.id,
    label: event.name,
  }));

  useEffect(() => {
    if (podId) {
      const filter = {
        where: {
          podId,
        },
      };
      const getEvents = async () => {
        try {
          const res = await fetchEvents(filter);
          if (res.status === 200) {
            setEvents(res.data);
          }
        } catch (err) {
          console.log(err);
        }
      };
      getEvents();
      setCheckins([]);
      setSelectedEvents([]);
    }
  }, [podId]);

  useEffect(() => {
    if (eventIds.length) filterHandler();
  }, [currPageNo]);

  const filterHandler = () => {
    setTotalPages(0);

    const getCheckins = async () => {
      const eventIdsCopy = [...eventIds];
      if (eventIdsCopy[0] === "*") {
        eventIdsCopy.splice(0, 1);
      }
      const filter: any = {
        eventIds: eventIdsCopy,
      };
      if (searchText.trim()) {
        filter.patientName = searchText.trim();
      }

      try {
        // Fetch the checkins.
        const [checkinRes, checkinCountRes] = await Promise.all([
          fetchCheckins({
            ...filter,
            offset: (currPageNo - 1) * pageSize,
            count: pageSize,
          }),
          fetchCheckinsCount(filter),
        ]);
        if (checkinCountRes.status === 200) {
          const noOfPages = Math.ceil(
            Number(checkinCountRes.data.count) / pageSize
          );
          setTotalPages(noOfPages);
        }
        if (checkinRes.status === 200) {
          setCheckins(checkinRes.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getCheckins();
  };

  const getServicesAvailed = (serviceIds: string[]) => {
    const serviceName = serviceIds.map((serviceId: string) => {
      const service = services.find((service) => service.id === serviceId);
      if (service) {
        return service.name;
      }
      return "";
    });
    return <MultiStatusView names={serviceName} maxCount={2} />;
  };

  const getEventName = (eventId?: string) => {
    return events.find((event) => event.id === eventId)?.name || "";
  };

  const checkInListTableColumn: Column<CheckinReportData>[] = [
    {
      header: "ID",
      accessor: (_, idx) => (currPageNo - 1) * pageSize + idx + 1,
      width: "10%",
    },
    {
      header: "PATIENT NAME",
      accessor: (e) => e.patientName,
    },
    {
      header: "ORDER CODE",
      accessor: (e) => e.orderCode,
    },
    {
      header: "STATUS",
      accessor: (e) =>
        e.orderStatus
          .split("_")
          .map(
            (string) =>
              string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
          )
          .join(" "),
    },
    {
      header: "EVENT NAME",
      accessor: (e) => getEventName(e.eventId),
    },
    {
      header: "MEDICAL SERVICES",
      accessor: (e) =>
        getServicesAvailed(
          e.services?.map((service) => service.serviceId) || []
        ),
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => editPatientDataHandler(e)}
            label='Edit'
            className={classes.editAction}
          />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];

  const onPodChange = (selectedOptions: MultiSelectOption[]) => {
    const eventIds = selectedOptions.map(
      (selectedOption) => selectedOption.value
    );

    setSelectedEvents(selectedOptions);
    setEventIds(eventIds);
  };

  const editPatientDataHandler = async (data: CheckinReportData) => {
    try {
      const [orderRes, patientRes] = await Promise.all([
        fetchOrders({
          id: data.orderId,
        }),
        fetchPatient(data.patientId),
      ]);

      if (orderRes.status === 200 && patientRes.status === 200) {
        const patientData = {
          patient: patientRes.data,
          order: orderRes.data[0],
          encounter: {},
        };
        setEditPatientData(patientData);
        setShowModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editPatientDataCloseHandler = (
    isUpdated: boolean,
    patientData?: Patient,
    orderData?: Order
  ) => {
    setShowModal(false);
    if (isUpdated && patientData && orderData) {
      setCheckins((prevState) => {
        return prevState.map((checkin) => {
          if (checkin.patientId === patientData.id) {
            // A patient may have more than one orders listed.
            return {
              ...checkin,
              patientName: getPatientFullName(patientData),
            };
          }
          return checkin;
        });
      });
    }
  };

  return (
    <>
      {showModal && (
        <EditPatient
          patientReportData={editPatientData}
          onClose={editPatientDataCloseHandler}
        />
      )}
      <PanelContainer name='Check-In List'>
        <PanelInnerContainer className={classes.innerContainer}>
          <Card>
            <div className={classes.filterContainer}>
              <Select
                label='Select POD'
                htmlFor='pod'
                options={pods}
                defaultOption={"Select POD"}
                nameExtractor='name'
                value={podId}
                onChange={(e) => {
                  setPodId(e.target.value);
                  setEventIds([]);
                }}
                className={classes.podSelect}
              />
              <div className={classes.filters}>
                <div className={classes.eventDropDown}>
                  <MultiSelect
                    label='Select Event'
                    onChange={onPodChange}
                    value={selectedEvents}
                    options={eventOptions}
                    isDisabled={!podId}
                    isAll
                  />
                </div>
              </div>
              <div className={classes.filterRight}>
                <SearchBar
                  placeholder='Search by patient name'
                  onChange={(searchText) => setSearchText(searchText)}
                  greyBackground
                />
                <PrimaryButton
                  name='Filter'
                  onClick={filterHandler}
                  disabled={!eventIds.length}
                />
              </div>
            </div>
          </Card>
          <TableContainer>
            <Table
              columns={checkInListTableColumn}
              data={checkins}
              isPaginated
              pageNo={currPageNo}
              totalPages={totalPages}
              onPrevClick={() => setCurrPageNo((state) => state - 1)}
              onNextClick={() => setCurrPageNo((state) => state + 1)}
            />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default CheckInList;

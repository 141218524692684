import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventFullAddress,
  getFormattedDate,
  getFormattedTime,
  getUserFullName,
} from "../../../utils";
import { AppDispatch, RootState } from "../../../store";
import { fetchUsers } from "../../../services/users";
import { editEvent } from "../../../store/actions/EventActions";
import { fetchProducts } from "../../../services/masters";
import { eventsActions } from "../../../store/slices/EventSlice";
import {
  fetchEncounterStats,
  fetchOrdersCount,
} from "../../../services/orders";
import { Note, SelectedService } from "../../../ts/models/Event";
import PanelContainer from "../../../components/Containers/PanelContainer";
import EventDetailsCard from "./EventDetailsCard";
import classes from "./EventDetails.module.css";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import InputLabel from "../../../components/Inputs/InputLabel";
import Status from "../../../components/Status/Status";
import Card from "../../../components/Card/Card";
import User from "../../../ts/models/User";
import Product from "../../../ts/models/Products";
import Input from "../../../components/Inputs/Input";
import ClosableBox from "../../../components/Box/ClosableBox";
import Table from "../../../components/CustTable/Table";
import { Column, ServiceAndProducts } from "../../../ts/types";
import MultiStatusView from "../../../components/Status/MultiStatusView";
import TableContainer from "../../../components/CustTable/TableContainer";

const EventDetails = () => {
  const { currEventDetails } = useSelector((state: RootState) => state.events);
  const serviceMaster = useSelector(
    (state: RootState) => state.masters.services
  );
  const currUserData = useSelector(
    (state: RootState) => state.users.currUserData
  );
  const dispatch: AppDispatch = useDispatch();

  const { name, startTime, endTime, services, capacity, id, medProfs, notes } =
    currEventDetails;

  const [totalOrders, setTotalOrders] = useState(0);
  const [note, setNote] = useState("");
  const [selectedMedProfs, setSelectedMedProfs] = useState<User[]>([]);
  const isUpcoming = startTime > Date.now();
  const isLive = startTime < Date.now() && endTime > Date.now();
  const [users, setUsers] = useState<User[]>([]);
  const [servicesAndProductList, setServicesAndProductList] = useState<
    ServiceAndProducts[]
  >([]);
  const [eventStates, setEventStates] = useState({
    administered: 0,
    checkedIn: 0,
    discharged: 0,
    medicationsAdministered: [],
  });

  const getUsers = async () => {
    const userIds = notes.map((note) => ({ $oid: note.userId }));

    if (userIds?.length) {
      const filter = {
        offset: 0,
        where: {
          _id: {
            $in: userIds,
          },
        },
      };

      try {
        const res = await fetchUsers(filter);
        if (res.status) {
          setUsers(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, [notes]);

  const getUserName = (userId: string) => {
    const user = users.find((name) => name.id === userId);
    if (user) {
      if (getUserFullName(user)) {
        return getUserFullName(user);
      } else {
        return "Super Admin";
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    fetchOrdersCount({
      eventId: id,
    })
      .then((res) => {
        if (res.status === 200) {
          setTotalOrders(res.data.count);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    fetchEncounterStats(id)
      .then((res) => {
        if (res.status === 200) {
          setEventStates(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const cardData = [
    {
      name: "Total Registered",
      value: totalOrders,
      color: "#E8F4FA",
    },
    {
      name: "Check-ins",
      value: eventStates.checkedIn,
      color: "#EAECFC",
    },
    {
      name: "Administered",
      value: eventStates.administered,
      color: "#eefcef",
    },
    {
      name: "Discharged",
      value: eventStates.discharged,
      color: "#ffefe3",
    },
  ];

  const submitNotesHandler = () => {
    let data: Note = {
      userId: currUserData.id,
      noteTime: Date.now(),
      noteText: note.trim(),
    };
    let newNotes: Note[] = [];
    if (notes) {
      newNotes = [...notes, data];
    } else {
      newNotes = [data];
    }

    dispatch(editEvent({ id: id, notes: newNotes }));
    dispatch(
      eventsActions.setCurrEventDetails({
        ...currEventDetails,
        notes: newNotes,
      })
    );
    setNote("");
  };

  useEffect(() => {
    const fetchMedProfsFromIds = async () => {
      const medProfIds = Array.from(medProfs).map((MedProfId) => ({
        $oid: MedProfId,
      }));

      if (medProfIds?.length) {
        const filter = {
          offset: 0,
          where: {
            _id: {
              $in: medProfIds,
            },
          },
        };

        try {
          const res = await fetchUsers(filter);

          if (res.status) {
            setSelectedMedProfs(res.data);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setSelectedMedProfs([]);
      }
    };

    fetchMedProfsFromIds();
  }, [medProfs]);

  const medProfsColumn: Column<User>[] = [
    {
      header: "ID",
      accessor: (_, idx) => idx + 1,
      width: "10%",
      style: { textAlign: "left" },
    },
    {
      header: "NAME",
      accessor: (medProf) => getUserFullName(medProf),
    },
    {
      header: "LOCATION",
      accessor: (medProf) => medProf.defaultLocation,
    },
    {
      header: "MEDICAL SERVICES",
      accessor: (medProf) => {
        const serviceNames = medProf.serviceTypes.map((serviceId) => {
          const service = serviceMaster.find(
            (service) => service.id === serviceId
          );

          if (service) {
            return service.name;
          } else {
            return "";
          }
        });
        return <MultiStatusView names={serviceNames} maxCount={2} />;
      },
    },
    {
      header: "ROLE OF THE USER",
      accessor: (medProf) => medProf.role,
    },
  ];

  const productsColumn: Column<ServiceAndProducts>[] = [
    {
      header: "ID",
      accessor: (_, idx) => idx + 1,
      width: "10%",
      style: { textAlign: "left" },
    },
    {
      header: "CODE",
      accessor: (e) => e.service.code,
    },
    {
      header: "MEDICAL SERVICES",
      accessor: (e) => <Status name={e.service.name} />,
    },
    {
      header: "PRODUCTS",
      accessor: (e: any) => {
        return e.products.map((product: any, idx: any) => (
          <div key={idx}>{product.name}</div>
        ));
      },
    },
  ];

  useEffect(() => {
    const addServiceAndProducts = async () => {
      const selectedServiceList: ServiceAndProducts[] = [];

      // collecting product ids to fetch products data
      const allProductsIds = new Set();
      let allProducts: Product[] = [];

      services.forEach((selectedService: SelectedService) => {
        selectedService.productIds.forEach((productId) =>
          allProductsIds.add(productId)
        );
      });

      const productIds = Array.from(allProductsIds).map((product) => ({
        $oid: product,
      }));

      if (productIds?.length) {
        const productFilter = {
          offset: 0,
          where: {
            _id: {
              $in: productIds,
            },
          },
        };

        try {
          const res = await fetchProducts(productFilter);
          if (res.status) {
            allProducts = res.data;
          }
        } catch (err) {
          console.log(err);
        }
      }

      for (let i = 0; i < services.length; i++) {
        const selectedService = serviceMaster.find(
          (service) => service.id === services[i].serviceId
        );

        let productList: Product[] = [];

        services[i].productIds.forEach((productId) => {
          const product = allProducts.find(
            (product) => product.id === productId
          );

          if (product) {
            productList.push(product);
          }
        });

        if (selectedService) {
          const selectedServiceAndProducts: ServiceAndProducts = {
            service: selectedService,
            products: productList,
          };

          selectedServiceList.push(selectedServiceAndProducts);
        }
      }

      setServicesAndProductList(selectedServiceList);
    };

    addServiceAndProducts();
  }, [services]);

  return (
    <PanelContainer name='Event Details' backBtn>
      <PanelInnerContainer>
        <div className={classes.main}>
          <Card>
            <div className={classes.eventName}>
              <div className={classes.name}>{name}</div>
              <Status
                className={classes.status}
                status={isUpcoming ? "upcoming" : isLive ? "active" : "closed"}
                name={isUpcoming ? "Upcoming" : isLive ? "Live" : "Closed"}
              />
            </div>
            <div className={classes.detailsTop}>
              <div className={classes.container}>
                <InputLabel label='Date:' />
                <div className={classes.date}>{`${getFormattedDate(
                  startTime
                )} - ${getFormattedDate(endTime)}`}</div>
                <div className={classes.capacity}>
                  <div>
                    <InputLabel label='Time:' />
                    <div className={classes.timeContainer}>{`${getFormattedTime(
                      startTime
                    )} - ${getFormattedTime(endTime)}`}</div>
                  </div>
                  <div>
                    <InputLabel label='Capacity:' />
                    <div className={classes.dataRow}>
                      {capacity === 0 ? "Not Fixed" : capacity}
                    </div>
                  </div>
                </div>
                <div className={classes.location}>
                  <InputLabel label='Location:' />
                  <div className={classes.dataRow}>
                    {getEventFullAddress(currEventDetails)}
                  </div>
                </div>
                <div className={classes.detailsBottom}>
                  <div className={classes.medServices}>
                    {servicesAndProductList.map((serviceName, idx) => (
                      <Status
                        status='upcoming'
                        name={serviceName.service.name}
                        key={idx}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className={classes.overview}>
                <InputLabel label='Overview:' />
                <div className={classes.cards}>
                  {cardData.map((card, idx) => (
                    <EventDetailsCard {...card} key={idx} />
                  ))}
                </div>
              </div>
              <div className={classes.medicationContainer}>
                <InputLabel label='Medications Administered:' />
                <div className={classes.medicationCard}>
                  {eventStates.medicationsAdministered.map(
                    (medication: any, idx) => (
                      <div className={classes.medications}>
                        <div className={classes.medicationName}>
                          {medication?.name}
                        </div>
                        <div className={classes.medicationCount}>
                          {medication?.count}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </Card>
          <div className={classes.closeBox}>
            <ClosableBox
              name={`Medical Professionals (${selectedMedProfs.length})`}
              defaultState>
              <TableContainer>
                <div className={classes.medProfsTable}>
                  <Table columns={medProfsColumn} data={selectedMedProfs} />
                </div>
              </TableContainer>
            </ClosableBox>
          </div>
          <div className={classes.closeBox}>
            <ClosableBox
              name={`Services (${servicesAndProductList.length})`}
              defaultState>
              <TableContainer>
                <div className={classes.medProfsTable}>
                  <Table
                    columns={productsColumn}
                    data={servicesAndProductList}
                  />
                </div>
              </TableContainer>
            </ClosableBox>
          </div>
          <Card className={classes.card}>
            <div className={classes.noteTitle}>Notes</div>
            <div className={classes.noteBox}>
              <div className={classes.notesContainer}>
                {notes?.map((note, idx) => (
                  <div key={idx}>
                    <div className={classes.userContainer}>
                      <div className={classes.userName}>
                        {getUserName(note.userId)}
                      </div>
                      <div className={classes.dateAndTime}>
                        <span>{`${getFormattedDate(
                          note.noteTime
                        )} - ${getFormattedTime(note.noteTime)}`}</span>
                      </div>
                    </div>
                    <div className={classes.noteText}>{note.noteText}</div>
                  </div>
                ))}
              </div>
              <Input
                value={note}
                name='New Note'
                onChange={(e) => setNote(e.target.value)}
                textarea
                inputClassName={classes.noteTextarea}
              />
            </div>
            <div className={classes.action}>
              <PrimaryButton
                name='Add Note'
                disabled={note.trim().length === 0}
                onClick={submitNotesHandler}
                className={classes.uploadBtn}
              />
            </div>
          </Card>
        </div>
      </PanelInnerContainer>
    </PanelContainer>
  );
};

export default EventDetails;

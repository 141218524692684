import { InsuranceCardImages, InsuranceDetails } from "../types";

export default class Patient {
  id!: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string | number;
  gender: string;
  race: string;
  ethnicity: string;
  address: string;
  zipCode: string;
  city: string;
  country: string;
  state: string;
  haveInsurance: boolean;
  insuranceDetails: InsuranceDetails;
  insuranceCardImages: InsuranceCardImages;
  isPrimaryMember: boolean;
  primaryMemberName: string;
  primaryMemberDob: string | number;
  haveSecondaryInsurance: boolean;
  secondaryInsuranceDetails: InsuranceDetails;
  eventIds: string[];

  constructor() {
    this.firstName = "";
    this.middleName = "";
    this.lastName = "";
    this.dob = "";
    this.gender = "";
    this.race = "";
    this.ethnicity = "";
    this.address = "";
    this.zipCode = "";
    this.city = "";
    this.country = "";
    this.state = "";
    this.haveInsurance = false;
    this.insuranceDetails = {
      providerName: "",
      policyNumber: "",
      groupNumber: "",
    };
    this.insuranceCardImages = {
        frontImage: "",
        backImage: "",
    };
    this.isPrimaryMember = false;
    this.primaryMemberName = "";
    this.primaryMemberDob = "";
    this.haveSecondaryInsurance = false;
    this.secondaryInsuranceDetails = {
      providerName: "",
      policyNumber: "",
      groupNumber: "",
    };
    this.eventIds = [];
  }
}

import { useSelector } from "react-redux";
import { useState } from "react";

import { RootState } from "../../store";
import { getClasses, getPODFullAddress } from "../../utils";
import { BillingData } from "../../ts/types";
import classes from "./BillingFileCard.module.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import Checkbox from "../../components/Checkbox/Checkbox";
import Status from "../../components/Status/Status";

type Props = {
  podData: BillingData;
  onDownloadCSV: (
    podId: string,
    eventIds: string[],
    sendForBilling: boolean
  ) => void;
  setEventIdHandler: (isChecked: boolean, eventId: string) => void;
};

const BillingFileCard: React.FC<Props> = ({
  podData,
  onDownloadCSV,
  setEventIdHandler,
}) => {
  const pods = useSelector((state: RootState) => state.pods.pods);

  const [sendForBilling, setSendForBilling] = useState(false);
  const [selectedEventsSet, setSelectedEventsSet] = useState<Set<string>>(
    new Set<string>()
  );

  const pod = pods.find((pod) => pod.id === podData.podId);

  const eventIds = Array.from(selectedEventsSet).map((eventId) => eventId);

  const isPodBilled = () => {
    return podData.events.every((event) => event.isBilled);
  };

  return (
    <>
      <div className={classes.billingContainer}>
        <div className={classes.container}>
          <div className={classes.nameContainer}>
            <div className={classes.name}>
              {pod?.name}
              {podData.events.length > 0 && (
                <Status
                  status={isPodBilled() ? "active" : "closed"}
                  name={isPodBilled() ? "Billed" : "Unbilled"}
                />
              )}
            </div>
            <div className={classes.podAddress}>{`${
              pod ? getPODFullAddress(pod) : ""
            }`}</div>
          </div>
          <div className={classes.eventContainer}>
            <div className={classes.name}>Events</div>
            <div className={classes.eventNameContainer}>
              {podData.events.length === 0 && (
                <div>No events for this period</div>
              )}
              {podData.events.map((event) => (
                <div className={classes.eventsContainer} key={event.id}>
                  <Checkbox
                    id={`checkbox-${event.id}`}
                    checked={selectedEventsSet.has(event.id)}
                    onChange={(e) => {
                      const value: boolean = !selectedEventsSet.has(event.id);
                      setEventIdHandler(value, event.id);
                      setSelectedEventsSet((prevState) => {
                        if (value) {
                          const newState: Set<string> = new Set<string>(
                            prevState.add(event.id)
                          );
                          return newState;
                        } else {
                          if (prevState.has(event.id)) {
                            const newState: Set<string> = new Set<string>(
                              prevState
                            );
                            newState.delete(event.id);
                            return newState;
                          } else {
                            return prevState;
                          }
                        }
                      });
                    }}
                    label={event.name}
                  />
                  <Status
                    className={classes.eventStatus}
                    status={event.isBilled ? "active" : "closed"}
                    name={event.isBilled ? "Billed" : "Unbilled"}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.actionContainer}>
          <div
            className={getClasses(
              classes.actions,
              podData.events.length === 0 || eventIds.length === 0
                ? classes.actionsDisabled
                : ""
            )}>
            <PrimaryButton
              className={classes.action}
              name='Download CSV'
              onClick={() =>
                onDownloadCSV(podData.podId, eventIds, sendForBilling)
              }
            />
            <Checkbox
              checked={sendForBilling}
              className={classes.sendForBillingCheckbox}
              label='Mark these records sent to billing'
              id={`check-box-${podData.podId}`}
              onChange={(checked) => setSendForBilling(checked)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingFileCard;

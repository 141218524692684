import { ReactElement } from "react";

import { Column } from "../../ts/types";
import { getClasses } from "../../utils";
import classes from "./Table.module.css";
import leftPathIcon from "../../assets/icons/left-path.svg";
import rightPathIcon from "../../assets/icons/right-path.svg";

interface CommonProps<T> {
  columns: Column<T>[];
  data: T[];
}

type ConditionalProps =
  | {
      isPaginated: boolean;
      pageNo: number;
      totalPages: number;
      onPrevClick: () => void;
      onNextClick: () => void;
    }
  | {
      isPaginated?: never;
      pageNo?: never;
      totalPages?: never;
      onPrevClick?: never;
      onNextClick?: never;
    };

type Props<T> = CommonProps<T> & ConditionalProps;

const Table = <T extends unknown>({
  columns,
  data,
  isPaginated,
  onPrevClick,
  onNextClick,
  pageNo,
  totalPages,
}: Props<T>): ReactElement => {
  return (
    <div className={classes.main}>
      <div className={classes.tableContainer}>
        <table>
          <thead className={classes.thead}>
            <tr>
              {columns.map((col, idx) => (
                <th style={{ width: col.width, ...col.style }} key={idx}>
                  {col.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((ele, index) => (
              <tr className={classes.row} key={index}>
                {columns.map((col, idx) => (
                  <td style={col.style} key={idx}>
                    {col.accessor(ele, index)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isPaginated && pageNo > 0 && totalPages > 0 && (
        <div className={classes.paginationContainer}>
          <div
            className={getClasses(
              classes.actionBtn,
              pageNo <= 1 ? classes.disabled : ""
            )}
            onClick={onPrevClick}>
            <img src={leftPathIcon} alt='Goto previous page' />
          </div>
          <div
            className={
              classes.pageNo
            }>{`${pageNo} of ${totalPages} pages`}</div>
          <div
            className={getClasses(
              classes.actionBtn,
              pageNo >= totalPages ? classes.disabled : ""
            )}
            onClick={onNextClick}>
            <img src={rightPathIcon} alt='Goto next page' />
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;

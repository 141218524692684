import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
  isAuthenticated: boolean;
  resourceName: string;
};

const initialState: InitialState = {
  isAuthenticated: !!localStorage.getItem("isLoggedIn"),
  resourceName: localStorage.getItem("resourceName") || "cimpar",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    logOut: (state) => {
      state.isAuthenticated = false;
      localStorage.removeItem("isLoggedIn");
    },
    setResourceName: (state, action) => {
      state.resourceName = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;

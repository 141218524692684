import { getClasses } from "../../utils";
import classes from "./PrimaryButton.module.css";

type Props = {
  name: string;
  disabled?: boolean;
  transparent?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick: () => void;
  icon?: JSX.Element;
};

const PrimaryButton: React.FC<Props> = ({
  name,
  className = "",
  style,
  disabled,
  transparent,
  onClick,
  icon,
}) => {
  return (
    <button
      onClick={onClick}
      className={getClasses(
        classes.main,
        disabled ? classes.disabled : "",
        transparent ? classes.transparent : "",
        className
      )}
      style={style}>
      {icon && <div className={classes.icon}>{icon}</div>}
      <span>{name}</span>
    </button>
  );
};

export default PrimaryButton;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { fetchEncountersCount } from "../../../services/orders";
import { fetchProducts } from "../../../services/masters";
import {
  getEventFullAddress,
  getFormattedDate,
  getFormattedTime,
} from "../../../utils";
import { RootState } from "../../../store";
import DataField from "../../../components/Box/DataField";
import Event from "../../../ts/models/Event";
import classes from "./EventReportDetails.module.css";
import Card from "../../../components/Card/Card";
import ClosableBox from "../../../components/Box/ClosableBox";
import Product from "../../../ts/models/Products";
import Status from "../../../components/Status/Status";

type Props = {
  evenReportData: Event;
  totalRegistered: any;
};

const EventReportDetails: React.FC<Props> = ({
  evenReportData,
  totalRegistered,
}) => {
  const allServices = useSelector((state: RootState) => state.masters.services);
  const [productsUsed, setProductsUsed] = useState<Product[]>([]);
  const [totalCheckins, setTotalCheckins] = useState(0);

  const { id, name, startTime, endTime, services } = evenReportData;

  const serviceNames = services.map((service) => {
    const { serviceId } = service;

    const serviceMaster = allServices.find(
      (service) => service.id === serviceId
    );

    if (serviceMaster) {
      return serviceMaster.name;
    }

    return "";
  });

  const filteredServiceNames = serviceNames.filter((e) => {
    return e !== null;
  });

  const productNames = async () => {
    const productIdSet = new Set();

    services.forEach((service) =>
      service.productIds.forEach((product) => {
        productIdSet.add(product);
      })
    );

    const productIds = Array.from(productIdSet);

    if (productIds?.length) {
      const productFilter = {
        where: {
          _id: {
            $in: productIds,
          },
        },
      };
      try {
        const res = await fetchProducts(productFilter);
        if (res.status) {
          setProductsUsed(res.data);
        }
      } catch (err) {}
    }
  };

  useEffect(() => {
    productNames();
  }, [evenReportData]);

  useEffect(() => {
    fetchEncountersCount({
      eventId: id,
    })
      .then((res) => {
        if (res.status === 200) {
          setTotalCheckins(res.data.count);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <div className={classes.eventName}>{name}</div>
        <div className={classes.address}>
          {getEventFullAddress(evenReportData)}
        </div>
        <DataField
          name='Date '
          data={`${getFormattedDate(startTime)} - ${getFormattedDate(endTime)}`}
        />
        <DataField
          name='Time '
          data={`${getFormattedTime(startTime)} - ${getFormattedTime(endTime)}`}
        />
      </Card>
      <ClosableBox name={"Event Information"} defaultState>
        <hr className={classes.hr} />
        <DataField
          name='Total Registered '
          data={totalRegistered.get(id) ?? 0}
        />
        <DataField name='Total Attendees ' data={totalCheckins} />
        <div className={classes.services}>
          <DataField name='Medical Services ' />
          <div className={classes.serviceNames}>
            {filteredServiceNames.map((serviceName) => (
              <Status name={serviceName} />
            ))}
          </div>
        </div>
      </ClosableBox>
      <ClosableBox name={"Products"} defaultState>
        <>
          {productsUsed.map((product, idx) => (
            <DataField name={`${idx + 1}. ${product.name}`} key={idx} />
          ))}
        </>
      </ClosableBox>
    </div>
  );
};

export default EventReportDetails;

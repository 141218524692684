import { useEffect, useState } from "react";
import Modal, { ModalBody, ModalFooter } from "../Modal/Modal";
import PrimaryButton from "../Buttons/PrimaryButton";
import Input from "../Inputs/Input";
import classes from "./DeleteConfirm.module.css";

export const DeleteConfirm: React.FC<{
  message: string | JSX.Element;
  name: string;
  collectionName: string;
  onClose: () => void;
  onConfirmation: () => void;
}> = ({ message, name, collectionName, onClose, onConfirmation }) => {
  const [text, setText] = useState("");
  const [disabled, setDisable] = useState(true);

  useEffect(() => {
    if (text === name) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [text]);

  return (
    <Modal title={<>Confirmation</>} onClose={onClose}>
      <>
        <ModalBody>
          <div className={classes.main}>
            <div>{message}</div>
            <div className={classes.inputContainer}>
              <Input
                name={`Type "${name}" to delete this ${collectionName}`}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton
              onClick={onConfirmation}
              name='OK'
              style={{ marginRight: "1rem" }}
              disabled={disabled}
            />
            <PrimaryButton onClick={onClose} name='Cancel' transparent />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default DeleteConfirm;

import React from "react";
import classes from "./MasterCard.module.css";
import { BASE_URL, MASTER_MANAGEMENT_URL } from "../../Routes/urls";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type Props = {
  name: string;
  path: string;
  icon: string;
};

const MasterCard: React.FC<Props> = ({ name, path, icon }) => {
  const navigate = useNavigate();
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  const navigationHandler = () => {
    navigate(
      BASE_URL + `/${resourceName}` + `/${MASTER_MANAGEMENT_URL}/` + path
    );
  };

  return (
    <div className={classes.main} onClick={navigationHandler}>
      <img src={icon} alt='' className={classes.icon} />
      <span>{name}</span>
    </div>
  );
};

export default MasterCard;

import { AppDispatch } from "..";
import {
  addUser,
  fetchUsers,
  fetchUsersCount,
  updateUser,
  updateUserStatus,
} from "../../services/users";
import { usersActions } from "../slices/UserSlice";
import User, { UserStatus } from "../../ts/models/User";
import { authActions } from "../slices/AuthSlice";
import { warningActions } from "../slices/WarningSlice";
import { UserRole } from "../../ts/enums";
import { fetchProfile, fetchTenants, logOut } from "../../services/login";

export const getUsers = (filter?: any, updatedPageNo?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      const [userRes, userCountRes] = await Promise.all([
        fetchUsers(filter),
        fetchUsersCount(filter.where),
      ]);
      if (userRes.status === 200 && userCountRes.status === 200) {
        // Removing super admin from data
        const users = userRes.data.filter(
          (user: User) => user.role !== UserRole.SuperAdmin
        );
        dispatch(
          usersActions.getUsers({
            users,
            count: userCountRes.data?.count,
            currPageNo: updatedPageNo,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addNewUser = (data: User, handleBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    return addUser(data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errorCode === "user_exists") {
            dispatch(
              warningActions.showWarning({
                message: "Email already exists",
              })
            );
          } else {
            dispatch(usersActions.addUser(res.data));
            handleBack();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateUserData = (data: User, handleBack: () => void) => {
  return async (dispatch: AppDispatch) => {
    return updateUser(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(usersActions.updateUser(res.data));
          handleBack();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateUserStatusData = (data: UserStatus) => {
  return async (dispatch: AppDispatch) => {
    updateUserStatus(data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(usersActions.updateUser(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchCurrentUser = () => {
  return async (dispatch: AppDispatch) => {
    fetchProfile()
      .then((res) => {
        if (res.status === 200) {
          dispatch(usersActions.fetchCurrUser(res.data));
        } else {
          localStorage.removeItem("isLoggedIn");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const logOutAndRemoveData = () => {
  return async (dispatch: AppDispatch) => {
    logOut()
      .then((res) => {
        if (res.status === 200) {
          dispatch(usersActions.fetchCurrUser({ ...new User() }));
          dispatch(authActions.logOut());
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchTenantsData = () => {
  return async (dispatch: AppDispatch) => {
    fetchTenants()
      .then((res) => {
        if (res.status === 200) {
          dispatch(usersActions.setTenants(res.data.tenants));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

import { createSlice } from "@reduxjs/toolkit";
import User from "../../ts/models/User";
import { Tenant } from "../../ts/types";

type InitialState = {
  users: User[];
  currUserData: User;
  totalMedProfsCount: number;
  userToBeEdited: User;
  currPageNo: number;
  count: number;
  tenants: Tenant[];
};

export const emptyUser = { ...new User() };

const initialState: InitialState = {
  users: [],
  currUserData: emptyUser,
  totalMedProfsCount: 0,
  userToBeEdited: emptyUser,
  currPageNo: 0,
  count: 0,
  tenants: [],
};

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUsers: (state, action) => {
      const { users, count, currPageNo } = action.payload;
      state.users = users;
      state.count = count;
      state.currPageNo = currPageNo;
    },
    fetchCurrUser: (state, action) => {
      state.currUserData = action.payload;
    },
    userToBeEdited: (state) => {
      state.userToBeEdited = emptyUser;
    },
    addUser: (state, action) => {
      state.users.push(action.payload);
    },
    setUserToBeEdited: (state, action) => {
      state.userToBeEdited = action.payload;
    },
    emptyUserToBeEdited: (state) => {
      state.userToBeEdited = emptyUser;
    },
    updateUser: (state, action) => {
      const index = state.users.findIndex(
        (user) => user.id === action.payload.id
      );
      if (index !== -1) {
        state.users[index] = { ...action.payload };
      }
    },
    setTotalMedProfsCount: (state, action) => {
      state.totalMedProfsCount = action.payload;
    },
    setTenants: (state, action) => {
      state.tenants = action.payload;
    },
  },
});

export const usersActions = users.actions;

export default users.reducer;

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../../store";
import {
  addNewService,
  updateServiceData,
} from "../../../store/actions/MasterActions";
import {
  fetchProducts,
  fetchProductsCount,
  fetchScreeningQuestions,
  fetchScreeningQuestionsCount,
} from "../../../services/masters";
import { Column } from "../../../ts/types";
import Modal, { ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import Table from "../../../components/CustTable/Table";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Service from "../../../ts/models/Service";
import classes from "./AddService.module.css";
import ScreeningQuestion from "../../../ts/models/ScreeningQuestion";
import SelectItemsModal from "../../../components/SelectItems/SelectItemsModal";
import Input from "../../../components/Inputs/Input";
import ActionContainer from "../../../components/CustTable/ActionContainer";
import LinkButton from "../../../components/Buttons/LinkButton";
import InputLabel from "../../../components/Inputs/InputLabel";
import addIcon from "../../../assets/icons/add-circle.svg";
import Product from "../../../ts/models/Products";
import Checkbox from "../../../components/Checkbox/Checkbox";

const productsSelectionColumns: Column<Product>[] = [
  {
    header: "CODE",
    accessor: (e) => <div>{e.code}</div>,
  },
  {
    header: "PRODUCT NAME",
    accessor: (e) => <div>{e.name}</div>,
  },
  {
    header: "DESCRIPTION",
    accessor: (e) => <div>{e.description}</div>,
  },
];

const ScreeningQueColumns: Column<ScreeningQuestion>[] = [
  {
    header: "CODE",
    accessor: (e) => <div>{e.code}</div>,
  },
  {
    header: "QUESTION",
    accessor: (e) => <div>{e.question}</div>,
  },
  {
    header: "OPTION",
    accessor: (e) => (
      <div className={classes.options}>
        {e.options.map((option, idx) => (
          <span key={idx}>{`${idx + 1}. ${option.text}`}</span>
        ))}
      </div>
    ),
    width: "15%",
  },
];

const pageSize = 10;

type props = {
  onClose: () => void;
  prevData?: Service;
  isEdit: Boolean;
};

const AddService: React.FC<props> = ({ onClose, prevData, isEdit }) => {
  const dispatch: AppDispatch = useDispatch();

  const [code, setCode] = useState<string>(prevData ? prevData.code : "");
  const [description, setDescription] = useState<string>(
    prevData ? prevData.description : ""
  );
  const [name, setName] = useState<string>(prevData ? prevData.name : "");
  const [isReportable, setIsReportable] = useState(
    prevData ? prevData.isReportableToAgency : false
  );
  const [totalPages, setTotalPages] = useState(1);

  const [showProductModal, setShowProductModal] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [selectedProductIds, setSelectedProductIds] = useState(
    prevData ? new Set(prevData.products) : new Set<string>()
  );
  const [showScreeningQueModal, setShowScreeningQueModal] = useState(false);
  const [screeningQue, setScreeningQue] = useState<ScreeningQuestion[]>([]);
  const [selectedScreeningQue, setSelectedScreeningQue] = useState<
    ScreeningQuestion[]
  >([]);
  const [selectedScreeningQueIds, setSelectedScreeningQueIds] = useState(
    prevData ? new Set(prevData.screeningQuestions) : new Set<string>()
  );

  const deleteHandler = (id: string) => {
    setSelectedProductIds((prevState) => {
      const newState: Set<string> = new Set<string>(prevState);
      newState.delete(id);
      return newState;
    });
    setSelectedProducts((prevState) =>
      prevState.filter((product) => product.id !== id)
    );
  };

  const deleteQuestionHandler = (id: string) => {
    setSelectedScreeningQueIds((prevState) => {
      const newState: Set<string> = new Set<string>(prevState);
      newState.delete(id);
      return newState;
    });
    setSelectedScreeningQue((prevQue) =>
      prevQue.filter((que) => que.id !== id)
    );
  };

  const addServiceHandler = () => {
    const newService: Service = new Service(
      code,
      name,
      description,
      isReportable,
      Array.from(selectedProductIds),
      Array.from(selectedScreeningQueIds)
    );

    if (prevData) {
      newService.id = prevData.id;
      dispatch(updateServiceData(newService));
    } else {
      dispatch(addNewService(newService));
    }

    onClose();
  };

  const searchProductsHandler = async (offset = 0, searchText = "") => {
    const filter = {
      limit: pageSize,
      offset,
      where: {
        isActive: true,
        $or: [
          {
            code: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
          {
            description: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
        ],
      },
    };
    try {
      const [productsRes, countRes] = await Promise.all([
        fetchProducts(filter),
        fetchProductsCount(filter.where),
      ]);

      const count = Math.ceil(Number(countRes.data.count) / pageSize);
      setProducts(productsRes.data);
      setTotalPages(count);
    } catch (err) {
      console.log(err);
    }
  };

  const searchScreeningQueHandler = async (offset = 0, searchText = "") => {
    const filter = {
      limit: pageSize,
      offset,
      where: {
        isActive: true,
        $or: [
          {
            code: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
          {
            question: {
              $regex: `.*${searchText}.*`,
              $options: "i",
            },
          },
        ],
      },
    };

    try {
      const [screenQuestionRes, countRes] = await Promise.all([
        fetchScreeningQuestions(filter),
        fetchScreeningQuestionsCount(filter.where),
      ]);
      const count = Math.ceil(Number(countRes.data.count) / pageSize);
      setScreeningQue(screenQuestionRes.data);
      setTotalPages(count);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchProductData = async () => {
      const productsIds = Array.from(selectedProductIds).map((productId) => ({
        $oid: productId,
      }));

      if (productsIds.length) {
        const filter = {
          offset: 0,
          where: {
            _id: {
              $in: productsIds,
            },
          },
        };
        const res = await fetchProducts(filter);
        if (res.status) {
          setSelectedProducts(res.data);
        }
      }
    };
    if (!showProductModal) fetchProductData();
  }, [showProductModal]);

  useEffect(() => {
    const fetchScreeningQueData = async () => {
      const screeningQueIds = Array.from(selectedScreeningQueIds).map(
        (screeningQueId) => ({
          $oid: screeningQueId,
        })
      );

      if (screeningQueIds.length) {
        const filter = {
          offset: 0,
          where: {
            _id: {
              $in: screeningQueIds,
            },
          },
        };

        const res = await fetchScreeningQuestions(filter);
        if (res.status) {
          setSelectedScreeningQue(res.data);
        }
      }
    };
    if (!showScreeningQueModal) fetchScreeningQueData();
  }, [showScreeningQueModal]);

  const productsColumn: Column<Product>[] = [
    {
      header: "ID",
      accessor: (e, idx) => idx! + 1,
      width: "10%",
    },
    {
      header: "PRODUCT NAME",
      accessor: (e) => e.name,
    },
    {
      header: "DESCRIPTION",
      accessor: (e) => e.description,
    },
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "10%",
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton onClick={() => deleteHandler(e.id)} label='Delete' />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];

  const screeningQueColumn: Column<ScreeningQuestion>[] = [
    {
      header: "ID",
      accessor: (e, idx) => idx! + 1,
      width: "10%",
    },
    {
      header: "QUESTION",
      accessor: (e) => e.question,
    },
    {
      header: "OPTIONS",
      accessor: (e) => (
        <div className={classes.options}>
          {e.options.map((option, idx) => (
            <span key={idx}>{`${idx + 1}. ${option.text}`}</span>
          ))}
        </div>
      ),
      width: "20%",
    },
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "10%",
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => deleteQuestionHandler(e.id)}
            label='Delete'
          />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];

  const isRequiredDataMissing = () => {
    if (!code?.trim() || !description?.trim()) {
      return true;
    }
    return false;
  };

  return (
    <>
      {showProductModal && (
        <SelectItemsModal<Product>
          title={"Select Products"}
          initialItemsSet={selectedProductIds}
          setItemsHandler={(items) => setSelectedProductIds(items)}
          onClose={() => setShowProductModal(false)}
          items={products}
          noOfPages={totalPages}
          columns={productsSelectionColumns}
          searchItemsHandler={searchProductsHandler}
          isPaginated
        />
      )}
      {showScreeningQueModal && (
        <SelectItemsModal<ScreeningQuestion>
          title={"Select Screening Questions"}
          initialItemsSet={selectedScreeningQueIds}
          setItemsHandler={(items) => setSelectedScreeningQueIds(items)}
          onClose={() => setShowScreeningQueModal(false)}
          items={screeningQue}
          noOfPages={totalPages}
          columns={ScreeningQueColumns}
          searchItemsHandler={searchScreeningQueHandler}
          isPaginated
        />
      )}
      <Modal
        className={classes.heading}
        title={isEdit ? <>Update Medical Service</> : <>Add Medical Service</>}
        onClose={onClose}
        style={{ width: "50%", height: "75%" }}>
        <>
          <ModalBody>
            <div className={classes.main}>
              <div className={classes.row}>
                <Input
                  name='Code'
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  className={classes.input}
                />
                <Input
                  name='Name'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  className={classes.input}
                />
                <Input
                  name='Description'
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  className={classes.input}
                  textarea
                />
                <Checkbox
                  className={classes.reportCheckbox}
                  checked={isReportable}
                  id={"report-agency"}
                  label={"Report to Agency"}
                  onChange={(checked) => setIsReportable(checked)}
                />
              </div>
              <div className={classes.products}>
                <InputLabel label='List of Products' />
                <Table columns={productsColumn} data={selectedProducts} />
                <div className={classes.selectItemContainer}>
                  <LinkButton
                    icon={<img src={addIcon} alt='' />}
                    label='Select Products'
                    onClick={() => setShowProductModal(true)}
                  />
                </div>
              </div>
              <div className={classes.products}>
                <InputLabel label='List of Screening Questions' />
                <Table
                  columns={screeningQueColumn}
                  data={selectedScreeningQue}
                />
                <div className={classes.selectItemContainer}>
                  <LinkButton
                    icon={<img src={addIcon} alt='' />}
                    label='Select Screening Questions'
                    onClick={() => setShowScreeningQueModal(true)}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className={classes.actions}>
              <PrimaryButton
                className={classes.actionBtn}
                name={isEdit ? "Update" : "Add"}
                onClick={addServiceHandler}
                style={{ marginRight: "1rem" }}
                disabled={isRequiredDataMissing()}
              />
              <PrimaryButton
                className={classes.actionBtn}
                name='Cancel'
                onClick={() => onClose()}
                transparent
              />
            </div>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};
export default AddService;

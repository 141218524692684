import axios from "../axios";

const PODS_URL = "/api/pods";

export function fetchPODS() {
  return axios({
    url: PODS_URL,
    method: "get",
  });
}

export function addNewPOD(data: FormData) {
  return axios({
    url: PODS_URL,
    method: "post",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function editAPOD(data: FormData, id: string) {
  return axios({
    url: `${PODS_URL}/${id}`,
    method: "put",
    data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function deleteAPOD(id: string) {
  return axios({
    url: `${PODS_URL}/${id}`,
    method: "delete",
  });
}

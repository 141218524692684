import { createSlice } from "@reduxjs/toolkit";
import { addDays } from "date-fns";

import {
  getEndOfDayTimestamp,
  getLocalDateString,
  getStartOfDayTimestamp,
} from "../../utils";
import { EventFilter } from "../../ts/types";
import { eventTypes } from "../../ts/consts";
import Event from "../../ts/models/Event";

type InitialState = {
  filter: EventFilter;
  events: Event[];
  totalEventCount: number;
  liveRegCount: number;
  liveEventsCount: number;
  eventToBeEdited: Event;
  currEventDetails: Event;
  currPageNo: number;
  count: number;
};

const emptyEvent = { ...new Event() };

const emptyFilter: EventFilter = {
  podId: "",
  searchText: "",
  startDate: getStartOfDayTimestamp(getLocalDateString(Date.now())),
  endDate: getEndOfDayTimestamp(
    getLocalDateString(addDays(new Date(), 6).getTime())
  ),
  isHome: eventTypes[0].id,
};

// Storing events and filter to redux to prevent filter's and event's data loss while returning from create new event page
const initialState: InitialState = {
  filter: emptyFilter,
  events: [],
  totalEventCount: 0,
  liveRegCount: 0,
  liveEventsCount: 0,
  eventToBeEdited: emptyEvent,
  currEventDetails: emptyEvent,
  currPageNo: 0,
  count: 0,
};

const events = createSlice({
  name: "events",
  initialState,
  reducers: {
    getEvents: (state, action) => {
      const { events, count, currPageNo } = action.payload;
      state.events = events;
      state.count = count;
      state.currPageNo = currPageNo;
    },
    addEvent: (state, action) => {
      const event: Event = action.payload;
      if (event.podId === state.filter.podId) state.events.push(event);
    },
    editEvent: (state, action) => {
      let index: number = -1;
      index = state.events.findIndex((event: Event) => {
        return event.id === action.payload.id;
      });
      if (index !== -1) {
        state.events[index] = { ...action.payload };
      }
    },
    getEventStats: (state, action) => {
      state.totalEventCount = action.payload.totalEventCount || 0;
      state.liveEventsCount = action.payload.liveEventsCount || 0;
      state.liveRegCount = action.payload.liveRegCount || 0;
    },
    setEventToBeEdited: (state, action) => {
      state.eventToBeEdited = action.payload;
    },
    newEventToBeEdited: (state) => {
      const now = new Date().getTime();
      state.eventToBeEdited = {
        ...emptyEvent,
        podId: state.filter.podId || "",
        startTime: now,
        endTime: now,
      };
    },
    setCurrEventDetails: (state, action) => {
      state.currEventDetails = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    deleteEvent: (state, action) => {
      let index: number = -1;
      index = state.events.findIndex((event: Event) => {
        return event.id === action.payload;
      });
      if (index !== -1) {
        state.events.splice(index, 1);
      }
    },
  },
});

export const eventsActions = events.actions;

export default events.reducer;

import { SelectedService } from "./Event";

export default class Order {
  id?: string;
  podId: string;
  eventId?: string;
  code?: string;
  regEmail: string;
  regPhoneNo: string;
  patientId?: string;
  appointmentTime?: number;
  creationTime?: number;
  services?: SelectedService[];
  infoQuestionResponses: InfoQuestionResponse[];
  relationshipToPatient: string;
  isActive?: boolean;

  constructor() {
    this.podId = "";
    this.regEmail = "";
    this.regPhoneNo = "";
    this.relationshipToPatient = "";
    this.infoQuestionResponses = [];
  }
}

export type EventOrderCount = {
  eventId: string;
  orderCount: number;
};

export type OrderStatus = {
  id: string;
  isActive: boolean;
};

export type InfoQuestionResponse = {
  questionId: string;
  responseText: string;
};

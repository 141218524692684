import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
  loading: number;
};

const initialState: InitialState = {
  loading: 0,
};

const loader = createSlice({
  name: "loader",
  initialState,
  reducers: {
    fetching: (state) => {
      state.loading += 1;
    },
    fetched: (state) => {
      state.loading -= 1;
    },
  },
});

export const loaderActions = loader.actions;

export default loader.reducer;

import { getClasses } from "../../utils";
import classes from "./LinkButton.module.css";

type Props = {
  label: string;
  icon?: JSX.Element;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onClick: () => void;
};

const LinkButton: React.FC<Props> = ({
  label,
  icon,
  disabled,
  className = "",
  style,
  onClick,
}) => {
  return (
    <div
      className={getClasses(
        classes.main,
        className,
        disabled ? classes.disabled : ""
      )}
      onClick={onClick}
      style={style}>
      {icon && <div className={classes.icon}>{icon}</div>}
      <span>{label}</span>
    </div>
  );
};

export default LinkButton;

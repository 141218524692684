import React from "react";
import classes from "./PanelContainer.module.css";
import TitleContainer from "../TitleContainer/TitleContainer";

type Props = {
  name: string;
  children: JSX.Element | JSX.Element[];
  backBtn?: boolean;
};

const PanelContainer: React.FC<Props> = ({ name, children, backBtn }) => {
  return (
    <div className={classes.main}>
      <TitleContainer name={name} backBtn={backBtn} />
      {children}
    </div>
  );
};

export default PanelContainer;

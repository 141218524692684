import { useState } from "react";

import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Input from "../../../components/Inputs/Input";
import Modal, { ModalBody, ModalFooter } from "../../../components/Modal/Modal";
import { addProduct, updateProduct } from "../../../services/masters";
import Product from "../../../ts/models/Products";
import classes from "./AddProduct.module.css";

type Props = {
  product: Product;
  onClose: (isUpdated: boolean) => void;
};

const AddProduct: React.FC<Props> = ({ product, onClose }) => {
  const [data, setData] = useState(product);
  const isEdit = data.id;

  const addProductHandler = async () => {
    let res;
    try {
      if (isEdit) {
        res = await updateProduct(data);
      } else {
        res = await addProduct(data);
      }
      if (res.status === 200) {
        onClose(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isRequiredDataMissing = () => {
    if (!data.code?.trim() || !data.description?.trim() || !data.name?.trim()) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        className={classes.heading}
        title={isEdit ? <>Update Product</> : <>Add Product</>}
        onClose={() => onClose(false)}>
        <>
          <ModalBody>
            <div className={classes.main}>
              <Input
                name='Code'
                value={data.code}
                onChange={(e) =>
                  setData((state) => ({ ...state, code: e.target.value }))
                }
                className={classes.input}
              />
              <Input
                name='CPT Code'
                value={data.cptCode}
                onChange={(e) =>
                  setData((state) => ({ ...state, cptCode: e.target.value }))
                }
                className={classes.input}
              />
              <Input
                name='Name'
                value={data.name}
                onChange={(e) =>
                  setData((state) => ({
                    ...state,
                    name: e.target.value,
                  }))
                }
                className={classes.input}
              />
              <Input
                name='Description'
                value={data.description}
                onChange={(e) =>
                  setData((state) => ({
                    ...state,
                    description: e.target.value,
                  }))
                }
                textarea
                className={classes.input}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className={classes.actions}>
              <PrimaryButton
                className={classes.actionBtn}
                name={isEdit ? "Update" : "Add"}
                onClick={addProductHandler}
                style={{ marginRight: "1rem" }}
                disabled={isRequiredDataMissing()}
              />
              <PrimaryButton
                className={classes.actionBtn}
                name='Cancel'
                onClick={() => onClose(false)}
                transparent
              />
            </div>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

export default AddProduct;

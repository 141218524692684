import Card from "../Card/Card";
import classes from "./FormBox.module.css";

type Props = {
  name: string;
  children: JSX.Element | JSX.Element[];
};

const FormBox: React.FC<Props> = ({ name, children }) => {
  return (
    <Card className={classes.main}>
      <>
        <div className={classes.nameContainer}>
          <div className={classes.name}>{name}</div>
        </div>
        <div>{children}</div>
      </>
    </Card>
  );
};

export default FormBox;

import classes from "./EventDetailsCard.module.css";

type Props = {
  name: string;
  value: number | string;
  color: string;
};

const EventDetailsCard: React.FC<Props> = ({ name, value, color }) => {
  return (
    <div style={{ backgroundColor: color }} className={classes.main}>
      <div className={classes.value}>{value}</div>
      <div className={classes.name}>{name}</div>
    </div>
  );
};

export default EventDetailsCard;

import axios from "axios";
import { ChangePasswordData, LoginData, ResetPasswordData } from "../ts/types";

const BASE = "/api";
const LOGIN_URL = BASE + "/login";
const LOGOUT_URL = BASE + "/logout";
const CHANGE_PASSWORD = BASE + "/change-password";
const FORGOT_PASSWORD = BASE + "/send-reset-password-link";
const UPDATE_PASSWORD = BASE + "/update-password";
const PROFILE_URL = BASE + "/profile";
const CIMPAR_BACKOFFICE_CLIENT_ID =
  "63e215e1-ce82-4252-8482-c1d3068f7f13.apps.reya.net";
const TENANT_URL = BASE + "/tenants";

export function login(data: LoginData) {
  return axios({
    url: LOGIN_URL,
    method: "post",
    data,
    headers: { Client: CIMPAR_BACKOFFICE_CLIENT_ID },
  });
}

export function logOut() {
  return axios({
    url: LOGOUT_URL,
    method: "get",
  });
}

export function changePassword(data: ChangePasswordData) {
  return axios({
    url: CHANGE_PASSWORD,
    method: "put",
    data,
  });
}

export function forgotPassword(data: ResetPasswordData) {
  return axios({
    url: FORGOT_PASSWORD,
    method: "post",
    data,
  });
}

export function resetPassword(data: ResetPasswordData) {
  return axios({
    url: UPDATE_PASSWORD,
    method: "post",
    data,
  });
}

export function fetchProfile() {
  return axios({
    url: PROFILE_URL,
    method: "get",
  });
}

export function fetchTenants() {
  return axios({
    url: TENANT_URL,
    method: "get",
  });
}

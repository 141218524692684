import { getClasses } from "../../utils";
import classes from "./Card.module.css";

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  style?: React.CSSProperties;
};

const Card: React.FC<Props> = ({ children, className = "", style }) => {
  return (
    <>
      <div className={getClasses(classes.card, className)} style={style}>
        {children}
      </div>
    </>
  );
};

export default Card;

import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../store";
import { warningActions } from "../../store/slices/WarningSlice";
import Modal from "../Modal/Modal";
import classes from "./WarningDialog.module.css";
import PrimaryButton from "../Buttons/PrimaryButton";

const WarningDialog = () => {
  const dispatch: AppDispatch = useDispatch();

  const { showWarning, message } = useSelector(
    (state: RootState) => state.warning
  );

  const closeHandler = () => {
    dispatch(warningActions.removeWarning());
  };

  return (
    <>
      {showWarning && (
        <Modal className={classes.main}>
          <>
            <div className={classes.title}>Error</div>
            <div className={classes.messageContainer}>{message}</div>
            <div className={classes.actionContainer}>
              <PrimaryButton name='OK' onClick={closeHandler} />
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

export default WarningDialog;

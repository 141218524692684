import { getClasses } from "../../utils";
import classes from "./InputLabel.module.css";

type Props = {
  label: string;
  className?: string;
  isInvalid?: boolean;
  invalidText?: string;
};

const InputLabel: React.FC<Props> = ({
  label,
  className = "",
  invalidText,
  isInvalid,
}) => {
  return (
    <div className={getClasses(classes.label, className)}>
      {label}
      {isInvalid !== undefined && (
        <sup>
          <span className={classes.asterisk}>*</span>
        </sup>
      )}
      {isInvalid && invalidText && (
        <div className={classes.errorText}>{invalidText}</div>
      )}
    </div>
  );
};

export default InputLabel;

export default class POD {
  id!: string;
  code: string;
  name: string;

  address: string;
  city: string;
  state: string;
  zipCode: string;
  contractNumber: string;
  contractExpiry: number;
  poc1Name: string;
  poc1Email: string;
  poc1PhoneNo: string;
  poc2Name: string;
  poc2Email: string;
  poc2PhoneNo: string;

  logoImage?: any;
  introHeading: string;
  introContent: string;
  heroImage?: any;
  eventGuidelines: Guideline[];
  welcomeText: string;
  infoQuestions?: string[];
  disclaimers: string[];
  landingPage: LandingPage;
  updateTime: number;
  isActive: boolean;

  constructor(
    code = "",
    name = "",
    introHeading = "",
    introContent = "",
    eventGuidelines = [],
    welcomeText = "",
    address = "",
    city = "",
    state = "",
    zipCode = "",
    contractNumber = "",
    contractExpiry = new Date().getTime(),
    poc1Name = "",
    poc1Email = "",
    poc1PhoneNo = "",
    poc2Name = "",
    poc2Email = "",
    poc2PhoneNo = "",
    infoQuestions = [],
    disclaimers = [],
    landingPage = {
      startTime: new Date().getTime(),
      endTime: new Date().getTime(),
      timezoneOffset: new Date().getTimezoneOffset() * -1,
      pageLink: "",
    },
    updateTime = new Date().getTime(),
    isActive: boolean = true
  ) {
    this.code = code;
    this.name = name;
    this.introHeading = introHeading;
    this.introContent = introContent;
    this.eventGuidelines = eventGuidelines;
    this.welcomeText = welcomeText;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.contractNumber = contractNumber;
    this.contractExpiry = contractExpiry;
    this.poc1Name = poc1Name;
    this.poc1Email = poc1Email;
    this.poc1PhoneNo = poc1PhoneNo;
    this.poc2Name = poc2Name;
    this.poc2Email = poc2Email;
    this.poc2PhoneNo = poc2PhoneNo;
    this.infoQuestions = infoQuestions;
    this.disclaimers = disclaimers;
    this.landingPage = landingPage;
    this.updateTime = updateTime;
    this.isActive = isActive;
  }
}

export type Guideline = {
  heading: string;
  text: string;
};

export type LandingPage = {
  startTime: number;
  endTime: number;
  timezoneOffset: number;
  pageLink: string;
};

import axios from "axios";
import { encodeQueryFilter } from "./utils";
const ACTIVITY_LOGS_URL = "/api/activity-log";

export function fetchLogs(filter: any) {
  let url = `${ACTIVITY_LOGS_URL}?filter=${encodeQueryFilter(filter)}`;
  return axios({
    url,
    method: "get",
  });
}

export function fetchLogsCount(filter: any) {
  let url = `${ACTIVITY_LOGS_URL}/count?where=${encodeQueryFilter(filter)}`;
  return axios({
    url,
    method: "get",
  });
}

export function downloadLogs(filter: any, timezoneOffset: string) {
  let url = `${ACTIVITY_LOGS_URL}/download-activity-log?filter=${encodeQueryFilter(
    filter
  )}&timezoneOffset=${timezoneOffset}`;
  return axios({
    url,
    method: "get",
    responseType: "blob",
  });
}

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { addDays } from "date-fns";

import {
  downloadFile,
  getEndOfDayTimestamp,
  getFormattedDate,
  getFormattedTime,
  getLocalDateString,
  getStartOfDayTimestamp,
} from "../../../utils";
import {
  downloadEventReport,
  fetchEventReport,
  fetchEventReportCount,
} from "../../../services/events";
import { fetchEventWiseOrderCount } from "../../../services/orders";
import { EventOrderCount } from "../../../ts/models/Order";
import { RootState } from "../../../store";
import { Column } from "../../../ts/types";
import EventReportDetails from "../ReportDetails/EventReportDetails";
import MultiStatusView from "../../../components/Status/MultiStatusView";
import TableContainer from "../../../components/CustTable/TableContainer";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import LinkButton from "../../../components/Buttons/LinkButton";
import SearchBar from "../../../components/SearchBar/SearchBar";
import classes from "./EventReport.module.css";
import Drawer from "../../../components/Drawer/Drawer";
import Select from "../../../components/Inputs/Select";
import Table from "../../../components/CustTable/Table";
import Event from "../../../ts/models/Event";
import Input from "../../../components/Inputs/Input";
import Card from "../../../components/Card/Card";

const pageSize = 20;

const EventReport = () => {
  const pods = useSelector((state: RootState) => state.pods.pods);
  const serviceMaster = useSelector(
    (state: RootState) => state.masters.services
  );
  const [orderCounts, setOrderCounts] = useState(new Map<string, number>());
  const [events, setEvents] = useState<Event[]>([]);
  const [showReport, setShowReport] = useState<
    { show: false } | { show: true; data: Event }
  >({ show: false });
  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(
    getStartOfDayTimestamp(getLocalDateString(Date.now()))
  );
  const [endDate, setEndDate] = useState(
    getEndOfDayTimestamp(getLocalDateString(addDays(new Date(), 6).getTime()))
  );
  const [podId, setPodId] = useState("");

  useEffect(() => {
    if (podId) getEventsData();
  }, [currPageNo]);

  useEffect(() => {
    if (events.length !== 0) {
      const eventIds = events.map((event) => event.id);
      fetchEventWiseOrderCount(eventIds)
        .then((res) => {
          if (res.status === 200) {
            var counts = new Map<string, number>();
            res.data.forEach((event: EventOrderCount) => {
              counts.set(event.eventId, event.orderCount);
            });
            setOrderCounts(counts);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOrderCounts(new Map<string, number>());
    }
  }, [events]);

  const getReportFilter = () => {
    const filter: any = {
      podId,
      startDate,
      endDate,
      offset: (currPageNo - 1) * pageSize,
      count: pageSize,
    };

    if (searchText) {
      filter.eventName = searchText;
    }

    return filter;
  };

  const viewDetailsHandler = (event: Event) => {
    setShowReport({ show: true, data: event });
  };

  const getEventsData = async () => {
    const filter = getReportFilter();
    try {
      const [eventsRes, countRes] = await Promise.all([
        fetchEventReport(filter),
        fetchEventReportCount(filter),
      ]);

      if (eventsRes?.status === 200) {
        setEvents(eventsRes.data);
      }
      if (countRes?.status === 200) {
        const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
        setTotalPages(noOfPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getEventReportFileName = () => {
    let fileName = `EventReport_${getLocalDateString(
      startDate
    )}_${getLocalDateString(endDate)}.csv`;
    return fileName.replaceAll("-", "_");
  };

  const downloadReportHandler = async () => {
    const filter = getReportFilter();
    delete filter.offset;
    delete filter.count;

    if (filter) {
      try {
        const res = await downloadEventReport(filter);
        downloadFile(res, getEventReportFileName());
      } catch (error) {
        console.log(error);
      }
    }
  };

  const checkStatus = (event: Event) => {
    const currTimestamp = Date.now();

    if (event.startTime < currTimestamp && currTimestamp < event.endTime) {
      return "Yes";
    }
    return "No";
  };

  const eventReportTableColumn: Column<Event>[] = [
    {
      header: "ID",
      accessor: (_, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "8%",
    },
    {
      header: "EVENT NAME",
      accessor: (e) => e.name,
      width: "20%",
    },
    {
      header: "START DATE - END DATE",
      accessor: (e) => {
        return (
          <div>
            <span style={{ display: "block" }}>{`${getFormattedDate(
              e.startTime
            )} - ${getFormattedDate(e.endTime)}`}</span>
            <span>{`${getFormattedTime(e.startTime)} - ${getFormattedTime(
              e.endTime
            )}`}</span>
          </div>
        );
      },
    },
    {
      header: "ORDERS",
      accessor: (e) => orderCounts.get(e.id) ?? 0,
    },
    {
      header: "MEDICAL SERVICES",
      accessor: (e) => {
        const serviceNames = e.services.map((serviceWithId) => {
          const service = serviceMaster.find(
            (service) => service.id === serviceWithId.serviceId
          );

          if (service) {
            return service.name;
          } else {
            return "";
          }
        });
        return <MultiStatusView names={serviceNames} maxCount={2} />;
      },
      width: "20%",
    },
    {
      header: "ACTIVE",
      accessor: (e) => checkStatus(e),
      style: { textAlign: "center" },
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <div className={classes.actions}>
          <LinkButton
            onClick={() => viewDetailsHandler(e)}
            label='View Details'
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {showReport.show && (
        <Drawer
          name='Event Report'
          onClose={() => setShowReport({ show: false })}>
          <EventReportDetails
            totalRegistered={orderCounts}
            evenReportData={showReport.data}
          />
        </Drawer>
      )}
      <Card>
        <div className={classes.filterContainer}>
          <div className={classes.filters}>
            <Select
              label='Select POD'
              htmlFor='pod'
              options={pods}
              defaultOption={"Select POD"}
              nameExtractor='name'
              value={podId}
              onChange={(e) => setPodId(e.target.value)}
              className={classes.select}
            />
            <Input
              type='date'
              name='Start Date'
              className={classes.filterDates}
              value={getLocalDateString(startDate || Date.now())}
              onChange={(e) => {
                const startDate = getStartOfDayTimestamp(e.target.value);
                setStartDate(startDate);
                if (startDate > endDate)
                  setEndDate(getEndOfDayTimestamp(e.target.value));
              }}
            />
            <Input
              type='date'
              name='End Date'
              className={classes.filterDates}
              value={getLocalDateString(endDate || Date.now())}
              onChange={(e) => setEndDate(getEndOfDayTimestamp(e.target.value))}
              min={getLocalDateString(startDate)}
            />
            <div className={classes.searchBar}>
              <SearchBar
                placeholder='Search by event name'
                onChange={(text) => {
                  setSearchText(text.trim());
                }}
                greyBackground
              />
            </div>
          </div>
          <div className={classes.filterBtnContainer}>
            <PrimaryButton
              className={classes.filterBtn}
              name='Filter'
              onClick={() => getEventsData()}
              disabled={!podId}
            />
            <PrimaryButton
              name='Download'
              onClick={downloadReportHandler}
              disabled={!podId}
            />
          </div>
        </div>
      </Card>
      <div className={classes.main}>
        <TableContainer>
          <Table<Event>
            columns={eventReportTableColumn}
            data={events}
            isPaginated
            pageNo={currPageNo}
            totalPages={totalPages}
            onPrevClick={() => setCurrPageNo((state) => state - 1)}
            onNextClick={() => setCurrPageNo((state) => state + 1)}
          />
        </TableContainer>
      </div>
    </>
  );
};
export default EventReport;

import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../store";
import { getDaysCount, getFormattedDate, getFormattedTime } from "../../utils";
import { RegistrationCount } from "../../ts/types";
import Event from "../../ts/models/Event";
import classes from "./EventCard.module.css";
import Status from "../../components/Status/Status";
import InputLabel from "../../components/Inputs/InputLabel";
import KebabMenu from "../../components/KebabMenu/KebabMenu";
import ServiceStatus from "../../components/Status/ServiceStatus";
import { UserRole } from "../../ts/enums";

type Props = {
  event: Event;
  registrationCountData: RegistrationCount[];
  orderCount: number;
  onEdit: (data: Event) => void;
  onView: (data: Event) => void;
  onStatusUpdate: (id: string, isActive: boolean) => void;
  onCopy: (data: Event) => void;
  onDelete: (data: Event, orderCount: number) => void;
};

const EventCard: React.FC<Props> = ({
  event,
  registrationCountData,
  orderCount,
  onEdit,
  onView,
  onStatusUpdate,
  onCopy,
  onDelete,
}) => {
  const servicesMaster = useSelector(
    (state: RootState) => state.masters.services
  );
  const userData = useSelector((state: RootState) => state.users.currUserData);

  const { id, name, startTime, endTime, services, isActive, capacity } = event;
  const totalDays = getDaysCount(startTime, endTime);

  const isUpcoming = startTime > Date.now();
  const isLive = startTime < Date.now() && endTime > Date.now();

  // Find the registration counts data for this event.
  const data = registrationCountData.find((data) => data.eventId === id);

  const serviceData = services.map((eventService) => {
    const masterService = servicesMaster.find(
      (service) => service.id === eventService.serviceId
    );

    var leftCapacity = capacity * totalDays;
    if (data) {
      // Find the registration count for the service.
      const registration = data.registrations.find(
        (registration) => registration.serviceId === eventService.serviceId
      );
      if (registration?.count) {
        leftCapacity -= registration.count;
      }
    }

    if (masterService) {
      return {
        serviceName: masterService.name,
        leftCapacity,
      };
    } else {
      return {
        serviceName: "",
        leftCapacity,
      };
    }
  });

  const getOptions = () => {
    const options = [
      { name: "View Details", onClick: () => onView(event) },
      { name: "Edit", onClick: () => onEdit(event) },
      { name: "Copy", onClick: () => onCopy(event) },
      {
        name: isActive ? "De-activate" : "Activate",
        onClick: () => onStatusUpdate(id, !isActive),
      },
    ];

    if (
      userData.role === UserRole.Admin ||
      userData.role === UserRole.SuperAdmin
    ) {
      options.push({
        name: "Delete",
        onClick: () => onDelete(event, orderCount),
      });
    }
    return options;
  };
  return (
    <>
      <div className={classes.main}>
        <div className={classes.topContainer}>
          <div>
            <Status
              name={isUpcoming ? "Upcoming" : isLive ? "Live" : "Closed"}
              status={isUpcoming ? "upcoming" : isLive ? "active" : "closed"}
            />
          </div>
          <KebabMenu options={getOptions()} isHorizontal />
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.nameDateContainer}>
            <div className={classes.name}>{name}</div>
            <div className={classes.date}>
              <div>
                <span style={{ display: "block" }}>{`${getFormattedDate(
                  startTime
                )} - ${getFormattedDate(endTime)}`}</span>
                <span style={{ display: "block" }}>{`${getFormattedTime(
                  startTime
                )} - ${getFormattedTime(endTime)}`}</span>
              </div>
            </div>
          </div>
          <div className={classes.order}>
            <div>{`Total ${orderCount} Orders`}</div>
          </div>
          <div className={classes.medServiceContainer}>
            <InputLabel label='Medical Services' />
            <div className={classes.medServices}>
              {serviceData.map((service, idx) => (
                <ServiceStatus
                  key={idx}
                  serviceName={service.serviceName}
                  count={service.leftCapacity}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;

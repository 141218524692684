import Modal, { ModalBody, ModalFooter } from "../../components/Modal/Modal";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import classes from "./UploadCSV.module.css";
import { useState } from "react";

type props = {
  uploadHandler: (file: any) => void;
  onClose: () => void;
};

const UploadCSV: React.FC<props> = ({ uploadHandler, onClose }) => {
  const [file, setFile] = useState<any>();

  return (
    <Modal title={<>Upload CSV</>} onClose={onClose}>
      <>
        <ModalBody>
          <div className={classes.main}>
            <div>
              <label>Select CSV File</label>
              <input
                type='file'
                accept='.csv'
                onChange={(e) => {
                  setFile(e.target.files?.item(0));
                }}
                className={classes.input}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton
              name='Upload'
              onClick={() => {
                uploadHandler(file);
              }}
              disabled={!file}
            />
            <PrimaryButton
              style={{ marginLeft: "1rem" }}
              name='Cancel'
              onClick={() => onClose()}
              transparent
            />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default UploadCSV;

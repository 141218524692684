import { useState } from "react";
import copyIcon from "../../assets/icons/copy.svg";
import classes from "./CopyButton.module.css";
import { copyTextToClipboard, getClasses } from "../../utils";

type Props = {
  copyText: string;
  tipText?: string,
  className?: string;
  style?: React.CSSProperties;
};

const CopyButton: React.FC<Props> = ({
  copyText,
  tipText = "Copy",
  className = "",
  style
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const copyHandler = () => {
    copyTextToClipboard(copyText).then(() => {
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 1000);
    })
  };

  return (
    <div className={getClasses(classes.main, className)} style={style}>
      <img
        className={classes.copyIcon}
        src={copyIcon}
        alt={tipText}
        title={tipText}
        onClick={copyHandler}
      />
      {
        showPopup &&
          <div className={classes.copiedPopup}>
            <span>Copied to clipboard</span>
          </div>
      }
    </div>
  );
};

export default CopyButton;

import Modal, { ModalBody } from "../Modal/Modal";
import classes from "./HelpModal.module.css";

type Props = {
  title: string;
  content: any;
  onClose: () => void;
};

const HelpModal: React.FC<Props> = ({ title, content, onClose }) => {
  return (
    <Modal title={<>{title}</>} onClose={onClose}>
      <>
        <ModalBody>
          <div className={classes.main}>
            {content && (
              <iframe
                title='help'
                src={content}
                frameBorder='0'
                scrolling='yes'
                width={"100%"}
                height={"100%"}
                sandbox=''
              />
            )}
          </div>
        </ModalBody>
      </>
    </Modal>
  );
};

export default HelpModal;

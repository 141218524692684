import { useState } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../store";
import { warningActions } from "../../store/slices/WarningSlice";
import { changePassword } from "../../services/login";
import { ChangePasswordData } from "../../ts/types";
import Modal, { ModalBody, ModalFooter } from "../../components/Modal/Modal";
import classes from "./ChangePassword.module.css";
import Input from "../../components/Inputs/Input";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

type props = {
  onClose: () => void;
};
const getInitialFieldErrors = () => {
  return {
    confirmPassword: false,
  };
};

const ChangePassword: React.FC<props> = ({ onClose }) => {
  const dispatch: AppDispatch = useDispatch();

  const [userDetails, setUserDetails] = useState<ChangePasswordData>({
    currentPassword: "",
    newPassword: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fieldErrors, setFieldErrors] = useState(getInitialFieldErrors());

  const isFieldsValid = (): boolean => {
    const fieldErrors = getInitialFieldErrors();
    if (userDetails.newPassword !== confirmPassword) {
      fieldErrors.confirmPassword = true;
    }

    setFieldErrors(fieldErrors);

    return Object.values(fieldErrors).every((value) => value === false);
  };

  const updatePasswordHandler = async () => {
    if (!isFieldsValid()) {
      return;
    }
    try {
      const res = await changePassword(userDetails);
      if (res.status === 200) {
        if (res.data.success) {
          onClose();
        } else {
          dispatch(
            warningActions.showWarning({
              message:
                res.data.errorCode === "wrong_password"
                  ? "Invalid current password"
                  : "Password Required",
            })
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isRequiredDataMissing = () => {
    if (
      !userDetails.currentPassword?.trim() ||
      !userDetails.newPassword?.trim() ||
      !confirmPassword?.trim()
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      title={<>Change Password</>}
      onClose={onClose}
      style={{ width: "20rem" }}>
      <>
        <ModalBody>
          <div className={classes.main}>
            <Input
              name='Current Password'
              type='password'
              value={userDetails.currentPassword}
              onChange={(e) => {
                setUserDetails((prevState) => {
                  return {
                    ...prevState,
                    currentPassword: e.target.value,
                  };
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  updatePasswordHandler();
                }
              }}
              className={classes.inputContainer}
              autoComplete='new-password'
            />
            <Input
              name='New Password'
              type='password'
              value={userDetails.newPassword}
              onChange={(e) => {
                setUserDetails((prevState) => {
                  return {
                    ...prevState,
                    newPassword: e.target.value,
                  };
                });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  updatePasswordHandler();
                }
              }}
              className={classes.inputContainer}
            />
            <Input
              name='Confirm Password'
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              isInvalid={fieldErrors.confirmPassword}
              invalidText={"Password does not match"}
              className={classes.inputContainer}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className={classes.actions}>
            <PrimaryButton
              className={classes.actionBtn}
              name={"Update"}
              onClick={updatePasswordHandler}
              style={{ marginRight: "1rem" }}
              disabled={isRequiredDataMissing()}
            />
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={onClose}
              transparent
            />
          </div>
        </ModalFooter>
      </>
    </Modal>
  );
};

export default ChangePassword;

import React from "react";
import classes from "./MultiStatusView.module.css";
import Status from "./Status";

type Props = {
  names: string[];
  maxCount?: number;
};

const MultiStatusView: React.FC<Props> = ({ names, maxCount = 1 }) => {
  const getStatuses = (): any[] => {
    const statuses: any = [];
    for (let i = 0; i < maxCount; i++) {
      if (names[i]) statuses.push(<Status key={names[i]} name={names[i]} />);
    }
    return statuses;
  };

  return (
    <div className={classes.main}>
      {names.length > 0 && getStatuses().map((status) => status)}
      {names.length > maxCount ? (
        <div
          className={classes.more}
          title={names.slice(maxCount, names.length).join(", ")}>
          +{names.length - maxCount}
        </div>
      ) : null}
    </div>
  );
};

export default MultiStatusView;

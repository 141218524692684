import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { MedicationAdministered, PatientReportData } from "../../ts/types";
import { fetchMedications, fetchSymptoms } from "../../services/masters";
import { RootState } from "../../store";
import {
  getAge,
  getFormattedDateTime,
  getFormattedDob,
  getPatientFullName,
  getBloodGlucoseUnit,
  getTemperatureUnit,
  addLocalOffsetToTimestamp,
} from "../../utils";
import { InsuranceStatus } from "../../ts/enums";
import CommonMaster from "../../ts/models/CommonMaster";
import ClosableBox from "../../components/Box/ClosableBox";
import DataField from "../../components/Box/DataField";
import classes from "./PatientDataDrawer.module.css";
import Event from "../../ts/models/Event";
import Card from "../../components/Card/Card";
import Medication from "../../ts/models/Medication";
import Patient from "../../ts/models/Patient";

type Props = {
  patientReportData: PatientReportData;
  event?: Event;
  checkInUpdateTime?: number;
  updatedPatientData?: Patient;
};

const PatientDataDrawer: React.FC<Props> = ({
  patientReportData,
  event,
  checkInUpdateTime,
  updatedPatientData,
}) => {
  const { genders, dispositions, services, incentives, ethnicGroups, races } =
    useSelector((state: RootState) => state.masters);
  const [symptoms, setSymptoms] = useState<CommonMaster[]>([]);
  const [medicationData, setMedicationData] =
    useState<Map<string, Medication>>();

  const { patient, encounter } = patientReportData;
  const { dob, gender } = patient;
  const encounterDetails = encounter?.encounterDetails;
  const discharge = encounter?.discharge;
  const dischargeIncentives: { description: string; serialNumber: string }[] =
    [];

  discharge?.incentives?.forEach((incentiveData) => {
    const masterIncentive = incentives.find(
      (incentive) => incentiveData.incentive === incentive.id
    );
    if (masterIncentive) {
      dischargeIncentives.push({
        description: masterIncentive.description,
        serialNumber: incentiveData.serialNumber,
      });
    }
  });

  useEffect(() => {
    const symptoms =
      encounterDetails
        ?.map((encounterDetail) => encounterDetail.observations?.symptoms)
        .flat()
        .filter((ele) => !!ele) || [];

    const getSymptoms = async () => {
      const symptomOIDs = symptoms.map((symptom) => ({
        $oid: symptom,
      }));

      if (symptomOIDs.length) {
        const filter = {
          offset: 0,
          where: {
            _id: {
              $in: symptomOIDs,
            },
          },
        };
        const { status, data } = await fetchSymptoms(filter);
        if (status === 200) {
          setSymptoms(data);
        }
      }
    };

    if (symptoms.length) {
      getSymptoms();
    }
  }, [encounterDetails]);

  const getGender = (gender: string): string => {
    const genderMaster = genders.find((gen) => gen.id === gender);

    if (genderMaster) {
      return genderMaster.description;
    }
    return "";
  };

  const getEthnicity = (ethnicity: string): string => {
    const ethnicGroupsMaster = ethnicGroups.find((eth) => eth.id === ethnicity);

    if (ethnicGroupsMaster) {
      return ethnicGroupsMaster.description;
    }
    return "";
  };

  const getRace = (race: string): string => {
    const racesMaster = races.find((e) => e.id === race);

    if (racesMaster) {
      return racesMaster.description;
    }
    return "";
  };

  const getDisposition = (): string => {
    const disposition = dispositions.find(
      (disposition) => disposition.id === encounter?.discharge?.disposition
    );

    if (disposition) {
      return disposition.description;
    }

    return "";
  };

  const getService = (serviceId: string) => {
    return services.find((service) => service.id === serviceId)?.name || "";
  };

  const getSymptoms = (symptomIds?: string[]) => {
    return symptoms
      .filter((symptom) => symptomIds?.includes(symptom.id))
      .map((symptom) => symptom.description)
      .join(", ");
  };

  useEffect(() => {
    const medications =
      encounterDetails
        ?.map(
          (encounterDetail) =>
            encounterDetail.administration?.medicationAdministered
        )
        .flat()
        .filter((ele) => !!ele) || [];

    const getMedicationData = async () => {
      const medicationIds = medications.map((medication) => ({
        $oid: medication?.medication,
      }));

      if (medicationIds.length) {
        const filter = {
          where: {
            _id: {
              $in: medicationIds,
            },
          },
        };
        const { status, data } = await fetchMedications(filter);
        if (status === 200) {
          let medicationData = new Map<string, Medication>();
          data.forEach((medication: Medication) => {
            medicationData.set(medication.id, medication);
          });

          setMedicationData(medicationData);
        }
      }
    };

    if (medications.length) {
      getMedicationData();
    }
  }, [encounterDetails]);

  const getMedicationName = (medicationId: string) => {
    if (medicationData?.get(medicationId)) {
      return medicationData.get(medicationId)?.name;
    }
    return "";
  };

  const getMedication = (
    medicationAdministeredData?: MedicationAdministered[]
  ) => {
    return (
      <DataField
        name='Medication:'
        data={
          <>
            {medicationAdministeredData?.length ? (
              medicationAdministeredData.map((medicationAdministered) => (
                <div key={medicationAdministered.medication}>
                  <div>{`${getMedicationName(
                    medicationAdministered.medication || ""
                  )} ${medicationAdministered.dosage}`}</div>
                  <div className={classes.administeredTime}>
                    {getFormattedDateTime(
                      medicationAdministered.administeredTime || 0
                    )}
                  </div>
                </div>
              ))
            ) : (
              <>None</>
            )}
          </>
        }
      />
    );
  };

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        {checkInUpdateTime && (
          <Card className={classes.card}>
            <div className={classes.patientName}>
              {getPatientFullName(patient)}
            </div>
            <div className={classes.age}>
              {`${typeof dob === "number" && getAge(dob)} years / ${getGender(
                gender
              )}`}
            </div>
            <DataField name='Event' data={event?.name} />
            <DataField
              name='Check-in Date'
              data={
                checkInUpdateTime
                  ? getFormattedDateTime(checkInUpdateTime)
                  : "Not checked-in"
              }
            />
          </Card>
        )}
        {encounterDetails ? (
          encounterDetails.map((encounterDetail, idx) => (
            <ClosableBox
              name={getService(encounterDetail.service)}
              defaultState
              key={idx}>
              <div className={classes.service}>
                {encounter?.status === "SERVICE_ADMINISTERED" && (
                  <>
                    <div className={classes.medications}>
                      Medication Administered:
                    </div>
                    <div className={classes.medicationsAdministered}>
                      {getMedication(
                        encounterDetail.administration?.medicationAdministered
                      )}
                    </div>
                  </>
                )}
                {encounter?.status === "UNDER_OBSERVATION" && (
                  <>
                    <div className={classes.observations}>Observations:</div>
                    {!!encounterDetail.observations?.bloodGlucose?.length && (
                      <DataField
                        name='Blood Glucose:'
                        data={`${
                          encounterDetail.observations.bloodGlucose[
                            encounterDetail.observations.bloodGlucose.length - 1
                          ].value
                        } ${getBloodGlucoseUnit(
                          encounterDetail.observations.bloodGlucose[
                            encounterDetail.observations.bloodGlucose.length - 1
                          ].unit
                        )}`}
                      />
                    )}
                    {!!encounterDetail.observations?.bloodPressure?.length && (
                      <DataField
                        name='Blood Pressure:'
                        data={
                          <div className={classes.bloodPressure}>
                            <div>
                              {` ${
                                encounterDetail.observations.bloodPressure[
                                  encounterDetail.observations.bloodPressure
                                    .length - 1
                                ].systolic
                              }/${
                                encounterDetail.observations.bloodPressure[
                                  encounterDetail.observations.bloodPressure
                                    .length - 1
                                ].diastolic
                              } mmHg`}
                            </div>
                          </div>
                        }
                      />
                    )}
                    {!!encounterDetail.observations?.pulse?.length && (
                      <DataField
                        name='Pulse:'
                        data={`${
                          encounterDetail.observations.pulse[
                            encounterDetail.observations.pulse.length - 1
                          ].value
                        } beats/min`}
                      />
                    )}
                    {!!encounterDetail.observations?.respiratoryRate
                      ?.length && (
                      <DataField
                        name='Respiratory Rate:'
                        data={`${
                          encounterDetail.observations.respiratoryRate[
                            encounterDetail.observations.respiratoryRate
                              .length - 1
                          ].value
                        } breaths/min`}
                      />
                    )}
                    {!!encounterDetail.observations?.spO2?.length && (
                      <DataField
                        name='SpO2:'
                        data={`${
                          encounterDetail.observations.spO2[
                            encounterDetail.observations.spO2.length - 1
                          ].value
                        }%`}
                      />
                    )}
                    {!!encounterDetail.observations?.temperature?.length && (
                      <DataField
                        name='Temperature:'
                        data={`${
                          encounterDetail.observations.temperature[
                            encounterDetail.observations.temperature.length - 1
                          ].value
                        } ${getTemperatureUnit(
                          encounterDetail.observations.temperature[
                            encounterDetail.observations.temperature.length - 1
                          ].unit
                        )}`}
                      />
                    )}
                    <div className={classes.symptoms}>Symptoms:</div>
                    <div className={classes.symptomsDescription}>
                      {getSymptoms(encounterDetail.observations?.symptoms)}
                    </div>
                  </>
                )}
              </div>
            </ClosableBox>
          ))
        ) : (
          <></>
        )}
        <>
          {encounter?.status === "DISCHARGED" && (
            <ClosableBox name='Discharge' defaultState>
              <DataField name='Disposition:' data={getDisposition()} />
              <DataField
                name='Incentives:'
                data={
                  <>
                    {dischargeIncentives.length ? (
                      dischargeIncentives.map((incentive, idx) => (
                        <div key={idx}>
                          {incentive.description}
                          {incentive.serialNumber
                            ? ` (${incentive.serialNumber})`
                            : ""}
                        </div>
                      ))
                    ) : (
                      <>None</>
                    )}
                  </>
                }
              />
            </ClosableBox>
          )}
        </>
        <>
          {!!updatedPatientData?.id && (
            <Card className={classes.card}>
              <div>
                {!!updatedPatientData?.firstName ? (
                  <DataField
                    name='Patient Name:'
                    data={`${updatedPatientData?.firstName} ${updatedPatientData?.middleName} ${updatedPatientData?.lastName}`}
                  />
                ) : (
                  <DataField
                    name='Patient Name:'
                    data={getPatientFullName(patient)}
                  />
                )}
                {!!updatedPatientData?.dob && (
                  <DataField
                    name='Date Of Birth:'
                    data={
                      typeof updatedPatientData?.dob === "number"
                        ? getFormattedDob(
                            addLocalOffsetToTimestamp(updatedPatientData?.dob)
                          )
                        : ""
                    }
                  />
                )}
                {!!updatedPatientData?.gender && (
                  <DataField
                    name='Gender:'
                    data={getGender(updatedPatientData?.gender)}
                  />
                )}
                {!!updatedPatientData?.race && (
                  <DataField
                    name='Race:'
                    data={getRace(updatedPatientData?.race)}
                  />
                )}
                {!!updatedPatientData?.ethnicity && (
                  <DataField
                    name='Ethnicity:'
                    data={getEthnicity(updatedPatientData?.ethnicity)}
                  />
                )}
                {!!updatedPatientData?.address && (
                  <DataField
                    name='Address:'
                    data={updatedPatientData?.address}
                  />
                )}
                {!!updatedPatientData?.zipCode && (
                  <DataField
                    name='Zip Code:'
                    data={updatedPatientData?.zipCode}
                  />
                )}
                {!!updatedPatientData?.city && (
                  <DataField name='City:' data={updatedPatientData?.city} />
                )}
                {!!updatedPatientData?.country && (
                  <DataField
                    name='Country:'
                    data={updatedPatientData?.country}
                  />
                )}
                {!!updatedPatientData?.state && (
                  <DataField name='State:' data={updatedPatientData?.state} />
                )}
                {typeof updatedPatientData?.haveInsurance === "boolean" && (
                  <DataField
                    name='Have insurance:'
                    data={
                      updatedPatientData?.haveInsurance
                        ? InsuranceStatus.Yes
                        : InsuranceStatus.No
                    }
                  />
                )}
                {!!updatedPatientData?.insuranceDetails?.providerName && (
                  <DataField
                    name='Insurance Company:'
                    data={updatedPatientData?.insuranceDetails?.providerName}
                  />
                )}
                {!!updatedPatientData?.insuranceDetails?.policyNumber && (
                  <DataField
                    name='Policy Number:'
                    data={updatedPatientData?.insuranceDetails?.policyNumber}
                  />
                )}
                {!!updatedPatientData?.insuranceDetails?.groupNumber && (
                  <DataField
                    name='Group Number:'
                    data={updatedPatientData?.insuranceDetails?.groupNumber}
                  />
                )}
                {typeof updatedPatientData?.isPrimaryMember === "boolean" && (
                  <DataField
                    name='Is primary member:'
                    data={
                      updatedPatientData?.isPrimaryMember
                        ? InsuranceStatus.Yes
                        : InsuranceStatus.No
                    }
                  />
                )}
                {!!updatedPatientData?.primaryMemberName && (
                  <DataField
                    name='Primary Member Name:'
                    data={updatedPatientData?.primaryMemberName}
                  />
                )}
                {!!updatedPatientData?.primaryMemberDob && (
                  <DataField
                    name='Primary Member Date Of Birth:'
                    data={
                      typeof updatedPatientData?.primaryMemberDob === "number"
                        ? getFormattedDob(
                            addLocalOffsetToTimestamp(
                              updatedPatientData?.primaryMemberDob
                            )
                          )
                        : ""
                    }
                  />
                )}
                {typeof updatedPatientData?.haveSecondaryInsurance ===
                  "boolean" && (
                  <DataField
                    name='Have secondary insurance:'
                    data={
                      updatedPatientData?.haveSecondaryInsurance
                        ? InsuranceStatus.Yes
                        : InsuranceStatus.No
                    }
                  />
                )}
                {!!updatedPatientData?.secondaryInsuranceDetails
                  ?.providerName && (
                  <DataField
                    name='Insurance Company (secondary):'
                    data={
                      updatedPatientData?.secondaryInsuranceDetails
                        ?.providerName
                    }
                  />
                )}
                {!!updatedPatientData?.secondaryInsuranceDetails
                  ?.policyNumber && (
                  <DataField
                    name='Policy Number (secondary):'
                    data={
                      updatedPatientData?.secondaryInsuranceDetails
                        ?.policyNumber
                    }
                  />
                )}
                {!!updatedPatientData?.secondaryInsuranceDetails
                  ?.groupNumber && (
                  <DataField
                    name='Group Number (secondary):'
                    data={
                      updatedPatientData?.secondaryInsuranceDetails?.groupNumber
                    }
                  />
                )}
              </div>
            </Card>
          )}
        </>
      </div>
    </div>
  );
};

export default PatientDataDrawer;

import { Navigate, Route, Routes, useParams } from "react-router";

import {
  BASE_URL,
  BILLING_REPORT_URL,
  CREATE_EVENT_URL,
  CREATE_POD_URL,
  DASHBOARD_URL,
  DISCLAIMERS_URL,
  DISPOSITIONS_URL,
  ENVIRONMENTAL_ALLERGIES_URL,
  ETHNIC_GROUPS_URL,
  EVENT_CALENDAR_URL,
  EVENT_REPORT_URL,
  EVENTS_URL,
  GENDERS_URL,
  INCENTIVES_URL,
  MASTER_MANAGEMENT_URL,
  MEDICATIONS_URL,
  PATIENT_REPORT_URL,
  POD_URL,
  PROCEDURES_URL,
  PRODUCTS_URL,
  RELATIONSHIPS_URL,
  REPORT_URL,
  AGENCY_REPORT_URL,
  ROUTES_URL,
  SCREENING_QUESTIONS_URL,
  SERVICES_URL,
  SIGN_IN_URL,
  SITES_URL,
  SYMPTOMS_URL,
  VFC_ELIGIBILITIES_URL,
  USER_MANAGEMENT_URL,
  EVENT_DETAILS_URL,
  ACKNOWLEDGEMENTS_URL,
  CREATE_USER_URL,
  CHECKIN_LIST_URL,
  MEDICATION_ALLERGIES_URL,
  UPDATE_EVENT_URL,
  UPDATE_POD_URL,
  UPDATE_USER_URL,
  FORGOT_PASSWORD_URL,
  RACES_URL,
  RESET_PASSWORD_URL,
  INFO_QUESTIONS_URL,
  LOGS_URL,
  HELP_URL,
  SURVEYS_URL,
  CREATE_SURVEY_URL,
  UPDATE_SURVEY_URL,
} from "./urls";
import { Master, Permission } from "../ts/enums";
import BillingFile from "../modules/BillingFile/BillingFile";
import Dashboard from "../modules/Dashboard/Dashboard";
import Events from "../modules/Events/Events";
import EventCalendar from "../modules/EventCalendar/EventCalendar";
import Incentives from "../modules/Masters/Incentive/Incentive";
import Masters from "../modules/Masters/Masters";
import Home from "../modules/Home/Home";
import SignIn from "../modules/SignIn/SignIn";
import Services from "../modules/Masters/Services/Services";
import CreatePOD from "../modules/Pod/CreatePOD";
import UserManagement from "../modules/UserManagement/UserManagement";
import Report from "../modules/Report/Report";
import CommonMasters from "../modules/Masters/CommonMaster/CommonMaster";
import PODs from "../modules/Pod/POD";
import EventReport from "../modules/Report/EventReport/EventReport";
import PatientReport from "../modules/Report/PatientReport/PatientReport";
import ScreeningQuestions from "../modules/Masters/ScreeningQuestion/ScreeningQuestions";
import CreateNewEvent from "../modules/Events/CreateEvent/CreateNewEvent";
import EventDetails from "../modules/Events/EventDetails/EventDetails";
import Acknowledgements from "../modules/Masters/Acknowledgement/Acknowledgements";
import CreateUser from "../modules/UserManagement/CreateUser";
import CheckInList from "../modules/CheckInList/CheckInList";
import Medication from "../modules/Masters/Medication/Medication";
import Products from "../modules/Masters/Product/Product";
import Protected from "./Protected";
import ForgotPassword from "../modules/ForgotPassword/ForgotPassword";
import AgencyReport from "../modules/Report/AgencyReport/AgencyReport";
import ResetPassword from "../modules/ForgotPassword/ResetPassword";
import InfoQuestions from "../modules/Masters/InfoQuestion/InfoQuestion";
import LogViewer from "../modules/LogViewer/LogViewer";
import UploadHelpDocument from "../modules/Masters/UploadHTML/UploadHelpDocument";
import Surveys from "../modules/Surveys/Surveys";
import CreateNewSurvey from "../modules/Surveys/CreateSurvey/CreateNewSurvey";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { authActions } from "../store/slices/AuthSlice";
import { logOutAndRemoveData } from "../store/actions/UserActions";
import { useEffect } from "react";

const sidebarRoutes = [
  {
    index: true,
    element: <Dashboard />,
  },
  {
    path: DASHBOARD_URL,
    element: <Dashboard />,
  },
  {
    path: POD_URL,
    element: <PODs />,
    requires: Permission.ManagePods,
  },
  {
    path: EVENTS_URL,
    element: <Events />,
    requires: Permission.ManageEvents,
  },
  {
    path: EVENT_CALENDAR_URL,
    element: <EventCalendar />,
    requires: Permission.ManageEvents,
  },
  {
    path: SURVEYS_URL,
    element: <Surveys />,
    requires: Permission.ManageEvents,
  },
  {
    path: CHECKIN_LIST_URL,
    element: <CheckInList />,
    requires: Permission.CreateReports,
  },
  {
    path: MASTER_MANAGEMENT_URL,
    element: <Masters />,
    requires: Permission.ManageMasters,
  },
  {
    path: USER_MANAGEMENT_URL,
    element: <UserManagement />,
    requires: Permission.ManageUsers,
  },
  {
    path: BILLING_REPORT_URL,
    element: <BillingFile />,
    requires: Permission.CreateReports,
  },
  {
    path: AGENCY_REPORT_URL,
    element: <AgencyReport />,
    requires: Permission.CreateReports,
  },
  {
    path: LOGS_URL,
    element: <LogViewer />,
    requires: Permission.ListActivityLogs,
  },
];

const masterManagementRoutes = [
  {
    path: SERVICES_URL,
    element: <Services />,
  },
  {
    path: PRODUCTS_URL,
    element: <Products />,
  },
  {
    path: GENDERS_URL,
    element: <CommonMasters masterName={Master.Genders} />,
  },
  {
    path: RACES_URL,
    element: <CommonMasters masterName={Master.Races} />,
  },
  {
    path: RELATIONSHIPS_URL,
    element: <CommonMasters masterName={Master.Relationships} />,
  },
  {
    path: ETHNIC_GROUPS_URL,
    element: <CommonMasters masterName={Master.EthnicGroups} />,
  },
  {
    path: INCENTIVES_URL,
    element: <Incentives />,
  },
  {
    path: SITES_URL,
    element: <CommonMasters masterName={Master.Sites} />,
  },
  {
    path: ROUTES_URL,
    element: <CommonMasters masterName={Master.Routes} />,
  },
  {
    path: MEDICATION_ALLERGIES_URL,
    element: (
      <CommonMasters masterName={Master.MedicationAllergies} isPaginated />
    ),
  },
  {
    path: ENVIRONMENTAL_ALLERGIES_URL,
    element: (
      <CommonMasters masterName={Master.EnvironmentalAllergies} isPaginated />
    ),
  },
  {
    path: SCREENING_QUESTIONS_URL,
    element: <ScreeningQuestions />,
  },
  {
    path: INFO_QUESTIONS_URL,
    element: <InfoQuestions />,
  },
  {
    path: MEDICATIONS_URL,
    element: <Medication />,
  },
  {
    path: DISPOSITIONS_URL,
    element: <CommonMasters masterName={Master.Dispositions} />,
  },
  {
    path: SYMPTOMS_URL,
    element: <CommonMasters masterName={Master.Symptoms} isPaginated />,
  },
  {
    path: PROCEDURES_URL,
    element: <CommonMasters masterName={Master.Procedures} />,
  },
  {
    path: DISCLAIMERS_URL,
    element: <CommonMasters masterName={Master.Disclaimers} />,
  },
  {
    path: ACKNOWLEDGEMENTS_URL,
    element: <Acknowledgements />,
  },
  {
    path: VFC_ELIGIBILITIES_URL,
    element: <CommonMasters masterName={Master.VfcEligibilities} />,
  },
  {
    path: HELP_URL,
    element: <UploadHelpDocument />,
  },
];

const protect = (element: JSX.Element, permission?: string) => {
  return <Protected permission={permission}>{element}</Protected>;
};

const Resource = () => {
  const { resourceName } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const storeResourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  let resource = resourceName;
  if (resource === "sign-in") {
    resource = "cimpar";
  }

  useEffect(() => {
    if (!isAuthenticated && resource) {
      dispatch(authActions.setResourceName(resource));
      localStorage.setItem("resourceName", resource);
    }
  }, [dispatch, isAuthenticated, resource]);

  if (!isAuthenticated && resource) {
    return <Navigate to={`${BASE_URL}/${resource}/${SIGN_IN_URL}`} replace />;
  }

  if (isAuthenticated && resourceName !== storeResourceName) {
    dispatch(logOutAndRemoveData());
    return null;
  }
  return <Navigate to={`${BASE_URL}`} replace />;
};

const Router = () => {
  const resourceName = useSelector(
    (state: RootState) => state.auth.resourceName
  );

  return (
    <Routes>
      <Route path='/:resourceName/*' element={<Resource />} />
      <Route
        path='*'
        element={<Navigate to={`${BASE_URL}/${resourceName}`} replace />}
      />
      <Route path={`${BASE_URL}/${resourceName}`} element={protect(<Home />)}>
        {sidebarRoutes.map((sidebarRoute, idx) => (
          <Route
            path={sidebarRoute.path}
            element={protect(sidebarRoute.element, sidebarRoute.requires)}
            index={sidebarRoute.index}
            key={idx}
          />
        ))}
        {masterManagementRoutes.map(({ path, element }, idx) => (
          <Route
            path={MASTER_MANAGEMENT_URL + `/${path}`}
            element={protect(element, Permission.ManageMasters)}
            key={idx}
          />
        ))}
        <Route
          path={POD_URL + `/${CREATE_POD_URL}`}
          element={protect(<CreatePOD />, Permission.ManagePods)}
        />
        <Route
          path={POD_URL + `/${UPDATE_POD_URL}`}
          element={protect(<CreatePOD />, Permission.ManagePods)}
        />
        <Route
          path={EVENTS_URL + `/${CREATE_EVENT_URL}`}
          element={protect(<CreateNewEvent />, Permission.ManageEvents)}
        />
        <Route
          path={EVENTS_URL + `/${UPDATE_EVENT_URL}`}
          element={protect(<CreateNewEvent />, Permission.ManageEvents)}
        />
        <Route
          path={EVENTS_URL + `/${EVENT_DETAILS_URL}`}
          element={protect(<EventDetails />, Permission.ManageEvents)}
        />
        <Route
          path={SURVEYS_URL + `/${CREATE_SURVEY_URL}`}
          element={protect(<CreateNewSurvey />, Permission.ManageEvents)}
        />
        <Route
          path={SURVEYS_URL + `/${UPDATE_SURVEY_URL}`}
          element={protect(<CreateNewSurvey />, Permission.ManageEvents)}
        />
        <Route
          path={USER_MANAGEMENT_URL + `/${CREATE_USER_URL}`}
          element={protect(<CreateUser />, Permission.ManageUsers)}
        />
        <Route
          path={USER_MANAGEMENT_URL + `/${UPDATE_USER_URL}`}
          element={protect(<CreateUser />, Permission.ManageUsers)}
        />
        <Route
          path={REPORT_URL}
          element={protect(<Report />, Permission.CreateReports)}>
          <Route
            index
            element={protect(<EventReport />, Permission.CreateReports)}
          />
          <Route
            path={EVENT_REPORT_URL}
            element={protect(<EventReport />, Permission.CreateReports)}
          />
          <Route
            path={PATIENT_REPORT_URL}
            element={protect(<PatientReport />, Permission.CreateReports)}
          />
        </Route>
      </Route>
      <Route
        path={BASE_URL + `/${resourceName}/${SIGN_IN_URL}`}
        element={<SignIn />}
      />
      <Route
        path={BASE_URL + `/${resourceName}/${FORGOT_PASSWORD_URL}`}
        element={<ForgotPassword />}
      />
      <Route
        path={BASE_URL + `/${resourceName}/${RESET_PASSWORD_URL}`}
        element={<ResetPassword />}
      />
    </Routes>
  );
};

export default Router;

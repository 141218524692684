import axios from "../axios";
import User, { UserStatus } from "../ts/models/User";
import { encodeQueryFilter } from "./utils";

const USERS_URL = "/api/users";
const UPLOAD_USERS_CSV_URL = USERS_URL + "/uploadcsv";
const DOWNLOAD_USERS_CSV_URL = USERS_URL + "/downloadcsv";

export function fetchUsers(filter?: any) {
  let url = USERS_URL;
  if (filter) {
    url += `?filter=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function fetchUsersCount(filter?: any) {
  let url = `${USERS_URL}/count`;
  if (filter) {
    url += `?where=${encodeQueryFilter(filter)}`;
  }
  return axios({
    url,
    method: "get",
  });
}

export function addUser(data: User) {
  return axios({
    url: USERS_URL,
    method: "post",
    data,
  });
}

export function updateUser(data: User) {
  return axios({
    url: `${USERS_URL}/${data.id}`,
    method: "put",
    data,
  });
}

export function updateUserStatus(data: UserStatus) {
  return axios({
    url: `${USERS_URL}/${data.id}`,
    method: "put",
    data,
  });
}

export function uploadUserData(file: any) {
  const formData = new FormData();
  formData.append("file", file);
  return axios({
    url: UPLOAD_USERS_CSV_URL,
    method: "post",
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function downloadUserData() {
  return axios({
    url: DOWNLOAD_USERS_CSV_URL,
    method: "get",
    responseType: "blob",
  });
}

export function fetchMedicalProfs(filter?: any) {
  filter = filter || {};

  filter.where = {
    ...filter.where,
    role: {
      $in: ["CLINICAL", "NON_CLINICAL", "NON_CLINICAL_PLUS"],
    },
  };

  return fetchUsers(filter);
}

export function fetchMedicalProfsCount(filter?: any) {
  filter = {
    ...filter,
    role: {
      $in: ["CLINICAL", "NON_CLINICAL", "NON_CLINICAL_PLUS"],
    },
  };

  return fetchUsersCount(filter);
}

export default class Acknowledgement {
  id!: string;
  type: string;
  description: string;

  constructor(type: string, description: string) {
    this.type = type;
    this.description = description;
  }
}
  
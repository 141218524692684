import ReactSelect, { components } from "react-select";

import classes from "./MultiSelect.module.css";
import Checkbox from "../Checkbox/Checkbox";
import InputLabel from "./InputLabel";

export type MultiSelectOption = {
  value: string;
  label: string;
};

type Props = {
  label: string;
  options: MultiSelectOption[];
  value: MultiSelectOption[];
  isDisabled?: boolean;
  isAll?: boolean;
  onChange: (newValue: any) => void;
};

const MultiSelect: React.FC<Props> = ({
  label,
  options,
  value,
  isDisabled,
  isAll = false,
  onChange,
}) => {
  const getOptions = () => {
    const newOptions = [...options];
    if (isAll) {
      if (newOptions.length)
        newOptions.unshift({
          label: "All",
          value: "*",
        });
    }
    return newOptions;
  };

  const newOnChange = (selectedOptions: any) => {
    let newSelectedOptions = [...selectedOptions];
    const isAllExistInSelected = value.some(
      (selectedOption) => selectedOption.value === "*"
    );

    const isAllExistInNewSelection = selectedOptions.some(
      (selectedOption: MultiSelectOption) => selectedOption.value === "*"
    );

    if (isAllExistInSelected) {
      if (isAllExistInNewSelection) {
        // Removing "All" option if any other option deselected.
        newSelectedOptions = selectedOptions.filter(
          (selectedOption: MultiSelectOption) => selectedOption.value !== "*"
        );
      } else {
        newSelectedOptions = [];
      }
    } else {
      //Checking whether "All" option is selected in new selection
      if (isAllExistInNewSelection) {
        newSelectedOptions = getOptions();
      }
    }

    onChange(newSelectedOptions);
  };

  const MultiValue = ({ getValue, index, children, ...props }: any) => {
    const isAllSelected = value.some(
      (selectedOption) => selectedOption.value === "*"
    );

    if (getValue().length === 1 && index === 0) {
      return (
        <components.MultiValue {...props}>{children}</components.MultiValue>
      );
    }

    if (index === 1) {
      if (isAllSelected) {
        return <>All selected</>;
      } else {
        return `${getValue().length} selected`;
      }
    }

    return <></>;
  };

  return (
    <div className={classes.main}>
      <InputLabel label={label} />
      <ReactSelect
        isDisabled={isDisabled}
        options={getOptions()}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          // Ignoring typescript error as return types does not matching for multi value
          // @ts-ignore
          MultiValue,
        }}
        onChange={isAll ? newOnChange : onChange}
        value={value}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: "1px solid var(--border-light-grey)",
            boxShadow: "none",
            "&:hover": {
              border: "1px solid var(--border-light-grey)",
            },
            borderRadius: "0.375rem",
            fontSize: "0.875rem",
            minHeight: "1.75rem",
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "100",
            fontSize: "0.875rem",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "white",
            },
            color: "black",
          }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            padding: "0px 4px",
          }),
          clearIndicator: (baseStyles, state) => ({
            ...baseStyles,
            padding: "0px 4px",
          }),
          valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            padding: "1px 8px",
          }),
        }}
      />
    </div>
  );
};

export default MultiSelect;

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <Checkbox
        checked={props.isSelected}
        id=''
        onChange={() => {}}
        label={props.label}
      />
    </components.Option>
  );
};

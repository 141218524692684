import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateServiceData } from "../../../store/actions/MasterActions";
import { AppDispatch, RootState } from "../../../store";
import { Column } from "../../../ts/types";
import Service from "../../../ts/models/Service";
import SearchBar from "../../../components/SearchBar/SearchBar";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import AddService from "./AddService";
import Table from "../../../components/CustTable/Table";
import PanelContainer from "../../../components/Containers/PanelContainer";
import TableContainer from "../../../components/CustTable/TableContainer";
import classes from "./Services.module.css";
import LinkButton from "../../../components/Buttons/LinkButton";
import ActionContainer from "../../../components/CustTable/ActionContainer";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import PanelActionContainer from "../../../components/Containers/PanelActionContainer";
import addIcon from "../../../assets/icons/add-circle-white.svg";

const Services = () => {
  const servicesData = useSelector(
    (state: RootState) => state.masters.services
  );
  const dispatch: AppDispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [showServiceModal, setShowServiceModal] = useState<{
    show: boolean;
    isEdit: boolean;
    prevData?: Service;
  }>({
    show: false,
    isEdit: false,
  });

  const serviceTableColumn: Column<Service>[] = [
    {
      header: "ID",
      accessor: (e, idx) => idx! + 1,
      width: "10%",
    },
    {
      header: "CODE",
      accessor: (e) => e.code,
      width: "10%",
    },
    {
      header: "NAME",
      accessor: (e) => e.name,
      width: "20%",
    },
    {
      header: "DESCRIPTION",
      accessor: (e) => e.description,
    },
    {
      header: "REPORT",
      accessor: (e) => (e.isReportableToAgency ? "YES" : "NO"),
      style: { textAlign: "center" },
      width: "10%",
    },
    {
      header: "ACTIVE",
      accessor: (e) => (e.isActive ? "YES" : "NO"),
      style: { textAlign: "center" },
      width: "10%",
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <ActionContainer>
          <LinkButton
            onClick={() => editMasterHandler(e)}
            label='Edit'
            className={classes.editAction}
          />
          <LinkButton
            onClick={() => updateStatusHandler(e.id, !e.isActive)}
            label={e.isActive ? "De-activate" : "Activate"}
          />
        </ActionContainer>
      ),
      width: "15%",
    },
  ];

  const updateStatusHandler = (id: string, isActive: boolean) => {
    dispatch(updateServiceData({ id, isActive }));
  };

  const addMasterHandler = () => {
    setShowServiceModal({ show: true, isEdit: false });
  };

  const editMasterHandler = (data: Service) => {
    setShowServiceModal({ show: true, isEdit: true, prevData: data });
  };

  const closeHandler = () => {
    setShowServiceModal({ show: false, isEdit: false });
  };

  const filteredService = servicesData.filter(
    (service) =>
      service.description.toLowerCase().includes(searchText.toLowerCase()) ||
      service.code.toLowerCase().includes(searchText.toLowerCase()) ||
      service.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      {showServiceModal.show && (
        <AddService
          onClose={closeHandler}
          isEdit={showServiceModal.isEdit}
          prevData={showServiceModal.prevData}
        />
      )}
      <PanelContainer name='Medical Services' backBtn>
        <PanelActionContainer
          leftChildren={
            <SearchBar
              onChange={(text) => {
                setSearchText(text);
              }}
            />
          }
          rightChildren={
            <PrimaryButton
              name='Add New'
              onClick={addMasterHandler}
              disabled={false}
              icon={<img src={addIcon} alt='' />}
            />
          }
        />
        <PanelInnerContainer>
          <TableContainer>
            <Table columns={serviceTableColumn} data={filteredService} />
          </TableContainer>
        </PanelInnerContainer>
      </PanelContainer>
    </>
  );
};

export default Services;

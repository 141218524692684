import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { uploadHelpDocuments } from "../../../services/masters";
import { navigationBack } from "../../../utils";
import Card from "../../../components/Card/Card";
import PanelContainer from "../../../components/Containers/PanelContainer";
import PanelInnerContainer from "../../../components/Containers/PanelInnerContainer";
import DragDropField from "../../../components/Inputs/DragDropField";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import classes from "./UploadHelpDocument.module.css";

type Props = {};

const UploadHelpDocument: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [podHelp, setPodHelp] = useState<any>(null);
  const [eventHelp, setEventHelp] = useState<any>(null);
  const [billingReportHelp, setBillingReportHelp] = useState<any>(null);
  const [userHelp, setUserHelp] = useState<any>(null);

  const handleBack = () => {
    navigationBack(location, navigate);
  };
  const uploadHelpHTMLDocuments = async () => {
    const formData = new FormData();
    if (podHelp) {
      formData.append("podHelp", podHelp);
    }
    if (eventHelp) {
      formData.append("eventHelp", eventHelp);
    }
    if (billingReportHelp) {
      formData.append("billingReportHelp", billingReportHelp);
    }
    if (userHelp) {
      formData.append("userHelp", userHelp);
    }

    try {
      const res = await uploadHelpDocuments(formData);
      if (res.status) {
        handleBack();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isRequiredDataMissing = () => {
    if (!!podHelp || !!eventHelp || !!billingReportHelp || !!userHelp) {
      return false;
    }
    return true;
  };

  return (
    <PanelContainer name='Help' backBtn>
      <PanelInnerContainer>
        <Card style={{ flex: 1 }}>
          <DragDropField
            className={classes.dragDropField}
            iconClassName={classes.uploadIcon}
            name='POD Help HTML'
            onChange={(file: any) => {
              setPodHelp(file);
            }}
            fileTypes={["html"]}
            selected={!!podHelp}>
            <div className={classes.docName}>{podHelp?.name}</div>
          </DragDropField>
          <DragDropField
            className={classes.dragDropField}
            iconClassName={classes.uploadIcon}
            name='Event Help HTML'
            onChange={(file: any) => {
              setEventHelp(file);
            }}
            fileTypes={["html"]}
            selected={!!eventHelp}>
            <div className={classes.docName}>{eventHelp?.name}</div>
          </DragDropField>
          <DragDropField
            className={classes.dragDropField}
            iconClassName={classes.uploadIcon}
            name='Billing Report Help HTML'
            onChange={(file: any) => {
              setBillingReportHelp(file);
            }}
            fileTypes={["html"]}
            selected={!!billingReportHelp}>
            <div className={classes.docName}>{billingReportHelp?.name}</div>
          </DragDropField>
          <DragDropField
            className={classes.dragDropField}
            iconClassName={classes.uploadIcon}
            name='User Help HTML'
            onChange={(file: any) => {
              setUserHelp(file);
            }}
            fileTypes={["html"]}
            selected={!!userHelp}>
            <div className={classes.docName}>{userHelp?.name}</div>
          </DragDropField>
          <div className={classes.actions}>
            <PrimaryButton
              className={classes.actionBtn}
              name={"Upload"}
              onClick={uploadHelpHTMLDocuments}
              style={{ marginRight: "1rem" }}
              disabled={isRequiredDataMissing()}
            />
            <PrimaryButton
              className={classes.actionBtn}
              name='Cancel'
              onClick={handleBack}
              transparent
            />
          </div>
        </Card>
      </PanelInnerContainer>
    </PanelContainer>
  );
};

export default UploadHelpDocument;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addDays } from "date-fns";

import {
  downloadFile,
  getActivityType,
  getEndOfDayTimestamp,
  getLocalDateString,
  getLogDateTime,
  getStartOfDayTimestamp,
  getUserFullName,
} from "../../utils";
import { AppDispatch, RootState } from "../../store";
import { UserRole } from "../../ts/enums";
import { Column, Encounter, LogData, PatientReportData } from "../../ts/types";
import { downloadLogs, fetchLogs, fetchLogsCount } from "../../services/logs";
import { getUsers } from "../../store/actions/UserActions";
import { fetchPatients } from "../../services/patients";
import MultiSelect, {
  MultiSelectOption,
} from "../../components/Inputs/MultiSelect";
import { fetchEncounters } from "../../services/orders";
import { fetchEvents } from "../../services/events";
import Card from "../../components/Card/Card";
import PanelContainer from "../../components/Containers/PanelContainer";
import classes from "./LogViewer.module.css";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import TableContainer from "../../components/CustTable/TableContainer";
import Table from "../../components/CustTable/Table";
import Input from "../../components/Inputs/Input";
import Select from "../../components/Inputs/Select";
import Patient from "../../ts/models/Patient";
import Event from "../../ts/models/Event";
import LinkButton from "../../components/Buttons/LinkButton";
import Drawer from "../../components/Drawer/Drawer";
import Order from "../../ts/models/Order";
import PatientDataDrawer from "./PatientDataDrawer";

const pageSize = 20;

const filterOptions = [
  { name: "Activity Type" },
  { name: "Users" },
  { name: "Patients" },
];

const LogViewer = () => {
  const dispatch: AppDispatch = useDispatch();
  const { users } = useSelector((state: RootState) => state.users);

  const [currPageNo, setCurrPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [skipCount, setSkipCount] = useState(true);
  const [startDate, setStartDate] = useState(
    getStartOfDayTimestamp(
      getLocalDateString(addDays(new Date(), -6).getTime())
    )
  );
  const [endDate, setEndDate] = useState(
    getEndOfDayTimestamp(getLocalDateString(Date.now()))
  );
  const [selectedFilter, setSelectedFilter] = useState("Activity Type");
  const [patientName, setPatientName] = useState("");
  const [logData, setLogData] = useState<LogData[]>([]);
  const [patientData, setPatientData] = useState<Patient[]>([]);
  const [selectedActivities, setSelectedActivities] = useState<
    MultiSelectOption[]
  >([]);
  const [selectedUsers, setSelectedUsers] = useState<MultiSelectOption[]>([]);
  const [activities, setActivities] = useState<string[]>([]);
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const [showReport, setShowReport] = useState<
    | { show: false }
    | {
        show: true;
        patientData: PatientReportData;
        event: Event;
        checkInUpdateTime?: number;
        updatedPatientData?: Patient;
      }
  >({
    show: false,
  });

  useEffect(() => {
    if (skipCount) {
      setSkipCount(false);
    } else {
      fetchLogsData();
    }
  }, [currPageNo]);

  const filterHandler = () => {
    setCurrPageNo(1);
    fetchLogsData();
  };

  const getLogsFilter = () => {
    const patientIds = patientData.map((patient) => patient.id);

    const filter: any = {
      limit: pageSize,
      offset: (currPageNo - 1) * pageSize,
      where: {
        time: {
          $gte: startDate,
          $lte: endDate,
        },
      },
      sortBy: "time DESC",
    };

    if (activities.length) {
      // Only send if all activities aren't selected.
      if (activities[0] !== "*") {
        filter.where.activityType = { $in: activities };
      }
    } else if (userEmails.length) {
      // Only send if all users aren't selected.
      if (userEmails[0] !== "*") {
        filter.where.user = { $in: userEmails };
      }
    } else if (patientIds.length) {
      filter.where.patientId = { $in: patientIds };
    }

    return filter;
  };

  const fetchLogsData = async () => {
    const filter = getLogsFilter();

    try {
      const [logRes, countRes] = await Promise.all([
        fetchLogs(filter),
        fetchLogsCount(filter.where),
      ]);
      if (logRes?.status === 200) {
        setLogData(logRes.data);
      }
      if (countRes?.status === 200) {
        const noOfPages = Math.ceil(Number(countRes.data.count) / pageSize);
        setTotalPages(noOfPages);
      } else {
        setTotalPages(0);
        setCurrPageNo(1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUsersData = async () => {
    const filter: any = {
      limit: pageSize,
      offset: 0,
      where: {
        role: {
          $ne: UserRole.SuperAdmin,
        },
      },
    };
    dispatch(getUsers(filter));
  };

  useEffect(() => {
    if (selectedFilter === "Users" && users.length === 0) {
      fetchUsersData();
    }
  }, [selectedFilter]);

  const fetchPatientData = async () => {
    const filter: any = {
      where: {
        fullName: {
          $regex: `.*${patientName}.*`,
          $options: "i",
        },
      },
    };

    try {
      const patientRes = await fetchPatients(filter);
      if (patientRes.status === 200) {
        setPatientData(patientRes.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (patientName) {
      const timeout = setTimeout(() => {
        fetchPatientData();
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [patientName]);

  const getActivity = (activityType: string, masterType: string) => {
    if (masterType) {
      let masterName = masterType;
      if (masterType === "MedicalAllergy") {
        // Medical Allergy entity is called Medication Allergy in the UI.
        masterName = "MedicationAllergy";
      }

      // We store the activity as UPDATE_MASTER and master name as Gender for example.
      // Here we combine the two and show as UPDATE_GENDER.
      const index = activityType.indexOf("_");
      if (index !== -1) {
        return `${activityType.slice(0, index)}_${masterName.toUpperCase()}`;
      }
      return activityType;
    } else {
      return activityType;
    }
  };

  const logsTableColumns: Column<LogData>[] = [
    {
      header: "ID",
      accessor: (_, idx) => (currPageNo - 1) * pageSize + idx! + 1,
      width: "8%",
    },
    {
      header: "USER",
      accessor: (e) => e.user,
      width: "15%",
    },
    {
      header: "ROLE",
      accessor: (e) => e.role,
      width: "10%",
    },
    {
      header: "TIME",
      accessor: (e) => getLogDateTime(e.time),
      width: "15%",
    },
    {
      header: "STATUS",
      accessor: (e) => (e.status ? "SUCCESS" : "FAILED"),
      width: "10%",
    },
    {
      header: "ACTIVITY",
      accessor: (e) => getActivity(e.activityType, e.masterType),
      width: "15%",
    },
    {
      header: "DATA",
      accessor: (e) => <div className={classes.data}>{e.data}</div>,
      width: "15%",
    },
    {
      header: "DEVICE",
      accessor: (e) => (
        <div>
          {`${e.device.platform}, ${e.device.deviceType}, ${e.device.browser},
          ${e.device.remoteAddr}`}
        </div>
      ),
      width: "15%",
    },
    {
      header: "ACTION",
      accessor: (e) => (
        <div>
          {(e.encounterId || e.patientData?.id) && (
            <LinkButton
              onClick={() => viewDetailsHandler(e)}
              label='View Details'
            />
          )}
        </div>
      ),
    },
  ];

  const isFilterDisabled = () => {
    if (
      activities.length > 0 ||
      userEmails.length > 0 ||
      (patientName.trim() && patientData.length > 0)
    ) {
      return false;
    }
    return true;
  };

  const changeOptionHandler = (option: string) => {
    setSelectedFilter(option);
    setSelectedActivities([]);
    setActivities([]);
    setSelectedUsers([]);
    setUserEmails([]);
    setPatientName("");
    setPatientData([]);
  };

  const onActivityChange = (selectedOptions: MultiSelectOption[]) => {
    const activities = selectedOptions.map(
      (selectedOption) => selectedOption.value
    );

    setSelectedActivities(selectedOptions);
    setActivities(activities);
  };

  const onUsersChange = (selectedOptions: MultiSelectOption[]) => {
    const usersEmails = selectedOptions.map(
      (selectedOption) => selectedOption.value
    );

    setSelectedUsers(selectedOptions);
    setUserEmails(usersEmails);
  };

  const userOptions = users.map((user) => ({
    value: user.email,
    label: getUserFullName(user),
  }));

  const viewDetailsHandler = async (logData: LogData) => {
    let order: Order = new Order();
    let encounter: Encounter = logData.encounterData;
    let eventId: string = "";
    let checkInUpdateTime: number;
    let updatedPatientData: Patient = logData.patientData;

    if (logData.encounterId) {
      try {
        const encounterRes = await fetchEncounters({
          id: logData.encounterId,
        });
        if (encounterRes.status === 200) {
          eventId = encounterRes.data[0].eventId;
          checkInUpdateTime = encounterRes.data[0].checkIn.updateTime;
        }
      } catch (error) {
        console.log(error);
      }

      const fetchPatientAndEventData = async () => {
        try {
          const [eventRes, patientRes] = await Promise.all([
            fetchEvents({ id: eventId }),
            fetchPatients({ id: logData.patientId }),
          ]);

          if (eventRes.status === 200 && patientRes.status === 200) {
            setShowReport({
              show: true,
              patientData: { patient: patientRes.data[0], order, encounter },
              event: eventRes.data[0],
              checkInUpdateTime: checkInUpdateTime,
              updatedPatientData: updatedPatientData,
            });
          }
        } catch (err) {
          console.log(err);
        }
      };
      if (encounter?.id) {
        fetchPatientAndEventData();
      }
    }

    if (logData.patientData?.id) {
      try {
        const patientRes = await fetchPatients({ id: logData.patientId });
        if (patientRes.status === 200) {
          setShowReport({
            show: true,
            patientData: { patient: patientRes.data[0], order, encounter },
            event: new Event(),
            updatedPatientData: updatedPatientData,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getLogsFileName = () => {
    let fileName = `Logs_${getLocalDateString(startDate)}_${getLocalDateString(
      endDate
    )}.csv`;
    return fileName.replaceAll("-", "_");
  };

  const downloadLogHandler = async () => {
    const filter = getLogsFilter();
    delete filter.limit;
    delete filter.offset;
    const timezoneOffset = new Date().getTimezoneOffset() * -1;

    if (filter) {
      try {
        const res = await downloadLogs(filter, timezoneOffset.toString());
        downloadFile(res, getLogsFileName());
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {showReport.show && (
        <Drawer
          name='Patient Data'
          onClose={() => setShowReport({ show: false })}>
          <PatientDataDrawer
            event={showReport.event}
            patientReportData={showReport.patientData}
            checkInUpdateTime={showReport.checkInUpdateTime}
            updatedPatientData={showReport.updatedPatientData}
          />
        </Drawer>
      )}
      <PanelContainer name='Logs'>
        <Card>
          <div className={classes.filterContainer}>
            <div className={classes.filters}>
              <Input
                type='date'
                name='Start Date'
                value={getLocalDateString(startDate || Date.now())}
                onChange={(e) => {
                  const startDate = getStartOfDayTimestamp(e.target.value);
                  setStartDate(startDate);
                  if (startDate > endDate)
                    setEndDate(getEndOfDayTimestamp(e.target.value));
                }}
                className={classes.input}
              />
              <Input
                type='date'
                name='End Date'
                value={getLocalDateString(endDate || Date.now())}
                onChange={(e) =>
                  setEndDate(getEndOfDayTimestamp(e.target.value))
                }
                min={getLocalDateString(startDate)}
                className={classes.input}
              />
              <Select
                label='Filter By'
                htmlFor='filter'
                options={filterOptions}
                nameExtractor='name'
                defaultOption='Select Type'
                value={selectedFilter}
                onChange={(e) => changeOptionHandler(e.target.value)}
                selectClass={classes.select}
              />
              {selectedFilter === "Activity Type" && (
                <div className={classes.selectFilter}>
                  <MultiSelect
                    label='Activity'
                    onChange={onActivityChange}
                    value={selectedActivities}
                    options={getActivityType}
                    isAll
                  />
                </div>
              )}
              {selectedFilter === "Users" && (
                <div className={classes.selectFilter}>
                  <MultiSelect
                    label='Users'
                    onChange={onUsersChange}
                    value={selectedUsers}
                    options={userOptions}
                    isAll
                  />
                </div>
              )}
              {selectedFilter === "Patients" && (
                <Input
                  name='Patient Name'
                  placeholder='Enter Patient Name'
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                  className={classes.input}
                />
              )}
            </div>
            <div className={classes.actionBtn}>
              <PrimaryButton
                name='Filter'
                onClick={filterHandler}
                disabled={isFilterDisabled()}
              />
              <PrimaryButton
                name='Download'
                onClick={downloadLogHandler}
                disabled={isFilterDisabled()}
              />
            </div>
          </div>
        </Card>
        <div className={classes.tableContainer}>
          <TableContainer>
            <Table
              columns={logsTableColumns}
              data={logData}
              isPaginated
              pageNo={currPageNo}
              totalPages={totalPages}
              onPrevClick={() => setCurrPageNo((state) => state - 1)}
              onNextClick={() => setCurrPageNo((state) => state + 1)}
            />
          </TableContainer>
        </div>
      </PanelContainer>
    </>
  );
};

export default LogViewer;
